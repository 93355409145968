import DeleteBtn from 'common/components/DeleteBtn';
import { ContextForm } from 'data/forms/advanced/contexts/context_form';
import { WazoDidRange } from 'data/misc/wazo_range';
import { EditCtx } from 'hooks/edit_hook';
import { ListValue, useFormListPropEdit } from 'hooks/list_edit_hook';
import React from 'react';
import ContextIncallRangeForm from './ContextIncallRangeForm';

export interface Props {
    valueEdit: EditCtx<ContextForm>;
}

const ContextIncallRangesForm: React.FC<Props> = (props) => {
    const [form, setFormValue] = props.valueEdit;

    const linesEditCtx = useFormListPropEdit<WazoDidRange>("incall_ranges", form.incall_ranges ?? [], setFormValue);

    return (
        <>
            <ContextIncallRangeForm saveValue={(p: WazoDidRange) => {
                linesEditCtx.addValue(p);
            }} />
            {linesEditCtx.values.map((v: ListValue<WazoDidRange>, index: number) => (
                <ContextIncallRangeForm
                    formKeyPath={`incall_ranges.${index}`}
                    key={v.index}
                    range={v.value}
                    saveValue={(p: WazoDidRange) => {
                        linesEditCtx.editValue({
                            index: v.index,
                            value: p,
                        });
                    }}
                >
                    <DeleteBtn
                        style={{ top: "0.75rem", right: "0.5rem" }}
                        onClick={() => linesEditCtx.removeValue(index)}
                    ></DeleteBtn>
                </ContextIncallRangeForm>
            ))}
        </>
    )
}

export default ContextIncallRangesForm
