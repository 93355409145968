import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import FormTab from 'components/misc/Tab';
import FormTabs from 'components/misc/Tabs';
import { AorOptions, AuthOptions, EndpointOptions, IdentifyOptions, OutboundAuthOptions, RegistrationOptions, RegistrationOutboundAuthOptions } from 'data/endpoints/sip_key_options/sip_key_options';
import { EndpointSIPForm } from 'data/forms/endpoints/endpoint_sip_form';
import { anyEmptyInList } from 'data/misc/wazo_list';
import { ReduceFn, Setter } from 'hooks/edit_hook';
import React, { PropsWithChildren, useState } from 'react';
import EndpointKeyValuesForm from './EndpointKeyValuesForm';

export interface Props extends FormKeyProps {
    endpoint: EndpointSIPForm;
    setter: Setter;
    reducer: ReduceFn;
    errorGeneral?: boolean;
}

const EndpointSIPFormComponent: React.FC<PropsWithChildren<Props>> = (props) => {
    const {setter: setForm, endpoint: form} = props;
    const [tab, setTab] = useState("general");

    return (
        <>
            <FormTabs style={{ marginTop: "1rem" }} tab={tab} setTab={setTab}>
                <FormTab value={"general"} label={"General"} error={tab !== "general" && props.errorGeneral} />
                <FormTab value={"aor"} label={"AOR"} error={tab !== "aor" && anyEmptyInList(form.aor_section_options)} />
                <FormTab value={"auth"} label={"Authentication"} error={tab !== "auth" && anyEmptyInList(form.auth_section_options)} />
                <FormTab value={"endpoint"} label={"Endpoint"} error={tab !== "endpoint" && anyEmptyInList(form.endpoint_section_options)} />
                <FormTab value={"identify"} label={"Identify"} error={tab !== "identify" && anyEmptyInList(form.identify_section_options)} />
                <FormTab value={"reg"} label={"Registration"} error={tab !== "reg" && anyEmptyInList(form.registration_section_options)} />
                <FormTab value={"regoutbound"} label={"Registration outbound auth"} error={tab !== "regoutbound" && anyEmptyInList(form.registration_outbound_auth_section_options)}  />
                <FormTab value={"outbound"} label={"Outbound auth"} error={tab !== "outbound" && anyEmptyInList(form.outbound_auth_section_options)} />
            </FormTabs>
            <Card>
                <CardBody noTopBorderRadius noShadow>
                    {tab === "general" &&
                        <>
                            {props.children}
                        </>
                    }
                    {tab === "aor" &&
                        <EndpointKeyValuesForm
                            options={AorOptions}
                            wazolist={form.aor_section_options}
                            onChange={(v) => setForm("aor_section_options", v)}
                        />
                    }
                    {tab === "auth" &&
                        <EndpointKeyValuesForm
                            options={AuthOptions}
                            wazolist={form.auth_section_options}
                            onChange={(v) => setForm("auth_section_options", v)}
                        />
                    }
                    {tab === "endpoint" &&
                        <EndpointKeyValuesForm
                            options={EndpointOptions}
                            wazolist={form.endpoint_section_options}
                            onChange={(v) => setForm("endpoint_section_options", v)}
                        />
                    }
                    {tab === "identify" &&
                        <EndpointKeyValuesForm
                            options={IdentifyOptions}
                            wazolist={form.identify_section_options}
                            onChange={(v) => setForm("identify_section_options", v)}
                        />
                    }
                    {tab === "reg" &&
                        <EndpointKeyValuesForm
                            options={RegistrationOptions}
                            wazolist={form.registration_section_options}
                            onChange={(v) => setForm("registration_section_options", v)}
                        />
                    }
                    {tab === "regoutbound" &&
                        <EndpointKeyValuesForm
                            options={RegistrationOutboundAuthOptions}
                            wazolist={form.registration_outbound_auth_section_options}
                            onChange={(v) => setForm("registration_outbound_auth_section_options", v)}
                        />
                    }
                    {tab === "outbound" &&
                        <EndpointKeyValuesForm
                            options={OutboundAuthOptions}
                            wazolist={form.outbound_auth_section_options}
                            onChange={(v) => setForm("outbound_auth_section_options", v)}
                        />
                    }
                </CardBody>
            </Card>
        </>
    )
}

export default EndpointSIPFormComponent
