import { MenuItem } from '@mui/material';
import Selectfield from 'common/fields/Selectfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import { NoRowPadding } from 'common/layout/Row';
import { HangupDestination } from 'data/misc/destinations';
import { useEdit } from 'hooks/edit_hook';
import React, { useContext, useEffect } from 'react';
import { ErrorContext, FormKeyProps } from '../providers/ErrorCtx';

export interface Props extends FormKeyProps {
    value: HangupDestination | undefined;
    onEdit: (d: HangupDestination) => void;
}

export const HangupLabels = {
    "normal": "Normal",
    "busy": "Occupé",
    "congestion": "Congestion",
};

const HangupDestinationForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [value, setValue, reduceValue] = useEdit({
        type: "hangup",
        cause: props.value?.cause ?? "normal",
    } as HangupDestination);

    useEffect(() => {
        reduceValue({
            cause: props.value?.cause ?? "normal",
        });
    }, [props.value]);

    useEffect(() => {
        props.onEdit(value);
    }, [value]);

    return (
        <>
            <LargeRow style={NoRowPadding}>
                <LeftColumn>
                    <Selectfield
                        label={"Raison"}
                        error={errorCtx.getError("cause", props.formKeyPath)}
                        value={value?.cause ?? "normal"}
                        name={"cause"}
                        editValue={setValue}
                    >
                        <MenuItem value={"normal"}>Normal</MenuItem>
                        <MenuItem value={"busy"}>Occupé</MenuItem>
                        <MenuItem value={"congestion"}>Congestion</MenuItem>
                    </Selectfield>
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default HangupDestinationForm
