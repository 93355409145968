import { CallerIDMode, GroupUserRef, RingGroup, RingStrategy } from "data/users/ring_groups/ring_group";
import { FallbacksForm, fallbacksToForm } from "../fallbacks_form";
import { ScheduleForm, scheduleFormToBody, scheduleToForm } from "../schedules/schedule_form";

export interface RingGroupForm {
    id?: number;
    uuid?: string;

    name?: string
    label: string;

    caller_id_mode?: CallerIDMode;
    caller_id_name?: string;

    mark_answered_elsewhere?: boolean;
    retry_delay?: number;
    ring_in_use?: boolean;
    ring_strategy?: RingStrategy;
    timeout?: number;
    user_timeout?: number;
    fallbacks: FallbacksForm;

    users?: GroupUserRef[];

    schedule?: ScheduleForm;
}

export const defaultRingGroupForm = (): RingGroupForm => {
    return {
        label: "",
        ring_strategy: "all",
        timeout: 30,
        retry_delay: 20,
        user_timeout: 15,
        fallbacks: { noanswer_destination: { type: "none" } },
    };
};

export const ringGroupToForm = (ringGroup: RingGroup): RingGroupForm => {
    return {
        id: ringGroup.id,
        uuid: ringGroup.uuid,
        name: ringGroup.name,
        label: ringGroup.label,
        caller_id_mode: ringGroup.caller_id_mode,
        caller_id_name: ringGroup.caller_id_name,
        mark_answered_elsewhere: ringGroup.mark_answered_elsewhere,
        retry_delay: ringGroup.retry_delay,
        ring_in_use: ringGroup.ring_in_use,
        ring_strategy: ringGroup.ring_strategy,
        timeout: ringGroup.timeout,
        user_timeout: ringGroup.user_timeout,
        fallbacks: ringGroup.fallbacks ? fallbacksToForm(ringGroup.fallbacks) : { noanswer_destination: { type: "none" } },
        users: ringGroup.users,
        schedule: ringGroup.schedule_details ? scheduleToForm(ringGroup.schedule_details) : undefined,
    };
};

export const ringGroupFormToBody = (ringGroup: RingGroupForm): object => {
    return {
        ...ringGroup,
        schedule: ringGroup.schedule ? scheduleFormToBody(ringGroup.schedule) : undefined,
    };
};
