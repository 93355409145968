import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import IconTooltip from 'common/components/Tooltip';
import Passwordfield from 'common/fields/Passwordfield';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultTrunkCreationForm, TrunkCreationForm } from 'data/forms/routes/trunks/trunk_creation_form';
import { TrunkTypeLabels, TrunkTypes } from 'data/routes/trunks/trunk';
import { NumericFormat } from 'formats/common';
import { useEdit } from 'hooks/edit_hook';
import React, { useContext, useEffect } from 'react';

export interface Props extends FormKeyProps {
    form?: TrunkCreationForm;
    onChange: (v: TrunkCreationForm) => void;
}

const TrunkCreationFormComponent: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const formEdit = useEdit<TrunkCreationForm>(props.form ?? defaultTrunkCreationForm());
    const [form, setForm] = formEdit;

    useEffect(() => {
        props.onChange(form);
    }, [form]);

    return (
        <>
            <Row>
                <LeftColumn>
                    <Selectfield
                        required
                        label={"Type"}
                        error={errorCtx.getError("trunk_type", props.formKeyPath)}
                        value={form.trunk_type}
                        name={"trunk_type"}
                        editValue={setForm}
                        options={TrunkTypes.map(v => {
                            return {
                                id: v as string,
                                label: TrunkTypeLabels[v],
                            };
                        })}
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Nom"}
                        error={errorCtx.getError("username", props.formKeyPath)}
                        value={form.username}
                        name={"username"}
                        editValue={setForm}
                    />
                </LeftColumn>
                {form.trunk_type === "sip" &&
                    <RightColumn>
                        <Textfield
                            label={"Étiquette"}
                            error={errorCtx.getError("label", props.formKeyPath)}
                            value={form.label}
                            name={"label"}
                            editValue={setForm}
                        />
                    </RightColumn>
                }
            </Row>
            <Row>
                <LeftColumn>
                    <Passwordfield
                        label={"Mot de passe"}
                        error={errorCtx.getError("password", props.formKeyPath)}
                        value={form.password}
                        name={"password"}
                        editValue={setForm}
                        EndAdornment={
                            < IconTooltip
                                variant="h6"
                                icon={faQuestionCircle}
                                tooltip={"Un mot de passe sera automatiquement atribué si aucun n'est assigné"}
                            />
                        }
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Hôte"}
                        error={errorCtx.getError("host", props.formKeyPath)}
                        value={form.host}
                        name={"host"}
                        editValue={setForm}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Port"}
                        error={errorCtx.getError("port", props.formKeyPath)}
                        value={form.port}
                        name={"port"}
                        editValue={setForm}
                        format={NumericFormat}
                    />
                </RightColumn>
            </Row>
        </>
    )
};

export default TrunkCreationFormComponent
