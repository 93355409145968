export interface FilterResult<T> {
    filtered?: number;
    total: number;
    items: T[];
}

export const defaultFilterResult = () => {
    return {
        total: 0,
        items: [],
    };
};
