import { ReduceFn, Setter, useEdit } from "hooks/edit_hook";
import { useCallback, useState } from "react";

export interface ListValueFormCtx<T> {
    form: T;
    isEditing: boolean;
    setValue: Setter;
    reduceValue: ReduceFn;
    editSetForm: Setter;
    editReduceForm: ReduceFn;
    saveValue: () => void;
    reset: (value?: {[key: string]: any}) => void;
}

export const useListValueForm = <T,>(initialValue: T, saveValue: (v: T) => void): ListValueFormCtx<T> => {
    const [form, setForm, reduceForm] = useEdit(initialValue);
    const [editing, setEditing] = useState(false);

    const setValue = useCallback((k: string, v: any) => {
        setEditing(true);
        setForm(k, v);
    }, [setForm]);

    const reduceValue = useCallback((v: {[key: string]: any}) => {
        setEditing(true);
        reduceForm(v);
    }, [reduceForm]);

    const reset = useCallback((value?: {[key: string]: any}) => {
        setEditing(false);

        reduceForm({
            ...(initialValue as any),
            ...value,
        });
    }, [reduceForm, initialValue]);

    const save = useCallback(() => {
        saveValue(form);
        setEditing(false);
    }, [form, saveValue]);

    return {
        form: form,
        isEditing: editing,
        saveValue: save,
        setValue: setValue,
        reduceValue: reduceValue,
        reset: reset,
        editReduceForm: reduceForm,
        editSetForm: setForm,
    }
};
