import { LineEndpointSIP } from "data/endpoints/line_endpoint_sip";
import { WazoRef } from "data/misc/wazo_ref";

export type LineType = "softphone"|"device"|"other"|"teams";

export const LineTypes: {[k: string]: string} = {
    "softphone": "Softphone",
    "device": "Appareil",
    "other": "Autre",
    "teams": "MS Teams",
};

export interface Line {
    id?: number;
    context: string;

    endpoint_sip?: LineEndpointSIP;
    exten: string;
    exten_ref?: WazoRef;
    users?: WazoRef[];

    device_id?: string;
    line_type: LineType;
    is_main: boolean;

    dialstring?: string;
}
