import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import { NoRowPadding } from 'common/layout/Row';
import { ExtensionDestination } from 'data/misc/destinations';
import { NumExtenFormat } from 'formats/common';
import { useEdit } from 'hooks/edit_hook';
import React, { useContext, useEffect } from 'react';
import { getDefaultContext } from '../providers/ContextsCtx';
import { DestinationContext } from '../providers/DestinationFormContext';
import { ErrorContext, FormKeyProps } from '../providers/ErrorCtx';

export interface Props extends FormKeyProps {
    value: ExtensionDestination | undefined;
    onEdit: (d: ExtensionDestination) => void;
}

const ExtensionDestinationForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const destinationCtx = useContext(DestinationContext);
    const [value, setValue, reduceValue] = useEdit({
        type: "extension",
        exten: props.value?.exten ?? "",
        context: props.value?.context ?? getDefaultContext(destinationCtx.contexts)?.id,
    } as ExtensionDestination);

    useEffect(() => {
        reduceValue({
            exten: props.value?.exten ?? "",
            context: props.value?.context ?? value.context,
        });
    }, [props.value]);

    useEffect(() => {
        props.onEdit(value);
    }, [value]);

    return (
        <>
            <LargeRow style={NoRowPadding}>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Numéro"}
                        error={errorCtx.getError("exten", props.formKeyPath)}
                        value={value?.exten}
                        name={"exten"}
                        editValue={setValue}
                        format={NumExtenFormat}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default ExtensionDestinationForm
