import { faArrowDownWideShort, faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useTheme } from '@mui/material';
import React, { PropsWithChildren, useCallback } from 'react'
import GridRow from '../layout/GridRow';
import GridColumn from '../layout/GridColumn';
import Shadow from '../Shadow';
import { StyledHeaderElement } from './ListHeaderDisplay.styled';
import { ReduceHandler } from 'hooks/list_hook';

export const toggleListOrder = (order: FilterDirection) => {
    return order === 'asc' ? 'desc':'asc';
}

export type FilterDirection = 'asc'|'desc';

export interface ListHeaderProps extends PropsWithChildren {
    templateColumns: string;
}

export const ListHeader: React.FC<ListHeaderProps> = (props) => {
    const theme = useTheme();

    return (
    <Shadow>
        <GridRow templateColumns={props.templateColumns} style={{
            backgroundColor: theme.palette.background.card,
            color: theme.palette.text.primary,
            margin: 0,
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem",
        }}>
            {props.children}
        </GridRow>
    </Shadow>
    );
}

export interface ListHeaderElementProps extends PropsWithChildren {
    isCurrent?: boolean;
    order?: string;
    direction?: FilterDirection;
    setOrder?: ReduceHandler;
}

export const ListHeaderElement: React.FC<ListHeaderElementProps> = (props) => {
    const onClick = useCallback(() => {
        if (!props.setOrder || !props.order) {
            return;
        }

        if (!props.isCurrent) {
            props.setOrder({
                "order": props.order,
                "direction": "asc",
            });
            return
        }

        props.setOrder({
            "direction": toggleListOrder(props.direction ?? "desc")
        });
    }, [props.isCurrent, props.setOrder, props.order, props.direction]);

    return (
        <StyledHeaderElement notInteractable={!props.setOrder}>
            <GridColumn onClick={() => onClick()} align='left'>
                <Typography variant='body1' color="secondary" style={{fontWeight: 600}}>
                    {props.children}
                </Typography>
                { props.isCurrent &&
                    <FontAwesomeIcon
                        style={{marginLeft: "1rem"}}
                        icon={props.direction === "asc" ? faArrowUpWideShort:faArrowDownWideShort}
                    ></FontAwesomeIcon>
                }
            </GridColumn>
        </StyledHeaderElement>
    );
}

export default ListHeaderElement;
