import DeleteBtn from 'common/components/DeleteBtn';
import { OutboundRouteForm } from 'data/forms/routes/outbound/outbound_route_form';
import { PatternForm } from 'data/forms/routes/outbound/pattern_form';
import { EditCtx } from 'hooks/edit_hook';
import { ListValue, useFormListPropEdit } from 'hooks/list_edit_hook';
import React from 'react';
import OutboundRoutePatternForm from './OutboundRoutePatternForm';

export interface Props {
    valueEdit: EditCtx<OutboundRouteForm>;
}

const OutboundRoutePatternsForm: React.FC<Props> = (props) => {
    const [form, setFormValue] = props.valueEdit;

    const linesEditCtx = useFormListPropEdit<PatternForm>("extensions", form.extensions, setFormValue);

    return (
        <>
            <OutboundRoutePatternForm showTooltips saveValue={(p: PatternForm) => {
                linesEditCtx.addValue(p);
            }} />
            {linesEditCtx.values.map((v: ListValue<PatternForm>, index: number) => (
                <OutboundRoutePatternForm
                    formKeyPath={`extensions.${index}`}
                    key={v.index}
                    pattern={v.value}
                    saveValue={(p: PatternForm) => {
                        linesEditCtx.editValue({
                            index: v.index,
                            value: p,
                        });
                    }}
                >
                    <DeleteBtn
                        style={{ top: "0.75rem", right: "0.5rem" }}
                        onClick={() => linesEditCtx.removeValue(index)}
                    ></DeleteBtn>
                </OutboundRoutePatternForm>
            ))}
        </>
    )
}

export default OutboundRoutePatternsForm
