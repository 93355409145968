import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react"

export interface Props extends PropsWithChildren {
    tooltip: string;
    icon?: IconDefinition;
    color?: string;
    variant?: string;
    style?: any;
    placement?: "top" | "right" | "bottom" | "left" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start";
}

const IconTooltip: React.FC<Props> = (props) => {
    return (
        <>
            <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={10000}
                title={props.tooltip}
                placement={props.placement ? props.placement : "top"}
            >
                {props.children || !props.icon ? (
                    <>{props.children}</>
                ) : (
                    <Typography
                        style={props.color ? { color: props.color, ...props.style } : { ...props.style }}
                        variant={props.variant ? props.variant as any : "h5"}
                    >
                        <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
                    </Typography>
                )}
            </Tooltip>
        </>
    )
}

export default IconTooltip
