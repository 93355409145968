import { Context } from 'data/advanced/contexts/context';
import { Destination } from 'data/misc/destinations';
import { Ivr } from 'data/services/ivr/ivr';
import { RingGroup } from 'data/users/ring_groups/ring_group';
import { User } from 'data/users/users/user';
import { Voicemail } from 'data/users/voicemails/voicemail';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { HangupLabels } from '../destinations/HangupDestinationForm';
import { ContextsContext } from './ContextsCtx';
import { IvrContext } from './IvrCtx';
import { RingGroupContext } from './RingGroupCtx';
import { UserContext } from './UserCtx';
import { VoicemailContext } from './VoicemailCtx';

export interface DestinationCtx {
    voicemails: Voicemail[];
    users: User[];
    sounds: string[];
    ivrs: Ivr[];
    groups: RingGroup[];
    contexts: Context[];
    getDestinationLabel: (d?: Destination) => string;
}

export const DestinationContext = createContext<DestinationCtx>({
    voicemails: [],
    users: [],
    sounds: [],
    ivrs: [],
    groups: [],
    contexts: [],
    getDestinationLabel: (d?: Destination) => "",
});

const DestinationContextProvider: React.FC<PropsWithChildren> = (props) => {
    const userCtx = useContext(UserContext);
    const voicemailCtx = useContext(VoicemailContext);
    const contextsCtx = useContext(ContextsContext);
    const ivrCtx = useContext(IvrContext);
    const groupCtx = useContext(RingGroupContext);

    const getDestinationLabel = useCallback((destination?: Destination) => {
        if (!destination) {
            return "Aucune";
        }

        switch (destination.type) {
            case "ivr":
                return `SVI (${ivrCtx.ivrs.find(i => i.id === Number(destination.ivr_id))?.name ?? "inconnu"})`;
            case "none":
                return "Aucune";
            case "user":
                return `Utilisateur (${userCtx.getUserLabel(destination.user_id) ?? "inconnu"})`;
            case "sound":
                return `Son: `;
            case "hangup":
                return `Raccrocher (${HangupLabels[destination.cause]})`;
            case "group":
                return `Groupe (${groupCtx.ringGroups.find(g => g.id === Number(destination.group_id))?.label ?? "inconnu"})`;
            case "voicemail":
                return `Boîte vocale (${voicemailCtx.voicemails.find(v => v.id === Number(destination.voicemail_id))?.name ?? "inconnue"})`;
            case "extension":
                return `Extension (${destination.exten})`;
        }
    }, [userCtx, voicemailCtx, ivrCtx, groupCtx]);

    const destinationCtx = useMemo<DestinationCtx>(() => {
        return {
            voicemails: voicemailCtx.voicemails ?? [],
            users: userCtx.users ?? [],
            sounds: [],
            ivrs: ivrCtx.ivrs ?? [],
            groups: groupCtx.ringGroups ?? [],
            contexts: contextsCtx.contexts ?? [],
            getDestinationLabel: getDestinationLabel,
        }
    }, [userCtx, contextsCtx, groupCtx, ivrCtx, voicemailCtx, getDestinationLabel]);

    return (
        <DestinationContext.Provider value={destinationCtx}>
            {props.children}
        </DestinationContext.Provider>
    )
}

export default DestinationContextProvider
