import { ScheduleForm, scheduleFormToBody, scheduleToForm } from "data/forms/users/schedules/schedule_form";
import { WazoRef } from "data/misc/wazo_ref";
import { OutboundRoute } from "data/routes/outbound/outbound_route";
import { PatternForm, patternToForm } from "./pattern_form";

export interface OutboundRouteForm {
    name: string
    description: string
    enabled?: boolean;

    id?: number;
    extensions: PatternForm[];
    trunks: WazoRef[];

    schedule?: ScheduleForm;
}

export const defaultOutboundRouteForm = (): OutboundRouteForm => {
    return {
        name: "",
        description: "",
        extensions: [],
        trunks: [],
    };
};

export const outboundRouteToForm = (outboundRoute: OutboundRoute): OutboundRouteForm => {
    return {
        id: outboundRoute.id,
        description: outboundRoute.description,
        name: outboundRoute.name,
        extensions: outboundRoute.extensions.map(e => patternToForm(e)),
        trunks: outboundRoute.trunks,
        schedule: outboundRoute.schedule_details ? scheduleToForm(outboundRoute.schedule_details) : undefined,
    };
};

export const outboundRouteFormToBody = (outboundRoute: OutboundRouteForm): object => {
    return {
        ...outboundRoute,
        schedule: outboundRoute.schedule ? scheduleFormToBody(outboundRoute.schedule) : undefined,
    };
};
