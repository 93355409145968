import { Tenant } from 'data/advanced/tenant/tenant';
import { defaultFilterResult } from 'data/api/filter_result';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllTenants } from 'services/tenants_service';

export interface TenantCtx {
    tenants: Tenant[];
    refresh: () => void;
    getLabel: (uuid: string) => string;
}

export const TenantContext = createContext<TenantCtx>({
    tenants: [],
    refresh: () => { },
    getLabel: () => "",
});

const TenantContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: tenants, refresh } = useCachedData("tenants", getAllTenants, defaultFilterResult());

    const ctx = useMemo<TenantCtx>(() => {
        return {
            tenants: tenants.items,
            refresh: refresh,
            getLabel: (uuid: string) => {
                const tenant = tenants.items.find(t => t.uuid === uuid);
                return tenant?.name ?? "";
            },
        }
    }, [tenants]);

    return (
        <TenantContext.Provider value={ctx}>
            {props.children}
        </TenantContext.Provider>
    )
}

export default TenantContextProvider
