// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: "Montserrat" !important;
}

html {
    --nav-size: 15rem;
}

body {
    height: 100%;
    width: 100%;
}

.no-scroll {
    overflow: hidden !important;
    -webkit-overflow-scrolling: unset !important;
    -ms-overflow-style: none !important;
}
.no-scroll::-webkit-scrollbar {
    display: none !important;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,4CAA4C;IAC5C,mCAAmC;AACvC;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA,gCAAgC;AAChC;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA,YAAY;AACZ;IACI,0BAA0B;AAC9B","sourcesContent":["* {\n    font-family: \"Montserrat\" !important;\n}\n\nhtml {\n    --nav-size: 15rem;\n}\n\nbody {\n    height: 100%;\n    width: 100%;\n}\n\n.no-scroll {\n    overflow: hidden !important;\n    -webkit-overflow-scrolling: unset !important;\n    -ms-overflow-style: none !important;\n}\n.no-scroll::-webkit-scrollbar {\n    display: none !important;\n}\n\n@keyframes rotation {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(359deg);\n    }\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* Firefox */\ninput[type=\"number\"] {\n    -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
