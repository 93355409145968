import { ScheduleForm, scheduleFormToBody, scheduleToForm } from "data/forms/users/schedules/schedule_form";
import { Destination } from "data/misc/destinations";
import { InboundRoute } from "data/routes/inbound/inbound_route";

export interface InboundRouteForm {
    id?: number;
    description?: string;
    exten: string;
    exten_id?: number;

    destination: Destination;

    schedule?: ScheduleForm;
}

export const defaultInboundRouteForm = (): InboundRouteForm => {
    return {
        exten: "",
        destination: { type: "none" },
    };
};

export const inboundRouteToForm = (inboundRoute: InboundRoute): InboundRouteForm => {
    return {
        id: inboundRoute.id,
        description: inboundRoute.description,
        exten: inboundRoute.extension.exten,
        exten_id: inboundRoute.extension.id,
        destination: inboundRoute.destination as Destination,
        schedule: inboundRoute.schedule_details ? scheduleToForm(inboundRoute.schedule_details) : undefined,
    };
};

export const inboundRouteFormToBody = (inboundRoute: InboundRouteForm): object => {
    return {
        ...inboundRoute,
        schedule: inboundRoute.schedule ? scheduleFormToBody(inboundRoute.schedule) : undefined,
    };
};
