import DeleteBtn from 'common/components/DeleteBtn';
import { ContextsContext } from 'components/misc/providers/ContextsCtx';
import { LineForm } from 'data/forms/users/users/line_form';
import { UserForm } from 'data/forms/users/users/user_form';
import { applyChange, EditCtx } from 'hooks/edit_hook';
import { ListValue, useFormListPropEdit } from 'hooks/list_edit_hook';
import React, { useContext, useMemo } from 'react';
import UserLineForm from './line/UserLineForm';

export interface Props {
    userEdit: EditCtx<UserForm>;
}

const UserLinesForm: React.FC<Props> = (props) => {
    const contextCtx = useContext(ContextsContext);
    const [form, setFormValue] = props.userEdit;

    const linesEditCtx = useFormListPropEdit<LineForm>("lines", form.lines, setFormValue);

    const availableExtensions = useMemo(() => {
        const extensions = new Set(contextCtx.validDefaultExtensions);
        linesEditCtx.values.forEach((v: ListValue<LineForm>) => {
            extensions.delete(Number(v.value.exten));
        });

        return extensions;
    }, [linesEditCtx, contextCtx.validDefaultExtensions]);

    return (
        <>
            <UserLineForm extensions={availableExtensions} saveValue={(l: LineForm) => {
                linesEditCtx.addValue(l);
            }} />
            {linesEditCtx.values.map((v: ListValue<LineForm>, index: number) => (
                <UserLineForm
                    formKeyPath={`update_lines.${index}`}
                    extensions={availableExtensions}
                    key={v.index}
                    line={v.value}
                    selectMainLine={(l: LineForm) => {
                        const newValues = linesEditCtx.values.map((val: ListValue<LineForm>) => {
                            return {
                                index: val.index,
                                value: applyChange(val.value, "is_main", val.value.id === l.id),
                            };
                        });
                        linesEditCtx.editValues(newValues);
                    }}
                    saveValue={(l: LineForm) => {
                        linesEditCtx.editValue({
                            index: v.index,
                            value: l,
                        });
                    }}
                >
                    <DeleteBtn
                        style={{ top: "0.75rem", right: "0.5rem" }}
                        onClick={() => linesEditCtx.removeValue(index)}
                    ></DeleteBtn>
                </UserLineForm>
            ))}
        </>
    )
}

export default UserLinesForm
