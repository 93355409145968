import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import React, { ReactNode, useState } from 'react'
import Textfield, { TextfieldProps } from './Textfield';

export interface Props extends TextfieldProps {
    EndAdornment?: ReactNode;
}

const Passwordfield: React.FC<Props> = ({
    style,
    required,
    disabled,
    format,
    error,
    label,
    value,
    name,
    editValue,
    EndAdornment,
    ...restProps
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Textfield
            name={name}
            editValue={editValue}
            required={required}
            disabled={disabled}
            error={error}
            format={format}
            label={label}
            variant="standard"
            type={showPassword ? "text" : "password"}
            value={value}
            InputProps={{
                endAdornment: (
                    <>
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
                        </IconButton>
                        {EndAdornment}
                    </>
                )
            }}
            {...restProps}
        >
            {restProps.children}
        </Textfield>
    )
}

export default Passwordfield
