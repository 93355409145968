import styled from '@emotion/styled';

export interface NavProps {
    theme: any;
    isVisible: boolean;
}

export const TopNav = styled.div<NavProps>`
    display: grid;
    grid-template-columns: ${props => props.isVisible ? "var(--nav-size)" : "0"} calc(50% - ${props => props.isVisible ? "var(--nav-size)" : "0.01rem"}) 50%;
    grid-template-areas: ". nav-content nav-actions";
    background: ${props => props.theme.palette.background.light};
    height: 4rem;
    width: 100%;
    overflow: hidden;
    text-align: center;
    justify-content: start;
    align-items: center;
    transition: grid-template-column 0.2s;

    @media screen and (max-width: 600px) {
        min-width: 80%;
        grid-template-columns: ${props => props.isVisible ? "var(--nav-size)" : "0"} calc(95% - ${props => props.isVisible ? "var(--nav-size)" : "0.01rem"});
        grid-template-areas: ". nav-content nav-actions";
    }
`;

export const TopNavContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    grid-area: nav-content;
`;

export const TopNavActions = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 1rem;
    grid-area: nav-actions;
`;

export const SideNav = styled.div<NavProps>`
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    grid-template-rows: 4rem 80% 10%;
    background: ${props => props.theme.palette.background.light};
    height: 100%;
    width: ${props => props.isVisible ? "var(--nav-size)" : "0"};
    overflow: hidden;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: width 0.3s;
`;

export const LogoImg = styled.img`
    height: auto;
    width: auto;
    max-height: calc(4rem - 2*0.5rem);
`;

export const Logo = styled.div`
    text-align: center;
    width: var(--nav-size);
`;

export const Content = styled.div`
    text-align: left;
    height: 100%;
    width: var(--nav-size);
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: var(--nav-size);
`;
