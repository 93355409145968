import React from 'react'
import { FormControl, FormLabel, TextareaAutosize, useTheme } from '@mui/material';
import { Setter } from 'hooks/edit_hook';

const TextareaStyle = {
    width: "100%",
    maxWidth: "100%",
    border: "none",
    borderRadius: "0.3rem",
    padding: "0.5rem",
} as any;

export interface TextfieldProps {
    style?: any;
    label: string;
    value: any;
    name: string;
    required?: boolean;
    disabled?: boolean;
    minRows?: number;
    editValue: Setter;
}

const Textarea: React.FC<TextfieldProps> = (props) => {
    const theme = useTheme();

    return (
        <FormControl style={{width: "100%"}}>
            <FormLabel style={{textAlign: "left"}}>{props.label}{props.required ? ' *':''}</FormLabel>
            <TextareaAutosize
                required={props.required}
                disabled={props.disabled}
                minRows={props.minRows ? props.minRows:2}
                color='primary'
                style={{
                    ...TextareaStyle,
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    ...props.style,
                }}
                value={props.value ? props.value:""}
                onChange={(v) => {
                    props.editValue(props.name, v.target.value);
                }}
            ></TextareaAutosize>
        </FormControl>
    )
}

export default Textarea
