import Selectfield from 'common/fields/Selectfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import { RingGroupContext } from 'components/misc/providers/RingGroupCtx';
import { UserForm } from 'data/forms/users/users/user_form';
import { EditCtx } from 'hooks/edit_hook';
import React, { useContext } from 'react';

export interface Props {
    userEdit: EditCtx<UserForm>;
}

const UserGroupsForm: React.FC<Props> = (props) => {
    const groupsCtx = useContext(RingGroupContext);
    const [form, setFormValue] = props.userEdit;

    return (
        <>
            <LargeRow>
                <LeftColumn>
                    <Selectfield
                        multiple
                        label={"Groupes"}
                        value={form.groups?.map(g => g.id)}
                        name={"groups"}
                        editValue={(_, v) => {
                            setFormValue("groups", v.map((g: number) => {
                                return {
                                    id: g,
                                };
                            }));
                        }}
                        options={groupsCtx.ringGroups.map((g) => {
                            return {
                                id: g.id as number,
                                label: g.label,
                            };
                        })}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default UserGroupsForm
