import styled from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material';
import Modal from 'common/modal/Modal';
import React, { useEffect, useState } from 'react';

export interface Props {
    isLoading: boolean;
    smallLoader?: boolean;
}

const StyledLoading = styled.div`
    position: absolute;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
`

const LoadingModal: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | undefined>();

    useEffect(() => {
        if (!props.isLoading) {
            clearTimeout(loadingTimeout);
            setIsLoading(false);
            setLoadingTimeout(undefined);
            return
        }

        setLoadingTimeout(setTimeout(() => {
            setIsLoading(true);
            setLoadingTimeout(undefined);
        }, 100));
    }, [props.isLoading]);

    return (
        <>
            {props.smallLoader ? (
                <>
                    {
                        isLoading &&
                        <div style={{
                            position: "absolute",
                            top: "1rem",
                            left: "50%",
                            transform: "translate(-50%, 0)",
                        }}>
                            <FontAwesomeIcon
                                style={{
                                    fontSize: "2rem",
                                    color: theme.palette.text.secondary,
                                    animation: "rotation 1.5s infinite",
                                }}
                                icon={faSpinner}
                            ></FontAwesomeIcon>
                        </div>
                    }
                </>
            ) : (

                <>
                    {
                        isLoading &&
                        <Modal>
                            <StyledLoading>
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: "4rem",
                                        color: theme.palette.text.secondary,
                                        animation: "rotation 1.5s infinite",
                                    }}
                                    icon={faSpinner}
                                ></FontAwesomeIcon>
                            </StyledLoading>
                        </Modal>
                    }
                </>
            )
            }
        </>
    )
}

export default LoadingModal
