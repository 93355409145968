import { Tenant } from "data/advanced/tenant/tenant";

export interface TenantForm {
    uuid?: string;
    name: string;
    lang: string;
}

export const defaultTenantForm = (): TenantForm => {
    return {
        name: "",
        lang: "fr_CA",
    };
};

export const tenantToForm = (tenant: Tenant): TenantForm => {
    return {
        uuid: tenant.uuid,
        name: tenant.name,
        lang: tenant.lang ?? "",
    };
};

export const tenantFormToBody = (tenant: TenantForm): object => {
    return {
        ...tenant,
    };
};
