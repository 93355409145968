import { useQueryClient } from "react-query";

export const Providers = [
    "contexts",
    "devices",
    "ivrs",
    "plugins",
    "ringGroups",
    "schedules",
    "sipTemplates",
    "trunks",
    "users",
    "voicemails",
    "lines",
    "directories",
] as const;

export type Provider = typeof Providers[number] | "tenants";

export interface ProvidersCtx {
    refreshProviders: (p: Provider[]) => void;
}

export const useProviders = (): ProvidersCtx => {
    const queryClient = useQueryClient();

    return {
        refreshProviders: (providers: Provider[]) => {
            providers.forEach((p) => {
                queryClient.invalidateQueries([p]);
            });
        },
    }
};
