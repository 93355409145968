import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import Modal from 'common/modal/Modal';
import ModalContent from 'common/modal/ModalContent';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import DestinationsProvider from 'components/misc/providers/DestinationsCtx';
import DeviceContextProvider from 'components/misc/providers/DeviceCtx';
import ErrorContextProvider from 'components/misc/providers/ErrorCtx';
import LineContextProvider from 'components/misc/providers/LineCtx';
import PluginContextProvider from 'components/misc/providers/PluginCtx';
import SipTemplateContextProvider from 'components/misc/providers/SipTemplateCtx';
import { UserForm } from 'data/forms/users/users/user_form';
import { useProviders } from 'form-hooks/providers_hook';
import { ListValue, useFormListPropEdit } from 'hooks/list_edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useMemo, useState } from 'react';
import { createManyUsers } from 'services/users_service';
import UserQuickCreateForm from './forms/UserQuickCreateForm';

export interface Props {
    onExit: (updated: boolean) => void;
}

const UserCreateManyFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const [list, setList] = useState<UserForm[]>([]);
    const listEditCtx = useFormListPropEdit<UserForm>("", [], (_, v) => setList(v));

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["users", "devices", "schedules", "voicemails"]);
    }, [providers, props]);

    const existingExtensions = useMemo(() => {
        const extensions: number[] = [];

        list.forEach((v) => {
            v.lines.forEach(l => extensions.push(Number(l.exten)));
        });

        return extensions;
    }, [list]);

    const { isSaving, save, error } = useSave({
        createHandler: createManyUsers,
        onCreate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <LineContextProvider>
                    <SipTemplateContextProvider>
                        <PluginContextProvider>
                            <DeviceContextProvider>
                                <DestinationsProvider>
                                    <Modal onClick={() => props.onExit(false)}>
                                        <ModalContent fixedLargeModal>
                                            <Card>
                                                <CardBody noShadow>
                                                    <Row>
                                                        <LeftColumn style={{ justifyContent: "start" }}>
                                                            <Typography variant='h5'>Nouveaux utilisateurs</Typography>
                                                        </LeftColumn>
                                                    </Row>
                                                    <UserQuickCreateForm
                                                        saveValue={(p: UserForm) => {
                                                            listEditCtx.addValue(p);
                                                        }}
                                                        existingExtensions={existingExtensions}
                                                    />
                                                    {listEditCtx.values.map((v: ListValue<UserForm>, index: number) => (
                                                        <UserQuickCreateForm
                                                            existingExtensions={existingExtensions}
                                                            formKeyPath={`${index}`}
                                                            key={v.index}
                                                            value={v.value}
                                                            saveValue={(p: UserForm) => {
                                                                listEditCtx.editValue({
                                                                    index: v.index,
                                                                    value: p,
                                                                });
                                                            }}
                                                        >
                                                            <DeleteBtn
                                                                style={{ top: "0.75rem", right: "0.5rem" }}
                                                                onClick={() => listEditCtx.removeValue(index)}
                                                            ></DeleteBtn>
                                                        </UserQuickCreateForm>
                                                    ))}
                                                    <FormActionsBar
                                                        disabled={list.length === 0}
                                                        onCancel={() => props.onExit(false)}
                                                        onConfirm={() => {
                                                            save(list)
                                                        }}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </ModalContent>
                                    </Modal>
                                </DestinationsProvider>
                            </DeviceContextProvider>
                        </PluginContextProvider>
                    </SipTemplateContextProvider>
                </LineContextProvider>
            </ErrorContextProvider>
            <LoadingModal isLoading={isSaving} />
        </>
    )
}

export default UserCreateManyFormComponent
