import React, { useContext, useEffect, useState } from 'react';
import 'styles/index.css';
import Content from './body/Content';
import { AuthenticationContext } from './misc/providers/AuthCtx';
import Dashboard from './dashboard/Dashboard';

const Home: React.FC = () => {
    const authCtx = useContext(AuthenticationContext);
    const [originalTenant, setOriginalTenant] = useState(authCtx.tenantId);;

    useEffect(() => {
        authCtx.selectTenant("46448f20-3910-4fef-bb34-df2b12c9bd39");

        return () => authCtx.selectTenant(originalTenant);
    }, []);

    return (
        <Content>
            <Dashboard />
        </Content>
    );
}

export default Home
