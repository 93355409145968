import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import PluginContextProvider from 'components/misc/providers/PluginCtx';
import UserContextProvider from 'components/misc/providers/UserCtx';
import { Device } from 'data/users/devices/device';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getDevices } from 'services/devices_service';
import DeviceFormComponent from './DeviceForm';
import DevicesList from './DevicesList';

const Devices: React.FC = (props) => {
    const devicesList = useFilterList(getDevices, "mac");
    const { state, editValue, setState } = useComponent<Device>();

    return (
        <Content>
            <PluginContextProvider>
                <UserContextProvider>
                    {state === "list" &&
                        <DevicesList
                            devicesList={devicesList}
                            showForm={(device?: Device) => {
                                setState("form", device);
                            }}
                        />
                    }
                    {state === "form" &&
                        <DeviceFormComponent
                            editValue={editValue}
                            onExit={(updated) => {
                                if (updated) {
                                    devicesList.refreshList();
                                }
                                setState("list", undefined);
                            }}
                        />
                    }
                </UserContextProvider>
            </PluginContextProvider>
            <LoadingModal smallLoader isLoading={devicesList.isLoading} />
        </Content>
    )
}

export default Devices
