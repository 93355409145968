import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { KeyOption } from 'data/endpoints/key_options';
import { WazoList, WazoListValue } from 'data/misc/wazo_list';
import { ListValue, useFormListPropEdit } from 'hooks/list_edit_hook';
import React, { useEffect } from 'react';
import EndpointKeyValueForm from './EndpointKeyValueForm';

export interface Props extends FormKeyProps {
    wazolist: WazoList;
    options: KeyOption[];
    onChange: (w: WazoList) => void;
}

const EndpointKeyValuesForm: React.FC<Props> = (props) => {
    const listEdit = useFormListPropEdit<WazoListValue>("", props.wazolist, (_, v) => props.onChange(v));

    useEffect(() => {
        if (!listEdit.initialized) {
            return;
        }

        const current = listEdit.getValues();
        const newValues = props.wazolist;
        if (JSON.stringify(current) !== JSON.stringify(newValues)) {
            listEdit.reset(newValues);
        }
    }, [props.wazolist]);

    return (
        <>
            <EndpointKeyValueForm options={props.options} onChange={(v: WazoListValue) => {
                listEdit.addValue(v);
            }} />
            {listEdit.values.map((v: ListValue<WazoListValue>, index: number) => (
                <EndpointKeyValueForm
                    autoUpdate
                    options={props.options}
                    key={v.index}
                    value={v.value}
                    onChange={(l: WazoListValue) => {
                        listEdit.editValue({
                            index: v.index,
                            value: l,
                        });
                    }}
                >
                    <Button variant='contained' color="error" onClick={() => listEdit.removeValue(index)}>
                        <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
                    </Button>
                </EndpointKeyValueForm>
            ))}
        </>
    )
}

export default EndpointKeyValuesForm
