import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import React from 'react';
import FormActions from './FormActions';

export interface Props {
    onConfirm: () => void;
    onCancel: () => void;
    okLabel?: string;
    cancelLabel?: string;
    disabled?: boolean;
}

const FormActionsBar: React.FC<Props> = (props) => {
    return (
        <LargeRow>
            <LeftColumn style={{justifyContent: "start", gap: "1rem"}}>
                <FormActions
                    disabled={props.disabled}
                    onConfirm={props.onConfirm}
                    onCancel={props.onCancel}
                    okLabel={props.okLabel}
                    cancelLabel={props.cancelLabel}
                />
            </LeftColumn>
        </LargeRow>
    )
}

export default FormActionsBar
