import { defaultFilterResult } from 'data/api/filter_result';
import { RingGroup } from 'data/users/ring_groups/ring_group';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllGroups } from 'services/groups_service';

export interface RingGroupCtx {
    ringGroups: RingGroup[];
    refresh: () => void;
}

export const RingGroupContext = createContext<RingGroupCtx>({
    ringGroups: [],
    refresh: () => { },
});

const RingGroupContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: ringGroups, refresh } = useCachedData("ringGroups", getAllGroups, defaultFilterResult());

    const ctx = useMemo<RingGroupCtx>(() => {
        return {
            ringGroups: ringGroups.items,
            refresh: refresh,
        }
    }, [ringGroups]);

    return (
        <RingGroupContext.Provider value={ctx}>
            {props.children}
        </RingGroupContext.Provider>
    )
}

export default RingGroupContextProvider
