import { Button, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import { InfoDisplay } from 'common/display/Display';
import ListDisplay from 'common/display/ListDisplay';
import ListHeaderElement, { ListHeader } from 'common/display/ListHeaderDisplay';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import ListActionBar from 'components/misc/ListActionBar';
import RequiresAccess from 'components/RequiresAccess';
import { Destination, DestinationTypes } from 'data/misc/destinations';
import { InboundRoute } from 'data/routes/inbound/inbound_route';
import { ListFilterCtx } from 'hooks/list_hook';
import React from 'react';

export interface Props {
    inboundRoutesList: ListFilterCtx<InboundRoute>;
    showForm: (inboundRoute?: InboundRoute) => void;
}

const InboundRoutesList: React.FC<Props> = (props) => {
    const { inboundRoutesList } = props;

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Routes entrantes</Typography>
                </LeftColumn>
                <RightColumn>
                    <RequiresAccess minAccessRole='Reseller'>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button variant="contained" onClick={() => props.showForm()}>Nouvelle route entrante</Button>
                            </LeftColumn>
                        </LargeRow>
                    </RequiresAccess>
                </RightColumn>
            </Row>
            <ListActionBar
                limit={inboundRoutesList.filter.limit}
                search={inboundRoutesList.filter.search}
                setFilterField={inboundRoutesList.setFilterField}
            ></ListActionBar>
            <LargeRow>
                <LeftColumn>
                    {inboundRoutesList.data.items.length > 0 ? (
                        <ListDisplay
                            byFilter
                            header={() => (
                                <ListHeader templateColumns={'25% 75%'}>
                                    <ListHeaderElement
                                        isCurrent={inboundRoutesList.filter.order === "exten"}
                                        order={"exten"}
                                        direction={inboundRoutesList.filter.direction}
                                        setOrder={inboundRoutesList.reduceFilter}
                                    >
                                        Numéro
                                    </ListHeaderElement>
                                    <ListHeaderElement>
                                        Destination
                                    </ListHeaderElement>
                                </ListHeader>
                            )}
                            items={inboundRoutesList.data.items}
                            itemsCount={inboundRoutesList.data.total}
                            maxItems={inboundRoutesList.filter.limit}
                            itemsLabel='route(s) entrante(s)'
                            onPageChange={(page) => {
                                inboundRoutesList.setFilterField("page", page);
                            }}
                            listElementComponent={(v: InboundRoute, index: number) => (
                                <Card key={`${index}-inboundRoutelist`}>
                                    <CardBody smallPadding editable onClick={() => props.showForm(v)}>
                                        <GridRow columnsForAllSmallerScreens templateColumns={"25% 75%"}>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' style={{ fontWeight: 600 }}>
                                                        {v.extension?.exten}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {DestinationTypes[(v.destination as Destination)?.type]}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <DeleteBtn onClick={() => { }}></DeleteBtn>
                                        </GridRow>
                                    </CardBody>
                                </Card>
                            )}>
                        </ListDisplay>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <LargeRow>
                                <LeftColumn>
                                    <Typography color="secondary" variant="h6">
                                        Aucune route entrante n'existe
                                    </Typography>
                                </LeftColumn>
                            </LargeRow>
                        </div>
                    )}
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default InboundRoutesList
