import Selectfield from 'common/fields/Selectfield';
import { OptionField } from 'data/endpoints/key_options';
import React, { useEffect } from 'react';

export interface Props {
    field: OptionField;
    value: string;
    setter: (v: string) => void;
    error?: string;
}

const OptionListFieldComponent: React.FC<Props> = (props) => {
    useEffect(() => {
        if (props.value !== undefined) {
            return;
        }

        if (props.field?.default) {
            props.setter(props.field.default);
        }
    }, [props]);

    return (
        <>
            <Selectfield
                name={"value"}
                label={"Valeur"}
                error={props.error}
                value={props.value}
                editValue={(_, v) => props.setter(v)}
                options={props.field.options.map(o => {
                    return {
                        id: o,
                        label: o,
                    };
                })}
            />
        </>
    )
}

export default OptionListFieldComponent
