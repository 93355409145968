import { defaultFilterResult } from 'data/api/filter_result';
import { Schedule } from 'data/users/schedules/schedule';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllSchedules } from 'services/schedules_service';

export interface ScheduleCtx {
    schedules: Schedule[];
    refresh: () => void;
}

export const ScheduleContext = createContext<ScheduleCtx>({
    schedules: [],
    refresh: () => { },
});

const ScheduleContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: schedules, refresh } = useCachedData("schedules", getAllSchedules, defaultFilterResult());

    const ctx = useMemo<ScheduleCtx>(() => {
        return {
            schedules: schedules.items,
            refresh: refresh,
        }
    }, [schedules]);

    return (
        <ScheduleContext.Provider value={ctx}>
            {props.children}
        </ScheduleContext.Provider>
    )
}

export default ScheduleContextProvider
