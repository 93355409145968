import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import 'styles/index.css';
import { NavItems, NavItemsHidden, NavSection } from './NavigationSection.styled';

export interface NavigationSectionProps extends PropsWithChildren {
    isCurrentRoute: boolean;
    label: string;
    onClick: () => void;
}

export const NavigationSection: React.FC<NavigationSectionProps> = (props) => {
    const theme = useTheme();

    return (
        <div style={{ width: "100%" }}>
            <NavSection onClick={() => props.onClick()} showDark={props.isCurrentRoute} theme={theme}>
                <FontAwesomeIcon
                    icon={props.isCurrentRoute ? faAngleDown : faAngleRight}
                ></FontAwesomeIcon>
                <Typography variant='body1'>
                    {props.label}
                </Typography>
            </NavSection>
            {props.isCurrentRoute ? (
                <NavItems>
                    {props.children}
                </NavItems>
            ) : (
                <NavItemsHidden>
                    {props.children}
                </NavItemsHidden>
            )}
        </div>
    );
}

export default NavigationSection;
