import { Pattern } from "data/routes/outbound/pattern";

export interface PatternForm {
    context: string
    exten: string
    enabled?: boolean;

    external_prefix?: string;
    prefix?: string;
    strip_digits?: number;

    id?: number;
}

export const defaultPatternForm = (defaultContext?: string): PatternForm => {
    return {
        external_prefix: undefined,
        prefix: undefined,
        strip_digits: undefined,
        context: defaultContext ?? "",
        exten: "",
    };
};

export const patternToForm = (pattern: Pattern): PatternForm => {
    return {
        id: pattern.id,
        context: pattern.context,
        exten: pattern.exten,
        external_prefix: pattern.external_prefix,
        prefix: pattern.prefix,
        strip_digits: pattern.strip_digits,
    };
};

export const patternFormToBody = (pattern: PatternForm): object => {
    return {
        ...pattern,
    };
};
