import { defaultFilterResult } from 'data/api/filter_result';
import { InboundRoute } from 'data/routes/inbound/inbound_route';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllInboundRoutes } from 'services/inbound_routes_service';

export interface InboundRouteCtx {
    incalls: InboundRoute[];
    refresh: () => void;
}

export const InboundRouteContext = createContext<InboundRouteCtx>({
    incalls: [],
    refresh: () => { },
});

const InboundRouteContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: incalls, refresh } = useCachedData("incalls", getAllInboundRoutes, defaultFilterResult());

    const ctx = useMemo<InboundRouteCtx>(() => {
        return {
            incalls: incalls.items,
            refresh: refresh,
        }
    }, [incalls, refresh]);

    return (
        <InboundRouteContext.Provider value={ctx}>
            {props.children}
        </InboundRouteContext.Provider>
    )
}

export default InboundRouteContextProvider
