import { Button } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import CallerID from 'common/fields/CallerID';
import CheckboxField from 'common/fields/Checkbox';
import Textfield from 'common/fields/Textfield';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import { ContextsContext } from 'components/misc/providers/ContextsCtx';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultUserForm, UserForm } from 'data/forms/users/users/user_form';
import { useListValueForm } from 'form-hooks/list_value_form_hook';
import { isValueChanged } from 'hooks/edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import UserLineEmbeddedForm from './line/UserLineEmbeddedForm';

export interface Props extends FormKeyProps {
    value?: UserForm;
    saveValue: (p: UserForm) => void;
    existingExtensions: number[];
}

const UserQuickCreateForm: React.FC<PropsWithChildren<Props>> = (props) => {
    const contextCtx = useContext(ContextsContext);
    const errorCtx = useContext(ErrorContext);
    const {
        form,
        setValue,
        editSetForm: setForm,
        reset,
        isEditing,
        saveValue
    } = useListValueForm(props.value ?? defaultUserForm(), props.saveValue);

    useEffect(() => {
        if (!isEditing || !props.value) {
            return
        }

        saveValue();
    }, [form]);

    const availableExtensions = useMemo(() => {
        const extensions = new Set(contextCtx.validDefaultExtensions);

        for (const ext of props.existingExtensions) {
            extensions.delete(ext);
        }

        return extensions;
    }, [contextCtx.validDefaultExtensions, props.existingExtensions]);


    return (
        <>
            <Card>
                <CardBody darkBody>
                    <GridRow templateColumns='80% 20%'>
                        <GridColumn columnDirection style={{ gap: "0.5rem" }}>
                            <GridRow templateColumns='25% 25% 50%'>
                                <GridColumn>
                                    <Textfield
                                        required
                                        label={"Prénom"}
                                        value={form.firstname}
                                        error={errorCtx.getError("firstname", props.formKeyPath)}
                                        name={"firstname"}
                                        editValue={setValue}
                                    />
                                </GridColumn>
                                <GridColumn>
                                    <Textfield
                                        label={"Nom"}
                                        value={form.lastname}
                                        error={errorCtx.getError("lastname", props.formKeyPath)}
                                        name={"lastname"}
                                        editValue={setValue}
                                    />
                                </GridColumn>
                                <GridColumn>
                                    <Textfield
                                        required
                                        label={"Courriel"}
                                        value={form.email}
                                        error={errorCtx.getError("email", props.formKeyPath)}
                                        name={"email"}
                                        editValue={setValue}
                                    />
                                </GridColumn>
                            </GridRow>
                            <UserLineEmbeddedForm
                                formKeyPath={keyPath([props.formKeyPath, "update_lines.0"])}
                                extensions={availableExtensions}
                                line={form.lines.length === 1 ? form.lines[0] : undefined}
                                saveValue={(v) => {
                                    if (form.lines.length === 0 || !isValueChanged(form.lines[0], v)) {
                                        setForm("lines", [v]);
                                        return;
                                    }
                                    setValue("lines", [v])
                                }}
                            />
                            <GridRow templateColumns='50% 50%'>
                                <GridColumn>
                                    <CallerID
                                        label={"Afficheur externe"}
                                        value={form.outgoing_caller_id}
                                        error={errorCtx.getError("outgoing_caller_id", props.formKeyPath)}
                                        name={"outgoing_caller_id"}
                                        editValue={setValue}
                                    />
                                </GridColumn>
                                <GridColumn>
                                    <CheckboxField
                                        disabled={form.update_lines.length === 0 || !form.update_lines[0].exten}
                                        label={"Boîte vocale"}
                                        value={form.voicemail !== undefined}
                                        name={""}
                                        editValue={(_, v) => {
                                            if (!v) {
                                                setValue("voicemail", undefined);
                                                return;
                                            }
                                            setValue("voicemail", {
                                                number: form.update_lines[0].exten,
                                            });
                                        }}
                                    />
                                </GridColumn>
                            </GridRow>
                        </GridColumn>
                        <GridColumn align='end' style={{ alignItems: "center" }}>
                            {!props.value && isEditing &&
                                <div style={{ gap: "0.5rem", display: "flex" }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            saveValue();
                                            if (!props.value) {
                                                reset();
                                            }
                                        }}
                                    >
                                        Ok
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        color="secondary"
                                        onClick={() => reset()}
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            }
                            {props.children}
                        </GridColumn>
                    </GridRow>
                </CardBody>
            </Card>
        </>
    )
}

export default UserQuickCreateForm
