import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import IconTooltip from 'common/components/Tooltip';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import { ContextsContext, getFirstContextOfType } from 'components/misc/providers/ContextsCtx';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultPatternForm, PatternForm } from 'data/forms/routes/outbound/pattern_form';
import { useListValueForm } from 'form-hooks/list_value_form_hook';
import { NumericFormat } from 'formats/common';
import React, { PropsWithChildren, useContext, useEffect } from 'react';

export interface Props extends FormKeyProps {
    pattern?: PatternForm;
    saveValue: (p: PatternForm) => void;
    showTooltips?: boolean;
}

const OutboundRoutePatternForm: React.FC<PropsWithChildren<Props>> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const contextsCtx = useContext(ContextsContext);
    const {
        form,
        setValue,
        editSetForm,
        reset,
        isEditing,
        saveValue
    } = useListValueForm(props.pattern ?? defaultPatternForm(), props.saveValue);

    useEffect(() => {
        if (!isEditing || !form.id) {
            return
        }

        saveValue();
    }, [form]);

    useEffect(() => {
        if (form.context) {
            return;
        }

        editSetForm("context", getFirstContextOfType(contextsCtx.contexts, "outcall")?.name);
    }, [form, contextsCtx.contexts]);

    return (
        <>
            <Card>
                <CardBody darkBody>
                    <GridRow templateColumns="15% 10% 20% 15% 15% 25%">
                        <GridColumn>
                            <Textfield
                                error={errorCtx.getError("external_prefix", props.formKeyPath)}
                                name={"external_prefix"}
                                label={"+ Préfix ajouté"}
                                value={form.external_prefix}
                                editValue={setValue}
                                format={NumericFormat}
                                InputProps={props.showTooltips ? {
                                    endAdornment: (
                                        <IconTooltip
                                            variant="body1"
                                            icon={faQuestionCircle}
                                            tooltip="Préfix ajouté au numéro avant de cheminer l'appel."
                                        />
                                    )
                                } : {}}
                            />
                        </GridColumn>
                        <GridColumn>
                            <Textfield
                                error={errorCtx.getError("prefix", props.formKeyPath)}
                                name={"prefix"}
                                label={"Préfix"}
                                value={form.prefix}
                                editValue={setValue}
                                format={NumericFormat}
                                InputProps={props.showTooltips ? {
                                    endAdornment: (
                                        <IconTooltip
                                            variant="body1"
                                            icon={faQuestionCircle}
                                            tooltip="Préfix au numéro à composer. Le préfix ne sera pas dans le numéro une fois acheminé."
                                        />
                                    )
                                } : {}}
                            />
                        </GridColumn>
                        <GridColumn>
                            <Textfield
                                required
                                error={errorCtx.getError("exten", props.formKeyPath)}
                                name={"exten"}
                                label={"Extension"}
                                value={form.exten}
                                editValue={setValue}
                                InputProps={props.showTooltips ? {
                                    endAdornment: (
                                        <IconTooltip
                                            variant="body1"
                                            icon={faQuestionCircle}
                                            tooltip="Format du numéro à composer. Les formats asterisk sont utilisés."
                                        />
                                    )
                                } : {}}
                            />
                        </GridColumn>
                        <GridColumn>
                            <Textfield
                                error={errorCtx.getError("strip_digits", props.formKeyPath)}
                                name={"strip_digits"}
                                label={"Chiffres à retirer du début"}
                                value={form.strip_digits ?? 0}
                                editValue={setValue}
                                format={NumericFormat}
                                InputProps={props.showTooltips ? {
                                    endAdornment: (
                                        <IconTooltip
                                            variant="body1"
                                            icon={faQuestionCircle}
                                            tooltip="Nombre de chiffres à retirer à partir du début du numéro (--> NPA)"
                                        />
                                    )
                                } : {}}
                            />
                        </GridColumn>
                        <GridColumn>
                            <Selectfield
                                required
                                error={errorCtx.getError("context", props.formKeyPath)}
                                name={"context"}
                                label={"Contexte"}
                                value={form.context}
                                editValue={setValue}
                                options={contextsCtx.contexts?.map(c => {
                                    return {
                                        id: c.name,
                                        label: c.label,
                                    };
                                })}
                            />
                        </GridColumn>
                        <GridColumn align='end'>
                            {!form.id && isEditing &&
                                <div style={{ gap: "0.5rem", display: "flex" }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            saveValue();
                                            if (!props.pattern) {
                                                reset();
                                            }
                                        }}
                                    >
                                        Ok
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        color="secondary"
                                        onClick={() => reset()}
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            }
                        </GridColumn>
                    </GridRow>
                    {props.children}
                </CardBody>
            </Card>
        </>
    )
}

export default OutboundRoutePatternForm
