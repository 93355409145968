import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { Tenant } from 'data/advanced/tenant/tenant';
import { defaultTenantForm, TenantForm, tenantToForm } from 'data/forms/advanced/tenant/tenant_update_form';
import { LanguageLabels, Languages } from 'data/lang';
import { useProviders } from 'form-hooks/providers_hook';
import { useData } from 'hooks/data_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect } from 'react';
import { getTenant, updateTenant } from 'services/tenants_service';

export interface Props extends FormKeyProps {
    editValue?: Tenant;
    onExit: (updated: boolean) => void;
}

const TenantFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const formEdit = useEdit<TenantForm>(defaultTenantForm());
    const [form, setForm, reduceForm] = formEdit;

    useEffect(() => {
        if (!props.editValue) {
            return
        }

        reduceForm(tenantToForm(props.editValue));
    }, [props.editValue]);

    const getTenantDetails = useCallback((authCtx: AuthenticationCtx): Promise<Tenant> => {
        if (!props.editValue?.uuid) {
            return new Promise(resolve => {
                resolve({
                    slug: "",
                    name: "",
                    uuid: "",
                    parent_uuid: "",
                } as Tenant);
            });
        }

        return getTenant(authCtx, props.editValue?.uuid);
    }, [props.editValue]);

    const { data: tenant, isLoading } = useData(getTenantDetails, { id: undefined });
    useEffect(() => {
        if (!tenant?.uuid) {
            return
        }

        reduceForm(tenantToForm(tenant));
    }, [tenant]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["tenants"]);
    }, [props]);

    const { isSaving, save, error } = useSave({
        id: form.uuid,
        updateHandler: updateTenant,
        onUpdate: onSave,
    });

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Client</Typography>
                </LeftColumn>
            </Row>
            <Card>
                <CardBody>
                    <Row>
                        <LeftColumn>
                            <Textfield
                                required
                                label={"Nom"}
                                error={error.getError("name")}
                                value={form.name}
                                name={"name"}
                                editValue={setForm}
                            />
                        </LeftColumn>
                    </Row>
                    <Row>
                        <LeftColumn>
                            <Selectfield
                                label={"Langue"}
                                value={form.lang}
                                name={"lang"}
                                editValue={setForm}
                                options={Languages.map(l => {
                                    return {
                                        id: l,
                                        label: LanguageLabels[l],
                                    };
                                })}
                            />
                        </LeftColumn>
                    </Row>
                </CardBody>
            </Card>
            <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
            <LoadingModal isLoading={isLoading || isSaving} />
        </>
    )
}

export default TenantFormComponent
