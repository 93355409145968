import React, { PropsWithChildren } from "react"

const StyledCard = {
    width: "100%",
    height: "100%",
} as any

export interface CardProps extends PropsWithChildren {
    className?: string;
    style?: React.CSSProperties;
}

const Card: React.FC<CardProps> = (props) => {
    return (
        <div className={props.className} style={{...StyledCard, ...props.style}}>
            {props.children}
        </div>
    )
}

export default Card
