import { get, jsonBody, post, put, tenantUrl } from './api';
import { FilterResult } from 'data/api/filter_result';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import { Message } from 'data/api/message';
import { buildQueryString, Filter } from 'data/api/filter';
import { Directory } from 'data/users/directory/directory';
import { DirectoryForm, directoryFormToBody } from 'data/forms/users/directory/directory_form';

const endpoint = "directory";

export const getAllDirectories = (authCtx: AuthenticationCtx): Promise<FilterResult<Directory>> => {
    return get<FilterResult<Directory>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getDirectories = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Directory>> => {
    return get<FilterResult<Directory>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getDirectory = (authCtx: AuthenticationCtx, id: string): Promise<Directory> => {
    return get<Directory>({
        url: tenantUrl(`${endpoint}/by/id/${id}`, authCtx),
        authentication: authCtx,
    });
}

export const updateDirectory = (authCtx: AuthenticationCtx, directory: DirectoryForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${directory.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(directoryFormToBody(directory)));
}

export const createDirectory = (authCtx: AuthenticationCtx, directory: DirectoryForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(directoryFormToBody(directory)));
}
