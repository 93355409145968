import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Chip } from "@mui/material";

export interface Props {
    id: string | number;
    label: string;
    onDelete: () => void;
};

export const SortableItem: React.FC<Props> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });

    const style = {
        paddingLeft: "0.1rem",
        paddingRight: "0.1rem",
        cursor: "grab",
        transform: CSS.Transform.toString({ x: transform?.x, y: transform?.y, scaleX: 1, scaleY: 1 } as any),
        transition: transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Chip
                key={props.id}
                label={props.label}
                onDelete={() => props.onDelete()}
            />
        </div>
    );
};


export default SortableItem;
