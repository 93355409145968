import { Context, ContextType } from "data/advanced/contexts/context";
import { WazoDidRange, WazoRange } from "data/misc/wazo_range";
import { WazoRef } from "data/misc/wazo_ref";

export interface ContextForm {
    type: ContextType;
    name: string;
    user_ranges?: WazoRange[];
    incall_ranges?: WazoDidRange[];
    label: string

    id?: number;

    contexts?: WazoRef[];
}

export const defaultContextForm = (): ContextForm => {
    return {
        type: "internal",
        name: "",
        label: "",
        user_ranges: [],
        incall_ranges: [],
    };
};

export const contextToForm = (context: Context): ContextForm => {
    return {
        type: context.type,
        id: context.id,
        name: context.name,
        label: context.label,
        user_ranges: context.user_ranges,
        incall_ranges: context.incall_ranges,
        contexts: context.contexts,
    };
};

export const contextFormToBody = (context: ContextForm): object => {
    return {
        ...context,
        incall_ranges: context.incall_ranges ? context.incall_ranges.map(r => {
            return {
                ...r,
                did_length: r.did_length ? r.did_length:undefined,
            }
        }) : undefined,
    };
};
