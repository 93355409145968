import styled from '@emotion/styled'
import React, { PropsWithChildren, useMemo } from 'react'

export const NoRowPadding = {
    paddingTop: "0rem",
    paddingBottom: "0rem",
} as any;

export const RowPadding = {
    paddingBottom: "0.1rem",
    paddingTop: "0.1rem",
} as any;

export interface GridRowProps {
    cols: string;
    columnsForAllSmallerScreens?: boolean;
}

const StyledGridRow = styled.div<GridRowProps>`
    width: 100%;
    display: grid;
    grid-template-columns: ${props => props.cols};
    gap: 1rem;

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        grid-template-columns: ${props => props.columnsForAllSmallerScreens ? "100%":props.cols};
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 100%;
    }
`;

export interface RowProps extends PropsWithChildren {
    style?: any;
    templateColumns?: string;
    gapRem?: number;
    columnsForAllSmallerScreens?: boolean;
}

const GridRow: React.FC<RowProps> = (props) => {
    const cols = useMemo(() => {
        const gap = props.gapRem ?? 1;
        const templateCols = props.templateColumns ? props.templateColumns:"50% 50%";
        const columnCount = templateCols.split(" ").length;
        const adjustedGap = 1 - gap / columnCount;
        return templateCols.split(" ").map(v => `calc(${v} - ${adjustedGap}rem)`).join(" ");
    }, [props.templateColumns, props.gapRem]);

  return (
    <StyledGridRow
        cols={cols}
        columnsForAllSmallerScreens={props.columnsForAllSmallerScreens}
        style={{...NoRowPadding, ...props.style}}
    >
        {props.children}
    </StyledGridRow>
  )
}

export default GridRow
