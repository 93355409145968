import Selectfield from 'common/fields/Selectfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import { DirectoriesContext } from 'components/misc/providers/DirectoriesCtx';
import { UserForm } from 'data/forms/users/users/user_form';
import { EditCtx } from 'hooks/edit_hook';
import React, { useContext } from 'react';

export interface Props {
    userEdit: EditCtx<UserForm>;
}

const UserDirectoriesForm: React.FC<Props> = (props) => {
    const directoriesCtx = useContext(DirectoriesContext);
    const [form, setFormValue] = props.userEdit;

    return (
        <>
            <LargeRow>
                <LeftColumn>
                    <Selectfield
                        multiple
                        label={"Répertoires"}
                        value={form.directories}
                        name={"directories"}
                        editValue={setFormValue}
                        options={directoriesCtx.directories.map((g) => {
                            return {
                                id: g.id as string,
                                label: g.name,
                            };
                        })}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default UserDirectoriesForm
