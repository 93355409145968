import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row, { NoRowPadding } from 'common/layout/Row';
import { GroupDestination } from 'data/misc/destinations';
import { NumericFormat } from 'formats/common';
import { useEdit } from 'hooks/edit_hook';
import React, { useContext, useEffect } from 'react';
import { DestinationContext } from '../providers/DestinationFormContext';
import { ErrorContext, FormKeyProps } from '../providers/ErrorCtx';

export interface Props extends FormKeyProps {
    value: GroupDestination | undefined;
    onEdit: (d: GroupDestination) => void;
}

const GroupDestinationForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const destinationCtx = useContext(DestinationContext);
    const [value, setValue, reduceValue] = useEdit({
        type: "group",
        group_id: props.value?.group_id,
        ring_time: props.value?.ring_time,
    } as GroupDestination);

    useEffect(() => {
        reduceValue({
            group_id: props.value?.group_id,
            ring_time: props.value?.ring_time,
        });
    }, [props.value]);

    useEffect(() => {
        props.onEdit(value);
    }, [value]);

    return (
        <>
            <Row style={NoRowPadding}>
                <LeftColumn>
                    <Selectfield
                        required
                        label={"Groupe"}
                        value={value?.group_id}
                        name={"group_id"}
                        error={errorCtx.getError("group_id", props.formKeyPath)}
                        editValue={setValue}
                        options={destinationCtx.groups.map(g => {
                            return {
                                id: g.id as number,
                                label: g.label ?? g.name,
                            };
                        })}
                    >
                    </Selectfield>
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Temps de sonnerie (secondes)"}
                        error={errorCtx.getError("ring_time", props.formKeyPath)}
                        value={value?.ring_time}
                        name={"ring_time"}
                        editValue={setValue}
                        format={NumericFormat}
                    />
                </RightColumn>
            </Row>
        </>
    )
}

export default GroupDestinationForm
