import React from 'react';
import 'styles/index.css';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import { EditCtx } from 'hooks/edit_hook';
import { UserForm } from 'data/forms/users/users/user_form';
import { Typography } from '@mui/material';
import UserFallbacksForm from 'components/users/users/forms/UserFallbacksForm';
import LargeRow from 'common/layout/LargeRow';

export interface Props {
    userCtx: EditCtx<UserForm>;
}

const UserFallbacksDashboard: React.FC<Props> = (props) => {
    return (
        <div style={{ gridArea: "fallbacks" }}>
            <Card>
                <CardBody smallPadding>
                    <LargeRow>
                        <Typography variant="h5">
                            Destinations alternatives
                        </Typography>
                    </LargeRow>
                    <UserFallbacksForm userEdit={props.userCtx} />
                </CardBody>
            </Card>
        </div>
    );
}

export default UserFallbacksDashboard
