import React, { PropsWithChildren } from 'react'
import { ContentBox } from './Content.styled'

const Content: React.FC<PropsWithChildren> = (props) => {
    return (
        <ContentBox>
            {props.children}
        </ContentBox>
    )
}

export default Content
