import { get, jsonBody, post, put, tenantUrl } from './api';
import { Context } from 'data/advanced/contexts/context';
import { FilterResult } from 'data/api/filter_result';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import { Message } from 'data/api/message';
import { ContextForm, contextFormToBody } from 'data/forms/advanced/contexts/context_form';
import { buildQueryString, Filter } from 'data/api/filter';

const endpoint = "context";

export const getAllContexts = (authCtx: AuthenticationCtx): Promise<FilterResult<Context>> => {
    return get<FilterResult<Context>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getContexts = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Context>> => {
    return get<FilterResult<Context>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getContext = (authCtx: AuthenticationCtx, id: number): Promise<Context> => {
    return get<Context>({
        url: tenantUrl(`${endpoint}/by/id/${id}`, authCtx),
        authentication: authCtx,
    });
}

export const updateContext = (authCtx: AuthenticationCtx, context: ContextForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${context.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(contextFormToBody(context)));
}

export const createContext = (authCtx: AuthenticationCtx, context: ContextForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(contextFormToBody(context)));
}
