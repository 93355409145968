import { WazoRef } from "data/misc/wazo_ref";
import { Fallbacks } from "../fallbacks";
import { Schedule } from "../schedules/schedule";

export const CallerIDModes = [
    "prepend",
    "overwrite",
    "append",
] as const;

export type CallerIDMode = typeof CallerIDModes[number];

export const RingStrategies = [
    "all",
    "random",
    "least_recent",
    "linear",
    "fewest_calls",
    "memorized_round_robin",
    "weight_random",
] as const;

export type RingStrategy = typeof RingStrategies[number];

export const RingStrategyLabel: { [key: string]: string } = {
    "all": "Tous en même temps",
    "random": "Aléatoire",
    "least_recent": "Moins récent",
    "linear": "Linéaire",
    "fewest_calls": "Le moins d'appels",
    "memorized_round_robin": "Distribué",
    "weight_random": "Pseudo-aléatoire",
} as const;

export interface GroupUserRef extends WazoRef {
    priority: number
    firstname?: string;
    lastname?: string;
}

export interface RingGroup {
    id?: number;
    uuid?: string;

    name?: string
    label: string;

    caller_id_mode?: CallerIDMode;
    caller_id_name?: string;

    mark_answered_elsewhere?: boolean;
    retry_delay?: number;
    ring_in_use?: boolean;
    ring_strategy?: RingStrategy;
    timeout?: number;
    user_timeout?: number;
    fallbacks?: Fallbacks;

    users?: GroupUserRef[];

    schedule?: WazoRef;
    schedule_details?: Schedule;
}
