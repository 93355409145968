import { defaultFilterResult } from 'data/api/filter_result';
import { Directory } from 'data/users/directory/directory';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react';
import { getAllDirectories } from 'services/directories_service';

export interface DirectoryCtx {
    directories: Directory[];
    refresh: () => void;
}

export const DirectoriesContext = createContext<DirectoryCtx>({
    directories: [],
    refresh: () => {},
});

const DirectoriesContextProvider: React.FC<PropsWithChildren> = (props) => {
    const {data: directories, refresh} = useCachedData("directories", getAllDirectories, defaultFilterResult());

    const ctx = useMemo<DirectoryCtx>(() => {
        return {
            directories: directories.items,
            refresh: refresh,
        }
    }, [directories, refresh]);

    return (
        <DirectoriesContext.Provider value={ctx}>
            {props.children}
        </DirectoriesContext.Provider>
    )
}

export default DirectoriesContextProvider
