import styled from '@emotion/styled'
import React, { PropsWithChildren } from 'react'
import { RowPadding } from './Row';

const StyledRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "content1";
`

export interface RowProps extends PropsWithChildren {
    style?: any;
}

const LargeRow: React.FC<RowProps> = (props) => {
  return (
    <StyledRow style={{...RowPadding, ...props.style}}>
        {props.children}
    </StyledRow>
  )
}

export default LargeRow
