import styled from "@emotion/styled"

export interface Props {
    editable?: boolean;
    noTopBorderRadius?: boolean;
    smallPadding?: boolean;
    color: string;
    theme: any;
}

export const StyledCardBody = styled.div<Props>`
    padding: ${props => props.smallPadding ? '0.5rem':'2rem 0.5rem'};
    border-radius: ${props => props.noTopBorderRadius ? "0rem 0rem 0.5rem 0.5rem":"0.5rem"};
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content;
    justify-items: center;
    justify-content: start;
    align-content: start;
    position: relative;
    gap: 0.5rem;
    height: 100%;
    width: 100%;
    flex-direction: column;
    background: ${props => props.theme.palette.background[props.color]};

    :hover {
        cursor: ${props => props.editable ? 'pointer':'cursor'};
        background: ${props => props.editable ? props.theme.palette.background.cardhover:props.theme.palette.background[props.color]};
    }
`
