import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { useCallback } from "react";
import SortableItem from "./SortableItem";

export interface ChipValue {
    id: string | number;
    label: string;
}

export interface Props {
    value: ChipValue[];
    editValue: (value: ChipValue[]) => void;
    onDelete: (id: string | number) => void;
}

export const SortableChips: React.FC<Props> = (props) => {
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 5,
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 50,
                tolerance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragEnd = useCallback((event: any) => {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const items = Array.from(props.value);
            const oldIndex = items.findIndex(v => v.id === active.id);
            const newIndex = items.findIndex(v => v.id === over.id);
            const nitems = arrayMove(items, oldIndex, newIndex);
            props.editValue(nitems);
        }
    }, [props]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={props.value}
                strategy={rectSortingStrategy}
            >
                {
                    props.value.map((val: ChipValue) => (
                        <SortableItem
                            key={val.id}
                            id={val.id}
                            label={val.label}
                            onDelete={() => {
                                props.onDelete(val.id);
                            }}
                        />
                    ))
                }
            </SortableContext>
        </DndContext>
    );
};


export default SortableChips;
