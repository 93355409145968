import styled from '@emotion/styled';

export interface Props {
    notInteractable?: boolean;
}

export const StyledHeaderElement = styled.div<Props>`
    font-weight: 600 !important;

    ${props => props.notInteractable ?
        ``
        :
        `:hover {
            cursor: pointer;
            filter: brightness(0.8);
        }`
    }
`;
