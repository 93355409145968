import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import { ErrorContext } from 'components/misc/providers/ErrorCtx';
import { SipTemplateContext } from 'components/misc/providers/SipTemplateCtx';
import { SipTemplateForm } from 'data/forms/advanced/sip_templates/sip_template_form';
import { EditCtx } from 'hooks/edit_hook';
import { useContext } from 'react';

export interface Props {
    valueEdit: EditCtx<SipTemplateForm>;
}

const SipTemplateGeneralForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const sipTemplatesCtx = useContext(SipTemplateContext);
    const [form, setFormValue] = props.valueEdit;

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Nom"}
                        error={errorCtx.getError("label") || errorCtx.getError("name")}
                        value={form.label}
                        name={"label"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
            </Row>
            <LargeRow>
                <LeftColumn>
                    <Selectfield
                        multiple
                        draggable
                        label={"Gabarits SIP parents"}
                        value={form.templates?.map(c => c.uuid) ?? []}
                        error={errorCtx.getError("templates")}
                        name={"templates"}
                        editValue={(_, v) => {
                            setFormValue("templates", v.map((u: number) => {
                                return {
                                    uuid: u,
                                };
                            }))
                        }}
                        options={sipTemplatesCtx.sipTemplates?.filter(t => t.uuid !== form.uuid).map(t => {
                            return {
                                id: t.uuid as string,
                                label: t.label ?? t.name,
                            };
                        })}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default SipTemplateGeneralForm
