import { useState, useEffect, useCallback } from 'react';

export type Dimensions = {
    width: number,
    height: number,
};

export type WindowContext = {
    dimensions: Dimensions,
    isPhoneWidth: () => boolean,
    isTabletWidth: () => boolean,
    isSmallScreen: () => boolean,
}

function getWindowDimensions(): Dimensions {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions(): WindowContext {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isPhoneWidth = useCallback(() => {
        return windowDimensions.width <= 600;
    }, [windowDimensions]);

    const isTabletWidth = useCallback(() => {
        return windowDimensions.width <= 1200 && windowDimensions.width > 600;
    }, [windowDimensions]);

    const isSmallScreen = useCallback(() => {
        return isTabletWidth() || isPhoneWidth();
    }, [isTabletWidth, isPhoneWidth]);

    return {
        dimensions: windowDimensions,
        isPhoneWidth,
        isTabletWidth,
        isSmallScreen,
    };
}
