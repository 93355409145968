import { Button, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import { InfoDisplay } from 'common/display/Display';
import ListDisplay from 'common/display/ListDisplay';
import ListHeaderElement, { ListHeader } from 'common/display/ListHeaderDisplay';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import ListActionBar from 'components/misc/ListActionBar';
import { LineContext } from 'components/misc/providers/LineCtx';
import RequiresAccess from 'components/RequiresAccess';
import { getUserMainLineLabel, User } from 'data/users/users/user';
import { ListFilterCtx } from 'hooks/list_hook';
import React, { useContext } from 'react';

export interface Props {
    usersList: ListFilterCtx<User>;
    showForm: (user?: User) => void;
    showCreateManyForm: () => void;
}

const UsersList: React.FC<Props> = (props) => {
    const linesCtx = useContext(LineContext);
    const { usersList } = props;

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start", alignItems: "start" }}>
                    <Typography variant='h5'>Utilisateurs</Typography>
                </LeftColumn>
                <RightColumn columnDirection>
                    <RequiresAccess minAccessRole='Reseller'>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button
                                    variant="contained"
                                    style={{width: "15rem"}}
                                    onClick={() => props.showForm()}
                                >
                                    Nouvel utilisateur
                                </Button>
                            </LeftColumn>
                        </LargeRow>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button
                                    variant="contained"
                                    style={{width: "15rem"}}
                                    onClick={() => props.showCreateManyForm()}
                                >
                                    Nouveaux utilisateurs
                                </Button>
                            </LeftColumn>
                        </LargeRow>
                    </RequiresAccess>
                </RightColumn>
            </Row>
            <ListActionBar
                limit={usersList.filter.limit}
                search={usersList.filter.search}
                setFilterField={usersList.setFilterField}
            ></ListActionBar>
            <LargeRow>
                <LeftColumn>
                    {usersList.data.items.length > 0 ? (
                        <ListDisplay
                            byFilter
                            header={() => (
                                <ListHeader templateColumns={'25% 50% 25%'}>
                                    <ListHeaderElement
                                        isCurrent={usersList.filter.order === "firstname"}
                                        order={"firstname"}
                                        direction={usersList.filter.direction}
                                        setOrder={usersList.reduceFilter}
                                    >
                                        Nom
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={usersList.filter.order === "email"}
                                        order={"email"}
                                        direction={usersList.filter.direction}
                                        setOrder={usersList.reduceFilter}
                                    >
                                        Courriel
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={usersList.filter.order === "exten"}
                                        order={"exten"}
                                        direction={usersList.filter.direction}
                                        setOrder={usersList.reduceFilter}
                                    >
                                        Poste
                                    </ListHeaderElement>
                                </ListHeader>
                            )}
                            items={usersList.data.items}
                            itemsCount={usersList.data.total}
                            maxItems={usersList.filter.limit}
                            itemsLabel='utilisateur(s)'
                            onPageChange={(page) => {
                                usersList.setFilterField("page", page);
                            }}
                            listElementComponent={(u: User, index: number) => (
                                <Card key={`${index}-userslist`}>
                                    <CardBody smallPadding editable onClick={() => props.showForm(u)}>
                                        <GridRow columnsForAllSmallerScreens templateColumns={"25% 50% 25%"}>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' style={{ fontWeight: 600 }}>
                                                        {u.firstname} {u.lastname}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {u.email}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {u.main_line?.id ? linesCtx.getLine(u.main_line.id)?.exten : ""}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <DeleteBtn onClick={() => {}}></DeleteBtn>
                                        </GridRow>
                                    </CardBody>
                                </Card>
                            )}>
                        </ListDisplay>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <LargeRow>
                                <LeftColumn>
                                    <Typography color="secondary" variant="h6">
                                        Aucun utilisateur n'existe
                                    </Typography>
                                </LeftColumn>
                            </LargeRow>
                        </div>
                    )}
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default UsersList
