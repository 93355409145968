import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import DirectoryFormComponent from './DirectoryForm';
import DirectoriesList from './DirectoriesList';
import { getDirectories } from 'services/directories_service';
import { Directory } from 'data/users/directory/directory';
import UserContextProvider from 'components/misc/providers/UserCtx';

const Directories: React.FC = (props) => {
    const directoriesList = useFilterList(getDirectories, "name");
    const { state, editValue, setState } = useComponent<Directory>();

    return (
        <Content>
            <UserContextProvider>
                {state === "list" &&
                    <DirectoriesList
                        directoriesList={directoriesList}
                        showForm={(directory?: Directory) => {
                            setState("form", directory);
                        }}
                    />
                }
                {state === "form" &&
                    <DirectoryFormComponent
                        onExit={(updated) => {
                            if (updated) {
                                directoriesList.refreshList();
                            }
                            setState("list");
                        }}
                        editValue={editValue}
                    />
                }
                <LoadingModal smallLoader isLoading={directoriesList.isLoading} />
            </UserContextProvider>
        </Content>
    )
}

export default Directories
