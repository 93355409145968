import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import { Ivr } from 'data/services/ivr/ivr';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getIvrs } from 'services/ivr_service';
import IvrFormComponent from './IvrForm';
import IvrsList from './IvrsList';

const Ivrs: React.FC = (props) => {
    const ivrsList = useFilterList(getIvrs, "name");
    const { state, editValue, setState } = useComponent<Ivr>();

    return (
        <Content>
            {state === "list" &&
                <IvrsList
                    ivrsList={ivrsList}
                    showForm={(ivr?: Ivr) => {
                        setState("form", ivr);
                    }}
                />
            }
            {state === "form" &&
                <IvrFormComponent
                    editValue={editValue}
                    onExit={(updated) => {
                        if (updated) {
                            ivrsList.refreshList();
                        }
                        setState("list", undefined);
                    }}
                />
            }
            <LoadingModal smallLoader isLoading={ivrsList.isLoading} />
        </Content>
    )
}

export default Ivrs
