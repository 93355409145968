import React from 'react';
import 'styles/index.css';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import { EditCtx } from 'hooks/edit_hook';
import { UserForm } from 'data/forms/users/users/user_form';
import { Button, IconButton, Typography, useTheme } from '@mui/material';
import LargeRow from 'common/layout/LargeRow';
import GridRow from 'common/layout/GridRow';
import GridColumn from 'common/layout/GridColumn';
import { InfoDisplay, Subtitle } from 'common/display/Display';
import { DisplayData } from 'common/display/DisplayData';
import { LineTypes } from 'data/users/users/line';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import IconTooltip from 'common/components/Tooltip';

export interface Props {
    userCtx: EditCtx<UserForm>;
}

const UserLinesDashboard: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [form] = props.userCtx

    return (
        <div style={{ gridArea: "lines" }}>
            <Card>
                <CardBody smallPadding>
                    <LargeRow>
                        <Typography variant="h5">
                            Lignes
                        </Typography>
                    </LargeRow>
                    {form.lines.map((l) => (
                        <Card key={l.id}>
                            <CardBody smallPadding darkBody>
                                <GridRow templateColumns='5% 20% 25% 25% 25%'>
                                    <GridColumn>
                                        {l.is_main &&
                                            <IconTooltip
                                                placement="left"
                                                style={{fontSize: "1rem"}}
                                                color={theme.palette.primary.main}
                                                tooltip="Ligne principale"
                                                icon={faStar}
                                            ></IconTooltip>
                                        }
                                    </GridColumn>
                                    <GridColumn>
                                        <InfoDisplay columnDirection>
                                            <Subtitle align="left" columnDirection>
                                                <Typography variant="body1">
                                                    Extension
                                                </Typography>
                                            </Subtitle>
                                            <DisplayData>
                                                {l.exten}
                                            </DisplayData>
                                        </InfoDisplay>
                                    </GridColumn>
                                    <GridColumn>
                                        <InfoDisplay columnDirection>
                                            <Subtitle align="left" columnDirection>
                                                <Typography variant="body1">
                                                    Type
                                                </Typography>
                                            </Subtitle>
                                            <DisplayData>
                                                {LineTypes[l.line_type]}
                                            </DisplayData>
                                        </InfoDisplay>
                                    </GridColumn>
                                    <GridColumn>
                                        <InfoDisplay columnDirection>
                                            <Subtitle align="left" columnDirection>
                                                <Typography variant="body1">
                                                    Étiquette
                                                </Typography>
                                            </Subtitle>
                                            <DisplayData>
                                                {l.label}
                                            </DisplayData>
                                        </InfoDisplay>
                                    </GridColumn>
                                    <GridColumn>
                                        {l.line_type === "softphone" &&
                                            <Button variant="contained">
                                                Voir le code QR
                                            </Button>
                                        }
                                    </GridColumn>
                                </GridRow>
                            </CardBody>
                        </Card>
                    ))}
                </CardBody>
            </Card>
        </div>
    );
}

export default UserLinesDashboard
