import { Button } from '@mui/material';
import Selectfield from 'common/fields/Selectfield';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import { ContextOptionField, KeyOption, OptionField, TextField } from 'data/endpoints/key_options';
import { WazoListValue } from 'data/misc/wazo_list';
import { useEdit } from 'hooks/edit_hook';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import ContextsListFieldComponent from './fields/ContextListFieldComponent';
import OptionListFieldComponent from './fields/OptionListFieldComponent';
import StrListFieldComponent from './fields/StrListFieldComponent';

export interface Props extends PropsWithChildren {
    options: KeyOption[];
    value?: WazoListValue;
    onChange: (w: WazoListValue) => void;
    autoUpdate?: boolean;
}

export interface KeyVal {
    key: string;
    value: string;
}

const defaultVal = () => {
    return {
        key: "",
        value: "",
    }
};

const EndpointKeyValueForm: React.FC<Props> = (props) => {
    const [val, _, reduceVal] = useEdit<KeyVal>(props.value ? {
        key: props.value[0],
        value: props.value[1],
    } : defaultVal());

    const save = useCallback((val: KeyVal) => {
        props.onChange([val.key, val.value]);
    }, [props]);

    const valField = useMemo(() => {
        return props.options.find(o => o.key === val.key);
    }, [val, props.options]);

    const valFieldType = useMemo(() => {
        return valField?.field?.type ?? "str";
    }, [valField]);

    const changeVal = useCallback((v: { [key: string]: any }) => {
        const newVal = {
            ...val,
            ...v,
        };

        reduceVal(newVal);
        if (props.autoUpdate && newVal.value !== undefined) {
            save(newVal);
        }
    }, [val, reduceVal, props, save]);

    return (
        <>
            <GridRow style={{ position: "relative" }} templateColumns='45% 45% 10%'>
                <GridColumn>
                    <Selectfield
                        name={"key"}
                        label={"Option"}
                        error={props.autoUpdate && !val.key ? "valeur obligatoire" : ""}
                        value={val.key}
                        editValue={(_, v) => {
                            changeVal({
                                key: v,
                                value: undefined,
                            });
                        }}
                        options={props.options.map((o) => {
                            return {
                                id: o.key,
                                label: o.key,
                            };
                        })}
                    />
                </GridColumn>
                <GridColumn>
                    {valFieldType === "str" &&
                        <StrListFieldComponent
                            error={props.autoUpdate && !val.value ? "valeur obligatoire" : ""}
                            field={valField?.field as TextField}
                            value={val.value}
                            setter={(v) => changeVal({ value: v })}
                        />
                    }
                    {valFieldType === "option" &&
                        <OptionListFieldComponent
                            error={props.autoUpdate && !val.value ? "valeur obligatoire" : ""}
                            field={valField?.field as OptionField}
                            value={val.value}
                            setter={(v) => changeVal({ value: v })}
                        />
                    }
                    {valFieldType === "contexts" &&
                        <ContextsListFieldComponent
                            error={props.autoUpdate && !val.value ? "valeur obligatoire" : ""}
                            field={valField?.field as ContextOptionField}
                            value={val.value}
                            setter={(v) => changeVal({ value: v })}
                        />
                    }
                </GridColumn>
                <GridColumn>
                    {!props.autoUpdate &&
                        <Button
                            disabled={!val.key || !val.value}
                            variant="contained"
                            onClick={() => {
                                save(val);
                                reduceVal(defaultVal());
                            }}
                        >
                            Ok
                        </Button>
                    }
                    {props.children}
                </GridColumn>
            </GridRow>
        </>
    )
}

export default EndpointKeyValueForm
