import { tenantUrl, get, jsonBody, post, put } from './api';
import { FilterResult } from 'data/api/filter_result';
import { Schedule } from 'data/users/schedules/schedule';
import { Message } from 'data/api/message';
import { ScheduleForm, scheduleFormToBody } from 'data/forms/users/schedules/schedule_form';
import { buildQueryString, Filter } from 'data/api/filter';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';

const endpoint = "schedule";

export const getAllSchedules = (authCtx: AuthenticationCtx): Promise<FilterResult<Schedule>> => {
    return get<FilterResult<Schedule>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getSchedules = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Schedule>> => {
    return get<FilterResult<Schedule>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const updateSchedule = (authCtx: AuthenticationCtx, user: ScheduleForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${user.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(scheduleFormToBody(user)));
}

export const createSchedule = (authCtx: AuthenticationCtx, user: ScheduleForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(scheduleFormToBody(user)));
}
