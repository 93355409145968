import { Button, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import { InfoDisplay } from 'common/display/Display';
import ListDisplay from 'common/display/ListDisplay';
import ListHeaderElement, { ListHeader } from 'common/display/ListHeaderDisplay';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import ListActionBar from 'components/misc/ListActionBar';
import { AuthenticationContext } from 'components/misc/providers/AuthCtx';
import { TenantContext } from 'components/misc/providers/TenantCtx';
import RequiresAccess from 'components/RequiresAccess';
import { Tenant } from 'data/advanced/tenant/tenant';
import { ListFilterCtx } from 'hooks/list_hook';
import React, { useContext } from 'react';

export interface Props {
    tenantsList: ListFilterCtx<Tenant>;
    showForm: (tenant?: Tenant) => void;
}

const TenantsList: React.FC<Props> = (props) => {
    const authCtx = useContext(AuthenticationContext);
    const tenantsCtx = useContext(TenantContext);
    const { tenantsList } = props;

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Clients</Typography>
                </LeftColumn>
                <RightColumn>
                    <RequiresAccess minAccessRole='Reseller'>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button variant="contained" onClick={() => props.showForm()}>Nouveau client</Button>
                            </LeftColumn>
                        </LargeRow>
                    </RequiresAccess>
                </RightColumn>
            </Row>
            <ListActionBar
                limit={tenantsList.filter.limit}
                search={tenantsList.filter.search}
                setFilterField={tenantsList.setFilterField}
            ></ListActionBar>
            <LargeRow>
                <LeftColumn>
                    {tenantsList.data.items.length > 0 ? (
                        <ListDisplay
                            byFilter
                            header={() => (
                                <ListHeader templateColumns={'35% 25% 35%'}>
                                    <ListHeaderElement
                                        isCurrent={tenantsList.filter.order === "name"}
                                        order={"name"}
                                        direction={tenantsList.filter.direction}
                                        setOrder={tenantsList.reduceFilter}
                                    >
                                        Nom
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={tenantsList.filter.order === "slug"}
                                        order={"slug"}
                                        direction={tenantsList.filter.direction}
                                        setOrder={tenantsList.reduceFilter}
                                    >
                                        Identifiant
                                    </ListHeaderElement>
                                    <ListHeaderElement>
                                        Parent
                                    </ListHeaderElement>
                                </ListHeader>
                            )}
                            items={tenantsList.data.items}
                            itemsCount={tenantsList.data.total}
                            maxItems={tenantsList.filter.limit}
                            itemsLabel='client(s)'
                            onPageChange={(page) => {
                                tenantsList.setFilterField("page", page);
                            }}
                            listElementComponent={(v: Tenant, index: number) => (
                                <Card key={`${index}-tenantlist`}>
                                    <CardBody smallPadding editable onClick={() => props.showForm(v)}>
                                        <GridRow columnsForAllSmallerScreens templateColumns={"35% 25% 35%"}>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' style={{ fontWeight: 600 }}>
                                                        {v.name}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {v.slug}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {tenantsCtx.getLabel(v.parent_uuid)}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            {authCtx.tenantId !== v.uuid &&
                                                <DeleteBtn onClick={() => { }}></DeleteBtn>
                                            }
                                        </GridRow>
                                    </CardBody>
                                </Card>
                            )}>
                        </ListDisplay>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <LargeRow>
                                <LeftColumn>
                                    <Typography color="secondary" variant="h6">
                                        Aucun client n'existe
                                    </Typography>
                                </LeftColumn>
                            </LargeRow>
                        </div>
                    )}
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default TenantsList
