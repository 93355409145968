import { faSync, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import { InfoDisplay } from 'common/display/Display';
import ListDisplay from 'common/display/ListDisplay';
import ListHeaderElement, { ListHeader } from 'common/display/ListHeaderDisplay';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import ListActionBar from 'components/misc/ListActionBar';
import { UserContext } from 'components/misc/providers/UserCtx';
import RequiresAccess from 'components/RequiresAccess';
import { Device } from 'data/users/devices/device';
import { ListFilterCtx } from 'hooks/list_hook';
import React, { useContext } from 'react';

export interface Props {
    devicesList: ListFilterCtx<Device>;
    showForm: (device?: Device) => void;
}

const DevicesList: React.FC<Props> = (props) => {
    const userCtx = useContext(UserContext);
    const { devicesList } = props;

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Appareils</Typography>
                </LeftColumn>
                <RightColumn>
                    <RequiresAccess minAccessRole='Reseller'>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button variant="contained" onClick={() => props.showForm()}>Nouvel appareil</Button>
                            </LeftColumn>
                        </LargeRow>
                    </RequiresAccess>
                </RightColumn>
            </Row>
            <ListActionBar
                limit={devicesList.filter.limit}
                search={devicesList.filter.search}
                setFilterField={devicesList.setFilterField}
            ></ListActionBar>
            <LargeRow>
                <LeftColumn>
                    {devicesList.data.items.length > 0 ? (
                        <ListDisplay
                            byFilter
                            header={() => (
                                <ListHeader templateColumns={'15% 20% 15% 15% 20% 10%'}>
                                    <ListHeaderElement
                                        isCurrent={devicesList.filter.order === "mac"}
                                        order={"mac"}
                                        direction={devicesList.filter.direction}
                                        setOrder={devicesList.reduceFilter}
                                    >
                                        MAC
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={devicesList.filter.order === "plugin"}
                                        order={"plugin"}
                                        direction={devicesList.filter.direction}
                                        setOrder={devicesList.reduceFilter}
                                    >
                                        Plugin
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={devicesList.filter.order === "ip"}
                                        order={"ip"}
                                        direction={devicesList.filter.direction}
                                        setOrder={devicesList.reduceFilter}
                                    >
                                        IP
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={devicesList.filter.order === "model"}
                                        order={"model"}
                                        direction={devicesList.filter.direction}
                                        setOrder={devicesList.reduceFilter}
                                    >
                                        Mod�le
                                    </ListHeaderElement>
                                    <ListHeaderElement>
                                        Utilisateur
                                    </ListHeaderElement>
                                    <ListHeaderElement></ListHeaderElement>
                                </ListHeader>
                            )}
                            items={devicesList.data.items}
                            itemsCount={devicesList.data.total}
                            maxItems={devicesList.filter.limit}
                            itemsLabel='appareil(s)'
                            onPageChange={(page) => {
                                devicesList.setFilterField("page", page);
                            }}
                            listElementComponent={(d: Device, index: number) => (
                                <Card key={`${index}-devicelist`}>
                                    <CardBody smallPadding editable onClick={() => props.showForm(d)}>
                                        <GridRow columnsForAllSmallerScreens templateColumns={"15% 20% 15% 15% 20% 10%"}>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' style={{ fontWeight: 600 }}>
                                                        {d.mac}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' noWrap>
                                                        {d.plugin}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {d.ip}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {d.model} {d.version}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {userCtx.getUserLabel(d.user?.uuid)}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection style={{ gap: "0.25rem" }}>
                                                    <Typography variant='body1'>
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            leaveTouchDelay={10000}
                                                            title={"Synchroniser"}
                                                            placement={"left"}
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            leaveTouchDelay={10000}
                                                            title={"R�initialiser"}
                                                            placement={"right"}
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                                                            </Button>
                                                        </Tooltip>
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <DeleteBtn onClick={() => { }}></DeleteBtn>
                                        </GridRow>
                                    </CardBody>
                                </Card>
                            )}>
                        </ListDisplay>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <LargeRow>
                                <LeftColumn>
                                    <Typography color="secondary" variant="h6">
                                        Aucun appareil n'existe
                                    </Typography>
                                </LeftColumn>
                            </LargeRow>
                        </div>
                    )}
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default DevicesList
