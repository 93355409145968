import { FollowMe } from "data/users/users/follow_me";

export interface FollowMeForm {
    id?: string;
    number: string;
}

export const followMeToForm = (followMe: FollowMe): FollowMeForm => {
    return {
        id: followMe.id,
        number: followMe.number,
    };
};
