import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import CheckboxField from 'common/fields/Checkbox';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { VoicemailForm } from 'data/forms/users/voicemails/voicemail_form';
import { LanguageLabels, Languages } from 'data/lang';
import { VoicemailTimezoneOptions } from 'data/misc/timezone';
import { NumericFormat } from 'formats/common';
import { Setter } from 'hooks/edit_hook';
import React, { useContext, useState } from 'react';

export interface Props extends FormKeyProps {
    value: VoicemailForm;
    setter: Setter;
}

const VoicemailEditFormComponent: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const { value: form, setter: setForm } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Numéro"}
                        error={errorCtx.getError("number", props.formKeyPath)}
                        value={form?.number}
                        name={"number"}
                        editValue={setForm}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Nom"}
                        error={errorCtx.getError("name", props.formKeyPath)}
                        value={form?.name}
                        name={"name"}
                        editValue={setForm}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        label={"Mot de passe"}
                        error={errorCtx.getError("password", props.formKeyPath)}
                        value={form?.password}
                        name={"password"}
                        editValue={setForm}
                        type={showPassword ? 'text' : 'password'}
                        format={NumericFormat}
                        InputProps={{
                            endAdornment: <Button variant="text" onClick={() => setShowPassword(!showPassword)}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
                            </Button>,
                        }}
                    />
                </LeftColumn>
                <RightColumn>
                    <Selectfield
                        label={"Langue"}
                        value={form?.language ?? "fr_CA"}
                        error={errorCtx.getError("language", props.formKeyPath)}
                        name={"language"}
                        editValue={setForm}
                        options={Languages.map(l => {
                            return {
                                id: l,
                                label: LanguageLabels[l],
                            };
                        })}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Selectfield
                        label={"Fuseau horaire"}
                        value={form?.timezone ?? "na-eastern"}
                        error={errorCtx.getError("timezone", props.formKeyPath)}
                        name={"timezone"}
                        editValue={setForm}
                        options={VoicemailTimezoneOptions}
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        label={"Courriel"}
                        error={errorCtx.getError("email", props.formKeyPath)}
                        value={form?.email}
                        name={"email"}
                        editValue={setForm}
                    />
                </LeftColumn>
                {form?.email &&
                    <RightColumn>
                        <CheckboxField
                            label={"Supprimer le message après l'envoi par courriel"}
                            value={form?.delete_messages ?? false}
                            name={"delete_messages"}
                            editValue={setForm}
                        />
                    </RightColumn>
                }
            </Row>
        </>
    )
}

export default VoicemailEditFormComponent
