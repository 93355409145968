import React, { useCallback } from 'react'
import { Setter, useEdit } from 'hooks/edit_hook';
import { Autocomplete, Chip, FormControl, FormHelperText, TextField, useTheme } from '@mui/material';
import { Format, formatDisplayValue, formatValue, isValidFormat } from './format';

export interface Props {
    style?: any;
    label?: string;
    value: string[];
    name: string;
    required?: boolean;
    error?: string;
    disabled?: boolean;
    editValue: Setter;
    format?: Format;
}

const Tagsfield: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [ctx, _setCtx, reduceCtx] = useEdit<{ tag: string, formatError: boolean }>({
        tag: "",
        formatError: false,
    });
    const getTags = useCallback(() => {
        return props.value;
    }, [props.value]);

    const addTag = useCallback(() => {
        const val = formatValue(ctx.tag.trim(), props.format);
        if (val === "") {
            return
        }
        if (!isValidFormat(val, props.format)) {
            reduceCtx({
                formatError: true,
            });
            return;
        }

        props.editValue(props.name, [...props.value, val]);
        reduceCtx({
            tag: "",
            formatError: false,
        });
    }, [ctx, reduceCtx, props]);

    const editTag = useCallback((val: string) => {
        let tags = [...props.value];
        if (ctx.tag.trim() !== "") {
            tags.push(ctx.tag.trim());
        }

        props.editValue(props.name, tags.filter(t => t !== val));
        reduceCtx({
            tag: val,
        });
    }, [reduceCtx, props, ctx]);

    return (
        <FormControl variant="standard" style={{ width: "100%", textAlign: "left", ...props.style }}>
            <Autocomplete
                multiple
                freeSolo
                open={false}
                disabled={props.disabled}
                onChange={(_e, v) => {
                    if (props.disabled) {
                        return;
                    }
                    props.editValue(props.name, v);
                }}
                value={getTags()}
                inputValue={ctx.tag}
                options={[]}
                renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                            <Chip
                                variant="outlined"
                                label={formatDisplayValue(option, props.format)}
                                key={key}
                                onClick={() => {
                                    editTag(option);
                                }}
                                {...tagProps}
                            />
                        );
                    })
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onKeyDown={(k) => {
                            if (k.key === "Enter") {
                                addTag();
                                k.stopPropagation();
                            }
                        }}
                        onBlur={() => {
                            addTag();
                        }}
                        onChange={(v) => {
                            reduceCtx({
                                tag: v.target.value
                            });
                        }}
                        disabled={props.disabled}
                        error={props.error !== undefined && props.error !== ""}
                        required={props.required}
                        variant="standard"
                        label={props.label}
                    ></TextField>
                )}
            ></Autocomplete>
            <FormHelperText style={{ color: theme.palette.error.main }}>
                {ctx.formatError ? props.format?.errorMsg ?? "la valeur n'est pas valide":props.error}
            </FormHelperText>
        </FormControl>
    )
}

export default Tagsfield
