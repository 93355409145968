import styled from '@emotion/styled';

export const ContentBox = styled.div`
    position: relative;
    grid-area: content;
    padding: 2rem 0;
    display: grid;
    grid-template-columns: 100%;
    height: 100%;
`;
