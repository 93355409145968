import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import CheckboxField from 'common/fields/Checkbox';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import Modal from 'common/modal/Modal';
import ModalContent from 'common/modal/ModalContent';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationContext } from 'components/misc/providers/AuthCtx';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { TenantContext } from 'components/misc/providers/TenantCtx';
import TrunkCreationFormComponent from 'components/routes/trunks/forms/TrunkCreationForm';
import { defaultTenantCreationForm, TenantCreationForm } from 'data/forms/advanced/tenant/tenant_creation_form';
import { defaultTrunkCreationForm } from 'data/forms/routes/trunks/trunk_creation_form';
import { LanguageLabels, Languages } from 'data/lang';
import { useProviders } from 'form-hooks/providers_hook';
import { MaxLengthContinuous } from 'formats/common';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useContext, useEffect } from 'react';
import { createTenant } from 'services/tenants_service';

export interface Props extends FormKeyProps {
    onExit: (updated: boolean) => void;
}

const TenantCreationFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const tenantsCtx = useContext(TenantContext);
    const authCtx = useContext(AuthenticationContext);
    const formEdit = useEdit<TenantCreationForm>(defaultTenantCreationForm());
    const [form, setForm, reduceForm] = formEdit;

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["tenants"]);
    }, [props]);

    useEffect(() => {
        if (!form.parent || !form.parent.uuid) {
            reduceForm({
                parent: { uuid: authCtx.tenantId }
            });
        }
    }, [authCtx]);

    const { isSaving, save, error } = useSave({
        createHandler: createTenant,
        onCreate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <Modal onClick={() => props.onExit(false)}>
                    <ModalContent fixedLargeModal>
                        <Card>
                            <CardBody noShadow smallPadding>
                                <Row>
                                    <LeftColumn style={{ justifyContent: "start" }}>
                                        <Typography variant='h5'>Nouveau client</Typography>
                                    </LeftColumn>
                                </Row>
                                <Row>
                                    <LeftColumn>
                                        <Textfield
                                            required
                                            label={"Nom"}
                                            error={error.getError("name")}
                                            value={form.name}
                                            name={"name"}
                                            editValue={setForm}
                                        />
                                    </LeftColumn>
                                    <RightColumn>
                                        <Textfield
                                            required
                                            label={"Identifiant"}
                                            error={error.getError("slug")}
                                            value={form.slug}
                                            name={"slug"}
                                            editValue={setForm}
                                            format={MaxLengthContinuous(10)}
                                        />
                                    </RightColumn>
                                </Row>
                                <Row>
                                    <LeftColumn>
                                        <Selectfield
                                            required
                                            label={"Parent"}
                                            error={error.getError("parent")}
                                            value={form.parent?.uuid ?? authCtx.tenantId}
                                            name={""}
                                            editValue={(_, v) => setForm("parent", { uuid: v })}
                                            options={tenantsCtx.tenants.map((t) => {
                                                return {
                                                    id: t.uuid,
                                                    label: t.name,
                                                };
                                            })}
                                        />
                                    </LeftColumn>
                                </Row>
                                <Row>
                                    <LeftColumn>
                                        <Selectfield
                                            label={"Langue"}
                                            error={error.getError("lang")}
                                            value={form.lang}
                                            name={"lang"}
                                            editValue={setForm}
                                            options={Languages.map(l => {
                                                return {
                                                    id: l,
                                                    label: LanguageLabels[l],
                                                };
                                            })}
                                        />
                                    </LeftColumn>
                                </Row>
                                <Row>
                                    <LeftColumn>
                                        <CheckboxField
                                            label={"Créer un trunk"}
                                            value={form.trunk !== undefined}
                                            name={""}
                                            editValue={(_, create) => {
                                                reduceForm({
                                                    trunk: create ? defaultTrunkCreationForm(form.slug) : undefined,
                                                });
                                            }}
                                        />
                                    </LeftColumn>
                                </Row>
                                {form.trunk !== undefined &&
                                    <TrunkCreationFormComponent
                                        formKeyPath='trunk'
                                        form={form.trunk}
                                        onChange={(v) => reduceForm({ trunk: v })}
                                    />
                                }
                            </CardBody>
                        </Card>
                        <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
                    </ModalContent>
                </Modal>
            </ErrorContextProvider>
            <LoadingModal isLoading={isSaving} />
        </>
    )
}

export default TenantCreationFormComponent
