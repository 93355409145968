import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import UserContextProvider from 'components/misc/providers/UserCtx';
import { Voicemail } from 'data/users/voicemails/voicemail';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getVoicemails } from 'services/voicemails_service';
import VoicemailFormComponent from './VoicemailForm';
import VoicemailsList from './VoicemailsList';

const Voicemails: React.FC = (props) => {
    const voicemailsList = useFilterList(getVoicemails, "name");
    const { state, editValue, setState } = useComponent<Voicemail>();

    return (
        <Content>
            <UserContextProvider>
                {state === "list" &&
                    <VoicemailsList
                        voicemailsList={voicemailsList}
                        showForm={(voicemail?: Voicemail) => {
                            setState("form", voicemail);
                        }}
                    />
                }
                {state === "form" &&
                    <VoicemailFormComponent
                        editValue={editValue}
                        onExit={(updated) => {
                            if (updated) {
                                voicemailsList.refreshList();
                            }
                            setState("list", undefined);
                        }}
                    />
                }
            </UserContextProvider>
            <LoadingModal smallLoader isLoading={voicemailsList.isLoading} />
        </Content>
    )
}

export default Voicemails
