import { Button, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import { InfoDisplay } from 'common/display/Display';
import ListDisplay from 'common/display/ListDisplay';
import ListHeaderElement, { ListHeader } from 'common/display/ListHeaderDisplay';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import ListActionBar from 'components/misc/ListActionBar';
import { UserContext } from 'components/misc/providers/UserCtx';
import RequiresAccess from 'components/RequiresAccess';
import { Voicemail } from 'data/users/voicemails/voicemail';
import { ListFilterCtx } from 'hooks/list_hook';
import React, { useContext } from 'react';

export interface Props {
    voicemailsList: ListFilterCtx<Voicemail>;
    showForm: (voicemail?: Voicemail) => void;
}

const VoicemailsList: React.FC<Props> = (props) => {
    const { voicemailsList } = props;
    const userCtx = useContext(UserContext);

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Boîtes vocales</Typography>
                </LeftColumn>
                <RightColumn>
                    <RequiresAccess minAccessRole='Reseller'>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button variant="contained" onClick={() => props.showForm()}>Nouvelle boîte vocale</Button>
                            </LeftColumn>
                        </LargeRow>
                    </RequiresAccess>
                </RightColumn>
            </Row>
            <ListActionBar
                limit={voicemailsList.filter.limit}
                search={voicemailsList.filter.search}
                setFilterField={voicemailsList.setFilterField}
            ></ListActionBar>
            <LargeRow>
                <LeftColumn>
                    {voicemailsList.data.items.length > 0 ? (
                        <ListDisplay
                            byFilter
                            header={() => (
                                <ListHeader templateColumns={'25% 10% 40% 25%'}>
                                    <ListHeaderElement
                                        isCurrent={voicemailsList.filter.order === "name"}
                                        order={"name"}
                                        direction={voicemailsList.filter.direction}
                                        setOrder={voicemailsList.reduceFilter}
                                    >
                                        Nom
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={voicemailsList.filter.order === "number"}
                                        order={"number"}
                                        direction={voicemailsList.filter.direction}
                                        setOrder={voicemailsList.reduceFilter}
                                    >
                                        Numéro
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={voicemailsList.filter.order === "email"}
                                        order={"email"}
                                        direction={voicemailsList.filter.direction}
                                        setOrder={voicemailsList.reduceFilter}
                                    >
                                        Courriel
                                    </ListHeaderElement>
                                    <ListHeaderElement>
                                        Utilisateur
                                    </ListHeaderElement>
                                </ListHeader>
                            )}
                            items={voicemailsList.data.items}
                            itemsCount={voicemailsList.data.total}
                            maxItems={voicemailsList.filter.limit}
                            itemsLabel='boîte(s) vocale(s)'
                            onPageChange={(page) => {
                                voicemailsList.setFilterField("page", page);
                            }}
                            listElementComponent={(v: Voicemail, index: number) => (
                                <Card key={`${index}-voicemaillist`}>
                                    <CardBody smallPadding editable onClick={() => props.showForm(v)}>
                                        <GridRow columnsForAllSmallerScreens templateColumns={"25% 10% 40% 25%"}>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' style={{ fontWeight: 600 }}>
                                                        {v.name}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' noWrap>
                                                        {v.number}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {v.email}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {userCtx.getUserLabel(v.user?.uuid)}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <DeleteBtn onClick={() => { }}></DeleteBtn>
                                        </GridRow>
                                    </CardBody>
                                </Card>
                            )}>
                        </ListDisplay>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <LargeRow>
                                <LeftColumn>
                                    <Typography color="secondary" variant="h6">
                                        Aucune boîte vocale n'existe
                                    </Typography>
                                </LeftColumn>
                            </LargeRow>
                        </div>
                    )}
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default VoicemailsList
