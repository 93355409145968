import styled from "@emotion/styled"

export interface ShadowProps {
    theme: any;
    fitContent?: boolean;
}

export const ShadowBox = styled.div<ShadowProps>`
    box-shadow: 1.95px 1.95px 1.95px 1px ${props => props.theme.palette.primary.shadow};
    border-radius: 0.5rem;
    width: 100%;
    height: ${props => props.fitContent ? 'fit-content' : '100%'};
`;
