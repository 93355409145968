import { Session } from 'data/session';
import { Providers, useProviders } from 'form-hooks/providers_hook';
import { Fn } from 'hooks/auth_hook';
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react'

export interface AuthenticationCtx {
    session: Session | null;
    tenantId: string;
    userId: string;
    connect: (onConnect?: Fn) => void;
    refresh: Fn;
    disconnect: Fn;
    selectTenant: (id: string) => void,
}

export const AuthenticationContext = createContext<AuthenticationCtx>({
    session: null,
    tenantId: "46448f20-3910-4fef-bb34-df2b12c9bd39",
    userId: "8fe1784e-7a91-4051-bd75-118ba2033314",
    connect: () => { },
    refresh: () => { },
    disconnect: () => { },
    selectTenant: (id: string) => {},
});

const AuthContextProvider: React.FC<PropsWithChildren> = (props) => {
    const providers = useProviders();
    const [tenantId, setTenantId] = useState("46448f20-3910-4fef-bb34-df2b12c9bd39");

    const ctx = useMemo<AuthenticationCtx>(() => {
        return {
            session: null,
            tenantId: tenantId,
            userId: "8fe1784e-7a91-4051-bd75-118ba2033314",
            connect: () => { },
            refresh: () => { },
            disconnect: () => { },
            selectTenant: (id: string) => {
                setTenantId(id);
            },
        }
    }, [tenantId]);

    useEffect(() => {
        providers.refreshProviders([...Providers]);
    }, [ctx]);

    return (
        <AuthenticationContext.Provider value={ctx}>
            {props.children}
        </AuthenticationContext.Provider>
    )
}

export default AuthContextProvider
