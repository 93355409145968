import Selectfield from 'common/fields/Selectfield';
import LeftColumn from 'common/layout/LeftColumn';
import Row, { NoRowPadding } from 'common/layout/Row';
import { SoundDestination } from 'data/misc/destinations';
import { useEdit } from 'hooks/edit_hook';
import React, { useContext, useEffect } from 'react';
import { ErrorContext, FormKeyProps } from '../providers/ErrorCtx';

export interface Props extends FormKeyProps {
    value: SoundDestination | undefined;
    onEdit: (d: SoundDestination) => void;
}

const SoundDestinationForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [value, setValue, reduceValue] = useEdit({
        type: "sound",
        filename: props.value?.filename ?? "",
        skip: props.value?.skip,
        no_answer: props.value?.no_answer,
    } as SoundDestination);

    useEffect(() => {
        reduceValue({
            filename: props.value?.filename ?? "",
            skip: props.value?.skip,
            no_answer: props.value?.no_answer,
        });
    }, [props.value]);

    useEffect(() => {
        props.onEdit(value);
    }, [value]);

    return (
        <>
            <Row style={NoRowPadding}>
                <LeftColumn>
                    <Selectfield
                        required
                        label={"Son"}
                        error={errorCtx.getError("filename", props.formKeyPath)}
                        value={value?.filename}
                        name={"filename"}
                        editValue={setValue}
                    >
                    </Selectfield>
                </LeftColumn>
            </Row>
        </>
    )
}

export default SoundDestinationForm
