import { Button } from '@mui/material';
import GridColumn from 'common/layout/GridColumn';
import { LineForm } from 'data/forms/users/users/line_form';
import { Line } from 'data/users/users/line';
import React from 'react';

export interface Props {
    line: LineForm|Line;
}

const UserLineSoftphoneForm: React.FC<Props> = (props) => {
    return (
        <>
            <GridColumn align="start">
                { props.line.id &&
                    <Button variant="contained">Voir le code QR</Button>
                }
            </GridColumn>
        </>
    )
}

export default UserLineSoftphoneForm
