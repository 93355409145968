import { SipTemplate } from "data/advanced/sip_templates/sip_template";
import { WazoList } from "data/misc/wazo_list";
import { WazoRef } from "data/misc/wazo_ref";

export interface SipTemplateForm {
    uuid?: string;

    name?: string
    label: string
    transports?: WazoRef[];
    templates: WazoRef[];
    aor_section_options: WazoList;
    auth_section_options: WazoList;
    endpoint_section_options: WazoList;
    identify_section_options: WazoList;
    outbound_auth_section_options: WazoList;
    registration_outbound_auth_section_options: WazoList;
    registration_section_options: WazoList;
}

export const sipTemplateToForm = (endpoint: SipTemplate): SipTemplateForm => {
    return {
        uuid: endpoint.uuid,
        aor_section_options: endpoint.aor_section_options,
        auth_section_options: endpoint.auth_section_options,
        endpoint_section_options: endpoint.endpoint_section_options,
        identify_section_options: endpoint.identify_section_options,
        label: endpoint.label,
        name: endpoint.name,
        outbound_auth_section_options: endpoint.outbound_auth_section_options,
        registration_outbound_auth_section_options: endpoint.registration_outbound_auth_section_options,
        registration_section_options: endpoint.registration_section_options,
        templates: endpoint.templates,
        transports: endpoint.transports,
    };
};

export const defaultSipTemplateForm = (): SipTemplateForm => {
    return {
        aor_section_options: [],
        auth_section_options: [],
        endpoint_section_options: [],
        identify_section_options: [],
        outbound_auth_section_options: [],
        registration_section_options: [],
        registration_outbound_auth_section_options: [],
        templates: [],
        label: "",
    };
};

