import CheckboxField from 'common/fields/Checkbox';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import DestinationForm from 'components/misc/destinations/DestinationForm';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { UserContext } from 'components/misc/providers/UserCtx';
import { RingGroupForm } from 'data/forms/users/ring_groups/ring_group_form';
import { RingStrategies, RingStrategyLabel } from 'data/users/ring_groups/ring_group';
import { NumericFormat } from 'formats/common';
import { EditCtx } from 'hooks/edit_hook';
import React, { useContext } from 'react';

export interface Props extends FormKeyProps {
    valueEdit: EditCtx<RingGroupForm>;
}

const RingGroupGeneralForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const userCtx = useContext(UserContext);
    const [form, setFormValue] = props.valueEdit;

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Nom"}
                        value={form.label}
                        error={errorCtx.getError("label", props.formKeyPath)}
                        name={"label"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Selectfield
                        label={"Stratégie"}
                        value={form.ring_strategy}
                        error={errorCtx.getError("ring_strategy", props.formKeyPath)}
                        name={"ring_strategy"}
                        editValue={setFormValue}
                        options={RingStrategies.map(s => {
                            return {
                                id: s,
                                label: RingStrategyLabel[s],
                            };
                        })}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Délai avant de réessayer"}
                        value={form.retry_delay}
                        error={errorCtx.getError("retry_delay", props.formKeyPath)}
                        name={"retry_delay"}
                        editValue={setFormValue}
                        format={NumericFormat}
                    />
                </RightColumn>
            </Row>
            <LargeRow>
                <LeftColumn>
                    <Selectfield
                        multiple
                        draggable
                        label={"Membres"}
                        value={form.users?.sort((a, b) => a.priority < b.priority ? -1:1).map(u => u.uuid)}
                        error={errorCtx.getError("users", props.formKeyPath)}
                        name={"users"}
                        editValue={(_, v) => setFormValue("users", v.map((u: string, index: number) => {
                            return {
                                uuid: u,
                                priority: index,
                            }
                        }))}
                        options={userCtx.users?.map((u) => {
                            return {
                                id: u.uuid as string,
                                label: userCtx.getUserLabel(u.uuid) ?? "",
                            };
                        })}
                    />
                </LeftColumn>
            </LargeRow>
            <Row>
                <LeftColumn>
                    <CheckboxField
                        label={"Sonner lorsque le poste est occupé"}
                        value={form.ring_in_use ?? true}
                        name={"ring_in_use"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Durée maximale"}
                        value={form.timeout}
                        error={errorCtx.getError("timeout", props.formKeyPath)}
                        name={"timeout"}
                        editValue={setFormValue}
                        format={NumericFormat}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <CheckboxField
                        label={"Marquer les appels comme répondus ailleurs lorsque annulé"}
                        value={form.mark_answered_elsewhere ?? false}
                        name={"mark_answered_elsewhere"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Durée maximale sonnerie poste"}
                        value={form.user_timeout}
                        error={errorCtx.getError("user_timeout", props.formKeyPath)}
                        name={"user_timeout"}
                        editValue={setFormValue}
                        format={NumericFormat}
                    />
                </RightColumn>
            </Row>
            <LargeRow style={{marginTop: "3rem"}}>
                <LeftColumn>
                    <DestinationForm
                        formKeyPath={`fallbacks.noanswer_destination`}
                        label={'Destination lorsque non répondu'}
                        value={form.fallbacks.noanswer_destination}
                        onEdit={(d) => setFormValue("fallbacks", { noanswer_destination: d })}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default RingGroupGeneralForm
