export interface WazoRange {
    start: string
    end: string
}

export interface WazoDidRange extends WazoRange {
    did_length: number;
}

export const defaultWazoRange = () => {
    return{
        start: "",
        end: "",
    }
};

export const defaultWazoDidRange = () => {
    return{
        start: "",
        end: "",
        did_length: 10,
    }
};
