import { ErrorMessage } from "./error_message";
import { RequestFailure, FormValidationError } from "./request_failure";

export interface FieldsMap {
    [field: string]: string;
}

export class ApiError extends Error {
    data: ErrorMessage | RequestFailure;
    response: Response;

    constructor(response: Response, rawData: string, message?: string) {
        super(message);

        this.response = response;
        try {
            this.data = JSON.parse(rawData);
        } catch (e) {
            this.data = {
                error: response.statusText,
            } as ErrorMessage;
        }
    }

    getErrorFields(): FieldsMap {
        if (!this.isInputError()) {
            return {};
        }

        const fieldsMap: FieldsMap = {};

        const details = ((this.data as RequestFailure).details as FormValidationError);
        details.errors.forEach((e) => {
            fieldsMap[e.field] = e.error;
        });
        return fieldsMap;
    }

    isMessageError(): boolean {
        return (this.data as ErrorMessage).error !== undefined;
    }

    isInputError(): boolean {
        const res = (this.data as RequestFailure);
        return res.error_type === "form_validation";
    }
}
