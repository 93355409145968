import React from 'react';
import 'styles/index.css';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import { EditCtx } from 'hooks/edit_hook';
import { UserForm } from 'data/forms/users/users/user_form';
import { Typography } from '@mui/material';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import UserForwardForm from 'components/users/users/forms/UserForwardForm';
import LargeRow from 'common/layout/LargeRow';

export interface Props extends FormKeyProps {
    userCtx: EditCtx<UserForm>;
}

const UserForwardsDashboard: React.FC<Props> = (props) => {
    const [form, setFormValue] = props.userCtx;

    return (
        <div style={{ gridArea: "forwards" }}>
            <Card>
                <CardBody smallPadding>
                    <LargeRow>
                        <Typography variant="h5">
                            Transferts d'appels
                        </Typography>
                    </LargeRow>
                    <UserForwardForm
                        formKeyPath='forwards.busy'
                        userEdit={props.userCtx}
                        label={'Occupé'}
                        value={form.forwards?.busy}
                        forwardName={'busy'}
                    />
                    <UserForwardForm
                        formKeyPath='forwards.noanswer'
                        userEdit={props.userCtx}
                        label={'Aucune réponse'}
                        value={form.forwards?.noanswer}
                        forwardName={'noanswer'}
                    />
                    <UserForwardForm
                        formKeyPath='forwards.unconditional'
                        userEdit={props.userCtx}
                        label={'Inconditionel'}
                        value={form.forwards?.unconditional}
                        forwardName={'unconditional'}
                    />
                </CardBody>
            </Card>
        </div>
    );
}

export default UserForwardsDashboard
