import { ThemeProvider } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { useEdit } from 'hooks/edit_hook';
import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import 'styles/palette.css';
import DarkTheme from './DarkTheme';
import LightTheme from './LightTheme';

declare module '@mui/material/styles' {
    interface SimplePaletteColorOptions {
        shadow?: string;
    }

    interface PaletteColor {
        shadow?: string;
    }

    interface TypeBackground {
        light: string;
        dark: string;
        darker: string;
        card: string;
        cardhover: string;
    }
}

export type ThemeType = "light" | "dark";

export interface ThemeCtx {
    theme: ThemeType;
    setDarkMode: (dark: boolean) => void,
}

export const ThemeContext = createContext<ThemeCtx>({
    theme: "light",
    setDarkMode: (_) => { },
});

type Theme = {
    theme: ThemeType,
}

const ThemeContextProvider: React.FC<PropsWithChildren> = (props) => {
    const [cookies, setCookies] = useCookies(["theme"]);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [themeState, _, reduce] = useEdit<Theme>({
        theme: prefersDarkMode ? "dark":"light",
    });

    const themeCtx = useMemo<ThemeCtx>(() => {
        return {
            theme: themeState.theme,
            setDarkMode: (dark: boolean) => {
                const theme = dark ? "dark":"light";
                setCookies("theme", theme, {
                    maxAge: 8979238402,
                });

                reduce({
                    theme: theme
                });
            }
        }
    }, [themeState]);

    const theme = useMemo(() => {
        switch (themeState.theme) {
            case "light":
                return LightTheme;
            case "dark":
                return DarkTheme;
        }
    }, [themeState]);

    useEffect(() => {
        if (!cookies || !prefersDarkMode) {
            return
        }

        if (!cookies.theme && prefersDarkMode) {
            themeCtx.setDarkMode(true);
        } else {
            themeCtx.setDarkMode(cookies.theme === "dark");
        }
    }, [cookies, prefersDarkMode]);

    return (
        <ThemeContext.Provider value={themeCtx}>
            <ThemeProvider theme={theme}>
                {props.children}
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}

export default ThemeContextProvider
