export interface ExtensionDestination {
    type: "extension",
    exten: string
    context: string
}

export interface GroupDestination {
    type: "group",
    group_id: string,
    ring_time?: number,
}

export type HangupCause = "normal" | "busy" | "congestion";

export interface HangupDestination {
    type: "hangup",
    cause: HangupCause,
}

export interface IvrDestination {
    type: "ivr",
    ivr_id: string,
}

export interface NoneDestination {
    type: "none"
}

export interface SoundDestination {
    type: "sound",
    filename: string,
    no_answer?: boolean,
    skip?: boolean,
}

export interface UserDestination {
    type: "user",
    user_id: string,
    ring_time?: number,
}

export type VoicemailGreeting = "busy" | "unavailable";

export interface VoicemailDestination {
    type: "voicemail",
    greeting: VoicemailGreeting,
    skip_instructions?: boolean,
    voicemail_id: string,
}

export type Destination = VoicemailDestination
    | UserDestination
    | SoundDestination
    | NoneDestination
    | IvrDestination
    | HangupDestination
    | GroupDestination
    | ExtensionDestination;

export type Destinations = {
    "Boîte vocale": VoicemailDestination,
    "Utilisateur": UserDestination,
    "Son": SoundDestination,
    "Aucune": NoneDestination,
    "IVR": IvrDestination,
    "Raccrocher": HangupDestination,
    "Groupe": GroupDestination,
    "Extension": ExtensionDestination,
};

export const DestinationTypes: {[k: string]: string} = {
    "voicemail": "Boîte vocale",
    "user": "Utilisateur",
    "sound": "Son",
    "none": "Aucune",
    "ivr": "SVI",
    "hangup": "Raccrocher",
    "group": "Groupe",
    "extension": "Extension",
};

