import styled from '@emotion/styled';
import React, { PropsWithChildren, useEffect } from 'react'
import ReactDOM from 'react-dom';

const StyledModal = styled.div`
    display: block;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.753);
`

export interface ModalProps extends PropsWithChildren {
    onClick?: () => void;
    usePortal?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
    useEffect(() => {
        document.body.classList.add("no-scroll");

        return () => document.body.classList.remove("no-scroll");
    }, []);

    return (
        <>
            {props.usePortal ?
                (
                    ReactDOM.createPortal(
                        <StyledModal
                            onClick={() => props.onClick ? props.onClick() : {}}>
                            {props.children}
                        </StyledModal>,
                        document.body
                    )
                ) : (
                    <StyledModal
                        onClick={() => props.onClick ? props.onClick() : {}}>
                        {props.children}
                    </StyledModal>
                )
            }
        </>
    )
}

export default Modal
