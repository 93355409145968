import { EndpointIAX } from "data/endpoints/endpoint_iax";
import { WazoList } from "data/misc/wazo_list";
import { IaxTrunkType } from "data/routes/trunks/trunk";
import { RegistrationIAXForm } from "./registration_iax_form";

export interface EndpointIAXForm {
    id?: number;

    name: string
    host?: string;
    options: WazoList;
    type?: IaxTrunkType;

    register_iax?: RegistrationIAXForm;
}

export const endpointIAXToForm = (endpoint: EndpointIAX): EndpointIAXForm => {
    return {
        id: endpoint.id,
        name: endpoint.name,
        host: endpoint.host,
        options: endpoint.options ?? [],
        type: endpoint.type,
        register_iax: endpoint.register_iax,
    };
};

export const defaultEndpointIAXForm = (): EndpointIAXForm => {
    return {
        name: "",
        options: [],
        type: "friend",
        register_iax: {
            auth_password: "",
            auth_username: "",
        }
    };
};
