import { useAuthDetails, UserRoleType } from 'form-hooks/auth_details_hook';
import { PropsWithChildren } from 'react';

export interface Props extends PropsWithChildren {
    minAccessRole: UserRoleType;
}

const RequiresAccess: React.FC<Props> = (props) => {
    const authDetails = useAuthDetails();

    return (
        <>
            { authDetails.hasAccessLevel(props.minAccessRole) ? (
                props.children
            ):(
                <></>
            )}
        </>
    )
}

export default RequiresAccess
