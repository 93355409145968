import { Tabs } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export interface Props extends PropsWithChildren {
    tab: string;
    setTab: (tab: string) => void;
    style?: any;
}

const FormTabs: React.FC<Props> = (props) => {
    return (
        <Tabs
            style={{width: "100%", ...props.style}}
            value={props.tab}
            onChange={(_, v) => props.setTab(v)}
            scrollButtons={"auto"}
            variant="scrollable"
        >
            {props.children}
        </Tabs>
    )
}

export default FormTabs
