export interface Format {
    format: RegExp;
    errorMsg?: string;
    formatter?: (v: any) => any;
    displayFormatter?: (v: any) => any;
}

export const isValidFormat = (value: any, formatter?: Format) => {
    if (!formatter || !value) {
        return true;
    }

    return formatter.format.test(value);
};

export const formatValue = (value: any, formatter?: Format) => {
    if (!formatter || !formatter.formatter || !value) {
        return value;
    }

    return formatter.formatter(value);
};

export const formatDisplayValue = (value: any, formatter?: Format) => {
    if (!formatter || !formatter.displayFormatter || !value) {
        return value;
    }

    return formatter.displayFormatter(value);
};
