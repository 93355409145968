import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import DeleteBtn from 'common/components/DeleteBtn';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import GridColumn from 'common/layout/GridColumn';
import Modal from 'common/modal/Modal';
import ModalClose from 'common/modal/ModalClose';
import ModalContent from 'common/modal/ModalContent';
import ConfirmationNotice from 'components/misc/ConfirmationNotice';
import { DeviceContext } from 'components/misc/providers/DeviceCtx';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { UserContext } from 'components/misc/providers/UserCtx';
import { defaultLineDeviceForm, LineDeviceForm } from 'data/forms/users/users/line_form';
import { Device } from 'data/users/devices/device';
import { MacFormat } from 'formats/common';
import { Setter } from 'hooks/edit_hook';
import React, { useCallback, useContext, useEffect, useState } from 'react';

export interface Props extends FormKeyProps {
    device?: LineDeviceForm;
    setter: Setter;
    saveValue: (l: LineDeviceForm) => void;
}

const UserLineDeviceForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const { device, setter } = props;
    const deviceCtx = useContext(DeviceContext);
    const userCtx = useContext(UserContext);
    const [selecting, setSelecting] = useState(false);
    const [confirmDevice, setConfirmDevice] = useState<Device | undefined>();

    const selectDevice = useCallback((device?: Device) => {
        setSelecting(false);
        const newDevice = device ? {
            ...device,
        } : defaultLineDeviceForm();
        props.saveValue(newDevice);
    }, [deviceCtx, props]);

    const reset = useCallback(() => {
        const device = defaultLineDeviceForm();
        props.saveValue(device);
    }, [props]);

    useEffect(() => {
        if (!props.device) {
            props.saveValue(defaultLineDeviceForm());
        }
    }, [props.device]);

    return (
        <>
            <GridColumn style={{ gap: "1rem" }}>
                <Button variant="outlined" color="secondary" onClick={() => setSelecting(true)}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </Button>
                <Textfield
                    required
                    error={errorCtx.getError("mac", props.formKeyPath)}
                    name={"mac"}
                    label={"MAC"}
                    value={device?.mac}
                    editValue={setter}
                    format={MacFormat}
                    disabled={device?.id !== undefined}
                    InputProps={{
                        endAdornment: <DeleteBtn
                            disabled={!device?.mac}
                            onClick={() => reset()}
                        ></DeleteBtn>
                    }}
                />
                <Selectfield
                    required
                    error={errorCtx.getError("plugin", props.formKeyPath)}
                    name={"plugin"}
                    label={"Plugin"}
                    value={device?.plugin}
                    editValue={setter}
                    options={deviceCtx.plugins.map((p) => {
                        return {
                            id: p,
                            label: p,
                        }
                    })}
                />
            </GridColumn>
            {selecting &&
                <Modal onClick={() => setSelecting(false)} usePortal>
                    <ModalContent>
                        <Selectfield
                            autoFocus
                            name=""
                            label="Appareil"
                            value=""
                            editValue={(_, v: string) => {
                                if (!v) {
                                    return
                                }

                                const device = deviceCtx.getDevice(v);
                                if (device?.user?.uuid) {
                                    setConfirmDevice(device);
                                } else {
                                    selectDevice(device);
                                }
                            }}
                            options={deviceCtx.devices.map(d => {
                                return {
                                    id: d.id as string,
                                    label: `${d.mac}${d.user?.uuid ? ` (assigné à ${userCtx.getUserLabel(d.user.uuid)})` : ""}`,
                                }
                            })}
                        />
                        <ModalClose onClick={() => setSelecting(false)}></ModalClose>
                    </ModalContent>
                </Modal>
            }
            {confirmDevice &&
                <>
                    <ConfirmationNotice
                        label={`Voulez-vous vraiment assigner l'appareil «${confirmDevice.mac}»? Il sera retiré du poste de «${userCtx.getUserLabel(confirmDevice.user?.uuid)}»`}
                        onCancel={() => setConfirmDevice(undefined)}
                        onConfirm={() => {
                            setConfirmDevice(undefined);
                            selectDevice(confirmDevice);
                        }}
                    />
                </>
            }
        </>
    )
}

export default UserLineDeviceForm
