import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import DestinationsProvider from 'components/misc/providers/DestinationsCtx';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import ScheduleContextProvider from 'components/misc/providers/ScheduleCtx';
import TrunkContextProvider from 'components/misc/providers/TrunkCtx';
import FormTab from 'components/misc/Tab';
import FormTabs from 'components/misc/Tabs';
import { defaultOutboundRouteForm, OutboundRouteForm, outboundRouteToForm } from 'data/forms/routes/outbound/outbound_route_form';
import { OutboundRoute } from 'data/routes/outbound/outbound_route';
import { useProviders } from 'form-hooks/providers_hook';
import { useData } from 'hooks/data_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect, useState } from 'react';
import { createOutboundRoute, getOutboundRoute, updateOutboundRoute } from 'services/outbound_routes_service';
import OutboundRouteGeneralForm from './forms/OutboundRouteGeneralForm';
import OutboundRoutePatternsForm from './forms/OutboundRoutePatternsForm';
import OutboundRouteScheduleForm from './forms/OutboundRouteScheduleForm';

export interface Props extends FormKeyProps {
    editValue?: OutboundRoute;
    onExit: (updated: boolean) => void;
}

const OutboundRouteFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const formEdit = useEdit<OutboundRouteForm>(defaultOutboundRouteForm());
    const [form, _, reduceForm] = formEdit;
    const [tab, setTab] = useState("general");

    const getOutcallDetails = useCallback((authCtx: AuthenticationCtx): Promise<OutboundRoute> => {
        if (!props.editValue?.id) {
            return new Promise(resolve => {
                resolve({
                    id: undefined,
                } as OutboundRoute);
            });
        }

        return getOutboundRoute(authCtx, props.editValue?.id);
    }, [props.editValue]);

    const { data: outcall, isLoading } = useData(getOutcallDetails, { id: undefined });
    useEffect(() => {
        if (!outcall?.id) {
            return
        }

        reduceForm(outboundRouteToForm(outcall));
    }, [outcall]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["schedules"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateOutboundRoute,
        createHandler: createOutboundRoute,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <DestinationsProvider>
                    <ScheduleContextProvider>
                        <TrunkContextProvider>
                            <Row>
                                <LeftColumn style={{ justifyContent: "start" }}>
                                    <Typography variant='h5'>Route sortante</Typography>
                                </LeftColumn>
                            </Row>
                            <FormTabs style={{ marginTop: "1rem" }} tab={tab} setTab={setTab}>
                                <FormTab
                                    value={"general"}
                                    label={"Général"}
                                    error={tab !== "general" && error.hasAnyErrorInPaths([
                                        "name",
                                        "description",
                                        "trunks",
                                    ])}
                                />
                                <FormTab
                                    value={"patterns"}
                                    label={"Extensions"}
                                    error={tab !== "patterns" && error.hasErrorInPath("extensions")}
                                />
                                <FormTab
                                    value={"schedule"}
                                    label={"Horaire"}
                                    error={tab !== "schedule" && error.hasErrorInPath("schedule")}
                                />
                            </FormTabs>
                            <Card>
                                <CardBody noTopBorderRadius>
                                    {tab === "general" &&
                                        <OutboundRouteGeneralForm valueEdit={formEdit} />
                                    }
                                    {tab === "patterns" &&
                                        <OutboundRoutePatternsForm valueEdit={formEdit} />
                                    }
                                    {tab === "schedule" &&
                                        <OutboundRouteScheduleForm valueEdit={formEdit} />
                                    }
                                </CardBody>
                            </Card>
                            <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
                        </TrunkContextProvider>
                    </ScheduleContextProvider>
                </DestinationsProvider>
            </ErrorContextProvider>
            <LoadingModal isLoading={isLoading || isSaving} />
        </>
    )
}

export default OutboundRouteFormComponent
