import React from 'react';
import 'styles/index.css';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import { EditCtx } from 'hooks/edit_hook';
import { UserForm } from 'data/forms/users/users/user_form';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import Row, { NoRowPadding } from 'common/layout/Row';
import LeftColumn from 'common/layout/LeftColumn';
import CheckboxField from 'common/fields/Checkbox';

export interface Props extends FormKeyProps {
    userCtx: EditCtx<UserForm>;
}

const UserDndDashboard: React.FC<Props> = (props) => {
    const [form, setFormValue] = props.userCtx;

    return (
        <div style={{ gridArea: "dnd" }}>
            <Card>
                <CardBody smallPadding>
                    <Row style={{ ...NoRowPadding }}>
                        <LeftColumn>
                            <CheckboxField
                                label={"Mode ne pas déranger"}
                                value={form.dnd ?? false}
                                name={"dnd"}
                                editValue={setFormValue}
                            />
                        </LeftColumn>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

export default UserDndDashboard
