import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

export const DisplayData = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
`

export interface DisplayProps extends PropsWithChildren {
    style?: any;
}

const DisplayDataComponent: React.FC<DisplayProps> = (props) => {
  return (
    <DisplayData style={{...props.style}}>
        {props.children}
    </DisplayData>
  )
}

export default DisplayDataComponent
