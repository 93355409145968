import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import { ContextsContext } from 'components/misc/providers/ContextsCtx';
import { ErrorContext } from 'components/misc/providers/ErrorCtx';
import { ContextTypeLabels, ContextTypes } from 'data/advanced/contexts/context';
import { ContextForm } from 'data/forms/advanced/contexts/context_form';
import { EditCtx } from 'hooks/edit_hook';
import React, { useContext } from 'react';

export interface Props {
    valueEdit: EditCtx<ContextForm>;
}

const ContextGeneralForm: React.FC<Props> = (props) => {
    const contextCtx = useContext(ContextsContext);
    const errorCtx = useContext(ErrorContext);
    const [form, setFormValue] = props.valueEdit;

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Nom"}
                        value={form.name}
                        error={errorCtx.getError("name")}
                        name={"name"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Étiquette"}
                        error={errorCtx.getError("label")}
                        value={form.label}
                        name={"label"}
                        editValue={setFormValue}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Selectfield
                        disabled={form.id !== undefined}
                        label={"Type"}
                        error={errorCtx.getError("type")}
                        value={form.type}
                        name={"type"}
                        editValue={setFormValue}
                        options={ContextTypes.map(t => {
                            return {
                                id: t,
                                label: ContextTypeLabels[t],
                            }
                        })}
                    />
                </LeftColumn>
            </Row>
            <LargeRow>
                <LeftColumn>
                    <Selectfield
                        multiple
                        draggable
                        label={"Contextes inclus"}
                        value={form.contexts?.map(c => c.id) ?? []}
                        error={errorCtx.getError("contexts")}
                        name={"contexts"}
                        editValue={(_, v) => {
                            setFormValue("contexts", v.map((u: number) => {
                                return {
                                    id: u,
                                };
                            }))
                        }}
                        options={contextCtx.contexts?.filter(t => t.id !== form.id).map(t => {
                            return {
                                id: t.id as number,
                                label: t.label ?? t.name,
                            };
                        })}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default ContextGeneralForm
