import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import { Tenant } from 'data/advanced/tenant/tenant';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getTenants } from 'services/tenants_service';
import TenantFormComponent from './TenantForm';
import TenantCreationFormComponent from './TenantCreationForm';
import TenantsList from './TenantsList';

const Tenants: React.FC = (props) => {
    const tenantsList = useFilterList(getTenants, "name");
    const { state, editValue, setState } = useComponent<Tenant>();

    return (
        <Content>
            {state === "list" &&
                <TenantsList
                    tenantsList={tenantsList}
                    showForm={(tenant?: Tenant) => {
                        setState("form", tenant);
                    }}
                />
            }
            {state === "form" &&
                <>
                    {editValue ? (
                        <TenantFormComponent
                            editValue={editValue}
                            onExit={(updated) => {
                                if (updated) {
                                    tenantsList.refreshList();
                                }
                                setState("list", undefined);
                            }}
                        />) : (
                        <TenantCreationFormComponent onExit={(updated) => {
                            if (updated) {
                                tenantsList.refreshList();
                            }
                            setState("list", undefined);
                        }} />
                    )}
                </>
            }
            <LoadingModal smallLoader isLoading={tenantsList.isLoading} />
        </Content>
    )
}

export default Tenants
