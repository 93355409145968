import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import LineContextProvider from 'components/misc/providers/LineCtx';
import { User } from 'data/users/users/user';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getUsers } from 'services/users_service';
import UserCreateManyFormComponent from './UserCreateManyForm';
import UserFormComponent from './UserForm';
import UsersList from './UsersList';

const Users: React.FC = (props) => {
    const usersList = useFilterList(getUsers, "firstname");
    const { state, editValue, setState } = useComponent<User>();

    return (
        <Content>
            <LineContextProvider>
                {state === "list" &&
                    <UsersList
                        usersList={usersList}
                        showForm={(user?: User) => {
                            setState("form", user);
                        }}
                        showCreateManyForm={() => {
                            setState("create-many");
                        }}
                    />
                }
                {state === "form" &&
                    <UserFormComponent
                        onExit={(updated) => {
                            if (updated) {
                                usersList.refreshList();
                            }
                            setState("list");
                        }}
                        editUser={editValue}
                    />
                }
                {state === "create-many" &&
                    <UserCreateManyFormComponent
                        onExit={(updated) => {
                            if (updated) {
                                usersList.refreshList();
                            }
                            setState("list");
                        }}
                    />
                }
            </LineContextProvider>
            <LoadingModal smallLoader isLoading={usersList.isLoading} />
        </Content>
    )
}

export default Users
