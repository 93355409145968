import { get, jsonBody, post, put, url } from './api';
import { FilterResult } from 'data/api/filter_result';
import { buildQueryString, Filter } from 'data/api/filter';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import { Tenant } from 'data/advanced/tenant/tenant';
import { Message } from 'data/api/message';
import { TenantForm, tenantFormToBody } from 'data/forms/advanced/tenant/tenant_update_form';
import { TenantCreationForm, tenantCreationFormToBody } from 'data/forms/advanced/tenant/tenant_creation_form';

const endpoint = "tenant";

export const getAllTenants = (authCtx: AuthenticationCtx): Promise<FilterResult<Tenant>> => {
    return get<FilterResult<Tenant>>({
        url: url(`${endpoint}`),
        authentication: authCtx,
    });
}

export const getTenants = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Tenant>> => {
    return get<FilterResult<Tenant>>({
        url: url(`${endpoint}/parent/${authCtx.tenantId}/${buildQueryString(filter)}`),
        authentication: authCtx,
    });
}

export const getTenant = (authCtx: AuthenticationCtx, tenantId: string): Promise<Tenant> => {
    return get<Tenant>({
        url: url(`${endpoint}/by/id/${tenantId}`),
        authentication: authCtx,
    });
}

export const updateTenant = (authCtx: AuthenticationCtx, tenant: TenantForm): Promise<Message> => {
    return put<Message>({
        url: url(`${endpoint}/${tenant.uuid}`),
        authentication: authCtx,
    }, jsonBody(tenantFormToBody(tenant)));
}

export const createTenant = (authCtx: AuthenticationCtx, tenant: TenantCreationForm): Promise<Message> => {
    return post<Message>({
        url: url(`${endpoint}`),
        authentication: authCtx,
    }, jsonBody(tenantCreationFormToBody(tenant)));
}
