import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultScheduleForm, ScheduleForm, scheduleToForm } from 'data/forms/users/schedules/schedule_form';
import { Schedule } from 'data/users/schedules/schedule';
import { useProviders } from 'form-hooks/providers_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect } from 'react';
import { createSchedule, updateSchedule } from 'services/schedules_service';
import ScheduleGeneralForm from './ScheduleGeneralForm';
import SchedulePeriodsForm from './SchedulePeriodsForm';

export interface Props extends FormKeyProps {
    editValue?: Schedule;
    onExit: (updated: boolean) => void;
}

const ScheduleFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const formEdit = useEdit<ScheduleForm>(defaultScheduleForm());
    const [form, setForm, reduceForm] = formEdit;

    useEffect(() => {
        if (!props.editValue) {
            return
        }

        reduceForm(scheduleToForm(props.editValue));
    }, [props.editValue]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["schedules"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateSchedule,
        createHandler: createSchedule,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <SchedulePeriodsForm
                    schedule={form}
                    onSave={(v) => reduceForm(v)}
                    errorGeneral={error.hasAnyErrorInPaths([
                        "timezone",
                        "name",
                        "closed_destination",
                    ])}
                >
                    <ScheduleGeneralForm
                        schedule={form}
                        setter={setForm}
                    />
                </SchedulePeriodsForm>
            </ErrorContextProvider>
            <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
            <LoadingModal isLoading={isSaving} />
        </>
    )
}

export default ScheduleFormComponent
