import React from 'react';
import { Button, Typography } from '@mui/material';
import Modal from 'common/modal/Modal';
import ModalContent from 'common/modal/ModalContent';
import LeftColumn from 'common/layout/LeftColumn';
import LargeRow from 'common/layout/LargeRow';

const FormStyle = {
    btn: {
        width: "10rem",
    }
} as any;

export interface Props {
    label: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationNotice: React.FC<Props> = (props) => {
    return (
        <Modal onClick={() => props.onCancel()} usePortal>
            <ModalContent style={{width: "50%"}}>
                <LargeRow>
                    <LeftColumn>
                        <Typography variant='h6'>
                            {props.label}
                        </Typography>
                    </LeftColumn>
                </LargeRow>
                <LargeRow>
                    <LeftColumn style={{gap: "1rem"}}>
                        <Button
                            onClick={() => props.onConfirm()}
                            style={{...FormStyle.btn}}
                            variant="contained"
                            color="primary"
                        >
                                OK
                        </Button>
                        <Button
                            onClick={() => props.onCancel()}
                            style={{...FormStyle.btn}}
                            variant="outlined"
                            color="secondary"
                        >
                                Annuler
                        </Button>
                    </LeftColumn>
                </LargeRow>
            </ModalContent>
        </Modal>
    )
}

export default ConfirmationNotice
