import { TrunkCreationForm, trunkCreationFormToBody } from "data/forms/routes/trunks/trunk_creation_form";
import { WazoRef } from "data/misc/wazo_ref";

export interface TenantCreationForm {
    slug: string;
    name: string;
    parent?: WazoRef;

    lang: string;
    trunk?: TrunkCreationForm;
}

export const defaultTenantCreationForm = (): TenantCreationForm => {
    return {
        slug: "",
        name: "",
        lang: "fr_CA",
    };
};

export const tenantCreationFormToBody = (tenant: TenantCreationForm): object => {
    return {
        ...tenant,
        trunk: tenant.trunk ? trunkCreationFormToBody(tenant.trunk) : undefined,
    };
};
