import React, { PropsWithChildren } from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import Contexts from './advanded/contexts/Contexts'
import SipTemplates from './advanded/sip_templates/SipTemplates'
import Tenants from './advanded/tenants/Tenants'
import Container from './body/Container'
import Home from './Home'
import NavbarContextProvider from './nav/NavbarContext'
import TopNavbar from './nav/TopNavbar'
import InboundRoutes from './routes/inbound/InboundRoutes'
import OutboundRoutes from './routes/outbound/OutboundRoutes'
import Trunks from './routes/trunks/Trunks'
import Ivrs from './services/ivr/Ivrs'
import Devices from './users/devices/Devices'
import Directories from './users/directories/Directories'
import RingGroups from './users/ring_groups/RingGroups'
import Schedules from './users/schedules/Schedules'
import Users from './users/users/Users'
import Voicemails from './users/voicemails/Voicemails'

const RoutesContainer: React.FC<PropsWithChildren> = (props) => {
    return (
        <Router>
            <NavbarContextProvider>
                <TopNavbar></TopNavbar>
                <Container>
                    {props.children}
                    <Routes>
                        <Route path='/' element={<Home/>}></Route>
                        <Route path='/users' element={<Users/>}></Route>
                        <Route path='/devices' element={<Devices/>}></Route>
                        <Route path='/voicemails' element={<Voicemails/>}></Route>
                        <Route path='/schedules' element={<Schedules/>}></Route>
                        <Route path='/inboundroutes' element={<InboundRoutes/>}></Route>
                        <Route path='/ringgroups' element={<RingGroups/>}></Route>
                        <Route path='/trunks' element={<Trunks/>}></Route>
                        <Route path='/outboundroutes' element={<OutboundRoutes/>}></Route>
                        <Route path='/ivr' element={<Ivrs/>}></Route>
                        <Route path='/tenants' element={<Tenants/>}></Route>
                        <Route path='/contexts' element={<Contexts/>}></Route>
                        <Route path='/siptemplates' element={<SipTemplates/>}></Route>
                        <Route path='/directories' element={<Directories/>}></Route>
                    </Routes>
                </Container>
            </NavbarContextProvider>
        </Router>
    )
}

export default RoutesContainer
