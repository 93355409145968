import { createTheme } from "@mui/material";

export const cssVar = (name: string) => {
    let value = getComputedStyle(document.documentElement).getPropertyValue(name).trim();
    return value ? value : "#fffff";
}

const LightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: cssVar("--light-theme-primary"),
            light: cssVar("--light-theme-primary-light"),
            shadow: cssVar("--light-theme-shadow")
        },
        secondary: {
            main: cssVar("--light-theme-secondary"),
            light: cssVar("--light-theme-secondary-light"),
        },
        error: {
            main: cssVar("--light-theme-error")
        },
        warning: {
            main: cssVar("--light-theme-warning")
        },
        success: {
            main: cssVar("--light-theme-success")
        },
        common: {
            white: cssVar("--dark-theme-white"),
            black: cssVar("--dark-theme-black"),
        },
        background: {
            default: cssVar("--light-theme-background"),
            light: cssVar("--light-theme-background-light"),
            card: cssVar("--light-theme-background-card"),
            cardhover: cssVar("--light-theme-background-card-hover"),
            dark: cssVar("--light-theme-background-med"),
            darker: cssVar("--light-theme-background-dark"),
        },
        text: {
            primary: cssVar("--light-theme-text-primary"),
            secondary: cssVar("--light-theme-text-secondary")
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "contained", color: "primary" },
                    style: {
                        color: cssVar("--light-theme-white"),
                        "&:hover": {
                            backgroundColor: cssVar("--light-theme-primary-light"),
                            filter: "brightness(0.9)",
                        },
                    }
                },
                {
                    props: { variant: "text", color: "primary" },
                    style: {
                        color: cssVar("--light-theme-secondary"),
                        "&:hover": {
                            color: cssVar("--light-theme-primary"),
                        },
                    }
                }
            ]
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: cssVar("--light-theme-secondary"),
                    "&:hover": {
                        color: cssVar("--light-theme-primary-light"),
                        filter: "brightness(0.9)",
                    },
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    whiteSpace: "unset",
                    wordBreak: "keep-all",
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    background: cssVar("--light-theme-background-card"),
                }
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    background: cssVar("--light-theme-background-card"),
                }
            }
        },
        MuiTabScrollButton: {
            styleOverrides: {
                root: {
                    background: cssVar("--light-theme-background-card"),
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: cssVar("--light-theme-error")
                }
            }
        },
    } as any
});

export default LightTheme;
