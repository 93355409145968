import { Background, BackgroundVariant, Controls, Edge, MiniMap, Node, NodeTypes, ReactFlow } from '@xyflow/react';
import React from 'react'
import '@xyflow/react/dist/style.css';
import { useTheme } from '@mui/material';
import IvrFlowNode from 'components/services/ivr/forms/IvrFlowNode';
import InteractableFlowNode from './InteractableFlowNode';
import IvrFlowChoiceNode from 'components/services/ivr/forms/IvrFlowChoiceNode';
import Shadow from 'common/Shadow';

export const IvrFlowNodeType = "ivr-flow-node";
export const IvrFlowChoiceNodeType = "ivr-flow-choice-node";
export const InteractableFlowNodeType = "interactable-flow-node";

export const FlowNodeTypes: any = {
    "ivr-flow-node": IvrFlowNode,
    "ivr-flow-choice-node": IvrFlowChoiceNode,
    "interactable-flow-node": InteractableFlowNode,
};

export interface Props {
    style?: any;
    nodes?: Node[];
    edges?: Edge[];
    nodeTypes?: NodeTypes;
    [key: string]: any;
}

export type FlowCtx = {
    nodes: Node[];
    edges: Edge[];
}

const FlowContainer: React.FC<Props> = ({ style, nodes, edges, nodeTypes, ...restProps }) => {
    const theme = useTheme();

    return (
        <Shadow>
            <div style={{
                width: '100%',
                height: '100%',
                borderRadius: "0.5rem",
                background: theme.palette.background.dark,
                ...style,
            }}>
                <ReactFlow
                    proOptions={{ hideAttribution: true }}
                    nodeTypes={{ ...FlowNodeTypes, ...nodeTypes }}
                    nodes={nodes}
                    edges={edges}
                    fitView
                    {...restProps}
                >
                    <Background color="#000000" variant={BackgroundVariant.Dots} />
                    <Controls />
                    <MiniMap />
                </ReactFlow>
            </div>
        </Shadow>
    )
}

export default FlowContainer
