import { EndpointIAXForm, endpointIAXToForm } from "data/forms/endpoints/endpoint_iax_form";
import { EndpointSIPForm, endpointSIPToForm } from "data/forms/endpoints/endpoint_sip_form";
import { Trunk } from "data/routes/trunks/trunk";

export interface TrunkForm {
    name: string;
    label?: string;
    context: string
    host?: string;
    port?: number;

    password?: string;

    id?: number;
    endpoint_sip?: EndpointSIPForm;
    endpoint_iax?: EndpointIAXForm;
}

export const defaultTrunkForm = (): TrunkForm => {
    return {
        name: "",
        context: "",
    };
};

export const trunkToForm = (trunk: Trunk): TrunkForm => {
    return {
        id: trunk.id,
        name: trunk.name,
        label: trunk.label ?? trunk.endpoint_sip?.label,
        context: trunk.context,
        endpoint_sip: trunk.endpoint_sip ? endpointSIPToForm(trunk.endpoint_sip):undefined,
        endpoint_iax: trunk.endpoint_iax ? endpointIAXToForm(trunk.endpoint_iax):undefined,
    };
};

export const trunkFormToBody = (trunk: TrunkForm): object => {
    return {
        ...trunk,
    };
};
