import styled from '@emotion/styled';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, useTheme } from '@mui/material';
import React from 'react'

const ModalCloseBtn = styled.div`
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
`

export interface ModalCloseProps {
    style?: any;
    onClick: () => void;
}

const ModalClose: React.FC<ModalCloseProps> = (props) => {
    const theme = useTheme();

    return (
        <ModalCloseBtn
            style={{...props.style}}
        >
            <IconButton
                style={{color: theme.palette.error.main}}
                onClick={() => props.onClick()}>
                    <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
            </IconButton>
        </ModalCloseBtn>
    )
}

export default ModalClose
