import React, { PropsWithChildren, useContext } from 'react'
import LeftColumn from 'common/layout/LeftColumn';
import LargeRow from 'common/layout/LargeRow';
import { useEdit } from 'hooks/edit_hook';
import { IvrForm } from 'data/forms/services/ivr/ivr_form';
import Modal from 'common/modal/Modal';
import ModalContent from 'common/modal/ModalContent';
import FormActionsBar from 'components/misc/FormActionsBar';
import DestinationForm from 'components/misc/destinations/DestinationForm';
import Textfield from 'common/fields/Textfield';
import { NumericFormat } from 'formats/common';
import Row from 'common/layout/Row';
import { ErrorContext } from 'components/misc/providers/ErrorCtx';

export interface Props {
    form: IvrForm;
    onChange: (f: IvrForm) => void;
    onCancel: () => void;
}

const IvrTimeoutForm: React.FC<PropsWithChildren<Props>> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setForm] = useEdit(props.form);

    return (
        <>
            <Modal onClick={() => props.onCancel()}>
                <ModalContent>
                    <Row>
                        <LeftColumn>
                            <Textfield
                                label={'Temps maximal'}
                                name={"timeout"}
                                error={errorCtx.getError("timeout")}
                                value={form.timeout}
                                editValue={setForm}
                                format={NumericFormat}
                            />
                        </LeftColumn>
                    </Row>
                    <LargeRow>
                        <LeftColumn>
                            <DestinationForm
                                smallLabel
                                formKeyPath="timeout_destination"
                                label={'Destination lorsque le temps maximal est atteint'}
                                value={form.timeout_destination}
                                onEdit={(d) => setForm("timeout_destination", d)}
                            />
                        </LeftColumn>
                    </LargeRow>
                    <FormActionsBar
                        onCancel={() => props.onCancel()}
                        onConfirm={() => {
                            props.onChange(form)
                        }}
                    />
                </ModalContent>
            </Modal>
        </>
    )
}

export default IvrTimeoutForm
