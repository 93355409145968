import { Button } from '@mui/material';
import Selectfield from 'common/fields/Selectfield';
import TimePickerField from 'common/fields/TimePicker';
import GridColumn from 'common/layout/GridColumn';
import { NoRowPadding } from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultSchedulePeriodForm, MonthDays, Months, SchedulePeriodForm, Weekdays } from 'data/forms/users/schedules/schedule_form';
import { useEdit, useEditHook } from 'hooks/edit_hook';
import React, { PropsWithChildren, ReactNode, useCallback, useContext } from 'react';

export interface Props extends PropsWithChildren, FormKeyProps {
    value?: SchedulePeriodForm;
    onChange: (p: SchedulePeriodForm) => void;
    autoUpdate?: boolean;
    childStyle?: any;
    actionChild?: ReactNode;
}

const SchedulePeriodValueForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, _, reduceForm] = useEdit<SchedulePeriodForm>(props.value ?? defaultSchedulePeriodForm());

    const save = useCallback((newForm: SchedulePeriodForm) => {
        props.onChange(newForm);
    }, [props]);

    const changeForm = useEditHook(form, reduceForm, (newForm) => {
        if (props.autoUpdate) {
            save(newForm);
        }
    });

    return (
        <>
            <GridColumn columnDirection>
                <Row style={NoRowPadding}>
                    <LeftColumn>
                        <TimePickerField
                            required
                            label={"Heure de début"}
                            value={form.hours_start}
                            error={errorCtx.getError("hours_start", props.formKeyPath)}
                            name={'hours_start'}
                            editValue={changeForm}
                        />
                    </LeftColumn>
                    <RightColumn>
                        <TimePickerField
                            required
                            label={"Heure de fin"}
                            value={form.hours_end}
                            error={errorCtx.getError("hours_end", props.formKeyPath)}
                            name={'hours_end'}
                            editValue={changeForm}
                        />
                    </RightColumn>
                </Row>
                <Row style={NoRowPadding}>
                    <LeftColumn>
                        <Selectfield
                            multiple
                            label={"Journées de la semaine"}
                            value={form.week_days}
                            error={errorCtx.getError("week_days", props.formKeyPath)}
                            name={'week_days'}
                            editValue={changeForm}
                            options={Weekdays}
                        />
                    </LeftColumn>
                    <RightColumn>
                        <Selectfield
                            multiple
                            label={"Mois"}
                            value={form.months}
                            error={errorCtx.getError("months", props.formKeyPath)}
                            name={'months'}
                            editValue={changeForm}
                            options={Months}
                        />
                    </RightColumn>
                </Row>
                <LargeRow style={NoRowPadding}>
                    <LeftColumn>
                        <Selectfield
                            multiple
                            label={"Jours du mois"}
                            value={form.month_days}
                            error={errorCtx.getError("month_days", props.formKeyPath)}
                            name={'month_days'}
                            editValue={changeForm}
                            options={MonthDays}
                        />
                    </LeftColumn>
                </LargeRow>
            </GridColumn>
            <GridColumn style={{...props.childStyle}}>
                {props.children}
            </GridColumn>
            <GridColumn>
                {!props.autoUpdate &&
                    <Button
                        variant="contained"
                        onClick={() => {
                            save(form);
                            reduceForm(defaultSchedulePeriodForm());
                        }}
                    >
                        Ajouter
                    </Button>
                }
                {props.actionChild}
            </GridColumn>
        </>
    )
}

export default SchedulePeriodValueForm
