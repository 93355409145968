import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import DestinationsProvider from 'components/misc/providers/DestinationsCtx';
import DirectoriesContextProvider from 'components/misc/providers/DirectoriesCtx';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import InboundRouteContextProvider from 'components/misc/providers/IncallCtx';
import { defaultIvrForm, IvrForm, ivrToForm } from 'data/forms/services/ivr/ivr_form';
import { Ivr } from 'data/services/ivr/ivr';
import { useProviders } from 'form-hooks/providers_hook';
import { useData } from 'hooks/data_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect } from 'react';
import { createIvr, getIvr, updateIvr } from 'services/ivr_service';
import IvrFlowFormComponent from './forms/IvrFlow';

export interface Props extends FormKeyProps {
    editValue?: Ivr;
    onExit: (updated: boolean) => void;
}

const IvrFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const formEdit = useEdit<IvrForm>(defaultIvrForm());
    const [form, _, reduceForm] = formEdit;

    const getIvrDetails = useCallback((authCtx: AuthenticationCtx): Promise<Ivr> => {
        if (!props.editValue?.id) {
            return new Promise(resolve => {
                resolve({
                    id: undefined,
                } as Ivr);
            });
        }

        return getIvr(authCtx, props.editValue?.id);
    }, [props.editValue]);

    const { data: ivr, isLoading } = useData(getIvrDetails, { id: undefined });
    useEffect(() => {
        if (!ivr?.id) {
            return
        }

        reduceForm(ivrToForm(ivr));
    }, [ivr]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["ivrs"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateIvr,
        createHandler: createIvr,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <DirectoriesContextProvider>
                    <InboundRouteContextProvider>
                        <DestinationsProvider>
                            <IvrFlowFormComponent isUpdate={props.editValue !== undefined} formEdit={formEdit} />
                            <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
                        </DestinationsProvider>
                    </InboundRouteContextProvider>
                </DirectoriesContextProvider>
            </ErrorContextProvider>
            <LoadingModal isLoading={isLoading || isSaving} />
        </>
    )
}

export default IvrFormComponent
