import { EndpointSIP } from "data/endpoints/endpoint_sip";
import { LineEndpointSIP } from "data/endpoints/line_endpoint_sip";
import { anyEmptyInList, WazoList } from "data/misc/wazo_list";
import { WazoRef } from "data/misc/wazo_ref";

export interface EndpointSIPForm {
    uuid?: string;

    aor_section_options: WazoList;
    auth_section_options: WazoList;
    endpoint_section_options: WazoList;
    identify_section_options: WazoList;
    label?: string
    name?: string
    outbound_auth_section_options: WazoList;
    registration_outbound_auth_section_options: WazoList;
    registration_section_options: WazoList;
    templates: WazoRef[];
    transport?: WazoRef;
}

export const endpointSIPToForm = (endpoint: EndpointSIP|LineEndpointSIP): EndpointSIPForm => {
    return {
        uuid: endpoint.uuid,
        aor_section_options: endpoint.aor_section_options,
        auth_section_options: endpoint.auth_section_options,
        endpoint_section_options: endpoint.endpoint_section_options,
        identify_section_options: endpoint.identify_section_options,
        label: endpoint.label,
        name: endpoint.name,
        outbound_auth_section_options: endpoint.outbound_auth_section_options,
        registration_outbound_auth_section_options: endpoint.registration_outbound_auth_section_options,
        registration_section_options: endpoint.registration_section_options,
        templates: endpoint.templates,
        transport: endpoint.transport,
    };
};

export const defaultEndpointSIPForm = (): EndpointSIPForm => {
    return {
        aor_section_options: [],
        auth_section_options: [],
        endpoint_section_options: [],
        identify_section_options: [],
        outbound_auth_section_options: [],
        registration_section_options: [],
        registration_outbound_auth_section_options: [],
        templates: [],
        label: undefined,
    };
};

export const isValidSipEndpointForm = (form: EndpointSIPForm): boolean => {
    return !anyEmptyInList(form.aor_section_options)
        && !anyEmptyInList(form.auth_section_options)
        && !anyEmptyInList(form.endpoint_section_options)
        && !anyEmptyInList(form.identify_section_options)
        && !anyEmptyInList(form.outbound_auth_section_options)
        && !anyEmptyInList(form.registration_section_options)
        && !anyEmptyInList(form.registration_outbound_auth_section_options);
};
