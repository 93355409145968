import { useCallback, useContext, useState } from "react";
import { NotificationContext } from "components/Notification";
import { handleError } from "services/api";
import { Message } from "data/api/message";
import { ApiError, FieldsMap } from "data/api/api_error";
import { AuthenticationContext, AuthenticationCtx } from "components/misc/providers/AuthCtx";

export interface SaveProps<T, R> {
    id?: string|number;
    createHandler?: (ctx: AuthenticationCtx, val: T) => Promise<R>;
    updateHandler?: (ctx: AuthenticationCtx, val: T) => Promise<Message>;
    onCreate?: (val: R) => void;
    onUpdate?: (res: Message) => void;
    onError?: (e: ApiError) => void;
}

export type SaveFn<T> = (e: T) => void;
export type GetError = (key: string, path?: string) => string;
export type HasError = (key: string, path?: string) => boolean;
export type HasErrorInPath = (path?: string) => boolean;
export type HasAnyErrors = (keys: string[], path?: string) => boolean;
export type HasAnyErrorInPaths = (paths: string[]) => boolean;
export type Error = {
    errors: FieldsMap,
    hasError: HasError,
    getError: GetError,
    hasErrorInPath: HasErrorInPath,
    hasAnyErrors: HasAnyErrors,
    hasAnyErrorInPaths: HasAnyErrorInPaths,
};

export type SaveCtx<T> = {
    isSaving: boolean,
    save: SaveFn<T>,
    error: Error,
};

export function keyPath(paths: (string|undefined)[]): string {
    return paths.filter(path => path != null).map(path => path?.replaceAll(/\.$/g, "")).join(".");
}

export function useSave<T, R>(props: SaveProps<T, R>): SaveCtx<T> {
    const notificationHandler = useContext(NotificationContext);
    const authCtx = useContext(AuthenticationContext);
    const [isDisabled, setIsDisabled] = useState(false);
    const [errorFields, setErrorFields] = useState<FieldsMap>({});

    const save = useCallback((val: T) => {
        setIsDisabled(true);
        const onError = (e: ApiError) => {
            handleError(e, notificationHandler, authCtx);
            if (e.getErrorFields) {
                setErrorFields(e.getErrorFields());
            }
            setIsDisabled(false);
            if (props.onError) {
                props.onError(e);
            }
        };

        if (props.id === undefined) {
            if (!props.createHandler) {
                return;
            }
            props.createHandler(authCtx, val).then((e: R) => {
                setErrorFields({});
                setIsDisabled(false);
                if (props.onCreate) {
                    props.onCreate(e);
                }
            }).catch(onError);
        } else {
            if (!props.updateHandler) {
                return;
            }
            props.updateHandler(authCtx, val).then((message: Message) => {
                setIsDisabled(false);
                setErrorFields({});
                if (props.onUpdate) {
                    props.onUpdate(message);
                }
            }).catch(onError);
        }
    }, [props]);

    const hasError = useCallback((key: string, path?: string): boolean => {
        key = keyPath([path, key]);
        return errorFields[key] !== undefined;
    }, [errorFields])

    const getError = useCallback((key: string, path?: string): string => {
        key = keyPath([path, key]);
        return hasError(key) ? errorFields[key] : "";
    }, [errorFields, hasError]);

    const hasErrorInPath = useCallback((path?: string): boolean => {
        if (!path) {
            return false;
        }

        path = path.replace(/\.$/, "");

        for (const key in errorFields) {
            if (key.startsWith(path)) {
                return true;
            }
        }

        return false;
    }, [errorFields])

    const hasAnyErrors = useCallback((keys: string[], path?: string): boolean => {
        return keys.some(k => hasError(k, path));
    }, [hasError])

    const hasAnyErrorInPaths = useCallback((paths: string[]): boolean => {
        return paths.some(p => hasErrorInPath(p));
    }, [hasErrorInPath])

    return {
        isSaving: isDisabled,
        save,
        error: {
            errors: errorFields,
            hasError,
            getError,
            hasErrorInPath,
            hasAnyErrors,
            hasAnyErrorInPaths,
        }
    };
}
