import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import CheckboxField from 'common/fields/Checkbox';
import Selectfield from 'common/fields/Selectfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import Modal from 'common/modal/Modal';
import ModalClose from 'common/modal/ModalClose';
import ModalContent from 'common/modal/ModalContent';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { ScheduleContext } from 'components/misc/providers/ScheduleCtx';
import { defaultScheduleForm, ScheduleForm, scheduleToForm } from 'data/forms/users/schedules/schedule_form';
import { useEdit } from 'hooks/edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ScheduleGeneralForm from './ScheduleGeneralForm';
import SchedulePeriodsForm from './SchedulePeriodsForm';

export interface Props extends FormKeyProps {
    schedule?: ScheduleForm;
    onChange: (s?: ScheduleForm) => void;
}

const UserScheduleForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const scheduleCtx = useContext(ScheduleContext);
    const [enableSchedule, setEnableSchedule] = useState(props.schedule !== undefined);
    const [showSchedulePeriods, setShowSchedulePeriods] = useState(false);
    const [selectExisting, setSelectExisting] = useState(false);
    const [form, setFormValue, reduceForm] = useEdit<ScheduleForm>(props.schedule ?? defaultScheduleForm());

    const selectSchedule = useCallback((scheduleId: number) => {
        setSelectExisting(false);
        const schedule = scheduleCtx.schedules.find(s => s.id === scheduleId)
        if (!schedule) {
            reduceForm(defaultScheduleForm())
            return;
        }

        const id = form.id;
        const newForm = scheduleToForm(schedule);
        newForm.id = id;
        reduceForm(newForm);
    }, [scheduleCtx, reduceForm]);

    useEffect(() => {
        if (!enableSchedule) {
            props.onChange(undefined);
            return
        }

        props.onChange(form);
    }, [form, enableSchedule]);

    return (
        <>
            <Row>
                <LeftColumn>
                    <CheckboxField
                        label={"Activer l'horaire"}
                        value={enableSchedule}
                        name={""}
                        editValue={(_k, v) => setEnableSchedule(v)}
                    />
                </LeftColumn>
                {enableSchedule &&
                    <RightColumn style={{gap: "1rem", justifyContent: "left"}}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            style={{gap: "1rem"}}
                            onClick={() => setSelectExisting(true)}
                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                            <Typography variant='body1' style={{fontSize: "0.75rem"}}>
                                Choisir un horaire existant
                            </Typography>
                        </Button>
                    </RightColumn>
                }
            </Row>
            {enableSchedule &&
                <>
                    <ScheduleGeneralForm
                        formKeyPath={props.formKeyPath}
                        schedule={form}
                        setter={setFormValue}
                    />
                    <LargeRow>
                        <LeftColumn>
                            <Button
                                variant="outlined"
                                color={errorCtx.hasAnyErrorInPaths([
                                    keyPath([props.formKeyPath, `open_periods`]),
                                    keyPath([props.formKeyPath, "exceptional_periods"])
                                ]) ? "error":"secondary"}
                                style={{width: "100%"}}
                                onClick={() => setShowSchedulePeriods(true)}
                            >
                                Modifier les périodes de l'horaire
                            </Button>
                        </LeftColumn>
                    </LargeRow>
                </>
            }
            {showSchedulePeriods &&
                <Modal onClick={() => setShowSchedulePeriods(false)}>
                    <ModalContent fixedLargeModal>
                        <SchedulePeriodsForm
                            formKeyPath={props.formKeyPath}
                            noGeneralTab
                            schedule={form}
                            onSave={(s) => {
                                setShowSchedulePeriods(false);
                                reduceForm(s);
                            }}
                        ></SchedulePeriodsForm>
                    </ModalContent>
                </Modal>
            }
            {selectExisting &&
                <Modal onClick={() => setSelectExisting(false)}>
                    <ModalContent>
                        <Selectfield
                            autoFocus
                            name=""
                            label="Choisir un horaire existant"
                            value=""
                            editValue={(_, v: number) => {
                                if (!v) {
                                    return
                                }

                                selectSchedule(v);
                            }}
                            options={scheduleCtx.schedules.map((s) => {
                                return {
                                    id: s.id as number,
                                    label: s.name,
                                };
                            })}
                        />
                        <ModalClose onClick={() => setSelectExisting(false)}></ModalClose>
                    </ModalContent>
                </Modal>
            }
        </>
    )
}

export default UserScheduleForm
