import { UserForm } from 'data/forms/users/users/user_form';
import { EditCtx } from 'hooks/edit_hook';
import React from 'react';
import UserFallbackForm from './UserFallbackForm';

export interface Props {
    userEdit: EditCtx<UserForm>;
}

const UserFallbacksForm: React.FC<Props> = (props) => {
    const [form] = props.userEdit;

    return (
        <>
            <UserFallbackForm
                formKeyPath='fallbacks.busy_destination'
                userEdit={props.userEdit}
                label={'Occupé'}
                value={form.fallbacks?.busy_destination}
                fallbackName={'busy_destination'}
            />
            <UserFallbackForm
                formKeyPath='fallbacks.congestion_destination'
                userEdit={props.userEdit}
                label={'Congestion'}
                value={form.fallbacks?.congestion_destination}
                fallbackName={'congestion_destination'}
            />
            <UserFallbackForm
                formKeyPath='fallbacks.fail_destination'
                userEdit={props.userEdit}
                label={'Échec'}
                value={form.fallbacks?.fail_destination}
                fallbackName={'fail_destination'}
            />
            <UserFallbackForm
                formKeyPath='fallbacks.noanswer_destination'
                userEdit={props.userEdit}
                label={'Aucune réponse'}
                value={form.fallbacks?.noanswer_destination}
                fallbackName={'noanswer_destination'}
            />
        </>
    )
}

export default UserFallbacksForm
