import Selectfield from 'common/fields/Selectfield';
import { ContextsContext, getFirstContextOfType } from 'components/misc/providers/ContextsCtx';
import { ContextOptionField } from 'data/endpoints/key_options';
import React, { useContext, useEffect } from 'react';

export interface Props {
    field: ContextOptionField;
    value: string;
    setter: (v: string) => void;
    error?: string;
}

const ContextsListFieldComponent: React.FC<Props> = (props) => {
    const contextsCtx = useContext(ContextsContext);

    useEffect(() => {
        if (props.value !== undefined) {
            return;
        }

        if (props.field?.default) {
            const defaultContext = getFirstContextOfType(contextsCtx.contexts, props.field.default);
            props.setter(defaultContext?.name ?? "");
        }
    }, [props]);

    return (
        <>
            <Selectfield
                name={"value"}
                label={"Context"}
                value={props.value}
                editValue={(_, v) => props.setter(v)}
                options={contextsCtx.contexts.map(c => {
                    return {
                        id: c.name,
                        label: c.label ?? c.name,
                    };
                })}
            />
        </>
    )
}

export default ContextsListFieldComponent
