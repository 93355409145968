import React, { PropsWithChildren } from 'react'
import LeftColumn from 'common/layout/LeftColumn';
import LargeRow from 'common/layout/LargeRow';
import { useEdit } from 'hooks/edit_hook';
import { IvrForm } from 'data/forms/services/ivr/ivr_form';
import Modal from 'common/modal/Modal';
import ModalContent from 'common/modal/ModalContent';
import FormActionsBar from 'components/misc/FormActionsBar';
import DestinationForm from 'components/misc/destinations/DestinationForm';

export interface Props {
    form: IvrForm;
    onChange: (f: IvrForm) => void;
    onCancel: () => void;
}

const IvrAbortForm: React.FC<PropsWithChildren<Props>> = (props) => {
    const [form, setForm] = useEdit(props.form);

    return (
        <>
            <Modal onClick={() => props.onCancel()}>
                <ModalContent>
                    <LargeRow>
                        <LeftColumn>
                            <DestinationForm
                                smallLabel
                                formKeyPath="abort_destination"
                                label={"Destination après que le nombre maximal d'essais est atteint"}
                                value={form.abort_destination}
                                onEdit={(d) => setForm("abort_destination", d)}
                            />
                        </LeftColumn>
                    </LargeRow>
                    <FormActionsBar
                        onCancel={() => props.onCancel()}
                        onConfirm={() => {
                            props.onChange(form)
                        }}
                    />
                </ModalContent>
            </Modal>
        </>
    )
}

export default IvrAbortForm
