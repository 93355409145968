import { EndpointIAX } from "data/endpoints/endpoint_iax"
import { EndpointSIP } from "data/endpoints/endpoint_sip"
import { TrunkForm } from "data/forms/routes/trunks/trunk_form";

export const TrunkTypes = [
    "iax",
    "sip",
] as const;

export const TrunkTypeLabels: {[key: string]: string} = {
    "iax": "IAX",
    "sip": "SIP",
} as const;

export type TrunkType = typeof TrunkTypes[number];

export const IaxTrunkTypes = [
    "friend",
    "peer",
    "user",
] as const;

export const IaxTrunkTypeLabels = {
    "friend": "Friend",
    "peer": "Peer",
    "user": "User",
} as const;

export type IaxTrunkType = typeof IaxTrunkTypes[number];

export interface Trunk {
    name: string;
    label?: string;
    context: string

    id?: number;
    endpoint_sip?: EndpointSIP;
    endpoint_iax?: EndpointIAX;
}

export const getTrunkType = (trunk: Trunk|TrunkForm): TrunkType => {
    return trunk.endpoint_iax ? "iax":"sip";
};
