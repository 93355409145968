import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import IconTooltip from 'common/components/Tooltip';
import Passwordfield from 'common/fields/Passwordfield';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import { ContextsContext } from 'components/misc/providers/ContextsCtx';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { TrunkForm } from 'data/forms/routes/trunks/trunk_form';
import { getValueFromList, updateValueInList, WazoList } from 'data/misc/wazo_list';
import { IaxTrunkTypeLabels, IaxTrunkTypes } from 'data/routes/trunks/trunk';
import { NumericFormat } from 'formats/common';
import { EditCtx, useChangeSubProp } from 'hooks/edit_hook';
import React, { useContext, useEffect, useState } from 'react';

export interface Props extends FormKeyProps {
    formEdit: EditCtx<TrunkForm>;
}

const TrunkIAXGeneralFormComponent: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const contextsCtx = useContext(ContextsContext);
    const [form, setForm, reduceForm] = props.formEdit;
    const [initialized, setInitialized] = useState(false);

    const changeEndpoint = useChangeSubProp("endpoint_iax", form.endpoint_iax, setForm);

    useEffect(() => {
        if (!form.endpoint_iax || initialized) {
            return;
        }

        const newGeneralForm = {
            password: getValueFromList("secret", form.endpoint_iax?.options),
            host: form.endpoint_iax.host,
            port: form.endpoint_iax.register_iax?.remote_port,
        };
        reduceForm(newGeneralForm);
        setInitialized(true);
    }, [form.endpoint_iax]);

    useEffect(() => {
        if (!form.endpoint_iax || !initialized) {
            return;
        }

        let options = form.endpoint_iax?.options;

        const updateCreds = (list: WazoList) => {
            list = updateValueInList("username", form.name, list);
            list = updateValueInList("secret", form.password ?? "", list);

            return list;
        };

        options = updateCreds(options);

        setForm("endpoint_iax", {
            ...form.endpoint_iax,
            host: form.host,
            register_iax: {
                id: form.endpoint_iax.register_iax?.id,
                auth_password: form.password,
                auth_username: form.name,
                remote_host: form.host,
                remote_port: form.port,
            },
            options,
        });
    }, [form]);

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Nom"}
                        value={form.name}
                        error={errorCtx.getError("name", props.formKeyPath)}
                        name={"name"}
                        editValue={setForm}
                    />
                </LeftColumn>
                <RightColumn>
                    <Selectfield
                        label={"Type"}
                        value={form.endpoint_iax?.type}
                        error={errorCtx.getError("type", props.formKeyPath)}
                        name={"type"}
                        editValue={changeEndpoint}
                        options={IaxTrunkTypes.map((v) => {
                            return {
                                id: v,
                                label: IaxTrunkTypeLabels[v],
                            };
                        })}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Selectfield
                        required
                        label={"Contexte"}
                        value={form.context}
                        error={errorCtx.getError("context", props.formKeyPath)}
                        name={"context"}
                        editValue={setForm}
                        options={contextsCtx.contexts?.map(c => {
                            return {
                                id: c.name,
                                label: c.label,
                            };
                        })}
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Passwordfield
                        label={"Mot de passe"}
                        value={form.password}
                        error={errorCtx.getError("password", props.formKeyPath)}
                        name={"password"}
                        editValue={setForm}
                        EndAdornment={
                            <IconTooltip
                                variant="h6"
                                icon={faQuestionCircle}
                                tooltip={"Un mot de passe sera automatiquement atribué si aucun n'est assigné"}
                            />
                        }
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Hôte"}
                        value={form.host}
                        error={errorCtx.getError("host", props.formKeyPath)}
                        name={"host"}
                        editValue={setForm}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Port"}
                        value={form.port}
                        error={errorCtx.getError("port", props.formKeyPath)}
                        name={"port"}
                        editValue={setForm}
                        format={NumericFormat}
                    />
                </RightColumn>
            </Row>
        </>
    )
}

export default TrunkIAXGeneralFormComponent
