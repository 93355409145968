import { Button } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import Textfield from 'common/fields/Textfield';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultWazoRange, WazoRange } from 'data/misc/wazo_range';
import { useListValueForm } from 'form-hooks/list_value_form_hook';
import { NumericFormat } from 'formats/common';
import React, { PropsWithChildren, useContext, useEffect } from 'react';

export interface Props extends FormKeyProps {
    range?: WazoRange;
    saveValue: (p: WazoRange) => void;
}

const ContextUserRangeForm: React.FC<PropsWithChildren<Props>> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const {
        form,
        setValue,
        reset,
        isEditing,
        saveValue
    } = useListValueForm(props.range ?? defaultWazoRange(), props.saveValue);

    useEffect(() => {
        if (!isEditing || !props.range) {
            return;
        }

        saveValue();
    }, [form]);

    return (
        <>
            <Card>
                <CardBody darkBody>
                    <GridRow templateColumns="40% 40% 20%">
                        <GridColumn>
                            <Textfield
                                required
                                error={errorCtx.getError("start", props.formKeyPath)}
                                name={"start"}
                                label={"Extension début"}
                                value={form.start}
                                editValue={setValue}
                                format={NumericFormat}
                            />
                        </GridColumn>
                        <GridColumn>
                            <Textfield
                                required
                                error={errorCtx.getError("end", props.formKeyPath)}
                                name={"end"}
                                label={"Extension fin"}
                                value={form.end}
                                editValue={setValue}
                                format={NumericFormat}
                            />
                        </GridColumn>
                        <GridColumn align='end'>
                            {!props.range && isEditing &&
                                <div style={{ gap: "0.5rem", display: "flex" }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            saveValue();
                                            if (!props.range) {
                                                reset();
                                            }
                                        }}
                                    >
                                        Ok
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        color="secondary"
                                        onClick={() => reset()}
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            }
                        </GridColumn>
                    </GridRow>
                    {props.children}
                </CardBody>
            </Card>
        </>
    )
}

export default ContextUserRangeForm
