import { Destination } from "data/misc/destinations";
import { Ivr } from "data/services/ivr/ivr";

export interface IvrChoiceForm {
    destination: Destination;
    exten: string;
}

export interface IvrForm {
    id?: number;

    name: string
    description?: string;
    menu_sound: string
    max_tries?: number;

    choices: IvrChoiceForm[];

    greeting_sound?: string;

    invalid_sound?: string;
    invalid_destination?: Destination;

    abort_sound?: string;
    abort_destination?: Destination;

    timeout?: number;
    timeout_destination?: Destination;

    directories: string[];
}

export const defaultIvrChoiceForm = (): IvrChoiceForm => {
    return {
        destination: { type: "none" },
        exten: "",
    };
};

export const defaultIvrForm = (): IvrForm => {
    return {
        name: "",
        menu_sound: "",
        choices: [],
        timeout: 5,
        max_tries: 3,
        directories: [],
    };
};

export const ivrToForm = (ivr: Ivr): IvrForm => {
    return {
        id: ivr.id,
        name: ivr.name,
        description: ivr.description,
        menu_sound: ivr.menu_sound,
        max_tries: ivr.max_tries,
        choices: ivr.choices as IvrChoiceForm[],
        greeting_sound: ivr.greeting_sound,
        invalid_sound: ivr.invalid_sound,
        invalid_destination: ivr.invalid_destination as Destination,
        abort_sound: ivr.abort_sound,
        abort_destination: ivr.abort_destination as Destination,
        timeout: ivr.timeout,
        timeout_destination: ivr.timeout_destination as Destination,
        directories: ivr.directories,
    };
};

export const ivrFormToBody = (ivr: IvrForm): object => {
    return {
        ...ivr,
    };
};
