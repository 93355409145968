import { useEffect, useState } from "react";

export const useLoading = (loading: boolean) => {
    const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout|undefined>();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const currentValue = loading;
        clearTimeout(loadingTimeout);

        setLoadingTimeout(setTimeout(() => {
            setLoading(currentValue);
            setLoadingTimeout(undefined);
        }, 150));
    }, [loading]);

    return isLoading;
};
