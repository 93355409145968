export type WazoListValue = [string, string];
export type WazoList = WazoListValue[];

export const getValueFromList = (key: string, list?: WazoList): string => {
    if (!list) {
        return "";
    }

    const val = list.find((v) => v[0] === key);
    return val ? val[1] : "";
};

export const updateValueInList = (key: string, value: string, list: WazoList): WazoList => {
    const i = list.findIndex((v) => v[0] === key);
    if (i === -1) {
        return list;
    }

    const newList = [...list];
    newList[i] = [key, value];
    return newList;
};

export const anyEmptyInList = (list: WazoList): boolean => {
    for (let [key, val] of list) {
        if (!key || !val) {
            return true;
        }
    }

    return false;
}
