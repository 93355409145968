import { KeyOption, OptionListField, StrField, YesNoListField } from "../key_options";

export const IaxChannelGlobalOptions: KeyOption[] = [
    { key: "adsi", field: YesNoListField() },
    { key: "allow" },
    { key: "disallow" },
    { key: "amaflags", field: OptionListField(["default", "omit", "billing", "documentation"], "default") },
    { key: "bandwidth", field: OptionListField(["low", "medium", "high"]) },
    { key: "codecpriority", field: OptionListField(["caller", "host", "disabled", "reqonly"], "host") },
    { key: "forcejitterbuffer", field: OptionListField(["yes"], "yes") },
    { key: "jitterbuffer", field: YesNoListField() },
    { key: "language", field: StrField("fr_CA") },
    { key: "maxjitterbuffer" },
    { key: "maxjitterinterps" },
    { key: "maxregexpire" },
    { key: "minregexpire" },
    { key: "mohinterpret", field: StrField("default") },
    { key: "mohsuggest", field: StrField("default") },
    { key: "regcontext" },
    { key: "regexten" },
    { key: "resyncthreshold" },
    { key: "trunk", field: YesNoListField() },
    { key: "trunkfreq" },
    { key: "trunktimestamps", field: YesNoListField() },
    { key: "accountcode" },
];

export const IaxChannelOnlyOptions: KeyOption[] = [
    { key: "secret" },
    { key: "username" },
    { key: "callerid" },
    { key: "defaultip" },
    { key: "inkeys" },
    { key: "mailbox" },
    { key: "outkey" },
    { key: "qualify" },
    { key: "qualifyfreqok" },
    { key: "qualifyfreqnotok" },
    { key: "qualifysmoothing" },
    { key: "auth" },
    { key: "requirecalltoken", field: YesNoListField("no") },
    { key: "sendani", field: YesNoListField("yes") },
    { key: "transfer", field: OptionListField(["yes", "no", "mediaonly"]) },
];

export const IaxChannelOptions: KeyOption[] = [...IaxChannelGlobalOptions, ...IaxChannelOnlyOptions];

export const IaxGlobalOnlyOptions: KeyOption[] = [
    { key: "authdebug", field: OptionListField(["no"], "no") },
    { key: "autokill" },
    { key: "bindport", field: StrField("4569") },
    { key: "bindaddr", field: StrField("0.0.0.0") },
    { key: "delayreject", field: YesNoListField() },
    { key: "iaxthreads" },
    { key: "iaxmaxthreads" },
    { key: "mailboxdetail", field: OptionListField(["yes"], "yes") },
    { key: "nochecksums", field: OptionListField(["yes"], "yes") },
    { key: "rtautoclear", field: OptionListField(["yes", "no", "seconds"]) },
    { key: "rtcachefriends", field: YesNoListField() },
    { key: "rtignoreregexpire", field: YesNoListField() },
    { key: "rtupdate", field: YesNoListField("yes") },
    {
        key: "tos", field: OptionListField([
            "CS0", "CS1", "CS2", "CS3", "CS4", "CS5", "CS6", "CS7", "AF11", "AF12", "AF13", "AF21", "AF22",
            "AF23", "AF31", "AF32", "AF33", "AF41", "AF42", "AF43", "ef"
        ])
    },
];

export const IaxGlobalOptions: KeyOption[] = [...IaxGlobalOnlyOptions, ...IaxChannelGlobalOptions];
