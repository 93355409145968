import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import LargeRow from '../layout/LargeRow';
import LeftColumn from '../layout/LeftColumn';
import { InfoDisplay } from './Display';

export interface Props extends PropsWithChildren {
    style?: any;
    items: any[];
    byFilter?: boolean;
    itemsCount?: number;
    maxItems?: number;
    refreshPage?: any;
    itemsLabel?: string;
    topAction?: (pageActions: () => any) => any;
    header?: () => any;
    listElementComponent: (item: any, index: number, globalIndex: number) => any;
    onPageChange?: (page: number) => void;
}

const ListDisplay: React.FC<Props> = (props) => {
    const maxItems = props.maxItems ? props.maxItems:5;
    const ref = useRef<any>();
    const [page, setPage] = useState(0);
    const [position, setPosition] = useState({x: 0, y: 0});

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(position.x, position.y);
        }, 100);
        if (props.onPageChange) {
            props.onPageChange(page);
        }
    }, [page]);

    useEffect(() => {
        if (!ref || !ref.current) {
            return;
        }

        const observerCallback = (entries: ResizeObserverEntry[]) => {
            window.requestAnimationFrame(() => {
                const target = entries[0].target as any;
                setPosition({x: target.offsetLeft, y: target.offsetTop});
            });
        }
        const observer = new ResizeObserver(observerCallback);
        observer.observe(ref.current);
        return () => ref.current && observer.unobserve(ref.current)
    }, [ref.current]);

    useEffect(() => {
        setPage(0);
    }, [props.refreshPage]);

    const itemsCount = useCallback(() => {
        return props.itemsCount ? props.itemsCount:props.items.length;
    }, [props.items]);

    const pageCount = useCallback(() => {
        return Math.max(0, Math.ceil(itemsCount() / maxItems) - 1);
    }, [maxItems, props.items, itemsCount]);

    const getDisplayItems = useCallback(() => {
        if (props.byFilter) {
            return props.items;
        }

        return props.items.slice(page * maxItems, (page + 1) * maxItems);
    }, [maxItems, props.items, page, props.byFilter]);

    const pageActions = useCallback(() => {
        return (
            <div style={{display: "flex", gap: "0.5rem", justifyContent: "center"}}>
                <Button
                    disabled={page === 0}
                    variant="contained"
                    className="icon-btn"
                    onClick={() => setPage(0)}
                    color="secondary"
                >
                    <FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>
                </Button>
                <Button
                    disabled={page === 0}
                    variant="contained"
                    className="icon-btn"
                    onClick={() => setPage(n => Math.max(n-1, 0))}
                    color="secondary"
                >
                    <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                </Button>
                <Button
                    disabled={page === pageCount()}
                    variant="contained"
                    className="icon-btn"
                    onClick={() => setPage(n => Math.min(n+1, pageCount()))}
                    color="secondary"
                >
                    <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                </Button>
                <Button
                    disabled={page === pageCount()}
                    variant="contained"
                    className="icon-btn"
                    onClick={() => setPage(pageCount)}
                    color="secondary"
                >
                    <FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon>
                </Button>
            </div>
        );
    }, [pageCount, setPage]);

    return (
        <div ref={ref} style={{width: "100%", marginBottom: "2rem", ...props.style}}>
            <div style={{display: "grid", gridTemplateColumns: "100%", gap: "0.5rem", marginBottom: "1rem"}}>
                {props.topAction && props.topAction(pageActions)}
                {props.header && props.header()}
                { getDisplayItems().map((item: any, index: number) => (
                    props.listElementComponent(item, index, index + (page * maxItems))
                ))}
                <LargeRow>
                    <LeftColumn>
                        <InfoDisplay columnDirection align={"center"}>
                            <Typography variant='body1'>Page <b>{page + 1} / {pageCount() + 1}</b></Typography>
                            <Typography variant='body1'><b>{getDisplayItems().length}</b> {props.itemsLabel ? props.itemsLabel:"record(s)"} sur <b>{itemsCount()}</b></Typography>
                        </InfoDisplay>
                    </LeftColumn>
                </LargeRow>
                {props.children}
            </div>
            {pageActions()}
        </div>
    )
}

export default ListDisplay
