import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import { OutboundRoute } from 'data/routes/outbound/outbound_route';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getOutboundRoutes } from 'services/outbound_routes_service';
import OutboundRouteFormComponent from './OutboundRouteForm';
import OutboundRoutesList from './OutboundRoutesList';

const OutboundRoutes: React.FC = (props) => {
    const outboundRoutesList = useFilterList(getOutboundRoutes, "name");
    const { state, editValue, setState } = useComponent<OutboundRoute>();

    return (
        <Content>
            {state === "list" &&
                <OutboundRoutesList
                    outboundRoutesList={outboundRoutesList}
                    showForm={(outboundRoute?: OutboundRoute) => {
                        setState("form", outboundRoute);
                    }}
                />
            }
            {state === "form" &&
                <OutboundRouteFormComponent
                    editValue={editValue}
                    onExit={(updated) => {
                        if (updated) {
                            outboundRoutesList.refreshList();
                        }
                        setState("list", undefined);
                    }}
                />
            }
            <LoadingModal smallLoader isLoading={outboundRoutesList.isLoading} />
        </Content>
    )
}

export default OutboundRoutes
