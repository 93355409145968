import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Typography, useTheme } from '@mui/material';
import 'styles/index.css';
import NavBtn from './NavigationBtn.styled';

export interface NavigationProps {
    icon: IconDefinition;
    isCurrentRoute: boolean;
    label: string;
    description?: string;
    onClick: () => void;
}

export const NavigationBtn = (props: NavigationProps) => {
    const theme = useTheme();

    const btnEl =
        <NavBtn
            theme={theme}
            variant='text'
            onClick={props.onClick}
            style={{color: props.isCurrentRoute ? theme.palette.success.main:theme.palette.text.primary}}
        >
            <FontAwesomeIcon style={{ fontSize: "1.25rem" }} icon={props.icon}></FontAwesomeIcon>
            <Typography variant='body1' style={{ fontSize: "0.85rem", textAlign: "left" }}>
                {props.label}
            </Typography>
        </NavBtn>;

    return (
        <Tooltip
            style={{ justifyItems: "center", justifyContent: "center", alignItems: "center" }}
            hidden
            enterTouchDelay={0}
            leaveTouchDelay={10000}
            title={props.description ? props.description : props.label}
            placement={"right"}
        >
            {btnEl}
        </Tooltip>
    );
}

export default NavigationBtn;
