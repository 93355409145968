import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import GridRow from 'common/layout/GridRow';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { SchedulePeriodForm } from 'data/forms/users/schedules/schedule_form';
import { ListValue, useFormListPropEdit } from 'hooks/list_edit_hook';
import { keyPath } from 'hooks/save_hook';
import React from 'react';
import SchedulePeriodValueForm from './SchedulePeriodValueForm';

export interface Props extends FormKeyProps {
    periods: SchedulePeriodForm[];
    onChange: (p: SchedulePeriodForm[]) => void;
}

const SchedulePeriodsFormComponent: React.FC<Props> = (props) => {
    const listEdit = useFormListPropEdit<SchedulePeriodForm>("", props.periods, (_, v) => props.onChange(v));

    return (
        <>
            <Card>
                <CardBody smallPadding darkBody>
                    <GridRow columnsForAllSmallerScreens templateColumns='85% 0% 15%'>
                        <SchedulePeriodValueForm onChange={(v: SchedulePeriodForm) => {
                            listEdit.addValue(v);
                        }} />
                    </GridRow>
                </CardBody>
            </Card>
            {listEdit.values.map((v: ListValue<SchedulePeriodForm>, index: number) => (
                <Card key={v.index}>
                    <CardBody smallPadding darkBody>
                        <GridRow columnsForAllSmallerScreens templateColumns='85% 0% 15%'>
                            <SchedulePeriodValueForm
                                autoUpdate
                                formKeyPath={keyPath([props.formKeyPath, index.toString()])}
                                value={v.value}
                                onChange={(l: SchedulePeriodForm) => {
                                    listEdit.editValue({
                                        index: v.index,
                                        value: l,
                                    });
                                }}
                                actionChild={
                                    <DeleteBtn
                                        style={{ top: "1rem" }}
                                        onClick={() => listEdit.removeValue(index)}
                                    />
                                }
                            ></SchedulePeriodValueForm>
                        </GridRow>
                    </CardBody>
                </Card>
            ))}
        </>
    )
}

export default SchedulePeriodsFormComponent
