import React, { PropsWithChildren } from "react"
import Shadow from "common/Shadow"
import { useTheme } from "@mui/material";
import { StyledCardBody } from "./CardBody.styled";

export interface CardBodyProps extends PropsWithChildren {
    className?: string;
    style?: React.CSSProperties;
    noShadow?: boolean;
    editable?: boolean;
    noTopBorderRadius?: boolean;
    smallPadding?: boolean;
    darkBody?: boolean;
    onClick?: () => void;
}

const CardBody: React.FC<CardBodyProps> = (props) => {
    const theme = useTheme();

    return (
        <Shadow hidden={props.noShadow}>
            <StyledCardBody
                onClick={() => props.onClick ? props.onClick():{}}
                theme={theme}
                color={props.darkBody ? 'default':'card'}
                noTopBorderRadius={props.noTopBorderRadius}
                smallPadding={props.smallPadding}
                editable={props.editable}
                className={props.className}
                style={{...props.style}}
            >
                {props.children}
            </StyledCardBody>
        </Shadow>
    )
}

export default CardBody
