import { Typography } from '@mui/material';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import EndpointSIPFormComponent from 'components/endpoints/EndpointSIPForm';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import ContextsContextProvider from 'components/misc/providers/ContextsCtx';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import SipTemplateContextProvider from 'components/misc/providers/SipTemplateCtx';
import { SipTemplate } from 'data/advanced/sip_templates/sip_template';
import { defaultSipTemplateForm, SipTemplateForm, sipTemplateToForm } from 'data/forms/advanced/sip_templates/sip_template_form';
import { isValidSipEndpointForm } from 'data/forms/endpoints/endpoint_sip_form';
import { useProviders } from 'form-hooks/providers_hook';
import { useData } from 'hooks/data_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect } from 'react';
import { createSipTemplate, getSipTemplate, updateSipTemplate } from 'services/sip_templates_service';
import SipTemplateGeneralForm from './forms/SipTemplateGeneralForm';

export interface Props extends FormKeyProps {
    editValue?: SipTemplate;
    onExit: (updated: boolean) => void;
}

const SipTemplateFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const formEdit = useEdit<SipTemplateForm>(defaultSipTemplateForm());
    const [form, setForm, reduceForm] = formEdit;

    const getTemplateDetails = useCallback((authCtx: AuthenticationCtx): Promise<SipTemplate> => {
        if (!props.editValue?.uuid) {
            return new Promise(resolve => {
                resolve({
                    uuid: undefined,
                } as SipTemplate);
            });
        }

        return getSipTemplate(authCtx, props.editValue?.uuid);
    }, [props.editValue]);

    const { data: template, isLoading } = useData(getTemplateDetails, { id: undefined });
    useEffect(() => {
        if (!template?.uuid) {
            return
        }

        reduceForm(sipTemplateToForm(template));
    }, [template]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["sipTemplates"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.uuid,
        updateHandler: updateSipTemplate,
        createHandler: createSipTemplate,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <ContextsContextProvider>
                    <SipTemplateContextProvider>
                        <Row>
                            <LeftColumn style={{ justifyContent: "start" }}>
                                <Typography variant='h5'>Gabarit SIP</Typography>
                            </LeftColumn>
                        </Row>
                        <EndpointSIPFormComponent
                            endpoint={form}
                            setter={setForm}
                            reducer={reduceForm}
                        >
                            <SipTemplateGeneralForm valueEdit={formEdit} />
                        </EndpointSIPFormComponent>
                        <FormActionsBar
                            disabled={!isValidSipEndpointForm(form)}
                            onCancel={() => props.onExit(false)}
                            onConfirm={() => save(form)}
                        />
                    </SipTemplateContextProvider>
                </ContextsContextProvider>
            </ErrorContextProvider>
            <LoadingModal isLoading={isLoading || isSaving} />
        </>
    )
}

export default SipTemplateFormComponent
