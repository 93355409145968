import { WazoDidRange, WazoRange } from "data/misc/wazo_range";
import { WazoRef } from "data/misc/wazo_ref";

export const ContextTypes = [
    "internal",
    "incall",
    "outcall",
] as const;

export const ContextTypeLabels = {
    "internal": "Interne",
    "incall": "Entrant",
    "outcall": "Sortant",
};

export type ContextType = typeof ContextTypes[number];

export interface Context {
    type: ContextType;
    name: string;
    user_ranges?: WazoRange[];
    incall_ranges?: WazoDidRange[];
    label: string

    id?: number;

    contexts?: WazoRef[];
}
