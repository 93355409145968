import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { MarkerType, Node, Position, XYPosition } from "@xyflow/react";
import DeleteBtn from "common/components/DeleteBtn";
import FlowActionBtn from "components/misc/flow/FlowActionBtn";
import { InteractableFlowNodeType, IvrFlowChoiceNodeType, IvrFlowNodeType } from "components/misc/flow/FlowContainer";
import { DestinationCtx } from "components/misc/providers/DestinationFormContext";
import { defaultIvrChoiceForm, IvrChoiceForm, IvrForm } from "data/forms/services/ivr/ivr_form";
import { EditCtx, ReduceFn } from "hooks/edit_hook";
import { FormListPropEdit } from "hooks/list_edit_hook";
import { ExpandCtx } from "./IvrFlow";
import { FlowChoiceNodeHeight, FlowChoiceNodeWidth } from "./IvrFlowChoiceNode";

export const TimeoutId = "ivr-dst-timeout";
export const InvalidId = "ivr-dst-invalid";
export const AbortId = "ivr-dst-abort";
export const ChoicesId = "ivr-choices";

export const getMainIvrNode = (formEdit: EditCtx<IvrForm>) => {
    const [form, _, reduceForm] = formEdit;

    return {
        id: 'ivr-main',
        type: IvrFlowNodeType,
        draggable: false,
        position: { x: 0, y: -100 },
        data: {
            form: form,
            onChange: reduceForm,
        },
    };
}

export const getTimeoutIvrNode = (
    timeout: Node | undefined,
    formEdit: EditCtx<IvrForm>,
    changeForm: ReduceFn,
    expand: EditCtx<ExpandCtx>,
    destinationCtx: DestinationCtx,
) => {
    const [form] = formEdit;
    const [_s, _s1, reduceExpand] = expand;

    return {
        id: TimeoutId,
        type: InteractableFlowNodeType,
        position: { x: timeout?.position?.x ?? 550, y: timeout?.position?.y ?? -70 },
        draggable: true,
        data: {
            formEdit: formEdit,
            title: "Temps maximal par essai atteint",
            disabled: form.timeout_destination == null,
            label: `Destination: ${destinationCtx.getDestinationLabel(form.timeout_destination)}`,
            formKeyPath: "timeout_destination",
            actionComponent: <FlowActionBtn
                icon={faPlus}
                onClick={() => changeForm({
                    timeout: 5,
                    timeout_destination: { type: "none" },
                })}
            />,
            deleteComponent: <DeleteBtn
                onClick={() => changeForm({
                    timeout: undefined,
                    timeout_destination: undefined,
                })}
            />,
            onExpand: () => reduceExpand({ type: "timeout" }),
            targetPosition: Position.Left,
            style: { width: "25rem" },
        },
    };
};

export const getInvalidIvrNode = (
    invalid: Node | undefined,
    formEdit: EditCtx<IvrForm>,
    changeForm: ReduceFn,
    expand: EditCtx<ExpandCtx>,
    destinationCtx: DestinationCtx,
) => {
    const [form] = formEdit;
    const [_s, _s1, reduceExpand] = expand;

    return {
        id: InvalidId,
        type: InteractableFlowNodeType,
        position: { x: invalid?.position?.x ?? 550, y: invalid?.position?.y ?? 95 },
        draggable: true,
        data: {
            formEdit: formEdit,
            title: "Opération invalide",
            disabled: form.invalid_destination == null,
            label: `Destination: ${destinationCtx.getDestinationLabel(form.invalid_destination)}`,
            formKeyPath: "invalid_destination",
            actionComponent: <FlowActionBtn
                icon={faPlus}
                onClick={() => changeForm({
                    invalid_sound: undefined,
                    invalid_destination: { type: "none" },
                })}
            />,
            deleteComponent: <DeleteBtn
                onClick={() => changeForm({
                    invalid_sound: undefined,
                    invalid_destination: undefined,
                })}
            />,
            onExpand: () => reduceExpand({ type: "invalid" }),
            targetPosition: Position.Left,
            style: { width: "25rem" },
        },
    };
};

export const getAbortIvrNode = (
    abort: Node | undefined,
    formEdit: EditCtx<IvrForm>,
    changeForm: ReduceFn,
    expand: EditCtx<ExpandCtx>,
    destinationCtx: DestinationCtx,
) => {
    const [form] = formEdit;
    const [_s, _s1, reduceExpand] = expand;

    return {
        id: AbortId,
        type: InteractableFlowNodeType,
        position: { x: abort?.position?.x ?? 550, y: abort?.position?.y ?? 262 },
        draggable: true,
        data: {
            formEdit: formEdit,
            title: "Nombre maximal d'essais atteint",
            disabled: form.abort_destination == null,
            label: `Destination: ${destinationCtx.getDestinationLabel(form.abort_destination)}`,
            formKeyPath: "abort_destination",
            actionComponent: <FlowActionBtn
                icon={faPlus}
                onClick={() => changeForm({
                    abort_sound: undefined,
                    abort_destination: { type: "none" }
                })}
            />,
            deleteComponent: <DeleteBtn
                onClick={() => changeForm({
                    abort_sound: undefined,
                    abort_destination: undefined,
                })}
            />,
            onExpand: () => reduceExpand({ type: "abort" }),
            targetPosition: Position.Left,
            style: { width: "25rem" },
        },
    };
};

export const getIvrChoicesNodes = (
    choicesListEdit: FormListPropEdit<IvrChoiceForm>,
    choices: Node | undefined,
    formEdit: EditCtx<IvrForm>,
    expand: EditCtx<ExpandCtx>,
    refresh: () => void,
    getChoiceNodePosition: (index: string | number) => XYPosition | undefined,
) => {
    const [_s, _s1, reduceExpand] = expand;

    return [{
        id: ChoicesId,
        type: InteractableFlowNodeType,
        position: { x: choices?.position?.x ?? -160, y: choices?.position?.y ?? 520 },
        data: {
            formEdit: formEdit,
            title: "Ajouter un choix",
            disabled: true,
            style: {
                width: `${FlowChoiceNodeWidth}px`,
            },
            actionComponent: <FlowActionBtn
                icon={faPlus}
                onClick={() => {
                    choicesListEdit.addValue(defaultIvrChoiceForm());
                    refresh();
                }}
            />,
            targetPosition: Position.Right,
        },
    },
    ...choicesListEdit.values.map((v, i: number) => {
        const sign = (i % 2 === 0 ? -1 : 1);
        return {
            id: v.index,
            type: IvrFlowChoiceNodeType,
            position: {
                x: getChoiceNodePosition(v.index)?.x ?? 0 + (sign === -1 ? -FlowChoiceNodeWidth / 2 : FlowChoiceNodeWidth),
                y: getChoiceNodePosition(v.index)?.y ?? 650 + (FlowChoiceNodeHeight + 50) * Math.floor(i / 2),
            },
            data: {
                value: v.value,
                onChange: (value: IvrChoiceForm) => choicesListEdit.editValue({
                    index: v.index,
                    value: value,
                }),
                targetPosition: sign === -1 ? Position.Right : Position.Left,
                formKeyPath: `choices.${i}`,
                deleteComponent: <DeleteBtn
                    onClick={() => {
                        choicesListEdit.removeValue(i);
                        refresh();
                    }}
                />,
                onExpand: () => reduceExpand({
                    type: "choice",
                    data: {
                        i: i,
                        index: v.index,
                    },
                }),
            },
        };
    })
    ];
};

export const getIvrEdges = (
    choicesListEdit: FormListPropEdit<IvrChoiceForm>,
    formEdit: EditCtx<IvrForm>,
    theme: any,
) => {
    const [form] = formEdit;

    const strokeStyle = {
        strokeWidth: 2,
        stroke: theme.palette.primary.main,
    };

    return [
        {
            id: 'main-timeout',
            source: 'ivr-main',
            sourceHandle: "timeout",
            target: TimeoutId,
            animated: form.timeout_destination != null,
            focusable: false,
            style: strokeStyle
        },
        {
            id: 'main-invalid',
            source: 'ivr-main',
            sourceHandle: "invalid",
            target: InvalidId,
            animated: form.invalid_destination != null,
            focusable: false,
            style: strokeStyle,
        },
        {
            id: 'main-abort',
            source: 'ivr-main',
            sourceHandle: "abort",
            target: AbortId,
            animated: form.abort_destination != null,
            focusable: false,
            style: strokeStyle,
        },
        {
            id: 'main-add-entry',
            source: 'ivr-main',
            sourceHandle: "choices",
            target: ChoicesId,
            animated: false,
            focusable: false,
        },
        ...choicesListEdit.values.map((v) => {
            return {
                id: `${v.index}-edge`,
                source: 'ivr-main',
                sourceHandle: "choices",
                target: v.index,
                animated: true,
                focusable: false,
                reconnectable: false,
                markerEnd: {
                    type: MarkerType.Arrow,
                    width: 20,
                    height: 20,
                    color: theme.palette.primary.main,
                },
                style: strokeStyle,
            };
        })
    ];
};
