import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import GridRow from 'common/layout/GridRow';
import DestinationForm from 'components/misc/destinations/DestinationForm';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultScheduleExceptionalPeriodForm, ExceptionalPeriodForm } from 'data/forms/users/schedules/schedule_form';
import { useEdit, useEditHook, useReduceEditHook } from 'hooks/edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { PropsWithChildren, useCallback } from 'react';
import SchedulePeriodValueForm from './SchedulePeriodValueForm';

export interface Props extends PropsWithChildren, FormKeyProps {
    value?: ExceptionalPeriodForm;
    onChange: (p: ExceptionalPeriodForm) => void;
    autoUpdate?: boolean;
}

const ScheduleExceptionalPeriodValueForm: React.FC<Props> = (props) => {
    const [form, _, reduceForm] = useEdit<ExceptionalPeriodForm>(props.value ?? defaultScheduleExceptionalPeriodForm());

    const save = useCallback((newForm: ExceptionalPeriodForm) => {
        props.onChange(newForm);
        if (!props.autoUpdate) {
            reduceForm(defaultScheduleExceptionalPeriodForm());
        }
    }, [props]);

    const changeForm = useEditHook(form, reduceForm, (newForm) => {
        if (!props.autoUpdate) {
            return;
        }
        save(newForm);
    });
    const changeReduceForm = useReduceEditHook(form, reduceForm, (newForm) => {
        save(newForm);
    });

    return (
        <>
            <Card>
                <CardBody smallPadding darkBody>
                    <GridRow columnsForAllSmallerScreens templateColumns='60% 30% 10%'>
                        <SchedulePeriodValueForm
                            formKeyPath={props.formKeyPath}
                            autoUpdate={props.autoUpdate}
                            value={form}
                            onChange={(p) => changeReduceForm({
                                ...p,
                                destination: form.destination
                            })}
                            childStyle={{
                                justifyContent: "start",
                                alignItems: "start",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            actionChild={props.children}
                        >
                            <DestinationForm
                                smallLabel
                                formKeyPath={keyPath([props.formKeyPath, "destination"])}
                                separateRowLabel
                                label={"Destination"}
                                value={form.destination}
                                onEdit={(d) => changeForm("destination", d)}
                            />
                        </SchedulePeriodValueForm>
                    </GridRow>
                </CardBody>
            </Card>
        </>
    )
}

export default ScheduleExceptionalPeriodValueForm
