import { defaultFilterResult } from 'data/api/filter_result';
import { Voicemail } from 'data/users/voicemails/voicemail';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllVoicemails } from 'services/voicemails_service';

export interface VoicemailCtx {
    voicemails: Voicemail[];
    refresh: () => void;
}

export const VoicemailContext = createContext<VoicemailCtx>({
    voicemails: [],
    refresh: () => { },
});

const VoicemailContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: voicemails, refresh } = useCachedData("voicemails", getAllVoicemails, defaultFilterResult());

    const ctx = useMemo<VoicemailCtx>(() => {
        return {
            voicemails: voicemails.items,
            refresh: refresh,
        }
    }, [voicemails]);

    return (
        <VoicemailContext.Provider value={ctx}>
            {props.children}
        </VoicemailContext.Provider>
    )
}

export default VoicemailContextProvider
