import styled from '@emotion/styled';
import { Button } from '@mui/material';

const NavBtn = styled(Button)`
    display: grid;
    grid-template-columns: 4rem auto;
    width: 100%;
    justify-content: left !important;
    margin: 0.25rem 0;

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        grid-template-columns: 100%;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 100%;
    }
`;

export default NavBtn;
