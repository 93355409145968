import { defaultFilterResult } from 'data/api/filter_result';
import { Trunk } from 'data/routes/trunks/trunk';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllTrunks } from 'services/trunks_service';

export interface TrunkCtx {
    trunks: Trunk[];
    refresh: () => void;
}

export const TrunkContext = createContext<TrunkCtx>({
    trunks: [],
    refresh: () => { },
});

const TrunkContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: trunks, refresh } = useCachedData("trunks", getAllTrunks, defaultFilterResult());

    const ctx = useMemo<TrunkCtx>(() => {
        return {
            trunks: trunks.items,
            refresh: refresh,
        }
    }, [trunks]);

    return (
        <TrunkContext.Provider value={ctx}>
            {props.children}
        </TrunkContext.Provider>
    )
}

export default TrunkContextProvider
