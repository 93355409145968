import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import FormTab from 'components/misc/Tab';
import FormTabs from 'components/misc/Tabs';
import { IaxChannelOptions } from 'data/endpoints/iax_key_options/iax_key_options';
import { EndpointIAXForm } from 'data/forms/endpoints/endpoint_iax_form';
import { anyEmptyInList } from 'data/misc/wazo_list';
import { ReduceFn, Setter } from 'hooks/edit_hook';
import React, { PropsWithChildren, useState } from 'react';
import EndpointKeyValuesForm from './EndpointKeyValuesForm';

export interface Props extends PropsWithChildren {
    endpoint: EndpointIAXForm;
    setter: Setter;
    reducer: ReduceFn;
    errorGeneral?: boolean;
}

const EndpointIAXFormComponent: React.FC<Props> = (props) => {
    const {setter: setForm, endpoint: form} = props;
    const [tab, setTab] = useState("general");

    return (
        <>
            <FormTabs style={{ marginTop: "1rem" }} tab={tab} setTab={setTab}>
                <FormTab value={"general"} label={"Général"} error={tab !== "general" && props.errorGeneral} />
                <FormTab value={"options"} label={"Options"} error={tab !== "options" && anyEmptyInList(form.options)} />
            </FormTabs>
            <Card>
                <CardBody noTopBorderRadius noShadow>
                    {tab === "general" &&
                        <>
                            {props.children}
                        </>
                    }
                    {tab === "options" &&
                        <EndpointKeyValuesForm
                            options={IaxChannelOptions}
                            wazolist={form.options}
                            onChange={(v) => setForm("options", v)}
                        />
                    }
                </CardBody>
            </Card>
        </>
    )
}

export default EndpointIAXFormComponent
