import { tenantUrl, get, jsonBody, post, put } from './api';
import { FilterResult } from 'data/api/filter_result';
import { SipTemplate } from 'data/advanced/sip_templates/sip_template';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import { Message } from 'data/api/message';
import { SipTemplateForm } from 'data/forms/advanced/sip_templates/sip_template_form';
import { buildQueryString, Filter } from 'data/api/filter';

const endpoint = "templates/sip";

export const getAllSipTemplates = (authCtx: AuthenticationCtx): Promise<FilterResult<SipTemplate>> => {
    return get<FilterResult<SipTemplate>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getSipTemplates = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<SipTemplate>> => {
    return get<FilterResult<SipTemplate>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getSipTemplate = (authCtx: AuthenticationCtx, id: string): Promise<SipTemplate> => {
    return get<SipTemplate>({
        url: tenantUrl(`${endpoint}/by/id/${id}`, authCtx),
        authentication: authCtx,
    });
}

export const updateSipTemplate = (authCtx: AuthenticationCtx, sipTemplate: SipTemplateForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${sipTemplate.uuid}`, authCtx),
        authentication: authCtx,
    }, jsonBody(sipTemplate));
}

export const createSipTemplate = (authCtx: AuthenticationCtx, sipTemplate: SipTemplateForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(sipTemplate));
}
