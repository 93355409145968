import { defaultFilterResult } from 'data/api/filter_result';
import { Device } from 'data/users/devices/device';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { getAllDevices } from 'services/devices_service';
import { PluginContext } from './PluginCtx';

export interface DeviceCtx {
    devices: Device[];
    plugins: string[];
    getDevice: (id: string) => Device | undefined;
    refresh: () => void;
}

export const DeviceContext = createContext<DeviceCtx>({
    devices: [],
    plugins: [],
    getDevice: (id: string) => { return undefined },
    refresh: () => { },
});

const DeviceContextProvider: React.FC<PropsWithChildren> = (props) => {
    const pluginCtx = useContext(PluginContext);
    const { data: devices, refresh } = useCachedData("devices", getAllDevices, defaultFilterResult());

    const ctx = useMemo<DeviceCtx>(() => {
        return {
            devices: devices.items,
            plugins: pluginCtx.plugins,
            getDevice: (id: string) => {
                return devices.items.find(d => d.id === id);
            },
            refresh: refresh,
        }
    }, [devices, pluginCtx]);

    return (
        <DeviceContext.Provider value={ctx}>
            {props.children}
        </DeviceContext.Provider>
    )
}

export default DeviceContextProvider
