import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import DeleteBtn from 'common/components/DeleteBtn';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { ExceptionalPeriodForm } from 'data/forms/users/schedules/schedule_form';
import { ListValue, useFormListPropEdit } from 'hooks/list_edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { PropsWithChildren } from 'react';
import ScheduleExceptionalPeriodValueForm from './ScheduleExceptionalPeriodValueForm';

export interface Props extends PropsWithChildren, FormKeyProps {
    periods: ExceptionalPeriodForm[];
    onChange: (p: ExceptionalPeriodForm[]) => void;
}

const ScheduleExceptionalPeriodsFormComponent: React.FC<Props> = (props) => {
    const listEdit = useFormListPropEdit<ExceptionalPeriodForm>("", props.periods, (_, v) => props.onChange(v));

    return (
        <>
            <ScheduleExceptionalPeriodValueForm onChange={(v: ExceptionalPeriodForm) => {
                listEdit.addValue(v);
            }} />
            {listEdit.values.map((v: ListValue<ExceptionalPeriodForm>, index: number) => (
                <ScheduleExceptionalPeriodValueForm
                    autoUpdate
                    formKeyPath={keyPath([props.formKeyPath, index.toString()])}
                    key={v.index}
                    value={v.value}
                    onChange={(l: ExceptionalPeriodForm) => {
                        listEdit.editValue({
                            index: v.index,
                            value: l,
                        });
                    }}
                >
                    <DeleteBtn
                        style={{ top: "1rem" }}
                        onClick={() => listEdit.removeValue(index)}
                    />
                </ScheduleExceptionalPeriodValueForm>
            ))}
        </>
    )
}

export default ScheduleExceptionalPeriodsFormComponent
