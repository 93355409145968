import { tenantUrl, get, jsonBody, post, put } from './api';
import { buildQueryString, Filter } from 'data/api/filter';
import { FilterResult } from 'data/api/filter_result';
import { Message } from 'data/api/message';
import { Trunk } from 'data/routes/trunks/trunk';
import { TrunkForm, trunkFormToBody } from 'data/forms/routes/trunks/trunk_form';
import { TrunkCreationForm, trunkCreationFormToBody } from 'data/forms/routes/trunks/trunk_creation_form';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';

const endpoint = "trunk";

export const getAllTrunks = (authCtx: AuthenticationCtx): Promise<FilterResult<Trunk>> => {
    return get<FilterResult<Trunk>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getTrunks = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Trunk>> => {
    return get<FilterResult<Trunk>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getTrunk = (authCtx: AuthenticationCtx, trunkId: number): Promise<Trunk> => {
    return get<Trunk>({
        url: tenantUrl(`${endpoint}/by/id/${trunkId}`, authCtx),
        authentication: authCtx,
    });
}

export const updateTrunk = (authCtx: AuthenticationCtx, trunk: TrunkForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${trunk.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(trunkFormToBody(trunk)));
}

export const createTrunk = (authCtx: AuthenticationCtx, trunk: TrunkCreationForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(trunkCreationFormToBody(trunk)));
}
