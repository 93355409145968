import { PropsWithChildren } from 'react'
import ContextsContextProvider from './ContextsCtx';
import DestinationContextProvider from './DestinationFormContext';
import IvrContextProvider from './IvrCtx';
import RingGroupContextProvider from './RingGroupCtx';
import ScheduleContextProvider from './ScheduleCtx';
import UserContextProvider from './UserCtx';
import VoicemailContextProvider from './VoicemailCtx';

const DestinationsProvider: React.FC<PropsWithChildren> = (props) => {
    return (
        <UserContextProvider>
            <IvrContextProvider>
                <ContextsContextProvider>
                    <VoicemailContextProvider>
                        <RingGroupContextProvider>
                            <ScheduleContextProvider>
                                <DestinationContextProvider>
                                    {props.children}
                                </DestinationContextProvider>
                            </ScheduleContextProvider>
                        </RingGroupContextProvider>
                    </VoicemailContextProvider>
                </ContextsContextProvider>
            </IvrContextProvider>
        </UserContextProvider>
    )
}

export default DestinationsProvider
