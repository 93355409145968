import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react'

const StyledColumn = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    grid-area: content1;

    @media screen and (max-width: 1200px) and (min-width: 600px) {
      margin-bottom: 0.75rem;
    }

    @media screen and (max-width: 600px) {
      margin-bottom: 0.75rem;
    }
`

export interface ColumnProps extends PropsWithChildren {
    style?: any;
    columnDirection?: boolean;
}

const LeftColumn: React.FC<ColumnProps> = (props) => {
  return (
    <StyledColumn style={props.columnDirection ? {flexDirection: "column", ...props.style}:{...props.style}}>
        {props.children}
    </StyledColumn>
  )
}

export default LeftColumn
