import CallerID from 'common/fields/CallerID';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { UserForm } from 'data/forms/users/users/user_form';
import { NumericFormat } from 'formats/common';
import { EditCtx } from 'hooks/edit_hook';
import React, { useContext } from 'react';

export interface Props extends FormKeyProps {
    userEdit: EditCtx<UserForm>;
}

const UserGeneralForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setFormValue] = props.userEdit;

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Prénom"}
                        value={form.firstname}
                        error={errorCtx.getError("firstname", props.formKeyPath)}
                        name={"firstname"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Nom"}
                        value={form.lastname}
                        error={errorCtx.getError("lastname", props.formKeyPath)}
                        name={"lastname"}
                        editValue={setFormValue}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Courriel"}
                        value={form.email}
                        error={errorCtx.getError("email", props.formKeyPath)}
                        name={"email"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <CallerID
                        label={"Afficheur interne"}
                        value={form.caller_id}
                        error={errorCtx.getError("caller_id", props.formKeyPath)}
                        name={"caller_id"}
                        editValue={setFormValue}
                        onlyName
                    />
                </LeftColumn>
                <RightColumn>
                    <CallerID
                        label={"Afficheur externe"}
                        value={form.outgoing_caller_id}
                        error={errorCtx.getError("outgoing_caller_id", props.formKeyPath)}
                        name={"outgoing_caller_id"}
                        editValue={setFormValue}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        label={"Temps de sonnerie (secondes)"}
                        value={form.ring_seconds}
                        error={errorCtx.getError("ring_seconds", props.formKeyPath)}
                        name={"ring_seconds"}
                        editValue={setFormValue}
                        format={NumericFormat}
                    />
                </LeftColumn>
            </Row>
        </>
    )
}

export default UserGeneralForm
