import { defaultFilterResult } from 'data/api/filter_result';
import { User } from 'data/users/users/user';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllUsers } from 'services/users_service';

export interface UserCtx {
    users: User[];
    getUser: (uuid?: string) => User | undefined;
    getUserLabel: (uuid?: string) => string | undefined;
    refresh: () => void;
}

export const UserContext = createContext<UserCtx>({
    users: [],
    getUser: () => undefined,
    getUserLabel: () => undefined,
    refresh: () => {},
});

const UserContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: users, refresh } = useCachedData("users", getAllUsers, defaultFilterResult());

    const ctx = useMemo<UserCtx>(() => {
        return {
            users: users.items,
            getUser: (uuid?: string) => users?.items?.find((u) => u.uuid === uuid),
            getUserLabel: (id?: string|number) => {
                const user = users?.items?.find((u) => u.uuid === id || u.id === id);
                return user ? `${user.firstname ?? ""} ${user.lastname ?? ""}` : undefined;
            },
            refresh: refresh,
        }
    }, [users, refresh]);

    return (
        <UserContext.Provider value={ctx}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContextProvider
