import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import { Trunk } from 'data/routes/trunks/trunk';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getTrunks } from 'services/trunks_service';
import TrunkCreationFormModalComponent from './TrunkCreationFormModal';
import TrunkFormComponent from './TrunkForm';
import TrunksList from './TrunksList';

const Trunks: React.FC = (props) => {
    const trunksList = useFilterList(getTrunks, "name");
    const { state, editValue, setState } = useComponent<Trunk>();

    return (
        <Content>
            {state === "list" &&
                <TrunksList
                    trunksList={trunksList}
                    showForm={(trunk?: Trunk) => {
                        setState("form", trunk);
                    }}
                />
            }
            {state === "form" &&
                <>
                    {editValue ? (
                        <TrunkFormComponent
                            editValue={editValue}
                            onExit={(updated) => {
                                if (updated) {
                                    trunksList.refreshList();
                                }
                                setState("list", undefined);
                            }}
                        />) : (
                        <TrunkCreationFormModalComponent onExit={(updated) => {
                            if (updated) {
                                trunksList.refreshList();
                            }
                            setState("list", undefined);
                        }} />
                    )}
                </>
            }
            <LoadingModal smallLoader isLoading={trunksList.isLoading} />
        </Content>
    )
}

export default Trunks
