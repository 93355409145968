import Textfield from 'common/fields/Textfield';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import ListLimitSelect from 'components/misc/ListLimitSelect';
import { Setter } from 'hooks/edit_hook';
import React from 'react';

export interface Props {
    search?: string;
    setFilterField: Setter;
    limit: number;
}

const ListActionBar: React.FC<Props> = (props) => {
    return (
        <GridRow templateColumns={"50% 40% 10%"}>
            <GridColumn>
                <Textfield
                    label={'Recherche'}
                    value={props.search}
                    name={"search"}
                    editValue={props.setFilterField}
                ></Textfield>
            </GridColumn>
            <GridColumn></GridColumn>
            <GridColumn>
                <ListLimitSelect limit={props.limit} setLimit={props.setFilterField} />
            </GridColumn>
        </GridRow>
    )
}

export default ListActionBar
