import { tenantUrl, get, jsonBody, post, put } from './api';
import { FilterResult } from 'data/api/filter_result';
import { Device } from 'data/users/devices/device';
import { buildQueryString, Filter } from 'data/api/filter';
import { DeviceForm, deviceFormToBody } from 'data/forms/users/devices/device_form';
import { Message } from 'data/api/message';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';

const endpoint = "device";

export const getAllDevices = (authCtx: AuthenticationCtx): Promise<FilterResult<Device>> => {
    return get<FilterResult<Device>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getAllPlugins = (authCtx: AuthenticationCtx): Promise<string[]> => {
    return get<string[]>({
        url: tenantUrl(`${endpoint}/plugins`, authCtx),
        authentication: authCtx,
    });
}

export const getDevices = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Device>> => {
    return get<FilterResult<Device>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const updateDevice = (authCtx: AuthenticationCtx, device: DeviceForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${device.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(deviceFormToBody(device)));
}

export const createDevice = (authCtx: AuthenticationCtx, device: DeviceForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(deviceFormToBody(device)));
}
