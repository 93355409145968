import { AuthenticationContext } from "components/misc/providers/AuthCtx";
import { jwtDecode } from "jwt-decode";
import { useCallback, useContext, useEffect, useState } from "react"

// Role access level, 0 highest
export const UserRole = {
    Admin: 0,
    Reseller: 1,
    TenantAdmin: 2,
    User: 3,
    Unauthenticated: -1,
};

export type UserRoleType = keyof typeof UserRole;

export const getUserRoleLabel = (r: UserRoleType) => {
    switch (r) {
        case "Unauthenticated":
            return null;
        case "Admin":
            return "Administrateur";
        case "Reseller":
            return "Revendeur";
        case "TenantAdmin":
            return "Administrateur client";
        case "User":
            return "Utilisateur";
        default:
            return r;
    }
};

export const useAuthDetails = () => {
    const auth = useContext(AuthenticationContext);
    const [token, setToken] = useState();

    useEffect(() => {
        if (!auth.session) {
            setToken(undefined);
            return;
        }

        setToken(jwtDecode(auth.session.access_token));
        return;
    }, [auth.session]);

    const getUserRole = useCallback((): number => {
        if (!token || !token["role_type"]) {
            return UserRole.Unauthenticated;
        }

        return UserRole[token["role_type"]];
    }, [token]);

    const getUserId = useCallback(() => {
        if (!token || !token["sub"]) {
            return "";
        }

        return token["sub"];
    }, [token]);

    const getRoleAccessLevel = useCallback((role: UserRoleType) => {
        return UserRole[role] ? UserRole[role]:-1;
    }, []);

    const isAdmin = useCallback(() => {
        return getUserRole() === UserRole.Admin;
    }, [token]);

    const hasAccessLevel = useCallback((level: UserRoleType) => {
        return true;

        const userRole = getUserRole();
        if (userRole === UserRole.Unauthenticated) {
            return false;
        }

        return isAdmin() || userRole <= getRoleAccessLevel(level);
    }, [getRoleAccessLevel, token, isAdmin]);

    const isSameUser = useCallback((userId?: string) => {
        if (!token) {
            return false;
        }

        return token["sub"] === userId;
    }, [token]);

    return {
        getUserId,
        getUserRole,
        isSameUser,
        isAdmin,
        hasAccessLevel,
    }
}
