import EmbeddedScheduleForm from 'components/users/schedules/EmbeddedScheduleForm';
import { InboundRouteForm } from 'data/forms/routes/inbound/inbound_route_form';
import { EditCtx } from 'hooks/edit_hook';
import React from 'react';

export interface Props {
    valueEdit: EditCtx<InboundRouteForm>;
}

const InboundRouteScheduleForm: React.FC<Props> = (props) => {
    const [form, setForm] = props.valueEdit;

    return (
        <>
            <EmbeddedScheduleForm
                formKeyPath='schedule'
                schedule={form.schedule}
                onChange={(s) => setForm("schedule", s)}
            />
        </>
    )
}

export default InboundRouteScheduleForm
