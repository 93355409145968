import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react'

export interface GridColumnProps {
    align?: string;
    columnDirection?: boolean;
}

const StyledGridColumn = styled.div<GridColumnProps>`
    width: 100%;
    align-items: center;
    justify-content: ${props => props.align ? props.align:'center'};
    text-align: center;
    display: flex;
    flex-direction: ${props => props.columnDirection ? 'column':''};

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        margin-bottom: 0.75rem;
        justify-content: center;
        flex-direction: column;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 0.75rem;
        justify-content: center;
        flex-direction: column;
    }
`;

export interface ColumnProps extends PropsWithChildren {
    style?: any;
    align?: string;
    columnDirection?: boolean;
    onClick?: () => void;
}

const GridColumn: React.FC<ColumnProps> = (props) => {
    return (
        <StyledGridColumn
            align={props.align}
            columnDirection={props.columnDirection}
            onClick={props.onClick ? props.onClick:() => {}}
            style={{...props.style}}
        >
            {props.children}
        </StyledGridColumn>
    )
}

export default GridColumn
