import { get, jsonBody, post, put, tenantUrl } from './api';
import { Ivr } from 'data/services/ivr/ivr';
import { FilterResult } from 'data/api/filter_result';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import { buildQueryString, Filter } from 'data/api/filter';
import { Message } from 'data/api/message';
import { IvrForm, ivrFormToBody } from 'data/forms/services/ivr/ivr_form';

const endpoint = "ivr";

export const getAllIvrs = (authCtx: AuthenticationCtx): Promise<FilterResult<Ivr>> => {
    return get<FilterResult<Ivr>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getIvrs = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Ivr>> => {
    return get<FilterResult<Ivr>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getIvr = (authCtx: AuthenticationCtx, id: number): Promise<Ivr> => {
    return get<Ivr>({
        url: tenantUrl(`${endpoint}/by/id/${id}`, authCtx),
        authentication: authCtx,
    });
}

export const updateIvr = (authCtx: AuthenticationCtx, ivr: IvrForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${ivr.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(ivrFormToBody(ivr)));
}

export const createIvr = (authCtx: AuthenticationCtx, ivr: IvrForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(ivrFormToBody(ivr)));
}
