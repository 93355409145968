import React, { useContext } from 'react';
import 'styles/index.css';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import { EditCtx } from 'hooks/edit_hook';
import { UserForm } from 'data/forms/users/users/user_form';
import { Typography } from '@mui/material';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import Tagsfield from 'common/fields/Tagsfield';
import { NumExtenFormat } from 'formats/common';
import LargeRow from 'common/layout/LargeRow';

export interface Props extends FormKeyProps {
    userCtx: EditCtx<UserForm>;
}

const UserFollowMeDashboard: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setFormValue] = props.userCtx;

    return (
        <div style={{ gridArea: "followme" }}>
            <Card>
                <CardBody smallPadding>
                    <LargeRow>
                        <Typography variant="h5">
                            Suivi d'appels
                        </Typography>
                    </LargeRow>
                    <Tagsfield
                        label={"Numéros suivi d'appels"}
                        value={form.follow_me?.map((v) => v.number) ?? []}
                        error={errorCtx.getError("follow_me", props.formKeyPath)}
                        name={"follow_me"}
                        format={NumExtenFormat}
                        editValue={(_, v) => setFormValue("follow_me", v.map((n: string) => {
                            return {
                                number: n,
                            }
                        }))}
                    />
                </CardBody>
            </Card>
        </div>
    );
}

export default UserFollowMeDashboard
