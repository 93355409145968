import { faArrowLeft, faArrowRight, faBuilding, faCalendar, faClipboard, faFolder, faGaugeHigh, faMusic, faNetworkWired, faPhone, faRobot, faTag, faUser, faUsers, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useContext, useState } from 'react';
import 'styles/index.css';
import { useLocation, useNavigate } from 'react-router';
import NavigationBtn from './NavigationBtn';
import NavigationSection from './NavigationSection';
import { NavContext } from './NavbarContext';

const Navigation: React.FC = () => {
    const navCtx = useContext(NavContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [section, setSection] = useState("");

    const toggleSection = useCallback((selection: string) => {
        if (section === selection) {
            setSection("");
        } else {
            setSection(selection);
        }
    }, [section]);

    const isCurrent = useCallback((route: string, absolute?: boolean) => {
        return absolute ? location.pathname === route : location.pathname.includes(route);
    }, [location]);

    const navigateToRoute = useCallback((route: string) => {
        navigate(route);
        navCtx.onNavigate();
    }, [navCtx]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                alignItems: "start",
            }}
        >
            <NavigationBtn
                icon={faGaugeHigh}
                isCurrentRoute={isCurrent("/", true)}
                label={'Tableau de bord'}
                onClick={() => navigateToRoute("/")}
            ></NavigationBtn>
            <NavigationSection
                label='Utilisateurs'
                isCurrentRoute={section === "users"}
                onClick={() => toggleSection("users")}
            >
                <NavigationBtn
                    icon={faUser}
                    isCurrentRoute={isCurrent("users")}
                    label={'Utilisateurs'}
                    onClick={() => navigateToRoute("users")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faPhone}
                    isCurrentRoute={isCurrent("devices")}
                    label={'Appareils'}
                    onClick={() => navigateToRoute("devices")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faVoicemail}
                    isCurrentRoute={isCurrent("voicemails")}
                    label={'Boîtes vocales'}
                    onClick={() => navigateToRoute("voicemails")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faUsers}
                    isCurrentRoute={isCurrent("ringgroups")}
                    label={"Groupes d'appel"}
                    onClick={() => navigateToRoute("ringgroups")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faCalendar}
                    isCurrentRoute={isCurrent("schedules")}
                    label={'Horaires'}
                    onClick={() => navigateToRoute("schedules")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faFolder}
                    isCurrentRoute={isCurrent("directories")}
                    label={'Répertoires'}
                    onClick={() => navigateToRoute("directories")}
                ></NavigationBtn>
            </NavigationSection>
            <NavigationSection
                label='Routes'
                isCurrentRoute={section === "routes"}
                onClick={() => toggleSection("routes")}
            >
                <NavigationBtn
                    icon={faArrowRight}
                    isCurrentRoute={isCurrent("inboundroutes")}
                    label={'Routes entrantes'}
                    onClick={() => navigateToRoute("inboundroutes")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faArrowLeft}
                    isCurrentRoute={isCurrent("outboundroutes")}
                    label={'Routes sortantes'}
                    onClick={() => navigateToRoute("outboundroutes")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faNetworkWired}
                    isCurrentRoute={isCurrent("trunks")}
                    label={'Trunks'}
                    onClick={() => navigateToRoute("trunks")}
                ></NavigationBtn>
            </NavigationSection>
            <NavigationSection
                label='Services'
                isCurrentRoute={section === "services"}
                onClick={() => toggleSection("services")}
            >
                <NavigationBtn
                    icon={faRobot}
                    isCurrentRoute={isCurrent("ivr")}
                    label={'SVI'}
                    description={"Serveur vocal interactif"}
                    onClick={() => navigateToRoute("ivr")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faMusic}
                    isCurrentRoute={false}
                    label={'Sons'}
                    onClick={() => navigateToRoute("")}
                ></NavigationBtn>
            </NavigationSection>
            <NavigationSection
                label='Avancé'
                isCurrentRoute={section === "advanced"}
                onClick={() => toggleSection("advanced")}
            >
                <NavigationBtn
                    icon={faClipboard}
                    isCurrentRoute={isCurrent("siptemplates")}
                    label={'Gabarits SIP'}
                    onClick={() => navigateToRoute("siptemplates")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faTag}
                    isCurrentRoute={isCurrent("contexts")}
                    label={'Contextes'}
                    onClick={() => navigateToRoute("contexts")}
                ></NavigationBtn>
                <NavigationBtn
                    icon={faBuilding}
                    isCurrentRoute={isCurrent("tenants")}
                    label={'Clients'}
                    onClick={() => navigateToRoute("tenants")}
                ></NavigationBtn>
            </NavigationSection>
        </div>
    )
}

export default Navigation
