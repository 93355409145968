import { Setter } from 'hooks/edit_hook';
import { useState } from 'react';
import { FormControl } from '@mui/base';
import ModalClose from 'common/modal/ModalClose';
import { LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers';
import { frFR } from '@mui/x-date-pickers/locales';
import { IconButton, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockFour, faTimes } from '@fortawesome/free-solid-svg-icons';

const DatefieldStyle = {
    width: "100%",
} as any;

export interface PickerProps {
    label: string;
    value?: moment.Moment;
    style?: any;
    required?: boolean;
    name: string;
    error?: string;
    disabled?: boolean;
    editValue: Setter;
    format?: string;
}

const TimePickerField = (props: PickerProps) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    return (
        <FormControl style={{ width: "100%", position: "relative" }}>
            <LocalizationProvider localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <TimePicker
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    slotProps={{
                        textField: {
                            disabled: props.disabled,
                            required: props.required,
                            error: (props.value && !props.value?.isValid()) || (props.error !== undefined && props.error !== ""),
                            helperText: props.value && !props.value.isValid() ? "l'heure est invalide" : props.error,
                            style: { ...DatefieldStyle, ...props.style },
                            variant: "standard",
                            onClick: () => setOpen(true),
                            InputProps: {
                                endAdornment: <span style={{ display: "flex" }}>
                                    <IconButton onClick={() => props.editValue(props.name, null)}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            style={{ color: theme.palette.error.main, fontSize: "16px" }}
                                        ></FontAwesomeIcon>
                                    </IconButton>
                                    <IconButton onClick={() => { }}>
                                        <FontAwesomeIcon icon={faClockFour} style={{ fontSize: "18px" }}></FontAwesomeIcon>
                                    </IconButton>
                                </span>
                            }
                        },
                    }}
                    slots={{
                        actionBar: () => (
                            <ModalClose onClick={() => setOpen(false)}></ModalClose>
                        ),
                    }}
                    sx={{
                        "& .MuiInput-underline:before": { borderBottomColor: (theme) => theme.palette.text.secondary }
                    }}
                    format={props.format ? props.format : "HH:mm"}
                    value={props.value ? props.value : null}
                    onChange={(value) => props.editValue(props.name, value)}
                    label={props.label}
                />
            </LocalizationProvider>
        </FormControl>
    )
}

export default TimePickerField
