import Textarea from 'common/fields/Textarea';
import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import DestinationForm from 'components/misc/destinations/DestinationForm';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { InboundRouteForm } from 'data/forms/routes/inbound/inbound_route_form';
import { NumExtenFormat } from 'formats/common';
import { EditCtx } from 'hooks/edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { useContext } from 'react';

export interface Props extends FormKeyProps {
    valueEdit: EditCtx<InboundRouteForm>;
}

const InboundRouteGeneralForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setFormValue] = props.valueEdit;

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        label={"Numéro"}
                        value={form.exten}
                        error={errorCtx.getError("exten", props.formKeyPath)}
                        name={"exten"}
                        editValue={setFormValue}
                        format={NumExtenFormat}
                    />
                </LeftColumn>
            </Row>
            <LargeRow>
                <LeftColumn>
                    <DestinationForm
                        smallLabel
                        formKeyPath={keyPath([props.formKeyPath, 'destination'])}
                        label={'Destination'}
                        value={form.destination}
                        onEdit={(d) => setFormValue("destination", d)}
                    />
                </LeftColumn>
            </LargeRow>
            <LargeRow>
                <LeftColumn>
                    <Textarea
                        label={"Description"}
                        value={form.description}
                        name={"description"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default InboundRouteGeneralForm
