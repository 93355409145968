import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import Selectfield from 'common/fields/Selectfield';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { UserContext } from 'components/misc/providers/UserCtx';
import { defaultVoicemailForm, VoicemailForm, voicemailToForm } from 'data/forms/users/voicemails/voicemail_form';
import { getUserLabel } from 'data/users/users/user';
import { Voicemail } from 'data/users/voicemails/voicemail';
import { useProviders } from 'form-hooks/providers_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useContext, useEffect } from 'react';
import { createVoicemail, updateVoicemail } from 'services/voicemails_service';
import VoicemailEditFormComponent from './VoicemailEditForm';

export interface Props extends FormKeyProps {
    editValue?: Voicemail;
    onExit: (updated: boolean) => void;
}

const VoicemailFormComponent: React.FC<Props> = (props) => {
    const usersCtx = useContext(UserContext);
    const providers = useProviders();
    const formEdit = useEdit<VoicemailForm>(defaultVoicemailForm());
    const [form, setForm, reduceForm] = formEdit;

    useEffect(() => {
        if (!props.editValue) {
            return
        }

        reduceForm(voicemailToForm(props.editValue));
    }, [props.editValue]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["voicemails", "users"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateVoicemail,
        createHandler: createVoicemail,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <Row>
                    <LeftColumn style={{ justifyContent: "start" }}>
                        <Typography variant='h5'>Boîte vocale</Typography>
                    </LeftColumn>
                </Row>
                <Card>
                    <CardBody>
                        <VoicemailEditFormComponent value={form} setter={setForm} />
                        <Row>
                            <LeftColumn>
                                <Selectfield
                                    label={"Utilisateur"}
                                    value={form?.user?.uuid}
                                    name={""}
                                    editValue={(_, v) => {
                                        const user = v ? { uuid: v } : undefined;
                                        setForm("user", user);
                                    }}
                                    options={usersCtx.users
                                        .filter(u => !u.voicemail_info?.id || u.voicemail_info.id === form.id)
                                        .map((u) => {
                                            return {
                                                id: u.uuid as string,
                                                label: getUserLabel(u),
                                            };
                                        })}
                                />
                            </LeftColumn>
                        </Row>
                    </CardBody>
                </Card>
            </ErrorContextProvider>
            <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
            <LoadingModal isLoading={isSaving} />
        </>
    )
}

export default VoicemailFormComponent
