import { EndpointSIPForm, endpointSIPToForm } from "data/forms/endpoints/endpoint_sip_form";
import { getRefId } from "data/misc/wazo_ref";
import { Line, LineType } from "data/users/users/line";

export interface LineDeviceForm {
    id?: string;
    plugin: string;
    mac: string;
}

export interface LineForm {
    id?: number;
    context?: string;
    label?: string;

    exten: string;
    exten_id?: number;
    endpoint_sip?: EndpointSIPForm;
    line_type: LineType;
    is_main: boolean;

    device?: LineDeviceForm;
}

export const defaultLineDeviceForm = (): LineDeviceForm => {
    return {
        id: undefined,
        plugin: "",
        mac: "",
    };
};

export const emptyDevice = (a: LineDeviceForm) => {
    return !a.id && !a.plugin && !a.mac;
};

export const equalDevice = (a: LineDeviceForm, b?: LineDeviceForm) => {
    return a.id === b?.id && a.plugin === b?.plugin && a.mac === b?.mac;
};

export const defaultLineForm = (): LineForm => {
    return {
        label: "",
        exten: "",
        line_type: "device",
        device: defaultLineDeviceForm(),
        is_main: false,
    };
};

export const lineToForm = (line: Line): LineForm => {
    return {
        id: line.id,
        context: line.context,
        label: line.endpoint_sip?.label,
        exten: line.exten,
        exten_id: line.exten_ref ? getRefId(line.exten_ref) as number:undefined,
        endpoint_sip: line.endpoint_sip ? endpointSIPToForm(line.endpoint_sip):undefined,
        line_type: line.line_type,
        device: line.device_id ? {id: line.device_id} as LineDeviceForm:undefined,
        is_main: line.is_main,
    };
};
