export type WazoRef = {
    name: string;
    id?: number;
    uuid?: string;
    label: string;
}

export const getRefId = (ref: {id?: number, uuid?: string}) => {
    return ref.uuid ? ref.uuid:ref.id;
};

export const toWazoRef = (obj: {[key: string]: any}): WazoRef => {
    return {
        id: obj["id"],
        uuid: obj["uuid"],
        label: "",
        name: "",
    };
};
