import { Button, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import { InfoDisplay } from 'common/display/Display';
import ListDisplay from 'common/display/ListDisplay';
import ListHeaderElement, { ListHeader } from 'common/display/ListHeaderDisplay';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import ListActionBar from 'components/misc/ListActionBar';
import RequiresAccess from 'components/RequiresAccess';
import { Context, ContextTypeLabels } from 'data/advanced/contexts/context';
import { ListFilterCtx } from 'hooks/list_hook';
import React from 'react';

export interface Props {
    contextsList: ListFilterCtx<Context>;
    showForm: (context?: Context) => void;
}

const ContextsList: React.FC<Props> = (props) => {
    const { contextsList } = props;

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Contexte</Typography>
                </LeftColumn>
                <RightColumn>
                    <RequiresAccess minAccessRole='Reseller'>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button variant="contained" onClick={() => props.showForm()}>Nouveau contexte</Button>
                            </LeftColumn>
                        </LargeRow>
                    </RequiresAccess>
                </RightColumn>
            </Row>
            <ListActionBar
                limit={contextsList.filter.limit}
                search={contextsList.filter.search}
                setFilterField={contextsList.setFilterField}
            ></ListActionBar>
            <LargeRow>
                <LeftColumn>
                    {contextsList.data.items.length > 0 ? (
                        <ListDisplay
                            byFilter
                            header={() => (
                                <ListHeader templateColumns={'25% 75%'}>
                                    <ListHeaderElement
                                        isCurrent={contextsList.filter.order === "label"}
                                        order={"label"}
                                        direction={contextsList.filter.direction}
                                        setOrder={contextsList.reduceFilter}
                                    >
                                        Nom
                                    </ListHeaderElement>
                                    <ListHeaderElement>
                                        Description
                                    </ListHeaderElement>
                                </ListHeader>
                            )}
                            items={contextsList.data.items}
                            itemsCount={contextsList.data.total}
                            maxItems={contextsList.filter.limit}
                            itemsLabel='contexte(s)'
                            onPageChange={(page) => {
                                contextsList.setFilterField("page", page);
                            }}
                            listElementComponent={(v: Context, index: number) => (
                                <Card key={`${index}-contextlist`}>
                                    <CardBody smallPadding editable onClick={() => props.showForm(v)}>
                                        <GridRow columnsForAllSmallerScreens templateColumns={"25% 75%"}>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' style={{ fontWeight: 600 }}>
                                                        {v.label ?? v.name}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1'>
                                                        {ContextTypeLabels[v.type]}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <DeleteBtn onClick={() => { }}></DeleteBtn>
                                        </GridRow>
                                    </CardBody>
                                </Card>
                            )}>
                        </ListDisplay>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <LargeRow>
                                <LeftColumn>
                                    <Typography color="secondary" variant="h6">
                                        Aucun contexte n'existe
                                    </Typography>
                                </LeftColumn>
                            </LargeRow>
                        </div>
                    )}
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default ContextsList
