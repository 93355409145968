import React from 'react';
import 'styles/index.css';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import { EditCtx } from 'hooks/edit_hook';
import { UserForm } from 'data/forms/users/users/user_form';
import { Typography } from '@mui/material';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import LargeRow from 'common/layout/LargeRow';
import UserScheduleForm from 'components/users/users/forms/UserScheduleForm';

export interface Props extends FormKeyProps {
    userCtx: EditCtx<UserForm>;
}

const UserScheduleDashboard: React.FC<Props> = (props) => {
    return (
        <div style={{ gridArea: "schedule" }}>
            <Card>
                <CardBody smallPadding>
                    <LargeRow>
                        <Typography variant="h5">
                            Horaire
                        </Typography>
                    </LargeRow>
                    <UserScheduleForm userEdit={props.userCtx}/>
                </CardBody>
            </Card>
        </div>
    );
}

export default UserScheduleDashboard
