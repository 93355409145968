import { Format } from "common/fields/format"

export const CallerIDFormat: Format = {
    format: new RegExp('^("[\\w\\s\\.-]+"\\s*<\\d+>)?\\s*$'),
    errorMsg: "l'afficheur doit être du format \"Nom\"<npanxxxxxx>",
    formatter: (callerid: string): string => {
        const matches = callerid.match(/("[\w\s\\.-]+"|[\w\s\\.-]+)\s*(<\d+>)/);
        if (!matches || matches.length < 3) {
            return callerid;
        }

        return `"${matches[1].replaceAll("\"", "").trim()}"${matches[2]}`;
    },
}

export const CallerIDNameFormat: Format = {
    format: new RegExp('^("?[\\s\\w\\.-]+"?)?\\s*$'),
    errorMsg: "l'afficheur ne doit pas contenir de caractères spéciaux autre que `.` et `-`",
    formatter: (callerid: string): string => {
        callerid = callerid.replaceAll(/"/g, "");
        if (callerid.length === 0) {
            return "";
        }

        return callerid;
    },
}

export const MacFormat: Format = {
    format: new RegExp('^(([\\d\\w]{2}:){5}[\\d\\w]{2})?$'),
    errorMsg: "l'adresse MAC doit être du format xx:xx:xx:xx:xx:xx",
    formatter: (mac: string): string => {
        mac = mac.replaceAll(":", "");
        return mac.length === 12 ? mac.match(/[\d\w]{1,2}/g)?.join(":") ?? mac : mac;
    },
}

export const NumExtenFormat: Format = {
    format: new RegExp('^\\d{0,10}$'),
    errorMsg: "le numéro doit être composé de chiffres et ne doit pas dépasser 10 caractères",
    displayFormatter: (num: string): string => {
        return num.length === 10 ? `(${num.substring(0, 3)}) ${num.substring(3, 6)}-${num.substring(6)}` : num;
    },
}

export const ExtenFormat: Format = {
    format: new RegExp('^\\d{0,4}$'),
    errorMsg: "le numéro doit être composé de chiffres et ne doit pas dépasser 4 caractères",
}

export const NumericFormat: Format = {
    format: new RegExp('^(\\d*)?$'),
    errorMsg: "le numéro doit être composé de chiffres",
}

export const MaxLengthContinuous = (length: number): Format => {
    return {
        format: new RegExp(`^([a-zA-Z0-9_]{0,${length}})?$`),
        errorMsg: "le champ ne doit comporter que des chiffres ou des lettres, sans espaces et d'au plus 10 caractères",
    }
}
