import { AuthenticationCtx } from "components/misc/providers/AuthCtx";
import { useCallback, useReducer } from "react";

export type GetHandler<T> = (ctx: AuthenticationCtx, id: string) => Promise<T>;
export type Setter = (key: string, value: any) => void;

export const validateRequired = (form: any, key: string) => {
    const valid = form[key] !== null && form[key] !== undefined && form[key] !== "";
    if (!valid) {
        return "champ obligatoire";
    }

    return "";
};

export const applyChange = (model: any, key: string, value: any) => {
    return {
        ...model,
        [key]: value,
    }
};

export const useChangeSubProp = (prop_key: string, sub_prop: any, setter: Setter) => {
    return useCallback((key: string, value: any) => {
        setter(prop_key, applyChange(sub_prop, key, value));
    }, [sub_prop, prop_key, setter]);
};

export const useReduceEditHook = (model: any, reducer: ReduceFn, hook: (model: any) => void) => {
    return useCallback((v: {[key: string]: any}) => {
        const newModel = {
            ...model,
            ...v,
        };
        reducer(newModel);
        hook(newModel);
    }, [model, reducer, hook]);
};

export const useEditHook = (model: any, reducer: ReduceFn, hook: (model: any) => void) => {
    return useCallback((key: string, value: any) => {
        const newModel = applyChange(model, key, value);
        reducer(newModel);
        hook(newModel);
    }, [model, reducer, hook]);
};

export const useReduceSubProp = (prop_key: string, sub_prop: any, setter: Setter) => {
    return useCallback((val: {[k: string]: any}) => {
        setter(prop_key, {...sub_prop, ...val});
    }, [sub_prop, prop_key, setter]);
};

export type Reducer = (state: any, action: any) => void;

export const isValueChanged = (a: any, b: any): boolean => {
    return JSON.stringify(a) !== JSON.stringify(b);
};

export const reducer = (state: any, updateState: any) => {
    const newState = {
        ...state,
        ...updateState,
    };
    if (!isValueChanged(newState, state)) {
        return state;
    }

    return newState;
}

export type ReduceFn = (val: {[key: string]: any}) => void;
export type EditCtx<T> = [T, Setter, ReduceFn];

export function useEdit<T>(defaultValue: T, reduceFn?: Reducer): EditCtx<T> {
    const [value, dispatch] = useReducer(reduceFn ?? reducer, defaultValue);

    const handleChange = useCallback((key: string, value: any) => {
        dispatch({[key]: value});
    }, []);

    return [value, handleChange, dispatch];
}
