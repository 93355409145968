import { Button, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import { InfoDisplay } from 'common/display/Display';
import ListDisplay from 'common/display/ListDisplay';
import ListHeaderElement, { ListHeader } from 'common/display/ListHeaderDisplay';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import ListActionBar from 'components/misc/ListActionBar';
import RequiresAccess from 'components/RequiresAccess';
import { Schedule } from 'data/users/schedules/schedule';
import { ListFilterCtx } from 'hooks/list_hook';
import React from 'react';

export interface Props {
    schedulesList: ListFilterCtx<Schedule>;
    showForm: (schedule?: Schedule) => void;
}

const SchedulesList: React.FC<Props> = (props) => {
    const { schedulesList } = props;

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Horaires</Typography>
                </LeftColumn>
                <RightColumn>
                    <RequiresAccess minAccessRole='Reseller'>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button variant="contained" onClick={() => props.showForm()}>Nouvel horaire</Button>
                            </LeftColumn>
                        </LargeRow>
                    </RequiresAccess>
                </RightColumn>
            </Row>
            <ListActionBar
                limit={schedulesList.filter.limit}
                search={schedulesList.filter.search}
                setFilterField={schedulesList.setFilterField}
            ></ListActionBar>
            <LargeRow>
                <LeftColumn>
                    {schedulesList.data.items.length > 0 ? (
                        <ListDisplay
                            byFilter
                            header={() => (
                                <ListHeader templateColumns={'75% 25%'}>
                                    <ListHeaderElement
                                        isCurrent={schedulesList.filter.order === "name"}
                                        order={"name"}
                                        direction={schedulesList.filter.direction}
                                        setOrder={schedulesList.reduceFilter}
                                    >
                                        Nom
                                    </ListHeaderElement>
                                    <ListHeaderElement
                                        isCurrent={schedulesList.filter.order === "timezone"}
                                        order={"timezone"}
                                        direction={schedulesList.filter.direction}
                                        setOrder={schedulesList.reduceFilter}
                                    >
                                        Fuseau horaire
                                    </ListHeaderElement>
                                </ListHeader>
                            )}
                            items={schedulesList.data.items}
                            itemsCount={schedulesList.data.total}
                            maxItems={schedulesList.filter.limit}
                            itemsLabel='horaire(s)'
                            onPageChange={(page) => {
                                schedulesList.setFilterField("page", page);
                            }}
                            listElementComponent={(v: Schedule, index: number) => (
                                <Card key={`${index}-schedulelist`}>
                                    <CardBody smallPadding editable onClick={() => props.showForm(v)}>
                                        <GridRow columnsForAllSmallerScreens templateColumns={"75% 25%"}>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' style={{ fontWeight: 600 }}>
                                                        {v.name}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' noWrap>
                                                        {v.timezone}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <DeleteBtn onClick={() => { }}></DeleteBtn>
                                        </GridRow>
                                    </CardBody>
                                </Card>
                            )}>
                        </ListDisplay>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <LargeRow>
                                <LeftColumn>
                                    <Typography color="secondary" variant="h6">
                                        Aucun horaire n'existe
                                    </Typography>
                                </LeftColumn>
                            </LargeRow>
                        </div>
                    )}
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default SchedulesList
