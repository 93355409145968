import styled from '@emotion/styled'
import { useTheme } from '@mui/material';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

const StyledModalContent = styled.div`
    min-width: 60%;
    position: absolute;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    border-radius: 0rem 0rem 0.15rem 0.15rem;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    border-radius: 4px;

    @media screen and (max-width: 600px) {
        min-width: 80%;
    }
`

export const LargeModalContent = {
    minWidth: "80%",
    minHeight: "40rem",
    alignContent: "start",
    top: "5rem",
    transform: "translate(-50%, 0)",
    marginTop: "0"
};

export interface ModalContentProps extends PropsWithChildren {
    style?: any;
    noBackground?: boolean;
    fixedLargeModal?: boolean;
}

const ModalContent: React.FC<ModalContentProps> = (props) => {
    const theme = useTheme();
    const ref = useRef<any>();
    const [marginTop, setMarginTop] = useState(0);

    useEffect(() => {
        if (!ref || !ref.current || props.fixedLargeModal) {
            return;
        }

        const observerCallback = (entries: ResizeObserverEntry[]) => {
            window.requestAnimationFrame(() => {
                setMarginTop(Math.max(0, entries[0].contentRect.height - window.innerHeight));
            });
        }
        const observer = new ResizeObserver(observerCallback);
        observer.observe(ref.current);
        return () => ref.current && observer.unobserve(ref.current)
    }, [ref.current]);

    return (
        <StyledModalContent
            ref={ref}
            style={{
                marginTop: `${marginTop > 0 ? (marginTop / 2) + 50 : 0}px`,
                backgroundColor: props.noBackground ? "none" : theme.palette.background.light,
                ...(props.fixedLargeModal ? LargeModalContent:{}),
                ...props.style
            }}
            onClick={(e) => e.stopPropagation()}>
            {props.children}
        </StyledModalContent>
    )
}

export default ModalContent
