import { get, jsonBody, post, put, tenantUrl } from './api';
import { FilterResult } from 'data/api/filter_result';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import { buildQueryString, Filter } from 'data/api/filter';
import { Message } from 'data/api/message';
import { Line } from 'data/users/users/line';
import { LineForm } from 'data/forms/users/users/line_form';

const endpoint = "line";

export const getAllLines = (authCtx: AuthenticationCtx): Promise<FilterResult<Line>> => {
    return get<FilterResult<Line>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getLines = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Line>> => {
    return get<FilterResult<Line>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getLine = (authCtx: AuthenticationCtx, id: number): Promise<Line> => {
    return get<Line>({
        url: tenantUrl(`${endpoint}/by/id/${id}`, authCtx),
        authentication: authCtx,
    });
}

export const updateLine = (authCtx: AuthenticationCtx, line: LineForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${line.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(line));
}

export const createLine = (authCtx: AuthenticationCtx, line: LineForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(line));
}
