import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useProviders } from 'form-hooks/providers_hook';
import { EditCtx, useEdit, useEditHook, useReduceEditHook, isValueChanged } from 'hooks/edit_hook';
import { defaultUserForm, UserForm, userToForm } from 'data/forms/users/users/user_form';
import { AuthenticationContext, AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import { User } from 'data/users/users/user';
import { getUser } from 'services/users_service';
import { useData } from 'hooks/data_hook';
import LoadingModal from 'components/misc/LoadingModal';
import UserGeneralDashboard from './UserGeneralDashboard';
import { DashboardGeneralStyled } from './Dashboard.styled';
import UserFallbacksDashboard from './UserFallbacksDashboard';
import UserVoicemailDashboard from './UserVoicemailDashboard';
import UserFollowMeDashboard from './UserFollowMeDashboard';
import UserForwardsDashboard from './UserForwardsDashboard';
import UserDndDashboard from './UserDndDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import FormActionsBar from 'components/misc/FormActionsBar';
import UserLinesDashboard from './UserLinesDashboard';
import UserScheduleDashboard from './UserScheduleDashboard';
import DestinationsProvider from 'components/misc/providers/DestinationsCtx';

const Dashboard: React.FC = () => {
    const authCtx = useContext(AuthenticationContext);
    const providers = useProviders();
    const userEdit = useEdit<UserForm>(defaultUserForm());
    const [form, setForm, reduceForm] = userEdit;
    const [tab, setTab] = useState("general");
    const [editing, setEditing] = useState(false);

    const getUserDetails = useCallback((authCtx: AuthenticationCtx): Promise<User> => {
        if (!authCtx.userId) {
            return new Promise(resolve => {
                resolve({
                    uuid: undefined,
                } as User);
            });
        }

        return getUser(authCtx, authCtx.userId);
    }, [authCtx]);

    const { data: user, isLoading } = useData(getUserDetails, { uuid: undefined });
    useEffect(() => {
        if (!user?.uuid) {
            return
        }

        reduceForm(userToForm(user));
    }, [user]);

    const changeForm = useEditHook(form, reduceForm, (newValue) => {
        setEditing(isValueChanged(form, newValue));
    });
    const changeReduceForm = useReduceEditHook(form, reduceForm, (newValue) => {
        setEditing(isValueChanged(form, newValue));
    });

    const userCtx: EditCtx<UserForm> = [form, changeForm, changeReduceForm];

    // const onSave = useCallback(() => {
    //     props.onExit(true);
    //     providers.refreshProviders(["users", "devices", "schedules", "voicemails"]);
    // }, [providers, props]);
    //
    // const { isSaving, save, error } = useSave({
    //     id: form.uuid,
    //     updateHandler: updateUser,
    //     createHandler: createUser,
    //     onCreate: onSave,
    //     onUpdate: onSave,
    // });

    return (
        <>
            <DestinationsProvider>
                <DashboardGeneralStyled>
                    {editing &&
                        <div style={{ gridArea: "save" }}>
                            <FormActionsBar
                                onCancel={() => {
                                    reduceForm(userToForm(user));
                                    setEditing(false);
                                }}
                                onConfirm={() => { }}
                            />
                        </div>
                    }
                    <Button
                        style={{ width: "100%", height: "100%", gridArea: "action-left", fontSize: "2rem" }}
                        variant="text"
                        color="secondary"
                        onClick={() => setTab("general")}
                        disabled={tab === "general"}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </Button>
                    {tab === "general" &&
                        <>
                            <UserGeneralDashboard userCtx={userCtx} />
                            <UserFollowMeDashboard userCtx={userCtx} />
                            <UserForwardsDashboard userCtx={userCtx} />
                            <UserDndDashboard userCtx={userCtx} />
                            <UserFallbacksDashboard userCtx={userCtx} />
                            <UserVoicemailDashboard userCtx={userCtx} />
                        </>
                    }
                    {tab === "misc" &&
                        <>
                            <UserLinesDashboard userCtx={userCtx} />
                            <UserScheduleDashboard userCtx={userCtx} />
                        </>
                    }
                    <Button
                        style={{ width: "100%", height: "100%", gridArea: "action-right", fontSize: "2rem" }}
                        variant="text"
                        color="secondary"
                        onClick={() => setTab("misc")}
                        disabled={tab === "misc"}
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                </DashboardGeneralStyled>
                <LoadingModal isLoading={isLoading} />
            </DestinationsProvider>
        </>

    );
}

export default Dashboard
