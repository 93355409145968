import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import ModalFormActionsBar from 'components/misc/ModalFormActionsBar';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import FormTab from 'components/misc/Tab';
import FormTabs from 'components/misc/Tabs';
import { ScheduleForm } from 'data/forms/users/schedules/schedule_form';
import { useEdit } from 'hooks/edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import ScheduleExceptionalPeriodsFormComponent from './periods/ScheduleExceptionalPeriodsForm';
import SchedulePeriodsFormComponent from './periods/SchedulePeriodsForm';

export interface Props extends PropsWithChildren, FormKeyProps {
    schedule: ScheduleForm;
    onSave: (s: ScheduleForm) => void;
    noGeneralTab?: boolean;
    errorGeneral?: boolean;
}

const SchedulePeriodsForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setForm, reduceForm] = useEdit(props.schedule);
    const [tab, setTab] = useState(props.noGeneralTab ? "open" : "general");

    useEffect(() => {
        reduceForm(props.schedule);
    }, [props.schedule]);

    useEffect(() => {
        if (props.noGeneralTab) {
            return;
        }

        props.onSave(form);
    }, [form]);

    return (
        <>
            {!props.noGeneralTab &&
                <Row>
                    <LeftColumn style={{ justifyContent: "start" }}>
                        <Typography variant='h5'>Horaire</Typography>
                    </LeftColumn>
                </Row>
            }
            <FormTabs style={{ marginTop: "1rem" }} tab={tab} setTab={setTab}>
                {!props.noGeneralTab &&
                    <FormTab
                        value={"general"}
                        label={"Général"}
                        error={tab !== "general" && props.errorGeneral}
                    />
                }
                <FormTab
                    value={"open"}
                    label={"Périodes d'ouverture"}
                    error={tab !== "open" && errorCtx.hasErrorInPath(keyPath([props.formKeyPath, "open_periods"]))}
                />
                <FormTab
                    value={"exceptions"}
                    label={"Périodes exceptionnelles"}
                    error={tab !== "exceptions" && errorCtx.hasErrorInPath(keyPath([props.formKeyPath, "exceptional_periods"]))}
                />
            </FormTabs>
            <Card>
                <CardBody noTopBorderRadius noShadow={props.noGeneralTab}>
                    {tab === "general" &&
                        <>
                            {props.children}
                        </>
                    }
                    {tab === "open" &&
                        <SchedulePeriodsFormComponent
                            formKeyPath={keyPath([props.formKeyPath, "open_periods"])}
                            periods={form.open_periods}
                            onChange={(p) => setForm("open_periods", p)}
                        />
                    }
                    {tab === "exceptions" &&
                        <ScheduleExceptionalPeriodsFormComponent
                            formKeyPath={keyPath([props.formKeyPath, "exceptional_periods"])}
                            periods={form.exceptional_periods}
                            onChange={(p) => setForm("exceptional_periods", p)}
                        />
                    }
                </CardBody>
            </Card>
            {props.noGeneralTab &&
                <ModalFormActionsBar onConfirm={() => props.onSave(form)} />
            }
        </>
    );
}

export default SchedulePeriodsForm
