import { Button, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import DeleteBtn from 'common/components/DeleteBtn';
import { InfoDisplay } from 'common/display/Display';
import ListDisplay from 'common/display/ListDisplay';
import ListHeaderElement, { ListHeader } from 'common/display/ListHeaderDisplay';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import ListActionBar from 'components/misc/ListActionBar';
import RequiresAccess from 'components/RequiresAccess';
import { RingGroup } from 'data/users/ring_groups/ring_group';
import { ListFilterCtx } from 'hooks/list_hook';
import React from 'react';

export interface Props {
    ringGroupsList: ListFilterCtx<RingGroup>;
    showForm: (ringGroup?: RingGroup) => void;
}

const RingGroupsList: React.FC<Props> = (props) => {
    const { ringGroupsList } = props;

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Groupe d'appel</Typography>
                </LeftColumn>
                <RightColumn>
                    <RequiresAccess minAccessRole='Reseller'>
                        <LargeRow>
                            <LeftColumn style={{ justifyContent: "right" }}>
                                <Button variant="contained" onClick={() => props.showForm()}>Nouveau groupe</Button>
                            </LeftColumn>
                        </LargeRow>
                    </RequiresAccess>
                </RightColumn>
            </Row>
            <ListActionBar
                limit={ringGroupsList.filter.limit}
                search={ringGroupsList.filter.search}
                setFilterField={ringGroupsList.setFilterField}
            ></ListActionBar>
            <LargeRow>
                <LeftColumn>
                    {ringGroupsList.data.items.length > 0 ? (
                        <ListDisplay
                            byFilter
                            header={() => (
                                <ListHeader templateColumns={'100%'}>
                                    <ListHeaderElement
                                        isCurrent={ringGroupsList.filter.order === "label"}
                                        order={"label"}
                                        direction={ringGroupsList.filter.direction}
                                        setOrder={ringGroupsList.reduceFilter}
                                    >
                                        Nom
                                    </ListHeaderElement>
                                </ListHeader>
                            )}
                            items={ringGroupsList.data.items}
                            itemsCount={ringGroupsList.data.total}
                            maxItems={ringGroupsList.filter.limit}
                            itemsLabel='groupe(s)'
                            onPageChange={(page) => {
                                ringGroupsList.setFilterField("page", page);
                            }}
                            listElementComponent={(v: RingGroup, index: number) => (
                                <Card key={`${index}-ringGrouplist`}>
                                    <CardBody smallPadding editable onClick={() => props.showForm(v)}>
                                        <GridRow columnsForAllSmallerScreens templateColumns={"100%"}>
                                            <GridColumn>
                                                <InfoDisplay columnDirection>
                                                    <Typography variant='body1' style={{ fontWeight: 600 }}>
                                                        {v.label}
                                                    </Typography>
                                                </InfoDisplay>
                                            </GridColumn>
                                            <DeleteBtn onClick={() => { }}></DeleteBtn>
                                        </GridRow>
                                    </CardBody>
                                </Card>
                            )}>
                        </ListDisplay>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <LargeRow>
                                <LeftColumn>
                                    <Typography color="secondary" variant="h6">
                                        Aucun groupe d'appel n'existe
                                    </Typography>
                                </LeftColumn>
                            </LargeRow>
                        </div>
                    )}
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default RingGroupsList
