import { faBars, faRefresh } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography, useTheme } from '@mui/material';
import { TopNav, TopNavContent, TopNavActions } from './Navbar.styled';
import { NavContext } from './NavbarContext';
import { Providers, useProviders } from 'form-hooks/providers_hook';
import { TenantContext } from 'components/misc/providers/TenantCtx';
import Selectfield from 'common/fields/Selectfield';
import { AuthenticationContext } from 'components/misc/providers/AuthCtx';
import useWindowDimensions from 'form-hooks/window_hook';

const TopNavbar: React.FC = () => {
    const navCtx = useContext(NavContext);
    const authCtx = useContext(AuthenticationContext);
    const tenantCtx = useContext(TenantContext);
    const windowCtx = useWindowDimensions();
    const theme = useTheme();
    const providers = useProviders();

    const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout|undefined>();

    const refreshData = useCallback(() => {
        if (refreshTimeout) {
            return
        }

        providers.refreshProviders(["tenants", ...Providers]);
        setRefreshTimeout(setTimeout(() => {
            setRefreshTimeout(undefined);
        }, 5000));
    }, [refreshTimeout, setRefreshTimeout, providers]);

    return (
        <TopNav theme={theme} isVisible={navCtx.isVisible()}>
            <TopNavContent>
                <Button variant="text" onClick={() => navCtx.toggleState()}>
                    <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                </Button>
                <Button
                    disabled={refreshTimeout !== undefined}
                    variant="text"
                    onClick={() => refreshData()}
                >
                    <FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon>
                </Button>
                <Selectfield
                    name={""}
                    value={authCtx.tenantId}
                    label={"Client"}
                    editValue={(_, v) => authCtx.selectTenant(v)}
                    options={tenantCtx.tenants?.map(t => {
                        return {
                            id: t.uuid,
                            label: t.name,
                        };
                    })}
                />
            </TopNavContent>
            {(!windowCtx.isPhoneWidth()) &&
                <TopNavActions>
                    <Typography variant='body1'>
                        Samuel Beaulieu - 255
                    </Typography>
                </TopNavActions>
            }
        </TopNav>
    )
}

export default TopNavbar
