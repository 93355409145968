import CheckboxField from 'common/fields/Checkbox';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row, { NoRowPadding } from 'common/layout/Row';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { ForwardDestinationForm } from 'data/forms/users/forwards_form';
import { UserForm } from 'data/forms/users/users/user_form';
import { NumExtenFormat } from 'formats/common';
import { EditCtx, useReduceSubProp } from 'hooks/edit_hook';
import React, { useContext } from 'react';

export interface Props extends FormKeyProps {
    userEdit: EditCtx<UserForm>;
    label: string;
    value: ForwardDestinationForm|undefined;
    forwardName: string;
}

const UserForwardForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setFormValue] = props.userEdit;

    const changeForward = useReduceSubProp("forwards", form.forwards, setFormValue);

    return (
        <>
            <Row style={{...NoRowPadding}}>
                <LeftColumn>
                    <CheckboxField
                        label={props.label}
                        value={props.value?.enabled ?? false}
                        name={"enabled"}
                        editValue={(_, v) => changeForward({
                            [props.forwardName]: {
                                enabled: v,
                                destination: props.value?.destination,
                            },
                        })}
                    />
                </LeftColumn>
                {props.value?.enabled &&
                    <RightColumn>
                        <Textfield
                            label={"Numéro destination"}
                            value={props.value?.destination}
                            error={errorCtx.getError("destination", props.formKeyPath)}
                            name={"destination"}
                            editValue={(_, v) => changeForward({
                                [props.forwardName]: {
                                    enabled: true,
                                    destination: v,
                                },
                            })}
                            format={NumExtenFormat}
                        />
                    </RightColumn>
                }
            </Row>
        </>
    )
}

export default UserForwardForm
