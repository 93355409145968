import { AuthenticationContext } from 'components/misc/providers/AuthCtx';
import { ComponentState } from 'data/component_state';
import { useEdit } from 'hooks/edit_hook';
import { useCallback, useContext, useEffect } from 'react';

export interface ComponentStateCtx<T> {
    state: ComponentState;
    editValue?: T;
    setState: (state: ComponentState, v?: T) => void;
}

interface ComponentStateContext<T> {
    state: ComponentState;
    editValue?: T;
}

export default function useComponent<T>(): ComponentStateCtx<T> {
    const authCtx = useContext(AuthenticationContext);

    const [component, _, reduceComponent] = useEdit<ComponentStateContext<T>>({
        state: "list",
        editValue: undefined,
    });

    const setState = useCallback((state: ComponentState, v?: T) => {
        reduceComponent({
            state: state,
            editValue: v,
        });
    }, [reduceComponent]);

    useEffect(() => {
        setState("list");
    }, [authCtx.tenantId]);

    return {
        state: component.state,
        editValue: component.editValue,
        setState,
    };
}
