import { Directory } from "data/users/directory/directory";

export interface DirectoryForm {
    id?: string;
    name: string;
    members: string[];
}

export const defaultDirectoryForm = (): DirectoryForm => {
    return {
        name: "",
        members: [],
    };
};

export const directoryToForm = (directory: Directory): DirectoryForm => {
    return {
        id: directory.id,
        name: directory.name,
        members: directory.members,
    };
};

export const directoryFormToBody = (directory: DirectoryForm): object => {
    return {
        ...directory,
    };
};
