import { FilterDirection } from "common/display/ListHeaderDisplay";

export interface Filter {
    page: number;
    limit: number;
    search?: string;
    order?: string;
    direction?: FilterDirection;
}

export const buildQueryString = (filter: Filter) => {
    let params: {[k: string]: any} = {"search": filter.search, "order": filter.order, "direction": filter.direction};
    let validKeys = Object.keys(params).filter(k => params[k] !== undefined);

    let query = "";
    if (validKeys.length > 0) {
        const validParams: string[] = [];
        validKeys.forEach((k: string) => {
            validParams.push(`${k}=${params[k]}`);
        });

        query = `?${validParams.join("&")}`;
    }

    return `page/${filter.page}/limit/${filter.limit}${encodeURI(query)}`;
}

export const equalFilter = (a: Filter, b: Filter) => {
    return JSON.stringify(a) === JSON.stringify(b);
};
