import React, { PropsWithChildren, useContext, useEffect } from 'react'
import Textfield from 'common/fields/Textfield';
import { useEdit } from 'hooks/edit_hook';
import { IvrChoiceForm } from 'data/forms/services/ivr/ivr_form';
import { DestinationContext } from 'components/misc/providers/DestinationFormContext';
import InteractableFlowNode, { InteractableProps } from 'components/misc/flow/InteractableFlowNode';
import { NumExtenFormat } from 'formats/common';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';

export interface Props extends InteractableProps, FormKeyProps {
    value: IvrChoiceForm;
    onChange: (v: IvrChoiceForm) => void;
}

export const FlowChoiceNodeWidth = 320;
export const FlowChoiceNodeHeight = 130;

const IvrFlowChoiceNode: React.FC<PropsWithChildren<any>> = ({ data, position, ...restProps }) => {
    const errorCtx = useContext(ErrorContext);
    const destinationCtx = useContext(DestinationContext);
    const props = data as Props;
    const [form, setForm, reduceForm] = useEdit(props.value);

    useEffect(() => {
        props.onChange(form);
    }, [form]);

    useEffect(() => {
        reduceForm(props.value);
    }, [props.value]);

    return (
        <>
            <InteractableFlowNode
                data={{
                    position: position,
                    formKeyPath: props.formKeyPath,
                    topComponent: (
                        < Textfield
                            label={'Option'}
                            error={errorCtx.getError("exten", props.formKeyPath)}
                            value={form.exten}
                            name={'exten'}
                            editValue={setForm}
                            format={NumExtenFormat}
                        />
                    ),
                    style: {
                        width: `${FlowChoiceNodeWidth}px`,
                    },
                    label: `Destination: ${destinationCtx.getDestinationLabel(form.destination)}`,
                    ...props,
                }}
                {...restProps}
            ></InteractableFlowNode>
        </>
    )
}

export default IvrFlowChoiceNode
