import React, { PropsWithChildren, useContext } from 'react'
import LeftColumn from 'common/layout/LeftColumn';
import LargeRow from 'common/layout/LargeRow';
import { useEdit } from 'hooks/edit_hook';
import { IvrChoiceForm } from 'data/forms/services/ivr/ivr_form';
import Modal from 'common/modal/Modal';
import ModalContent from 'common/modal/ModalContent';
import FormActionsBar from 'components/misc/FormActionsBar';
import DestinationForm from 'components/misc/destinations/DestinationForm';
import Textfield from 'common/fields/Textfield';
import { NumExtenFormat } from 'formats/common';
import Row from 'common/layout/Row';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { keyPath } from 'hooks/save_hook';

export interface Props extends FormKeyProps {
    form: IvrChoiceForm;
    onChange: (f: IvrChoiceForm) => void;
    onCancel: () => void;
}

const IvrChoiceFormComponent: React.FC<PropsWithChildren<Props>> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setForm] = useEdit(props.form);

    return (
        <>
            <Modal onClick={() => props.onCancel()}>
                <ModalContent>
                    <Row>
                        <LeftColumn>
                            <Textfield
                                label={'Option'}
                                name={"exten"}
                                error={errorCtx.getError("exten", props.formKeyPath)}
                                value={form.exten}
                                editValue={setForm}
                                format={NumExtenFormat}
                            />
                        </LeftColumn>
                    </Row>
                    <LargeRow>
                        <LeftColumn>
                            <DestinationForm
                                smallLabel
                                label={'Destination'}
                                formKeyPath={keyPath([props.formKeyPath, "destination"])}
                                value={form.destination}
                                onEdit={(d) => setForm("destination", d)}
                            />
                        </LeftColumn>
                    </LargeRow>
                    <FormActionsBar
                        onCancel={() => props.onCancel()}
                        onConfirm={() => {
                            props.onChange(form)
                        }}
                    />
                </ModalContent>
            </Modal>
        </>
    )
}

export default IvrChoiceFormComponent
