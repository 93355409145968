import { VoicemailTimezone } from "data/misc/timezone";
import { WazoRef } from "data/misc/wazo_ref";
import { Voicemail } from "data/users/voicemails/voicemail";

export interface VoicemailForm {
    id?: number;

    name?: string;
    context?: string;
    number: string;
    email?: string;
    language?: string;
    timezone?: VoicemailTimezone;
    password?: string;

    user?: WazoRef;

    delete_messages?: boolean;
}

export const defaultVoicemailForm = (): VoicemailForm  => {
    return {
        number: "",
    };
}

export const voicemailToForm = (voicemail: Voicemail): VoicemailForm => {
    return {
        id: voicemail.id,
        name: voicemail.name,
        context: voicemail.context,
        number: voicemail.number,
        email: voicemail.email,
        language: voicemail.language,
        timezone: voicemail.timezone ?? "na-eastern",
        password: voicemail.password ?? "",
        delete_messages: voicemail.delete_messages,
        user: voicemail.user,
    };
};

export const voicemailFormToBody = (voicemail: VoicemailForm): object => {
    return {
        id: voicemail.id,
        name: voicemail.name,
        context: voicemail.context,
        number: voicemail.number,
        email: voicemail.email,
        language: voicemail.language,
        timezone: voicemail.timezone ?? "na-eastern",
        password: voicemail.password ? voicemail.password : undefined,
        delete_messages: voicemail.delete_messages,
        user: voicemail.user,
    };
};
