import { ContextListField, KeyOption, OptionField, StrField, YesNoListField } from "../key_options";

export const AorOptions: KeyOption[] = [
    { key: "contact" },
    { key: "max_contacts" },
    { key: "default_expiration" },
    { key: "mailboxes" },
    { key: "voicemail_extension" },
    { key: "maximum_expiration" },
    { key: "minimum_expiration" },
    { key: "remove_existing" },
    { key: "remove_unavailable" },
    { key: "qualify_frequency" },
    { key: "qualify_timeout" },
    { key: "authenticate_qualify" },
    { key: "outbound_proxy" },
    { key: "support_path" },
];

export const IdentifyOptions: KeyOption[] = [
    { key: "endpoint" },
    { key: "match" },
    { key: "match_header" },
    { key: "srv_lookups" },
];

export const AuthOptions: KeyOption[] = [
    { key: "username" },
    { key: "password" },
    { key: "auth_type" },
    { key: "nonce_lifetime" },
    { key: "md5_cred" },
    { key: "refresh_token" },
    { key: "oauth_clientid" },
    { key: "oauth_secret" },
    { key: "realm" },
];

export const RegistrationOptions: KeyOption[] = [
    { key: "client_uri" },
    { key: "server_uri" },
    { key: "line", field: YesNoListField("yes") },
    { key: "contact_user" },
    { key: "max_retries" },
    { key: "retry_interval" },
    { key: "expiration" },
    { key: "auth_rejection_permanent" },
    { key: "contact_header_params" },
    { key: "fatal_retry_interval" },
    { key: "outbound_proxy" },
    { key: "support_outbound" },
    { key: "support_path" },
    { key: "transport" },
];

export const RegistrationOutboundAuthOptions: KeyOption[] = [
    { key: "password" },
    { key: "username" },
    { key: "auth_type" },
    { key: "nonce_lifetime" },
    { key: "md5_cred" },
    { key: "refresh_token" },
    { key: "oauth_clientid" },
    { key: "oauth_secret" },
    { key: "realm" },
];

export const OutboundAuthOptions: KeyOption[] = [
    { key: "password" },
    { key: "username" },
    { key: "auth_type" },
    { key: "nonce_lifetime" },
    { key: "md5_cred" },
    { key: "refresh_token" },
    { key: "oauth_clientid" },
    { key: "oauth_secret" },
    { key: "realm" },
];

export const EndpointOptions: KeyOption[] = [
    { key: "allow", field: StrField("!all,ulaw") },
    { key: "language", field: StrField("fr_CA") },
    { key: "context", field: ContextListField("internal") },
    { key: "direct_media", field: YesNoListField("no") },
    { key: "force_rport", field: YesNoListField("yes") },
    { key: "identify_by", field: StrField("header,auth_username,username") },
    { key: "rewrite_contact", field: YesNoListField("yes") },
    { key: "inband_progress", field: YesNoListField("no") },
    { key: "rtp_symmetric", field: YesNoListField("yes") },
    { key: "send_rpid", field: YesNoListField("yes") },
    { key: "trust_id_inbound", field: YesNoListField("yes") },
    { key: "dtmf_mode", field: { type: "option", default: "rfc4733", options: ["rfc4733", "inband", "info"] } as OptionField },
    { key: "notify_early_inuse_ringing", field: YesNoListField("yes") },
    { key: "allow_transfer", field: YesNoListField("yes") },
    { key: "use_ptime", field: YesNoListField("yes") },
    { key: "allow_subscribe", field: YesNoListField("yes") },
    { key: "webrtc", field: YesNoListField("yes") },
    { key: "dtls_auto_generate_cert", field: YesNoListField("yes") },
    { key: "100rel" },
    { key: "aggregate_mwi" },
    { key: "allow_overlap" },
    { key: "overlap_context" },
    { key: "aors" },
    { key: "auth" },
    { key: "callerid" },
    { key: "callerid_privacy" },
    { key: "callerid_tag" },
    { key: "direct_media_glare_mitigation" },
    { key: "direct_media_method" },
    { key: "trust_connected_line" },
    { key: "send_connected_line" },
    { key: "connected_line_method" },
    { key: "disable_direct_media_on_nat" },
    { key: "disallow" },
    { key: "media_address" },
    { key: "bind_rtp_to_media_address" },
    { key: "ice_support" },
    { key: "redirect_method" },
    { key: "mailboxes" },
    { key: "mwi_subscribe_replaces_unsolicited" },
    { key: "voicemail_extension" },
    { key: "moh_suggest" },
    { key: "outbound_auth" },
    { key: "outbound_proxy" },
    { key: "rtp_ipv6" },
    { key: "send_diversion" },
    { key: "send_history_info" },
    { key: "send_pai" },
    { key: "rpid_immediate" },
    { key: "tenantid" },
    { key: "timers_min_se" },
    { key: "timers" },
    { key: "timers_sess_expires" },
    { key: "transport" },
    { key: "trust_id_outbound" },
    { key: "type" },
    { key: "use_avpf" },
    { key: "force_avp" },
    { key: "media_use_received_transport" },
    { key: "media_encryption" },
    { key: "media_encryption_optimistic" },
    { key: "g726_non_standard" },
    { key: "call_group" },
    { key: "pickup_group" },
    { key: "named_call_group" },
    { key: "named_pickup_group" },
    { key: "device_state_busy_at" },
    { key: "t38_udptl" },
    { key: "t38_udptl_ec" },
    { key: "t38_udptl_maxdatagram" },
    { key: "fax_detect" },
    { key: "fax_detect_timeout" },
    { key: "t38_udptl_nat" },
    { key: "t38_udptl_ipv6" },
    { key: "t38_bind_udptl_to_media_address" },
    { key: "tone_zone" },
    { key: "one_touch_recording" },
    { key: "record_on_feature" },
    { key: "record_off_feature" },
    { key: "rtp_engine" },
    { key: "user_eq_phone" },
    { key: "moh_passthrough" },
    { key: "sdp_owner" },
    { key: "sdp_session" },
    { key: "tos_audio" },
    { key: "tos_video" },
    { key: "cos_audio" },
    { key: "cos_video" },
    { key: "sub_min_expiry" },
    { key: "from_user" },
    { key: "mwi_from_user" },
    { key: "from_domain" },
    { key: "dtls_verify" },
    { key: "dtls_rekey" },
    { key: "dtls_cert_file" },
    { key: "dtls_private_key" },
    { key: "dtls_cipher" },
    { key: "dtls_ca_file" },
    { key: "dtls_ca_path" },
    { key: "dtls_setup" },
    { key: "dtls_fingerprint" },
    { key: "srtp_tag_32" },
    { key: "set_var" },
    { key: "message_context" },
    { key: "accountcode" },
    { key: "preferred_codec_only" },
    { key: "incoming_call_offer_pref" },
    { key: "outgoing_call_offer_pref" },
    { key: "rtp_keepalive" },
    { key: "rtp_timeout" },
    { key: "rtp_timeout_hold" },
    { key: "acl" },
    { key: "deny" },
    { key: "permit" },
    { key: "contact_acl" },
    { key: "contact_deny" },
    { key: "contact_permit" },
    { key: "subscribe_context" },
    { key: "contact_user" },
    { key: "asymmetric_rtp_codec" },
    { key: "rtcp_mux" },
    { key: "refer_blind_progress" },
    { key: "max_audio_streams" },
    { key: "max_video_streams" },
    { key: "bundle" },
    { key: "incoming_mwi_mailbox" },
    { key: "follow_early_media_fork" },
    { key: "accept_multiple_sdp_answers" },
    { key: "suppress_q850_reason_headers" },
    { key: "ignore_183_without_sdp" },
    { key: "stir_shaken" },
    { key: "stir_shaken_profile" },
    { key: "allow_unauthenticated_options" },
    { key: "security_negotiation" },
    { key: "security_mechanisms" },
    { key: "geoloc_incoming_call_profile" },
    { key: "geoloc_outgoing_call_profile" },
    { key: "send_aoc" },
];
