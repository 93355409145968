import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row, { NoRowPadding } from 'common/layout/Row';
import { UserDestination } from 'data/misc/destinations';
import { getUserLabel } from 'data/users/users/user';
import { NumericFormat } from 'formats/common';
import { useEdit } from 'hooks/edit_hook';
import React, { useContext, useEffect } from 'react';
import { DestinationContext } from '../providers/DestinationFormContext';
import { ErrorContext, FormKeyProps } from '../providers/ErrorCtx';

export interface Props extends FormKeyProps {
    value: UserDestination | undefined;
    onEdit: (d: UserDestination) => void;
}

const UserDestinationForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const destinationCtx = useContext(DestinationContext);
    const [value, setValue, reduceValue] = useEdit({
        type: "user",
        user_id: props.value?.user_id,
        ring_time: props.value?.ring_time,
    } as UserDestination);

    useEffect(() => {
        reduceValue({
            user_id: props.value?.user_id,
            ring_time: props.value?.ring_time,
        });
    }, [props.value]);

    useEffect(() => {
        props.onEdit(value);
    }, [value]);

    return (
        <>
            <Row style={NoRowPadding}>
                <LeftColumn>
                    <Selectfield
                        required
                        label={"Utilisateur"}
                        value={value?.user_id}
                        error={errorCtx.getError("user_id", props.formKeyPath)}
                        name={"user_id"}
                        editValue={setValue}
                        options={destinationCtx.users.map(u => {
                            return {
                                id: u.id as number,
                                label: getUserLabel(u),
                            };
                        })}
                    >
                    </Selectfield>
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Temps de sonnerie (secondes)"}
                        value={value?.ring_time}
                        name={"ring_time"}
                        error={errorCtx.getError("ring_time", props.formKeyPath)}
                        editValue={setValue}
                        format={NumericFormat}
                    />
                </RightColumn>
            </Row>
        </>
    )
}

export default UserDestinationForm
