import { FilterDirection } from "common/display/ListHeaderDisplay";
import { AuthenticationCtx } from "components/misc/providers/AuthCtx";
import { equalFilter, Filter } from "data/api/filter";
import { defaultFilterResult, FilterResult } from "data/api/filter_result";
import { useCallback, useEffect, useState } from "react";
import { Getter, useData } from "./data_hook";
import { useEdit } from "./edit_hook";

export interface ListCtx<T> {
    refreshList: () => void;
    isLoading: boolean;
    data: T[];
}

export type ReduceHandler = (val: { [k: string]: any }) => void;

export interface ListFilterCtx<T> {
    refreshList: () => void;
    isLoading: boolean;
    data: FilterResult<T>;
    filter: Filter;
    setFilterField: (k: string, v: any) => void;
    reduceFilter: ReduceHandler;
}

export type FilterGetter<T> = (auth: AuthenticationCtx, filter: Filter) => Promise<FilterResult<T>>;

export function useList<T>(getter: Getter<T[]>, default_value = []): ListCtx<T> {
    const { data: res, isLoading, refresh } = useData(getter, default_value);

    return {
        refreshList: () => refresh(),
        isLoading: isLoading,
        data: res,
    }
};

export function useFilterList<T>(getter: FilterGetter<T>, defaultOrder: string = "", defaultDirection: FilterDirection = "asc"): ListFilterCtx<T> {
    const [filterTimeout, setFilterTimeout] = useState<NodeJS.Timeout | undefined>();
    const [editFilter, _, reduceEditFilter] = useEdit({
        page: 0,
        limit: 30,
        order: defaultOrder,
        direction: defaultDirection,
    });
    const [filter, _s, reduce] = useEdit({
        page: 0,
        limit: 30,
        order: defaultOrder,
        direction: defaultDirection,
    });

    const getData = useCallback((authCtx: AuthenticationCtx) => {
        return getter(authCtx, filter);
    }, [filter, getter]);

    const { data: res, isLoading, refresh } = useData(getData, defaultFilterResult());

    useEffect(() => {
        if (filterTimeout) {
            clearTimeout(filterTimeout);
        }

        const timeout = setTimeout(() => {
            if (!equalFilter(editFilter, filter)) {
                reduce({ ...editFilter });
            }
            setFilterTimeout(undefined);
        }, 250);
        setFilterTimeout(timeout);
    }, [editFilter]);

    const setFilterField = useCallback((k: string, v: any) => {
        if (k === "page" || k === "limit") {
            reduce({ [k]: v });
            reduceEditFilter({ [k]: v });
        } else {
            reduceEditFilter({ [k]: v });
        }
    }, [reduceEditFilter, reduce]);

    const reduceFilter = useCallback((val: { [k: string]: any }) => {
        if ("page" in val || "limit" in val) {
            reduce(val);
            reduceEditFilter(val);
        } else {
            reduceEditFilter(val);
        }
    }, [reduceEditFilter, reduce]);

    return {
        refreshList: () => refresh(),
        isLoading: isLoading,
        data: res,
        filter: editFilter,
        setFilterField: setFilterField,
        reduceFilter: reduceFilter,
    }
};
