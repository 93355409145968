import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import DestinationForm from 'components/misc/destinations/DestinationForm';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { ScheduleForm } from 'data/forms/users/schedules/schedule_form';
import { Destination } from 'data/misc/destinations';
import { TimezoneOptions } from 'data/misc/timezone';
import { Setter } from 'hooks/edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { useContext } from 'react';

export interface Props extends FormKeyProps {
    schedule: ScheduleForm;
    setter: Setter;
}

const ScheduleGeneralForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const form = props.schedule;

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Nom"}
                        value={form.name}
                        error={errorCtx.getError("name", props.formKeyPath)}
                        name={"name"}
                        editValue={props.setter}
                    />
                </LeftColumn>
                <RightColumn>
                    <Selectfield
                        label={"Fuseau horaire"}
                        value={form.timezone}
                        error={errorCtx.getError("timezone", props.formKeyPath)}
                        name={"timezone"}
                        editValue={props.setter}
                        options={TimezoneOptions}
                    />
                </RightColumn>
            </Row>
            <LargeRow>
                <LeftColumn>
                    <DestinationForm
                        formKeyPath={keyPath([props.formKeyPath, "closed_destination"])}
                        label={"Destination hors horaire"}
                        value={form.closed_destination}
                        onEdit={(d: Destination) => props.setter("closed_destination", d)}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default ScheduleGeneralForm
