import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import logo from 'assets/images/Logo120x120.png';
import React, { useContext, useEffect } from 'react';
import Navigation from './Navigation';
import NavigationBtn from './NavigationBtn';
import { SideNav, Logo, LogoImg, Content, Actions } from './Navbar.styled';
import { useTheme } from '@mui/system';
import { NavContext } from './NavbarContext';
import { FormControlLabel, Switch } from '@mui/material';
import { ThemeContext } from 'themes/Theme';
import { AuthenticationContext } from 'components/misc/providers/AuthCtx';

const Navbar: React.FC = () => {
    const themeCtx = useContext(ThemeContext);
    const navCtx = useContext(NavContext);
    const theme = useTheme();
    const authCtx = useContext(AuthenticationContext);
    const { session, refresh } = authCtx;

    useEffect(() => {
        if (session === null) {
            authCtx.refresh();
        }
    }, [session, refresh]);

    return (
        <SideNav theme={theme} isVisible={navCtx.isVisible()}>
            <Logo>
                <LogoImg src={logo} alt="Florence Telecom" />
            </Logo>
            <Content>
                <Navigation></Navigation>
            </Content>
            <Actions>
                <NavigationBtn
                    icon={faArrowRightFromBracket}
                    isCurrentRoute={false}
                    label="Déconnexion"
                    onClick={() => authCtx.disconnect()}
                ></NavigationBtn>
                <FormControlLabel
                    style={{justifyContent: "center"}}
                    labelPlacement='end'
                    label={"Mode sombre"}
                    control={
                        <Switch
                            checked={themeCtx.theme === "dark"}
                            onChange={(_, checked: boolean) => themeCtx.setDarkMode(checked)}
                        ></Switch>
                    }
                ></FormControlLabel>
            </Actions>
        </SideNav>
    )
}

export default Navbar
