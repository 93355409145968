import React, { ReactNode } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material';
import { Setter } from 'hooks/edit_hook';

export interface CheckboxFieldProps {
    style?: any;
    label?: string;
    value: boolean;
    name: string;
    disabled?: boolean;
    editValue: Setter;
    labelPlacement?: "top" | "start" | "end" | "bottom";
    icon?: ReactNode;
    checkedIcon?: ReactNode;
}

const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
    const checkbox = () => (
        <Checkbox
            icon={props.icon}
            checkedIcon={props.checkedIcon}
            disabled={props.disabled}
            checked={props.value}
            onChange={(v) => {
                props.editValue(props.name, v.target.checked);
            }}
        ></Checkbox>
    );

    return (
        <>
            {props.label ? (
                <FormControlLabel
                    style={{width: "100%", ...props.style}}
                    disabled={props.disabled}
                    label={props.label}
                    control={checkbox()}
                    labelPlacement={props.labelPlacement}
                ></FormControlLabel>
            ) : (
                <>{checkbox()}</>
            )}
        </>
    )
}

export default CheckboxField
