import DestinationForm from 'components/misc/destinations/DestinationForm';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { UserForm } from 'data/forms/users/users/user_form';
import { Destination } from 'data/misc/destinations';
import { EditCtx, useReduceSubProp } from 'hooks/edit_hook';
import React from 'react';

export interface Props extends FormKeyProps {
    userEdit: EditCtx<UserForm>;
    label: string;
    value: Destination | undefined;
    fallbackName: string;
}

const UserFallbackForm: React.FC<Props> = (props) => {
    const [form, setFormValue] = props.userEdit;

    const changeFallback = useReduceSubProp("fallbacks", form.fallbacks, setFormValue);

    return (
        <>
            <DestinationForm
                formKeyPath={props.formKeyPath}
                label={props.label}
                value={props.value}
                onEdit={(d: Destination) => changeFallback({
                    [props.fallbackName]: d
                })}
            />
        </>
    )
}

export default UserFallbackForm
