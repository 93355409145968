import React, { PropsWithChildren, ReactNode } from 'react'
import '@xyflow/react/dist/style.css';
import { useTheme } from '@mui/material';
import { FlowNodeStyled } from './FlowNode.styled';

export interface FlowNodeProps extends PropsWithChildren {
    style?: any;
    disabled?: boolean;
    actionComponent?: ReactNode;
    padding?: number;
}

const FlowNodeComponent: React.FC<FlowNodeProps> = (props) => {
    const theme = useTheme();

    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
            >
                {props.disabled ? (
                    <FlowNodeStyled
                        theme={theme}
                        style={{
                            filter: "blur(0.04rem) opacity(0.5)",
                            pointerEvents: "none",
                            ...props.style,
                        }}
                        padding={props.padding}
                    >
                        {props.children}
                    </FlowNodeStyled>
                ) : (
                    <FlowNodeStyled
                        theme={theme}
                        style={{
                            ...props.style,
                        }}
                        padding={props.padding}
                    >
                        {props.children}
                    </FlowNodeStyled>
                )}
                {props.actionComponent}
            </div>
        </>
    )
}

export default FlowNodeComponent
