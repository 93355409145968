import { useTheme } from '@mui/material';
import { applyNodeChanges } from '@xyflow/react';
import FlowContainer, { FlowCtx } from 'components/misc/flow/FlowContainer';
import { DestinationContext } from 'components/misc/providers/DestinationFormContext';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { IvrChoiceForm, IvrForm } from 'data/forms/services/ivr/ivr_form';
import { Ref } from 'data/ref';
import { EditCtx, useEdit } from 'hooks/edit_hook';
import { useFormListPropEdit } from 'hooks/list_edit_hook';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import IvrAbortForm from './IvrAbortForm';
import IvrChoiceFormComponent from './IvrChoiceForm';
import { AbortId, ChoicesId, getAbortIvrNode, getInvalidIvrNode, getIvrChoicesNodes, getIvrEdges, getMainIvrNode, getTimeoutIvrNode, InvalidId, TimeoutId } from './IvrFlow.nodes';
import IvrInvalidForm from './IvrInvalidForm';
import IvrTimeoutForm from './IvrTimeoutForm';

export interface Props extends FormKeyProps {
    isUpdate?: Boolean;
    formEdit: EditCtx<IvrForm>;
}

export type ExpandType = "invalid" | "timeout" | "abort" | "choice";

export interface ExpandCtx {
    type?: ExpandType;
    data?: any;
}

const IvrFlowFormComponent: React.FC<Props> = (props) => {
    const theme = useTheme();
    const destinationCtx = useContext(DestinationContext);
    const [form, setForm, reduceForm] = props.formEdit;
    const [flowRefresh, setFlowRefresh] = useState(0);
    const [choicesInitialized, setChoicesInitialized] = useState(false);
    const [flow, _, reduceFlow] = useEdit<FlowCtx>({
        nodes: [],
        edges: [],
    });
    const expand = useEdit<ExpandCtx>({
        type: undefined,
        data: undefined,
    });
    const [expandCtx, _s, reduceExpand] = expand;

    const choicesListEdit = useFormListPropEdit<IvrChoiceForm>("choices", form.choices, setForm);

    const changeForm = useCallback((v: { [k: string]: any }) => {
        reduceForm(v);
        setFlowRefresh(n => n + 1);
    }, [reduceForm, setFlowRefresh]);

    const resetExpand = useCallback(() => {
        reduceExpand({
            type: undefined,
            data: undefined,
        });
    }, [reduceExpand]);

    useEffect(() => {
        if (choicesInitialized || !choicesListEdit.initialized) {
            return;
        }
        setChoicesInitialized(true);
        setFlowRefresh(n => n + 1);
    }, [choicesListEdit.values]);

    useEffect(() => {
        if ((form && !props.isUpdate) || (form?.name && props.isUpdate)) {
            return
        }

        setFlowRefresh(n => n + 1);
    }, [props.formEdit]);

    const getChoiceNodePosition = useCallback((nodeIndex?: Ref) => {
        const node = flow.nodes.find(n => n.id === nodeIndex);
        return node?.position;
    }, [flow]);

    useEffect(() => {
        if (!form || (!form.name && props.isUpdate)) {
            return
        }

        const timeout = flow.nodes.find(n => n.id === TimeoutId);
        const invalid = flow.nodes.find(n => n.id === InvalidId);
        const abort = flow.nodes.find(n => n.id === AbortId);
        const choices = flow.nodes.find(n => n.id === ChoicesId);

        reduceFlow({
            nodes: [
                getMainIvrNode(props.formEdit),
                getTimeoutIvrNode(timeout, props.formEdit, changeForm, expand, destinationCtx),
                getInvalidIvrNode(invalid, props.formEdit, changeForm, expand, destinationCtx),
                getAbortIvrNode(abort, props.formEdit, changeForm, expand, destinationCtx),
                ...getIvrChoicesNodes(choicesListEdit, choices, props.formEdit, expand, () => setFlowRefresh(n => n + 1), getChoiceNodePosition),
            ],
            edges: getIvrEdges(choicesListEdit, props.formEdit, theme),
        });
    }, [flowRefresh]);

    const onNodesChange = useCallback((changes: any) => {
        reduceFlow({
            nodes: applyNodeChanges(changes, flow.nodes)
        })
    }, [form, flow, reduceFlow]);

    return (
        <>
            <div
                style={{
                    width: "100%",
                    minHeight: "75vh",
                }}
            >
                <FlowContainer
                    nodes={flow.nodes}
                    edges={flow.edges}
                    onNodesChange={onNodesChange}
                ></FlowContainer>
            </div>
            {expandCtx.type === "invalid" &&
                <IvrInvalidForm
                    form={form}
                    onChange={(v) => {
                        changeForm(v);
                        resetExpand();
                    }}
                    onCancel={resetExpand}
                />
            }
            {expandCtx.type === "abort" &&
                <IvrAbortForm
                    form={form}
                    onChange={(v) => {
                        changeForm(v);
                        resetExpand();
                    }}
                    onCancel={resetExpand}
                />
            }
            {expandCtx.type === "timeout" &&
                <IvrTimeoutForm
                    form={form}
                    onChange={(v) => {
                        changeForm(v);
                        resetExpand();
                    }}
                    onCancel={resetExpand}
                />
            }
            {expandCtx.type === "choice" &&
                <IvrChoiceFormComponent
                    formKeyPath={`choices.${expandCtx.data.i}`}
                    form={choicesListEdit.values.find(c => c.index === expandCtx.data.index)?.value as IvrChoiceForm}
                    onChange={(v) => {
                        choicesListEdit.editValue({
                            index: expandCtx.data.index,
                            value: v,
                        });

                        setFlowRefresh(n => n + 1);
                        resetExpand();
                    }}
                    onCancel={resetExpand}
                />
            }
        </>
    )
}

export default IvrFlowFormComponent
