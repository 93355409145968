import { tenantUrl, get, jsonBody, post, put } from './api';
import { Voicemail } from 'data/users/voicemails/voicemail';
import { FilterResult } from 'data/api/filter_result';
import { VoicemailForm, voicemailFormToBody } from 'data/forms/users/voicemails/voicemail_form';
import { Message } from 'data/api/message';
import { buildQueryString, Filter } from 'data/api/filter';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';

const endpoint = "voicemail";

export const getAllVoicemails = (authCtx: AuthenticationCtx): Promise<FilterResult<Voicemail>> => {
    return get<FilterResult<Voicemail>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getVoicemails = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<Voicemail>> => {
    return get<FilterResult<Voicemail>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const updateVoicemail = (authCtx: AuthenticationCtx, voicemail: VoicemailForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${voicemail.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(voicemailFormToBody(voicemail)));
}

export const createVoicemail = (authCtx: AuthenticationCtx, voicemail: VoicemailForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(voicemailFormToBody(voicemail)));
}
