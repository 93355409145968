import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import Modal from 'common/modal/Modal';
import ModalContent from 'common/modal/ModalContent';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultTrunkCreationForm, TrunkCreationForm } from 'data/forms/routes/trunks/trunk_creation_form';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback } from 'react';
import { createTrunk } from 'services/trunks_service';
import TrunkCreationFormComponent from './forms/TrunkCreationForm';

export interface Props extends FormKeyProps {
    onExit: (updated: boolean) => void;
}

const TrunkCreationFormModalComponent: React.FC<Props> = (props) => {
    const [form, _, reduceForm] = useEdit<TrunkCreationForm>(defaultTrunkCreationForm());

    const onSave = useCallback(() => {
        props.onExit(true);
    }, [props]);

    const { isSaving, save, error } = useSave({
        createHandler: createTrunk,
        onCreate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <Modal onClick={() => props.onExit(false)}>
                    <ModalContent>
                        <Card>
                            <CardBody noShadow smallPadding>
                                <Row>
                                    <LeftColumn style={{ justifyContent: "start" }}>
                                        <Typography variant='h5'>Nouveau trunk</Typography>
                                    </LeftColumn>
                                </Row>
                                <TrunkCreationFormComponent form={form} onChange={(v) => reduceForm(v)} />
                            </CardBody>
                        </Card>
                        <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
                    </ModalContent>
                </Modal>
            </ErrorContextProvider>
            <LoadingModal isLoading={isSaving} />
        </>
    )
}

export default TrunkCreationFormModalComponent
