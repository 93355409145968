import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import ContextsContextProvider from 'components/misc/providers/ContextsCtx';
import IvrContextProvider from 'components/misc/providers/IvrCtx';
import RingGroupContextProvider from 'components/misc/providers/RingGroupCtx';
import { RingGroup } from 'data/users/ring_groups/ring_group';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getRingGroups } from 'services/groups_service';
import RingGroupFormComponent from './RingGroupForm';
import RingGroupsList from './RingGroupsList';

const RingGroups: React.FC = (props) => {
    const ringGroupsList = useFilterList(getRingGroups, "label");
    const { state, editValue, setState } = useComponent<RingGroup>();

    return (
        <Content>
            <ContextsContextProvider>
                <RingGroupContextProvider>
                    <IvrContextProvider>
                        {state === "list" &&
                            <RingGroupsList
                                ringGroupsList={ringGroupsList}
                                showForm={(ringGroup?: RingGroup) => {
                                    setState("form", ringGroup);
                                }}
                            />
                        }
                        {state === "form" &&
                            <RingGroupFormComponent
                                onExit={(updated) => {
                                    if (updated) {
                                        ringGroupsList.refreshList();
                                    }
                                    setState("list");
                                }}
                                editValue={editValue}
                            />
                        }
                    </IvrContextProvider>
                </RingGroupContextProvider>
            </ContextsContextProvider>
            <LoadingModal smallLoader isLoading={ringGroupsList.isLoading} />
        </Content>
    )
}

export default RingGroups
