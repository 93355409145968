import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import DestinationsProvider from 'components/misc/providers/DestinationsCtx';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { UserContext } from 'components/misc/providers/UserCtx';
import { defaultDirectoryForm, DirectoryForm, directoryToForm } from 'data/forms/users/directory/directory_form';
import { Directory } from 'data/users/directory/directory';
import { getUserLabel } from 'data/users/users/user';
import { useProviders } from 'form-hooks/providers_hook';
import { useData } from 'hooks/data_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useContext, useEffect } from 'react';
import { createDirectory, getDirectory, updateDirectory } from 'services/directories_service';

export interface Props extends FormKeyProps {
    editValue?: Directory;
    onExit: (updated: boolean) => void;
}

const DirectoryFormComponent: React.FC<Props> = (props) => {
    const usersCtx = useContext(UserContext);
    const providers = useProviders();
    const formEdit = useEdit<DirectoryForm>(defaultDirectoryForm());
    const [form, setForm, reduceForm] = formEdit;

    const getDirectoryDetails = useCallback((authCtx: AuthenticationCtx): Promise<Directory> => {
        if (!props.editValue?.id) {
            return new Promise(resolve => {
                resolve({
                    id: undefined,
                } as Directory);
            });
        }

        return getDirectory(authCtx, props.editValue?.id);
    }, [props.editValue]);

    const { data: directory, isLoading } = useData(getDirectoryDetails, { id: undefined });
    useEffect(() => {
        if (!directory?.id) {
            return
        }

        reduceForm(directoryToForm(directory));
    }, [directory]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["directories"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateDirectory,
        createHandler: createDirectory,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <DestinationsProvider>
                <Row>
                    <LeftColumn style={{ justifyContent: "start" }}>
                        <Typography variant='h5'>Répertoire</Typography>
                    </LeftColumn>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <LeftColumn>
                                <Textfield
                                    required
                                    label="Nom"
                                    value={form.name}
                                    name="name"
                                    editValue={setForm}
                                    error={error.getError("name")}
                                />
                            </LeftColumn>
                        </Row>
                        <LargeRow>
                            <LeftColumn>
                                <Selectfield
                                    multiple
                                    required
                                    label="Membres"
                                    value={form.members}
                                    name="members"
                                    editValue={setForm}
                                    error={error.getError("name")}
                                    options={usersCtx.users.map((u) => {
                                        return {
                                            id: u.uuid as string,
                                            label: getUserLabel(u),
                                        }
                                    })}
                                />
                            </LeftColumn>
                        </LargeRow>
                    </CardBody>
                </Card>
                <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
            </DestinationsProvider>
            <LoadingModal isLoading={isLoading || isSaving} />
        </>
    );
}

export default DirectoryFormComponent
