import { getRefId, WazoRef } from "data/misc/wazo_ref";
import { Fallbacks } from "../fallbacks";
import { Forwards } from "../forwards";
import { FuncKey } from "../funckey";
import { Schedule } from "../schedules/schedule";
import { Voicemail } from "../voicemails/voicemail";
import { FollowMe } from "./follow_me";
import { Line } from "./line";

export interface User {
    id?: number;
    uuid?: string;
    tenant_uuid?: string;
    firstname: string;
    lastname?: string;
    description?: string;

    caller_id?: string;
    outgoing_caller_id?: string;
    voicemail_info?: Voicemail;

    email: string
    call_record_incoming_external_enabled?: boolean;
    call_record_incoming_internal_enabled?: boolean;
    call_record_outgoing_external_enabled?: boolean;
    call_record_outgoing_internal_enabled?: boolean;
    ring_seconds?: number;
    simultaneous_calls?: number;

    dnd?: boolean;

    lines: WazoRef[];
    lines_details: Line[];
    main_line?: WazoRef;

    groups?: WazoRef[];
    directories?: string[];

    forwards?: Forwards;
    fallbacks?: Fallbacks;

    schedule?: WazoRef;
    schedule_details?: Schedule;

    follow_me?: FollowMe[];
    funckeys: FuncKey[];
}

export const getUserLabel = (user: User) => {
    return `${user.firstname}${user.lastname ? ' ' + user.lastname:''}`;
};

export const getUserMainLineLabel = (user: User) => {
    if (user.main_line) {
        return user.main_line.label;
    }

    if (user.lines.length === 0) {
        return "";
    }
    const lineRef = user.lines[0];

    const line = user.lines_details.find(l => getRefId(l) === getRefId(lineRef as WazoRef))
    return line?.exten ?? lineRef.label;
};
