import { Tab, TabProps, useTheme } from '@mui/material';
import React from 'react';

export interface Props extends TabProps {
    error?: boolean;
}

const FormTab: React.FC<Props> = ({error, ...restProps}) => {
    const theme = useTheme();

    return (
        <Tab
            style={error ? {
                color: theme.palette.error.main,
            }: {}}
            {...restProps}
        ></Tab>
    )
}

export default FormTab
