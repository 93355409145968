import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import IconTooltip from 'common/components/Tooltip';
import Passwordfield from 'common/fields/Passwordfield';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import { ContextsContext } from 'components/misc/providers/ContextsCtx';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { SipTemplateContext } from 'components/misc/providers/SipTemplateCtx';
import { TrunkForm } from 'data/forms/routes/trunks/trunk_form';
import { getValueFromList, updateValueInList, WazoList } from 'data/misc/wazo_list';
import { NumericFormat } from 'formats/common';
import { EditCtx, useChangeSubProp } from 'hooks/edit_hook';
import React, { useContext, useEffect, useState } from 'react';

export interface Props extends FormKeyProps {
    formEdit: EditCtx<TrunkForm>;
}

const TrunkSIPGeneralFormComponent: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const contextsCtx = useContext(ContextsContext);
    const sipTemplatesCtx = useContext(SipTemplateContext);
    const [form, setForm, reduceForm] = props.formEdit;
    const [initialized, setInitialized] = useState(false);

    const changeEndpoint = useChangeSubProp("endpoint_sip", form.endpoint_sip, setForm);

    useEffect(() => {
        if (!form.endpoint_sip || initialized) {
            return;
        }

        const serverUri = getValueFromList("server_uri", form.endpoint_sip?.registration_section_options);
        const serverUriPattern = new RegExp(/sip:(?<host>[-_\w.]+)(:(?<port>\d+))?/);
        const parts = serverUri.match(serverUriPattern)?.groups ?? {};

        const newGeneralForm = {
            password: getValueFromList("password", form.endpoint_sip?.auth_section_options),
            host: parts["host"],
            port: parts["port"],
        };
        reduceForm(newGeneralForm);
        setInitialized(true);
    }, [form.endpoint_sip]);

    useEffect(() => {
        if (!form.endpoint_sip || !initialized) {
            return;
        }

        let auth = form.endpoint_sip?.auth_section_options;
        let registerOutbound = form.endpoint_sip?.registration_outbound_auth_section_options;
        let outboundRegister = form.endpoint_sip?.outbound_auth_section_options;
        let register = form.endpoint_sip?.registration_section_options;
        let aor = form.endpoint_sip?.aor_section_options;

        const updateCreds = (list: WazoList) => {
            list = updateValueInList("username", form.name, list);
            list = updateValueInList("password", form.password ?? "", list);

            return list;
        };

        auth = updateCreds(auth);
        registerOutbound = updateCreds(registerOutbound);
        outboundRegister = updateCreds(outboundRegister);

        if (form.host) {
            aor = updateValueInList("contact", `sip:${form.host}`, aor);
            const hostUri = `${form.host}${form.port ? `:${form.port}` : ''}`;
            register = updateValueInList("server_uri", `sip:${hostUri}`, register);
            register = updateValueInList("client_uri", `sip:${form.name}@${hostUri}`, register);
        }

        setForm("endpoint_sip", {
            ...form.endpoint_sip,
            registration_section_options: register,
            registration_outbound_auth_section_options: registerOutbound,
            outbound_auth_section_options: outboundRegister,
            auth_section_options: auth,
            aor_section_options: aor,
        });
    }, [form]);

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Nom"}
                        value={form.name}
                        error={errorCtx.getError("name", props.formKeyPath)}
                        name={"name"}
                        editValue={setForm}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Étiquette"}
                        value={form.label}
                        error={errorCtx.getError("label", props.formKeyPath)}
                        name={"label"}
                        editValue={setForm}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Selectfield
                        required
                        label={"Contexte"}
                        value={form.context}
                        error={errorCtx.getError("context", props.formKeyPath)}
                        name={"context"}
                        editValue={setForm}
                        options={contextsCtx.contexts?.map(c => {
                            return {
                                id: c.name,
                                label: c.label,
                            };
                        })}
                    />
                </LeftColumn>
                <RightColumn>
                    <Selectfield
                        multiple
                        draggable
                        label={"Gabarits SIP"}
                        value={form.endpoint_sip?.templates.map(v => v.uuid)}
                        error={errorCtx.getError("templates", props.formKeyPath)}
                        name={"templates"}
                        editValue={(_, v) => changeEndpoint("templates", v.map((u: string) => {
                            return {
                                uuid: u,
                            };
                        }))}
                        options={sipTemplatesCtx.sipTemplates?.map(c => {
                            return {
                                id: c.uuid as string,
                                label: c.label ?? c.name,
                            };
                        })}
                    />
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Passwordfield
                        label={"Mot de passe"}
                        value={form.password}
                        error={errorCtx.getError("password", props.formKeyPath)}
                        name={"password"}
                        editValue={setForm}
                        EndAdornment={
                            <IconTooltip
                                variant="h6"
                                icon={faQuestionCircle}
                                tooltip={"Un mot de passe sera automatiquement atribué si aucun n'est assigné"}
                            />
                        }
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Hôte"}
                        value={form.host}
                        error={errorCtx.getError("host", props.formKeyPath)}
                        name={"host"}
                        editValue={setForm}
                    />
                </LeftColumn>
                <RightColumn>
                    <Textfield
                        label={"Port"}
                        value={form.port}
                        error={errorCtx.getError("port", props.formKeyPath)}
                        name={"port"}
                        editValue={setForm}
                        format={NumericFormat}
                    />
                </RightColumn>
            </Row>
        </>
    )
}

export default TrunkSIPGeneralFormComponent
