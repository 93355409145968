import EndpointIAXFormComponent from 'components/endpoints/EndpointIAXForm';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultEndpointIAXForm } from 'data/forms/endpoints/endpoint_iax_form';
import { TrunkForm } from 'data/forms/routes/trunks/trunk_form';
import { EditCtx, useChangeSubProp, useReduceSubProp } from 'hooks/edit_hook';
import React, { useContext } from 'react';
import TrunkIAXGeneralFormComponent from './TrunkIAXGeneralForm';

export interface Props extends FormKeyProps {
    formEdit: EditCtx<TrunkForm>;
}

const TrunkIAXFormComponent: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setForm] = props.formEdit;

    const endpoint = form.endpoint_iax ?? defaultEndpointIAXForm();
    const setEndpointForm = useChangeSubProp("endpoint_iax", form.endpoint_iax, setForm);
    const reduceEndpointForm = useReduceSubProp("endpoint_iax", form.endpoint_iax, setForm);

    return (
        <>
            <EndpointIAXFormComponent
                endpoint={endpoint}
                setter={setEndpointForm}
                reducer={reduceEndpointForm}
                errorGeneral={errorCtx.hasAnyErrorInPaths([
                    "name",
                    "type",
                    "context",
                    "host",
                    "port",
                ])}
            >
                <TrunkIAXGeneralFormComponent formEdit={props.formEdit} />
            </EndpointIAXFormComponent>
        </>
    )
}

export default TrunkIAXFormComponent
