import { Typography } from '@mui/material';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import ContextsContextProvider from 'components/misc/providers/ContextsCtx';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import SipTemplateContextProvider from 'components/misc/providers/SipTemplateCtx';
import { defaultTrunkForm, TrunkForm, trunkToForm } from 'data/forms/routes/trunks/trunk_form';
import { getTrunkType, Trunk } from 'data/routes/trunks/trunk';
import { useProviders } from 'form-hooks/providers_hook';
import { useData } from 'hooks/data_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect } from 'react';
import { getTrunk, updateTrunk } from 'services/trunks_service';
import TrunkIAXFormComponent from './forms/TrunkIAXForm';
import TrunkSIPFormComponent from './forms/TrunkSIPForm';

export interface Props extends FormKeyProps {
    editValue?: Trunk;
    onExit: (updated: boolean) => void;
}

const TrunkFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const formEdit = useEdit<TrunkForm>(defaultTrunkForm());
    const [form, _, reduceForm] = formEdit;

    const getTrunkDetails = useCallback((authCtx: AuthenticationCtx): Promise<Trunk> => {
        if (!props.editValue?.id) {
            return new Promise(resolve => {
                resolve({
                    id: undefined,
                } as Trunk);
            });
        }

        return getTrunk(authCtx, props.editValue?.id);
    }, [props.editValue]);

    const { data: trunk, isLoading } = useData(getTrunkDetails, { id: undefined });
    useEffect(() => {
        if (!trunk?.id) {
            return
        }

        reduceForm(trunkToForm(trunk));
    }, [trunk]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["trunks"]);
    }, [props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateTrunk,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <SipTemplateContextProvider>
                    <ContextsContextProvider>
                        <Row>
                            <LeftColumn style={{ justifyContent: "start" }}>
                                <Typography variant='h5'>Trunk</Typography>
                            </LeftColumn>
                        </Row>
                        {getTrunkType(form) === "iax" ?
                            (
                                <TrunkIAXFormComponent formEdit={formEdit} />
                            ) :
                            (
                                <TrunkSIPFormComponent formEdit={formEdit} />
                            )
                        }
                        <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
                    </ContextsContextProvider>
                </SipTemplateContextProvider>
            </ErrorContextProvider>
            <LoadingModal isLoading={isLoading || isSaving} />
        </>
    )
}

export default TrunkFormComponent
