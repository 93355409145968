import { useTheme } from '@mui/material'
import { NavContext } from 'components/nav/NavbarContext';
import React, { PropsWithChildren, useContext } from 'react'
import { ContainerBox } from './Container.styled';

const Container: React.FC<PropsWithChildren> = (props) => {
    const navCtx = useContext(NavContext);
    const theme = useTheme();

    return (
        <ContainerBox theme={theme} state={navCtx.state}>
            {props.children}
        </ContainerBox>
    )
}

export default Container
