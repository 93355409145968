import { Destination } from "data/misc/destinations";
import { Fallbacks } from "data/users/fallbacks";

export interface FallbacksForm {
    busy_destination?: Destination;
    congestion_destination?: Destination;
    fail_destination?: Destination;
    noanswer_destination?: Destination;
}

export const fallbacksToForm = (fallbacks: Fallbacks): FallbacksForm => {
    return {
        busy_destination: fallbacks.busy_destination ? {
            ...fallbacks.busy_destination
        } as Destination:undefined,
        congestion_destination: fallbacks.congestion_destination ? {
            ...fallbacks.congestion_destination
        } as Destination:undefined,
        fail_destination: fallbacks.fail_destination ? {
            ...fallbacks.fail_destination
        } as Destination:undefined,
        noanswer_destination: fallbacks.noanswer_destination ? {
            ...fallbacks.noanswer_destination
        } as Destination:undefined,
    };
};
