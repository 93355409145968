import { SelectOption } from "common/fields/Selectfield";
import { Destination } from "data/misc/destinations";
import { Timezone } from "data/misc/timezone";
import { Schedule, SchedulePeriod } from "data/users/schedules/schedule";
import moment from "moment";

export const Weekdays: SelectOption[] = [
    { id: 1, label: "Lundi" },
    { id: 2, label: "Mardi" },
    { id: 3, label: "Mercredi" },
    { id: 4, label: "Jeudi" },
    { id: 5, label: "Vendredi" },
    { id: 6, label: "Samedi" },
    { id: 7, label: "Dimanche" },
];

export const Months: SelectOption[] = [
    { id: 1, label: "Janvier" },
    { id: 2, label: "Février" },
    { id: 3, label: "Mars" },
    { id: 4, label: "Avril" },
    { id: 5, label: "Mai" },
    { id: 6, label: "Juin" },
    { id: 7, label: "Juillet" },
    { id: 8, label: "Août" },
    { id: 9, label: "Septembre" },
    { id: 10, label: "Octobre" },
    { id: 11, label: "Novembre" },
    { id: 12, label: "Décembre" },
];

export const MonthDays: SelectOption[] = Array(31).fill(0).map((_, i: number) => {
    return {
        id: i + 1,
        label: (i + 1).toString(),
    };
});

export interface SchedulePeriodForm {
    hours_start?: moment.Moment;
    hours_end?: moment.Moment;
    month_days: number[];
    week_days: number[];
    months: number[];
}

export interface ExceptionalPeriodForm {
    hours_start?: moment.Moment;
    hours_end?: moment.Moment;
    month_days: number[];
    week_days: number[];
    months: number[];
    destination: Destination
}

export interface ScheduleForm {
    id?: number;

    name: string;
    open_periods: SchedulePeriodForm[];
    exceptional_periods: ExceptionalPeriodForm[];
    timezone: Timezone;
    closed_destination: Destination;
}

export const defaultScheduleForm = (): ScheduleForm => {
    return {
        id: undefined,
        name: "",
        open_periods: [],
        exceptional_periods: [],
        timezone: "America/New_York",
        closed_destination: { type: "none" },
    };
};

export const defaultSchedulePeriodForm = (): SchedulePeriodForm => {
    return {
        hours_start: undefined,
        hours_end: undefined,
        month_days: [],
        months: [],
        week_days: [],
    };
};

export const defaultScheduleExceptionalPeriodForm = (): ExceptionalPeriodForm => {
    return {
        ...defaultSchedulePeriodForm(),
        destination: { type: "none" },
    };
};

export const schedulePeriodToForm = (period: SchedulePeriod): SchedulePeriodForm => {
    return {
        ...period,
        hours_start: moment(`2024-01-01 ${period.hours_start}`),
        hours_end: moment(`2024-01-01 ${period.hours_end}`),
    };
};

export const schedulePeriodFormToBody = (period: SchedulePeriodForm): object => {
    return {
        ...period,
        hours_start: period.hours_start?.format("HH:mm"),
        hours_end: period.hours_end?.format("HH:mm"),
    };
};

export const scheduleToForm = (schedule: Schedule, id?: number): ScheduleForm => {
    return {
        id: id ?? schedule.id,
        name: schedule.name,
        open_periods: schedule.open_periods?.map((p) => schedulePeriodToForm(p)) ?? [],
        exceptional_periods: schedule.exceptional_periods?.map(p => {
            return { ...p, ...schedulePeriodToForm(p) } as ExceptionalPeriodForm
        }) ?? [],
        timezone: schedule.timezone,
        closed_destination: schedule.closed_destination as Destination,
    };
};

export const scheduleFormToBody = (schedule: ScheduleForm): object => {
    return {
        ...schedule,
        open_periods: schedule.open_periods.map((p) => schedulePeriodFormToBody(p)),
        exceptional_periods: schedule.exceptional_periods.map(p => {
            return { ...p, ...schedulePeriodFormToBody(p) } as ExceptionalPeriodForm
        }),
    };
};
