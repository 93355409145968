import { Error, GetError, HasAnyErrorInPaths, HasAnyErrors, HasError, HasErrorInPath } from 'hooks/save_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'

export interface ErrorCtx {
    getError: GetError,
    hasError: HasError,
    hasErrorInPath: HasErrorInPath,
    hasAnyErrorInPaths: HasAnyErrorInPaths,
    hasAnyErrors: HasAnyErrors,
}

export interface ErrorProps {
    error: Error;
}

export interface FormKeyProps {
    formKeyPath?: string;
}

export const ErrorContext = createContext<ErrorCtx>({
    hasError: () => false,
    getError: () => "",
    hasErrorInPath: () => false,
    hasAnyErrorInPaths: () => false,
    hasAnyErrors: () => false,
});

const ErrorContextProvider: React.FC<PropsWithChildren<ErrorProps>> = (props) => {
    const ctx = useMemo<ErrorCtx>(() => {
        return {
            hasError: props.error.hasError,
            getError: props.error.getError,
            hasErrorInPath: props.error.hasErrorInPath,
            hasAnyErrors: props.error.hasAnyErrors,
            hasAnyErrorInPaths: props.error.hasAnyErrorInPaths,
        }
    }, [props]);

    return (
        <ErrorContext.Provider value={ctx}>
            {props.children}
        </ErrorContext.Provider>
    )
}

export default ErrorContextProvider
