import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import { SipTemplate } from 'data/advanced/sip_templates/sip_template';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getSipTemplates } from 'services/sip_templates_service';
import SipTemplateFormComponent from './SipTemplateForm';
import SipTemplatesList from './SipTemplatesList';

const SipTemplates: React.FC = (props) => {
    const sipTemplatesList = useFilterList(getSipTemplates, "label");
    const { state, editValue, setState } = useComponent<SipTemplate>();

    return (
        <Content>
            {state === "list" &&
                <SipTemplatesList
                    sipTemplatesList={sipTemplatesList}
                    showForm={(sipTemplate?: SipTemplate) => {
                        setState("form", sipTemplate);
                    }}
                />
            }
            {state === "form" &&
                <SipTemplateFormComponent
                    editValue={editValue}
                    onExit={(updated) => {
                        if (updated) {
                            sipTemplatesList.refreshList();
                        }
                        setState("list", undefined);
                    }}
                />
            }
            <LoadingModal smallLoader isLoading={sipTemplatesList.isLoading} />
        </Content>
    )
}

export default SipTemplates
