import Selectfield from 'common/fields/Selectfield';
import LeftColumn from 'common/layout/LeftColumn';
import Row, { NoRowPadding } from 'common/layout/Row';
import { IvrDestination } from 'data/misc/destinations';
import { useEdit } from 'hooks/edit_hook';
import React, { useContext, useEffect } from 'react';
import { DestinationContext } from '../providers/DestinationFormContext';
import { ErrorContext, FormKeyProps } from '../providers/ErrorCtx';

export interface Props extends FormKeyProps {
    value: IvrDestination | undefined;
    onEdit: (d: IvrDestination) => void;
}

const IvrDestinationForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const destinationCtx = useContext(DestinationContext);
    const [value, setValue, reduceValue] = useEdit({
        type: "ivr",
        ivr_id: props.value?.ivr_id,
    } as IvrDestination);

    useEffect(() => {
        reduceValue({
            ivr_id: props.value?.ivr_id,
        });
    }, [props.value]);

    useEffect(() => {
        props.onEdit(value);
    }, [value]);

    return (
        <>
            <Row style={NoRowPadding}>
                <LeftColumn>
                    <Selectfield
                        required
                        label={"SVI"}
                        value={value?.ivr_id}
                        name={"ivr_id"}
                        error={errorCtx.getError("ivr_id", props.formKeyPath)}
                        editValue={setValue}
                        options={destinationCtx.ivrs.map(i => {
                            return {
                                id: i.id as number,
                                label: i.name,
                            };
                        })}
                    >
                    </Selectfield>
                </LeftColumn>
            </Row>
        </>
    )
}

export default IvrDestinationForm
