import React, { useContext } from 'react';
import 'styles/index.css';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import { EditCtx } from 'hooks/edit_hook';
import { UserForm } from 'data/forms/users/users/user_form';
import { Typography } from '@mui/material';
import Textfield from 'common/fields/Textfield';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import CallerID from 'common/fields/CallerID';
import GridRow from 'common/layout/GridRow';
import GridColumn from 'common/layout/GridColumn';
import LargeRow from 'common/layout/LargeRow';

export interface Props extends FormKeyProps {
    userCtx: EditCtx<UserForm>;
}

const UserGeneralDashboard: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setFormValue] = props.userCtx;

    return (
        <div style={{ gridArea: "general" }}>
            <Card>
                <CardBody smallPadding>
                    <LargeRow>
                        <Typography variant="h5">
                            Informations générales
                        </Typography>
                    </LargeRow>
                    <GridRow templateColumns='25% 25% 25% 25%'>
                        <GridColumn>
                            <Textfield
                                required
                                label={"Prénom"}
                                value={form.firstname}
                                error={errorCtx.getError("firstname", props.formKeyPath)}
                                name={"firstname"}
                                editValue={setFormValue}
                            />
                            <Textfield
                                label={"Nom"}
                                value={form.lastname}
                                error={errorCtx.getError("lastname", props.formKeyPath)}
                                name={"lastname"}
                                editValue={setFormValue}
                            />
                        </GridColumn>
                        <GridColumn>
                            <Textfield
                                required
                                label={"Courriel"}
                                value={form.email}
                                error={errorCtx.getError("email", props.formKeyPath)}
                                name={"email"}
                                editValue={setFormValue}
                            />
                        </GridColumn>
                        <GridColumn>
                            <CallerID
                                label={"Afficheur interne"}
                                value={form.caller_id}
                                error={errorCtx.getError("caller_id", props.formKeyPath)}
                                name={"caller_id"}
                                editValue={setFormValue}
                                onlyName
                            />
                        </GridColumn>
                        <GridColumn>
                            <CallerID
                                label={"Afficheur externe"}
                                value={form.outgoing_caller_id}
                                error={errorCtx.getError("outgoing_caller_id", props.formKeyPath)}
                                name={"outgoing_caller_id"}
                                editValue={setFormValue}
                            />
                        </GridColumn>
                    </GridRow>
                </CardBody>
            </Card>
        </div>
    );
}

export default UserGeneralDashboard
