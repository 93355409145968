import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import { InboundRoute } from 'data/routes/inbound/inbound_route';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getInboundRoutes } from 'services/inbound_routes_service';
import InboundRouteFormComponent from './InboundRouteForm';
import InboundRoutesList from './InboundRoutesList';

const InboundRoutes: React.FC = (props) => {
    const inboundRoutesList = useFilterList(getInboundRoutes, "exten");
    const { state, editValue, setState } = useComponent<InboundRoute>();

    return (
        <Content>
            {state === "list" &&
                <InboundRoutesList
                    inboundRoutesList={inboundRoutesList}
                    showForm={(inboundRoute?: InboundRoute) => {
                        setState("form", inboundRoute);
                    }}
                />
            }
            {state === "form" &&
                <InboundRouteFormComponent
                    editValue={editValue}
                    onExit={(updated) => {
                        if (updated) {
                            inboundRoutesList.refreshList();
                        }
                        setState("list", undefined);
                    }}
                />
            }
            <LoadingModal smallLoader isLoading={inboundRoutesList.isLoading} />
        </Content>
    )
}

export default InboundRoutes
