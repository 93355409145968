import { tenantUrl, get, jsonBody, post, put } from './api';
import { RingGroup } from 'data/users/ring_groups/ring_group';
import { FilterResult } from 'data/api/filter_result';
import { Message } from 'data/api/message';
import { buildQueryString, Filter } from 'data/api/filter';
import { RingGroupForm, ringGroupFormToBody } from 'data/forms/users/ring_groups/ring_group_form';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';

const endpoint = "group";

export const getAllGroups = (authCtx: AuthenticationCtx): Promise<FilterResult<RingGroup>> => {
    return get<FilterResult<RingGroup>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getRingGroups = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<RingGroup>> => {
    return get<FilterResult<RingGroup>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getRingGroup = (authCtx: AuthenticationCtx, uuid: string): Promise<RingGroup> => {
    return get<RingGroup>({
        url: tenantUrl(`${endpoint}/by/id/${uuid}`, authCtx),
        authentication: authCtx,
    });
}

export const updateRingGroup = (authCtx: AuthenticationCtx, ringGroup: RingGroupForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${ringGroup.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(ringGroupFormToBody(ringGroup)));
}

export const createRingGroup = (authCtx: AuthenticationCtx, ringGroup: RingGroupForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(ringGroupFormToBody(ringGroup)));
}
