import { faAngleDown, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import CheckboxField from 'common/fields/Checkbox';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import { DeviceContext } from 'components/misc/providers/DeviceCtx';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultLineDeviceForm, defaultLineForm, LineDeviceForm, LineForm } from 'data/forms/users/users/line_form';
import { LineType, LineTypes } from 'data/users/users/line';
import { useListValueForm } from 'form-hooks/list_value_form_hook';
import { reducer, useChangeSubProp } from 'hooks/edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import UserLineDeviceForm from './line_types/LineDeviceForm';
import UserLineSoftphoneForm from './line_types/LineSoftphoneForm';
import UserLineEndpointSIPForm from './UserLineEndpointSIPForm';
import UserLineGeneralForm from './UserLineGeneralForm';

export interface Props extends FormKeyProps {
    extensions: Set<number>;
    line?: LineForm;
    saveValue: (l: LineForm) => void;
    selectMainLine?: (l: LineForm) => void;
}

const UserLineForm: React.FC<PropsWithChildren<Props>> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const deviceCtx = useContext(DeviceContext);
    const [showEndpoint, setShowEndpoint] = useState(false);
    const [originalDevice, setOriginalDevice] = useState<LineDeviceForm | undefined>();

    const {
        form: line,
        setValue,
        reduceValue,
        reset,
        isEditing: editing,
        saveValue: save,
        editReduceForm: reduceForm,
    } = useListValueForm(props.line ?? defaultLineForm(), props.saveValue);

    useEffect(() => {
        if (!editing || line.id == null) {
            return
        }

        save();
    }, [line]);

    useEffect(() => {
        if (props.line) {
            reduceForm(props.line);
            setOriginalDevice(props.line.device);
        }
        if (!deviceCtx || !props.line || !props.line.device?.id) {
            return
        }

        const device = deviceCtx.getDevice(props.line.device.id);
        if (device && device.mac !== line.device?.mac) {
            const lineDevice = {
                id: device.id,
                mac: device.mac,
                plugin: device.plugin,
            };

            setOriginalDevice(lineDevice);
            const newLine = reducer(line, {
                device: lineDevice,
            });
            reduceForm(newLine);
            props.saveValue(newLine);
        }
    }, [props.line, deviceCtx]);

    const changeDevice = useChangeSubProp("device", line.device, setValue);
    const setDevice = useCallback((device: LineDeviceForm) => {
        setValue("device", device);
    }, [setValue]);

    const resetForm = useCallback(() => {
        reset(originalDevice ? {
            device: originalDevice,
        } : {});
    }, [props, originalDevice, reset]);

    const extensions = useMemo(() => {
        const extensions = new Set(props.extensions);
        extensions.add(Number(line.exten));
        return Array.from(extensions.values()).map((e) => {
            return {
                id: e.toString(),
                label: e.toString(),
            };
        });
    }, [props.extensions, line.exten]);

    return (
        <>
            <Card>
                <CardBody smallPadding darkBody>
                    <GridRow columnsForAllSmallerScreens templateColumns='5% 10% 20% 15% 30% 20%'>
                        <GridColumn>
                            {props.selectMainLine &&
                                <CheckboxField
                                    icon={<FontAwesomeIcon icon={faStar} />}
                                    checkedIcon={<FontAwesomeIcon color="primary" icon={faStar} />}
                                    value={line.is_main}
                                    name={'is_main'}
                                    editValue={(_, _v) => {
                                        if (!props.selectMainLine) {
                                            return;
                                        }
                                        props.selectMainLine(line);
                                    }}
                                ></CheckboxField>
                            }
                        </GridColumn>
                        <GridColumn>
                            <Selectfield
                                required
                                error={errorCtx.getError("exten", props.formKeyPath)}
                                name={"exten"}
                                label={"Extension"}
                                value={line.exten}
                                editValue={setValue}
                                options={extensions}
                            />
                        </GridColumn>
                        <GridColumn>
                            <Textfield
                                error={errorCtx.getError("label", props.formKeyPath)}
                                name={"label"}
                                label={"Étiquette"}
                                value={line.label}
                                editValue={setValue}
                            />
                        </GridColumn>
                        <GridColumn>
                            <Selectfield
                                error={errorCtx.getError("line_type", props.formKeyPath)}
                                name={"line_type"}
                                label={"Type"}
                                value={line.line_type}
                                editValue={(_k, v: LineType) => {
                                    reduceValue({
                                        line_type: v,
                                        device: v === "device" ? defaultLineDeviceForm() : undefined,
                                    });
                                }}
                                options={Object.keys(LineTypes).map(l => {
                                    return {
                                        id: l,
                                        label: LineTypes[l],
                                    };
                                })}
                            />
                        </GridColumn>
                        {line.line_type === "device" &&
                            <UserLineDeviceForm
                                formKeyPath={keyPath([props.formKeyPath, "device"])}
                                setter={changeDevice}
                                device={line.device}
                                saveValue={(d: LineDeviceForm) => setDevice(d)}
                            />
                        }
                        {line.line_type === "softphone" &&
                            <UserLineSoftphoneForm line={line} />
                        }
                        {line.line_type !== "device" && line.line_type !== "softphone" &&
                            <GridColumn></GridColumn>
                        }
                        <GridColumn align='end'>
                            {line.id == null && editing &&
                                <div style={{ gap: "0.5rem", display: "flex" }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            save();
                                            if (!props.line) {
                                                resetForm();
                                            }
                                        }}
                                    >
                                        Ok
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        color="secondary"
                                        onClick={() => resetForm()}
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            }
                            {line.id !== undefined &&
                                <Button variant='text' onClick={() => setShowEndpoint(true)}>
                                    <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                </Button>
                            }
                        </GridColumn>
                    </GridRow>
                    {props.children}
                </CardBody>
            </Card>
            <UserLineEndpointSIPForm
                formKeyPath={keyPath([props.formKeyPath, "endpoint_sip"])}
                endpoint={line.endpoint_sip}
                show={showEndpoint}
                onExit={() => setShowEndpoint(false)}
                saveValue={(v) => {
                    setShowEndpoint(false);
                    setValue("endpoint_sip", v);
                }}
            >
                <UserLineGeneralForm
                    line={line}
                    onChange={(l) => {
                        reduceValue(l);
                    }}
                />
            </UserLineEndpointSIPForm>
        </>
    )
}

export default UserLineForm
