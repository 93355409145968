import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import { Context } from 'data/advanced/contexts/context';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getContexts } from 'services/contexts_service';
import ContextFormComponent from './ContextForm';
import ContextsList from './ContextsList';

const Contexts: React.FC = (props) => {
    const contextsList = useFilterList(getContexts, "name");
    const { state, editValue, setState } = useComponent<Context>();

    return (
        <Content>
            {state === "list" &&
                <ContextsList
                    contextsList={contextsList}
                    showForm={(context?: Context) => {
                        setState("form", context);
                    }}
                />
            }
            {state === "form" &&
                <ContextFormComponent
                    editValue={editValue}
                    onExit={(updated) => {
                        if (updated) {
                            contextsList.refreshList();
                        }
                        setState("list", undefined);
                    }}
                />
            }
            <LoadingModal smallLoader isLoading={contextsList.isLoading} />
        </Content>
    )
}

export default Contexts
