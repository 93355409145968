import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import ContextsContextProvider from 'components/misc/providers/ContextsCtx';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import FormTab from 'components/misc/Tab';
import FormTabs from 'components/misc/Tabs';
import { Context } from 'data/advanced/contexts/context';
import { ContextForm, contextToForm, defaultContextForm } from 'data/forms/advanced/contexts/context_form';
import { useProviders } from 'form-hooks/providers_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect, useState } from 'react';
import { createContext, updateContext } from 'services/contexts_service';
import ContextGeneralForm from './forms/ContextGeneralForm';
import ContextIncallRangesForm from './forms/ContextIncallRangesForm';
import ContextUserRangesForm from './forms/ContextUserRangesForm';

export interface Props extends FormKeyProps {
    editValue?: Context;
    onExit: (updated: boolean) => void;
}

const ContextFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const formEdit = useEdit<ContextForm>(defaultContextForm());
    const [form, _, reduceForm] = formEdit;
    const [tab, setTab] = useState("general");

    useEffect(() => {
        if (!props.editValue) {
            return
        }

        reduceForm(contextToForm(props.editValue));
    }, [props.editValue]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["contexts"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateContext,
        createHandler: createContext,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <ContextsContextProvider>
                    <Row>
                        <LeftColumn style={{ justifyContent: "start" }}>
                            <Typography variant='h5'>Contexte</Typography>
                        </LeftColumn>
                    </Row>
                    <FormTabs style={{ marginTop: "1rem" }} tab={tab} setTab={setTab}>
                        <FormTab
                            value={"general"}
                            label={"Général"}
                            error={tab !== "general" && error.hasAnyErrorInPaths([
                                "name",
                                "label",
                                "type",
                                "contexts",
                            ])}
                        />
                        {(form.type === "incall" || form.type === "internal") &&
                            <FormTab
                                value={"ranges"}
                                label={"Extensions"}
                                error={tab !== "ranges" && error.hasAnyErrorInPaths([
                                    "user_ranges",
                                    "incall_ranges",
                                ])}
                            />
                        }
                    </FormTabs>
                    <Card>
                        <CardBody noTopBorderRadius>
                            {tab === "general" &&
                                <ContextGeneralForm valueEdit={formEdit} />
                            }
                            {tab === "ranges" && form.type === "incall" &&
                                <ContextIncallRangesForm valueEdit={formEdit} />
                            }
                            {tab === "ranges" && form.type === "internal" &&
                                <ContextUserRangesForm valueEdit={formEdit} />
                            }
                        </CardBody>
                    </Card>
                    <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
                </ContextsContextProvider>
            </ErrorContextProvider>
            <LoadingModal isLoading={isSaving} />
        </>
    )
}

export default ContextFormComponent
