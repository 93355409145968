import { defaultFilterResult } from 'data/api/filter_result';
import { Ivr } from 'data/services/ivr/ivr';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllIvrs } from 'services/ivr_service';

export interface IvrCtx {
    ivrs: Ivr[];
    refresh: () => void;
}

export const IvrContext = createContext<IvrCtx>({
    ivrs: [],
    refresh: () => { },
});

const IvrContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: ivrs, refresh } = useCachedData("ivrs", getAllIvrs, defaultFilterResult());

    const ctx = useMemo<IvrCtx>(() => {
        return {
            ivrs: ivrs.items,
            refresh: refresh,
        }
    }, [ivrs]);

    return (
        <IvrContext.Provider value={ctx}>
            {props.children}
        </IvrContext.Provider>
    )
}

export default IvrContextProvider
