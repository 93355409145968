import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import ContextsContextProvider from 'components/misc/providers/ContextsCtx';
import DestinationsProvider from 'components/misc/providers/DestinationsCtx';
import DeviceContextProvider from 'components/misc/providers/DeviceCtx';
import DirectoriesContextProvider from 'components/misc/providers/DirectoriesCtx';
import ErrorContextProvider from 'components/misc/providers/ErrorCtx';
import LineContextProvider from 'components/misc/providers/LineCtx';
import PluginContextProvider from 'components/misc/providers/PluginCtx';
import RingGroupContextProvider from 'components/misc/providers/RingGroupCtx';
import ScheduleContextProvider from 'components/misc/providers/ScheduleCtx';
import SipTemplateContextProvider from 'components/misc/providers/SipTemplateCtx';
import FormTab from 'components/misc/Tab';
import FormTabs from 'components/misc/Tabs';
import { defaultUserForm, UserForm, userToForm } from 'data/forms/users/users/user_form';
import { User } from 'data/users/users/user';
import { useProviders } from 'form-hooks/providers_hook';
import { useData } from 'hooks/data_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect, useState } from 'react';
import { createUser, getUser, updateUser } from 'services/users_service';
import UserDirectoriesForm from './forms/UserDirectoriesForm';
import UserFallbacksForm from './forms/UserFallbacksForm';
import UserGeneralForm from './forms/UserGeneralForm';
import UserGroupsForm from './forms/UserGroupsForm';
import UserLinesForm from './forms/UserLinesForm';
import UserScheduleForm from './forms/UserScheduleForm';
import UserServicesForm from './forms/UserServicesForm';
import UserVoicemailForm from './forms/UserVoicemailForm';

export interface Props {
    editUser?: User;
    onExit: (updated: boolean) => void;
}

const UserFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const userEdit = useEdit<UserForm>(defaultUserForm());
    const [form, _1, reduceForm] = userEdit;
    const [tab, setTab] = useState("general");

    const getUserDetails = useCallback((authCtx: AuthenticationCtx): Promise<User> => {
        if (!props.editUser?.uuid) {
            return new Promise(resolve => {
                resolve({
                    uuid: undefined,
                } as User);
            });
        }

        return getUser(authCtx, props.editUser.uuid);
    }, [props.editUser]);

    const { data: user, isLoading } = useData(getUserDetails, { uuid: undefined });
    useEffect(() => {
        if (!user?.uuid) {
            return
        }

        reduceForm(userToForm(user));
    }, [user]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["users", "devices", "schedules", "voicemails", "lines", "ringGroups"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.uuid,
        updateHandler: updateUser,
        createHandler: createUser,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <DirectoriesContextProvider>
                    <LineContextProvider>
                        <ContextsContextProvider>
                            <RingGroupContextProvider>
                                <ScheduleContextProvider>
                                    <SipTemplateContextProvider>
                                        <PluginContextProvider>
                                            <DeviceContextProvider>
                                                <DestinationsProvider>
                                                    <Row>
                                                        <LeftColumn style={{ justifyContent: "start" }}>
                                                            <Typography variant='h5'>Utilisateur</Typography>
                                                        </LeftColumn>
                                                    </Row>
                                                    <FormTabs style={{ marginTop: "1rem" }} tab={tab} setTab={setTab}>
                                                        <FormTab
                                                            value={"general"}
                                                            label={"Général"}
                                                            error={tab !== "general" && error.hasAnyErrors([
                                                                "firstname",
                                                                "lastname",
                                                                "email",
                                                                "caller_id",
                                                                "outgoing_caller_id",
                                                                "ring_seconds",
                                                            ])}
                                                        />
                                                        <FormTab
                                                            value={"services"}
                                                            label={"Services"}
                                                            error={tab !== "services" && error.hasAnyErrorInPaths([
                                                                "follow_me",
                                                                "forwards",
                                                            ])}
                                                        />
                                                        <FormTab
                                                            value={"fallbacks"}
                                                            label={"Destinations alternatives"}
                                                            error={tab !== "fallbacks" && error.hasErrorInPath("fallbacks")}
                                                        />
                                                        <FormTab
                                                            value={"lines"}
                                                            label={"Lignes"}
                                                            error={tab !== "lines" && error.hasErrorInPath("update_lines")}
                                                        />
                                                        <FormTab
                                                            value={"voicemail"}
                                                            label={"Boîte vocale"}
                                                            error={tab !== "voicemail" && error.hasErrorInPath("voicemail")}
                                                        />
                                                        <FormTab
                                                            value={"schedule"}
                                                            label={"Horaire"}
                                                            error={tab !== "schedule" && error.hasErrorInPath("schedule")}
                                                        />
                                                        <FormTab
                                                            value={"funckey"}
                                                            label={"Raccourcis appareil"}
                                                            error={tab !== "funckey" && error.hasErrorInPath("funckeys")}
                                                        />
                                                        <FormTab
                                                            value={"groups"}
                                                            label={"groupes"}
                                                            error={tab !== "gruops" && error.hasErrorInPath("groups")}
                                                        />
                                                        <FormTab
                                                            value={"directories"}
                                                            label={"Répertoires"}
                                                            error={tab !== "directories" && error.hasErrorInPath("directories")}
                                                        />
                                                    </FormTabs>
                                                    <Card>
                                                        <CardBody noTopBorderRadius>
                                                            {tab === "general" &&
                                                                <UserGeneralForm userEdit={userEdit} />
                                                            }
                                                            {tab === "services" &&
                                                                <UserServicesForm userEdit={userEdit} />
                                                            }
                                                            {tab === "fallbacks" &&
                                                                <UserFallbacksForm userEdit={userEdit} />
                                                            }
                                                            {tab === "lines" &&
                                                                <UserLinesForm userEdit={userEdit} />
                                                            }
                                                            {tab === "voicemail" &&
                                                                <UserVoicemailForm userEdit={userEdit} />
                                                            }
                                                            {tab === "schedule" &&
                                                                <UserScheduleForm userEdit={userEdit} />
                                                            }
                                                            {tab === "groups" &&
                                                                <UserGroupsForm userEdit={userEdit} />
                                                            }
                                                            {tab === "directories" &&
                                                                <UserDirectoriesForm userEdit={userEdit} />
                                                            }
                                                        </CardBody>
                                                    </Card>
                                                    <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
                                                </DestinationsProvider>
                                            </DeviceContextProvider>
                                        </PluginContextProvider>
                                    </SipTemplateContextProvider>
                                </ScheduleContextProvider>
                            </RingGroupContextProvider>
                        </ContextsContextProvider>
                    </LineContextProvider>
                </DirectoriesContextProvider>
            </ErrorContextProvider>
            <LoadingModal isLoading={isSaving || isLoading} />
        </>
    )
}

export default UserFormComponent
