import React, { PropsWithChildren } from 'react'
import '@xyflow/react/dist/style.css';
import { Button, ButtonProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export interface Props extends ButtonProps {
    style?: any;
    icon: IconDefinition;
}

const RelationBtnStyle: any = {
    fontSize: "1.25rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

const FlowActionBtn: React.FC<PropsWithChildren<Props>> = ({ style, icon, ...restProps }) => {
    return (
        <>
            <Button
                style={{
                    ...RelationBtnStyle,
                    ...style,
                }}
                variant="text"
                {...restProps}
            >
                <FontAwesomeIcon icon={icon} />
            </Button>
        </>
    )
}

export default FlowActionBtn
