import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react'

const StyledColumn = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    grid-area: content2;
`

export interface ColumnProps extends PropsWithChildren {
    style?: any;
    columnDirection?: boolean;
}

const RightColumn: React.FC<ColumnProps> = (props) => {
  return (
    <StyledColumn style={props.columnDirection ? {flexDirection: "column", ...props.style}:{...props.style}}>
        {props.children}
    </StyledColumn>
  )
}

export default RightColumn
