import React, { ReactNode, useContext } from 'react'
import '@xyflow/react/dist/style.css';
import LeftColumn from 'common/layout/LeftColumn';
import LargeRow from 'common/layout/LargeRow';
import { Button, Typography } from '@mui/material';
import { Handle, NodeProps, Position } from '@xyflow/react';
import FlowNodeComponent, { FlowNodeProps } from './FlowNodeComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExpand } from '@fortawesome/free-solid-svg-icons';
import { ErrorContext, FormKeyProps } from '../providers/ErrorCtx';

export interface InteractableProps extends FlowNodeProps, FormKeyProps {
    style?: any;
    topComponent?: ReactNode;
    deleteComponent?: ReactNode;
    title?: string;
    label?: string;
    sourceId?: string;
    sourcePosition?: Position;
    targetId?: string;
    targetPosition?: Position;
    onExpand?: () => void;
}

const InteractableFlowNode: React.FC<NodeProps<any>> = ({ id, data }) => {
    const errorCtx = useContext(ErrorContext);
    const props = data as InteractableProps;

    return (
        <>
            <FlowNodeComponent
                style={{ padding: "0.25rem 1rem", ...props.style }}
                disabled={props.disabled}
                actionComponent={props.disabled ? props.actionComponent : <></>}
            >
                {errorCtx.hasErrorInPath(props.formKeyPath) &&
                    <div style={{position: "absolute", top: "0.1rem", left: "0.25rem"}}>
                        <Typography variant="body1" color="error" style={{ fontWeight: 600 }}>
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </Typography>
                    </div>
                }
                <LargeRow style={{ marginBot: "1rem" }}>
                    <LeftColumn style={{ justifyContent: "start" }}>
                        {props.topComponent ? (
                            <>
                                {props.topComponent}
                            </>
                        ) : (
                            <>
                                <Typography variant="body1" color="secondary" style={{ fontWeight: 600 }}>
                                    {props.title}
                                </Typography>
                            </>
                        )}
                    </LeftColumn>
                </LargeRow>
                <LargeRow>
                    <LeftColumn style={{ justifyContent: "start" }}>
                        {props.label &&
                            <Typography
                                variant="body1"
                                color="secondary"
                            >
                                {props.label}
                            </Typography>
                        }
                    </LeftColumn>
                </LargeRow>
                {!props.disabled &&
                    <>
                        <div style={{ position: "absolute", top: "1.5rem", right: "0rem" }}>
                            {props.deleteComponent}
                        </div>
                    </>
                }
                {!props.disabled && props.onExpand !== undefined &&
                    <>
                        <Button
                            color={errorCtx.hasErrorInPath(props.formKeyPath) ? "error":"primary"}
                            onClick={() => props.onExpand && props.onExpand()}
                            style={{ position: "absolute", bottom: "0.75rem", right: "-0.5rem" }}
                        >
                            <FontAwesomeIcon icon={faExpand} />
                        </Button>
                    </>
                }
            </FlowNodeComponent>
            {props.sourcePosition &&
                <Handle
                    type="source"
                    position={props.sourcePosition}
                    id={props.sourceId ?? `${id}-source`}
                    isConnectable={true}
                />
            }
            {props.targetPosition &&
                <Handle
                    type="target"
                    position={props.targetPosition}
                    id={props.targetId ?? `${id}-target`}
                    isConnectable={true}
                />
            }
        </>
    )
}

export default InteractableFlowNode
