import styled from '@emotion/styled';

export interface Props {
    theme: any;
    padding?: number;
}

export const FlowNodeStyled = styled.div<Props>`
    background: ${props => props.theme.palette.background.card};
    border: 1px solid black;
    border-radius: 0.2rem;
    min-height: 2.5rem;
    min-width: 10rem;
    padding: ${props => props.padding ?? 2}rem;
    position: relative;
`;
