import { Setter } from "./edit_hook";
import { useCallback, useEffect, useState } from "react";
import { Ref } from "data/ref";
import { v4 as uuidv4 } from 'uuid';

export interface ListValue<T> {
    index: Ref;
    value: T;
}

export interface FormListPropEdit<T> {
    values: ListValue<T>[];
    initialized: boolean;
    getValues: () => T[];
    getListIndex: () => Ref;
    isEmptyList: () => boolean;
    addValues: (values: any[]) => void;
    addValue: (value: any) => void;
    editValue: (value: any) => void;
    editValues: (values: any[]) => void;
    removeValue: (i: number) => void;
    getValueFromList: (index: Ref) => any;
    reset: (values: any[]) => void;
}

export const getListIndex = () => uuidv4();

export function useFormListPropEdit<T>(propKey: string, values: any[], changeValue: Setter): FormListPropEdit<T> {
    const [initialized, setInitialized] = useState(false);
    const [listValues, setListValues] = useState<ListValue<T>[]>([]);

    useEffect(() => {
        if (initialized || values.length === 0) {
            return;
        }

        setInitialized(true);
        addValues(values);
    }, [values]);

    useEffect(() => {
        if (!initialized) {
            return;
        }

        changeValue(propKey, listValues.map(l => l.value));
    }, [listValues]);

    const isEmptyList = useCallback(() => listValues.length === 0, [listValues]);

    const editValue = useCallback((value: ListValue<T>) => {
        setInitialized(true);
        const i = listValues.findIndex((b: ListValue<T>) => b.index === value.index);
        if (i === -1) {
            return;
        }

        const newValues = [...listValues];
        newValues[i] = {
            ...newValues[i],
            value: value.value,
        };
        setListValues(newValues);
    }, [setListValues, listValues]);

    const editValues = useCallback((values: ListValue<T>[]) => {
        setInitialized(true);

        const newValues = [...listValues];
        for (const newValue of values) {
            const i = listValues.findIndex((b: ListValue<T>) => b.index === newValue.index);
            if (i === -1) {
                continue;
            }

            newValues[i] = {
                ...newValues[i],
                value: newValue.value,
            };
        }
        setListValues(newValues);
    }, [setListValues, listValues]);

    const addValues = useCallback((values: any[]) => {
        setInitialized(true);
        const currentValues = [...listValues];
        const newValues = values.map((val) => {
            return {
                index: getListIndex(),
                value: val,
            };
        });
        currentValues.push(...newValues);
        setListValues(currentValues);
    }, [setListValues, listValues]);

    const addValue = useCallback((value: any) => {
        setInitialized(true);
        const newValue: ListValue<T> = {
            index: getListIndex(),
            value: value,
        };
        setListValues([...listValues, newValue]);
    }, [setListValues, listValues]);

    const removeValue = useCallback((i: number) => {
        setInitialized(true);
        const newValues = [...listValues];
        newValues.splice(i, 1);
        setListValues(newValues);
    }, [setListValues, listValues]);

    const getValueFromList = useCallback((index: Ref) => {
        const i = listValues.findIndex((b: ListValue<T>) => b.index === index);
        return i !== -1 ? listValues[i] : null;
    }, [listValues]);

    const reset = useCallback((values: any[]) => {
        setInitialized(true);
        const newValues = values.map((val) => {
            return {
                index: getListIndex(),
                value: val,
            };
        });
        setListValues(newValues);
    }, [setListValues]);

    return {
        values: listValues,
        initialized,
        getValues: () => listValues.map(l => l.value),
        getListIndex,
        isEmptyList,
        addValues,
        addValue,
        editValue,
        editValues,
        removeValue,
        getValueFromList,
        reset,
    };
}
