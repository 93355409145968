import DeleteBtn from 'common/components/DeleteBtn';
import { ContextForm } from 'data/forms/advanced/contexts/context_form';
import { WazoRange } from 'data/misc/wazo_range';
import { EditCtx } from 'hooks/edit_hook';
import { ListValue, useFormListPropEdit } from 'hooks/list_edit_hook';
import React from 'react';
import ContextUserRangeForm from './ContextUserRangeForm';

export interface Props {
    valueEdit: EditCtx<ContextForm>;
}

const ContextUserRangesForm: React.FC<Props> = (props) => {
    const [form, setFormValue] = props.valueEdit;

    const linesEditCtx = useFormListPropEdit<WazoRange>("user_ranges", form.user_ranges ?? [], setFormValue);

    return (
        <>
            <ContextUserRangeForm saveValue={(p: WazoRange) => {
                linesEditCtx.addValue(p);
            }} />
            {linesEditCtx.values.map((v: ListValue<WazoRange>, index: number) => (
                <ContextUserRangeForm
                    formKeyPath={`user_ranges.${index}`}
                    key={v.index}
                    range={v.value}
                    saveValue={(p: WazoRange) => {
                        linesEditCtx.editValue({
                            index: v.index,
                            value: p,
                        });
                    }}
                >
                    <DeleteBtn
                        style={{ top: "0.75rem", right: "0.5rem" }}
                        onClick={() => linesEditCtx.removeValue(index)}
                    ></DeleteBtn>
                </ContextUserRangeForm>
            ))}
        </>
    )
}

export default ContextUserRangesForm
