import { Device } from "data/users/devices/device";

export interface DeviceForm {
    id?: string;

    vendor?: string;
    version?: string;
    sn?: string;
    model?: string;

    ip?: string;
    plugin: string
    mac: string
}

export const defaultDeviceForm = (): DeviceForm => {
    return {
        plugin: "",
        mac: "",
    };
};

export const deviceToForm = (device: Device): DeviceForm => {
    return {
        id: device.id,
        vendor: device.vendor,
        version: device.version,
        sn: device.sn,
        model: device.model,
        ip: device.ip,
        plugin: device.plugin,
        mac: device.mac,
    };
};

export const deviceFormToBody = (device: DeviceForm): object =>�{
    return {
        id: device.id,
        plugin: device.plugin,
        mac: device.mac,
    };
};
