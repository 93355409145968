import { useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react'
import { StyledInfoDisplay, StyledSubtitle } from './Display.styled';

export interface DisplayProps extends PropsWithChildren {
    style?: any;
    align?: any;
    columnDirection?: boolean;
}

export const Subtitle: React.FC<DisplayProps> = (props) => {
    const theme = useTheme();

    return (
        <StyledSubtitle
            theme={theme}
            columnDirection={props.columnDirection}
            align={props.align}
            style={{ ...props.style }}
        >
            {props.children}
        </StyledSubtitle>
    )
}

export const InfoDisplay: React.FC<DisplayProps> = (props) => {
    const theme = useTheme();

    return (
        <StyledInfoDisplay
            theme={theme}
            columnDirection={props.columnDirection}
            align={props.align}
            style={{ ...props.style }}
        >
            {props.children}
        </StyledInfoDisplay>
    )
}
