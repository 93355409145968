import { SipTemplate } from 'data/advanced/sip_templates/sip_template';
import { defaultFilterResult } from 'data/api/filter_result';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllSipTemplates } from 'services/sip_templates_service';

export interface SipTemplateCtx {
    sipTemplates: SipTemplate[];
    refresh: () => void;
}

export const SipTemplateContext = createContext<SipTemplateCtx>({
    sipTemplates: [],
    refresh: () => { },
});

const SipTemplateContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: sipTemplates, refresh } = useCachedData("sipTemplates", getAllSipTemplates, defaultFilterResult());

    const ctx = useMemo<SipTemplateCtx>(() => {
        return {
            sipTemplates: sipTemplates.items,
            refresh: refresh,
        }
    }, [sipTemplates]);

    return (
        <SipTemplateContext.Provider value={ctx}>
            {props.children}
        </SipTemplateContext.Provider>
    )
}

export default SipTemplateContextProvider
