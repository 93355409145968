import { Typography } from '@mui/material';
import CheckboxField from 'common/fields/Checkbox';
import Tagsfield from 'common/fields/Tagsfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import Row, { NoRowPadding } from 'common/layout/Row';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { UserForm } from 'data/forms/users/users/user_form';
import { NumExtenFormat } from 'formats/common';
import { EditCtx } from 'hooks/edit_hook';
import React, { useContext } from 'react';
import UserForwardForm from './UserForwardForm';

export interface Props extends FormKeyProps {
    userEdit: EditCtx<UserForm>;
}

const UserServicesForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setFormValue] = props.userEdit;

    return (
        <>
            <Row style={{...NoRowPadding}}>
                <LeftColumn>
                    <CheckboxField
                        label={"Mode ne pas déranger"}
                        value={form.dnd ?? false}
                        name={"dnd"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
            </Row>
            <LargeRow style={{...NoRowPadding}}>
                <Typography variant='h6'>Transferts d'appels</Typography>
            </LargeRow>
            <UserForwardForm
                formKeyPath='forwards.busy'
                userEdit={props.userEdit}
                label={'Occupé'}
                value={form.forwards?.busy}
                forwardName={'busy'}
            />
            <UserForwardForm
                formKeyPath='forwards.noanswer'
                userEdit={props.userEdit}
                label={'Aucune réponse'}
                value={form.forwards?.noanswer}
                forwardName={'noanswer'}
            />
            <UserForwardForm
                formKeyPath='forwards.unconditional'
                userEdit={props.userEdit}
                label={'Inconditionel'}
                value={form.forwards?.unconditional}
                forwardName={'unconditional'}
            />
            <LargeRow style={{...NoRowPadding}}>
                <Typography variant='h6'>
                    Suivi d'appels
                </Typography>
            </LargeRow>
            <Tagsfield
                label={"Numéros suivi d'appels"}
                value={form.follow_me?.map((v) => v.number) ?? []}
                error={errorCtx.getError("follow_me", props.formKeyPath)}
                name={"follow_me"}
                format={NumExtenFormat}
                editValue={(_, v) => setFormValue("follow_me", v.map((n: string) => {
                    return {
                        number: n,
                    }
                }))}
            />
        </>
    )
}

export default UserServicesForm
