import Modal from 'common/modal/Modal';
import ModalContent from 'common/modal/ModalContent';
import EndpointSIPFormComponent from 'components/endpoints/EndpointSIPForm';
import ModalFormActionsBar from 'components/misc/ModalFormActionsBar';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultEndpointSIPForm, EndpointSIPForm, isValidSipEndpointForm } from 'data/forms/endpoints/endpoint_sip_form';
import { useEdit } from 'hooks/edit_hook';
import React, { PropsWithChildren, useEffect } from 'react';

export interface Props extends PropsWithChildren, FormKeyProps {
    endpoint?: EndpointSIPForm;
    show?: boolean;
    onExit: () => void;
    saveValue: (l: EndpointSIPForm) => void;
}

const UserLineEndpointSIPForm: React.FC<Props> = (props) => {
    const [form, setForm, reduceForm] = useEdit<EndpointSIPForm>(props.endpoint ?? defaultEndpointSIPForm());

    useEffect(() => {
        reduceForm(props.endpoint ?? defaultEndpointSIPForm());
    }, [props.show]);

    useEffect(() => {
        if (!props.endpoint) {
            return;
        }

        reduceForm({
            name: props.endpoint.name,
            label: props.endpoint.label,
            templates: props.endpoint.templates,
        });
    }, [props.endpoint]);

    return (
        <>
            {props.show &&
                <Modal onClick={() => props.onExit()}>
                    <ModalContent fixedLargeModal>
                        <EndpointSIPFormComponent
                            formKeyPath={props.formKeyPath}
                            endpoint={form}
                            setter={setForm}
                            reducer={reduceForm}
                        >
                            {props.children}
                        </EndpointSIPFormComponent>
                        <ModalFormActionsBar
                            disabled={!isValidSipEndpointForm(form)}
                            onConfirm={() => props.saveValue(form)}
                        />
                    </ModalContent>
                </Modal>
            }
        </>
    )
}

export default UserLineEndpointSIPForm
