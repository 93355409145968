import styled from '@emotion/styled'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@mui/material'
import React from 'react'

const StyledDeleteBtn = styled.div`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);
    font-weight: bold;

    :not(.disabled):hover {
        cursor: pointer;
        filter: brightness(0.75);
    }

	:is(.disabled) {
		filter: opacity(0.5);
	}
`

export interface DeleteBtnProps {
    onClick: () => void;
    style?: any;
    disabled?: boolean;
}

const DeleteBtn: React.FC<DeleteBtnProps> = (props) => {
    const theme = useTheme();
	return (
		<StyledDeleteBtn
            style={{color: theme.palette.error.main, ...props.style}}
            className={props.disabled ? 'disabled':''}
            onClick={() => {
                if (!props.disabled) {
                    props.onClick()
                }
            }}
        >
			<FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
		</StyledDeleteBtn>
	)
}

export default DeleteBtn
