import styled from '@emotion/styled';
import { NavState } from 'components/nav/NavbarContext';

export interface ContainerProps {
    theme: any;
    state: NavState;
}

export const ContainerBox = styled.div<ContainerProps>`
    display: grid;
    grid-template-columns: ${props => props.state === "visible" ? "var(--nav-size)":"0"} 5% auto 5%;
    grid-template-areas: "nav . content .";
    height: 100%;
    width: 100%;

    transition: grid-template-columns 0.3s;

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        .container {
            grid-template-columns: 10% 80% 10%;
        }
    }

    @media screen and (max-width: 600px) {
        .container {
            grid-template-columns: 10% 80% 10%;
        }
    }
`;
