import styled from '@emotion/styled'
import React, { PropsWithChildren } from 'react'

export const NoRowPadding = {
    paddingTop: "0rem",
    paddingBottom: "0rem",
} as any;

export const RowPadding = {
    paddingBottom: "0.75rem",
    paddingTop: "0.75rem",
} as any;

const StyledRow = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 47.5% auto 47.5%;
    grid-template-areas: "content1 . content2";

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        gap: 1rem;
        grid-template-columns: 100%;
        grid-template-areas: "content1"
                             "content2";
    }

    @media screen and (max-width: 600px) {
        gap: 1rem;
        grid-template-columns: 100%;
        grid-template-areas: "content1"
                             "content2";
    }
`

export interface RowProps extends PropsWithChildren {
    style?: any;
}

const Row: React.FC<RowProps> = (props) => {
  return (
    <StyledRow style={{...RowPadding, ...props.style}}>
        {props.children}
    </StyledRow>
  )
}

export default Row
