import { TrunkType } from "data/routes/trunks/trunk";
import { env } from "env/env";

export interface TrunkCreationForm {
    username: string;
    label?: string;
    password?: string;
    host: string;
    port?: number;
    account_code?: string;

    trunk_type: TrunkType;
}

export const defaultTrunkCreationForm = (username?: string): TrunkCreationForm => {
    return {
        username: username ?? "",
        host: env.defaultTrunkHost,
        trunk_type: "iax",
    };
};

export const trunkCreationFormToBody = (trunk: TrunkCreationForm): object => {
    return {
        ...trunk,
    };
};
