import { Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { PluginContext } from 'components/misc/providers/PluginCtx';
import { UserContext } from 'components/misc/providers/UserCtx';
import { defaultDeviceForm, DeviceForm, deviceToForm } from 'data/forms/users/devices/device_form';
import { Device } from 'data/users/devices/device';
import { useProviders } from 'form-hooks/providers_hook';
import { MacFormat } from 'formats/common';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useContext, useEffect } from 'react';
import { createDevice, updateDevice } from 'services/devices_service';

export interface Props extends FormKeyProps {
    editValue?: Device;
    onExit: (updated: boolean) => void;
}

const DeviceFormComponent: React.FC<Props> = (props) => {
    const { editValue } = props;
    const providers = useProviders();
    const formEdit = useEdit<DeviceForm>(defaultDeviceForm());
    const [form, setForm, reduceForm] = formEdit;
    const pluginCtx = useContext(PluginContext);
    const userCtx = useContext(UserContext);

    useEffect(() => {
        if (!props.editValue) {
            return
        }

        reduceForm(deviceToForm(props.editValue));
    }, [props.editValue]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["devices"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateDevice,
        createHandler: createDevice,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <Row>
                <LeftColumn style={{ justifyContent: "start" }}>
                    <Typography variant='h5'>Appareil</Typography>
                </LeftColumn>
            </Row>
            <Card>
                <CardBody>
                    <Row>
                        <LeftColumn>
                            <Textfield
                                required
                                name={"mac"}
                                label={"MAC"}
                                value={form?.mac}
                                editValue={setForm}
                                format={MacFormat}
                                disabled={form?.id !== undefined}
                                error={error.getError("mac")}
                            />
                        </LeftColumn>
                        <RightColumn>
                            <Selectfield
                                required
                                name={"plugin"}
                                label={"Plugin"}
                                value={form?.plugin}
                                editValue={setForm}
                                error={error.getError("plugin", props.formKeyPath)}
                                options={pluginCtx.plugins.map((p) => {
                                    return {
                                        id: p,
                                        label: p,
                                    }
                                })}
                            />
                        </RightColumn>
                    </Row>
                    {form.id &&
                        <>
                            <Row>
                                <LeftColumn>
                                    <Textfield
                                        disabled
                                        name={""}
                                        label={"IP"}
                                        value={editValue?.ip}
                                        editValue={() => { }}
                                    />
                                </LeftColumn>
                                <RightColumn>
                                    <Textfield
                                        disabled
                                        name={""}
                                        label={"Modèle"}
                                        value={editValue?.model ? `${editValue?.model ?? ""} ${editValue?.version ?? ""}` : ""}
                                        editValue={() => { }}
                                    />
                                </RightColumn>
                            </Row>
                            <Row>
                                <LeftColumn>
                                    <Textfield
                                        disabled
                                        name={""}
                                        label={"Utilisateur"}
                                        value={userCtx.getUserLabel(editValue?.user?.uuid)}
                                        editValue={() => { }}
                                    />
                                </LeftColumn>
                            </Row>
                        </>
                    }
                </CardBody>
            </Card>
            <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
            <LoadingModal isLoading={isSaving}/>
        </>
    )
}

export default DeviceFormComponent
