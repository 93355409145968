import EmbeddedScheduleForm from 'components/users/schedules/EmbeddedScheduleForm';
import { UserForm } from 'data/forms/users/users/user_form';
import { EditCtx } from 'hooks/edit_hook';
import React from 'react';

export interface Props {
    userEdit: EditCtx<UserForm>;
}

const UserScheduleForm: React.FC<Props> = (props) => {
    const [user, setUserValue] = props.userEdit;

    return (
        <>
            <EmbeddedScheduleForm
                formKeyPath='schedule'
                schedule={user.schedule}
                onChange={(s) => setUserValue("schedule", s)}
            />
        </>
    )
}

export default UserScheduleForm
