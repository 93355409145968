import Content from 'components/body/Content';
import LoadingModal from 'components/misc/LoadingModal';
import DestinationsProvider from 'components/misc/providers/DestinationsCtx';
import { Schedule } from 'data/users/schedules/schedule';
import useComponent from 'form-hooks/component_hook';
import { useFilterList } from 'hooks/list_hook';
import React from 'react'
import { getSchedules } from 'services/schedules_service';
import ScheduleFormComponent from './ScheduleForm';
import SchedulesList from './SchedulesList';

const Schedules: React.FC = (props) => {
    const schedulesList = useFilterList(getSchedules, "name");
    const { state, editValue, setState } = useComponent<Schedule>();

    return (
        <Content>
            <DestinationsProvider>
                {state === "list" &&
                    <SchedulesList
                        schedulesList={schedulesList}
                        showForm={(schedule?: Schedule) => {
                            setState("form", schedule);
                        }}
                    />
                }
                {state === "form" &&
                    <ScheduleFormComponent
                        onExit={(updated) => {
                            if (updated) {
                                schedulesList.refreshList();
                            }
                            setState("list");
                        }}
                        editValue={editValue}
                    />
                }
            </DestinationsProvider>
            <LoadingModal smallLoader isLoading={schedulesList.isLoading} />
        </Content>
    )
}

export default Schedules
