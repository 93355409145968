import { Context, ContextType } from 'data/advanced/contexts/context';
import { defaultFilterResult } from 'data/api/filter_result';
import { Line } from 'data/users/users/line';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { getAllContexts } from 'services/contexts_service';
import { LineContext } from './LineCtx';

export interface ContextCtx {
    validDefaultExtensions: Set<number>;
    contexts: Context[];
    refresh: () => void;
}

export const ContextsContext = createContext<ContextCtx>({
    validDefaultExtensions: new Set(),
    contexts: [],
    refresh: () => {},
});

export const getFirstContextOfType = (ctx: Context[], type: ContextType): Context|undefined => {
    return ctx.find(c => c.type === type);
};

export const getDefaultContext = (ctx: Context[]): Context|undefined => {
    return ctx.find(c => c.type === "internal")
};

export const getDefaultContexts = (ctx: Context[]): Context[] => {
    return ctx.filter(c => c.type === "internal")
};

export const getDefaultContextValidExtensions = (ctx: Context[], lines: Line[]): Set<number> => {
    const existingLines = new Set();
    for (const line of lines) {
        existingLines.add(line.exten);
    }

    const contexts: Context[] = ctx.filter(c => c.type === "internal");
    const extensions: Set<number> = new Set();

    for (const ctx of contexts) {
        if (!ctx.user_ranges) {
            continue;
        }

        for (const range of ctx.user_ranges) {
            for (let i = Number(range.start); i <= Number(range.end); i++) {
                if (existingLines.has(i.toString())) {
                    continue;
                }
                extensions.add(i);
            }
        }
    }

    return extensions;
};

const ContextsContextProvider: React.FC<PropsWithChildren> = (props) => {
    const linesCtx = useContext(LineContext);
    const {data: contexts, refresh} = useCachedData("contexts", getAllContexts, defaultFilterResult());

    const ctx = useMemo<ContextCtx>(() => {
        return {
            validDefaultExtensions: getDefaultContextValidExtensions(contexts.items ?? [], linesCtx.lines),
            contexts: contexts.items,
            refresh: refresh,
        }
    }, [contexts, refresh, linesCtx.lines]);

    return (
        <ContextsContext.Provider value={ctx}>
            {props.children}
        </ContextsContext.Provider>
    )
}

export default ContextsContextProvider
