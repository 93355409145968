import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Setter } from 'hooks/edit_hook';
import { Autocomplete, Box, Button, Chip, Divider, FormControl, FormHelperText, InputLabel, Paper, Select, TextField, useTheme } from '@mui/material';
import SortableChips from './draggable/SortableChips';

export interface SelectOption {
    id: string | number;
    label: string;
}

export interface SelectfieldProps extends PropsWithChildren {
    style?: any;
    label?: string;
    value: any;
    name: string;
    required?: boolean;
    error?: string;
    disabled?: boolean;
    editValue: Setter;
    options?: SelectOption[];
    autoFocus?: boolean;
    multiple?: boolean;
    draggable?: boolean;
}

const Selectfield: React.FC<SelectfieldProps> = (props) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const opt = useCallback(() => {
        const opt = props.options?.find(o => o.id === props.value);
        return opt ? opt : { id: "", label: "" };
    }, [props.options, props.value]);

    const opts = useCallback(() => {
        if (!props.multiple) {
            return [];
        }
        const values = new Set(props.value);
        return props.options?.filter(o => values.has(o.id));
    }, [props.options, props.value]);

    const selectAll = useCallback(() => {
        if (!props.multiple) {
            return
        }

        setOpen(false);
        props.editValue(props.name, props.options?.map(o => o.id) ?? []);
    }, [props.options, props.editValue, props.name, props.multiple]);

    useEffect(() => {
        setOpen(props.autoFocus ?? false);
    }, []);

    const onChange = useCallback((v: (SelectOption | SelectOption[])) => {
        if (props.disabled) {
            return;
        }

        if (props.multiple) {
            const newValue = v as SelectOption[];
            props.editValue(props.name, newValue.map(val => val.id));
        } else {
            const newValue = v as SelectOption;
            props.editValue(props.name, newValue?.id);
        }
    }, [props]);

    const deleteItem = useCallback((id: string | number) => {
        props.editValue(props.name, props.value.filter((v: any) => v !== id));
    }, [props]);

    return (
        <FormControl variant="standard" style={{ width: "100%", textAlign: "left", ...props.style }}>
            {props.options ? (
                <>
                    <Autocomplete
                        multiple={props.multiple}
                        autoFocus={props.autoFocus}
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        onChange={(_, v: any) => onChange(v)}
                        value={props.multiple ? opts() : opt()}
                        options={props.options}
                        renderOption={(optProps, option) => {
                            const opt = option as SelectOption;
                            return <li {...optProps} key={opt.id}>{opt.label}</li>
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus={props.autoFocus}
                                disabled={props.disabled}
                                error={props.error !== undefined && props.error !== ""}
                                required={props.required}
                                variant="standard"
                                label={props.label}
                            ></TextField>
                        )}
                        sx={{
                            "& .MuiInput-underline:before": { borderBottomColor: (theme) => theme.palette.text.secondary }
                        }}
                        renderTags={(value: (SelectOption | SelectOption[])[]) => {
                            const values = value as SelectOption[];
                            return props.draggable ? (
                                <SortableChips
                                    value={props.value.map((v: string) => values.find(val => val.id === v))}
                                    editValue={onChange}
                                    onDelete={deleteItem}
                                />) : (
                                <>
                                    {values.map(v => (
                                        <Chip
                                            key={v.id}
                                            label={v.label}
                                            onDelete={() => deleteItem(v.id)}
                                        />
                                    ))}
                                </>
                            );
                        }}
                        PaperComponent={(paperProps) => {
                            const { children, ...restPaperProps } = paperProps;
                            return (
                                <Paper {...restPaperProps}>
                                    {props.multiple &&
                                        <>
                                            <Box onMouseDown={(e) => e.preventDefault()} pl={0.5} py={0.5}>
                                                <Button variant="text" onClick={(e) => {
                                                    e.preventDefault();
                                                    selectAll();
                                                }}>
                                                    Tout sélectionner
                                                </Button>
                                            </Box>
                                            <Divider />
                                        </>
                                    }
                                    {children}
                                </Paper>
                            );
                        }}
                    ></Autocomplete>
                </>
            ) : (
                <>
                    {props.label && (
                        <InputLabel>{props.label} {props.required ? (
                            <span style={props.error !== undefined && props.error !== "" ? { color: theme.palette.error.main } : {}}>*</span>
                        ) : ''}</InputLabel>
                    )}
                    <Select
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                        error={props.error !== undefined && props.error !== ""}
                        required={props.required}
                        value={props.value ? props.value : ""}
                        onChange={(v) => props.editValue(props.name, v.target.value)}
                        sx={{
                            ":before": { borderBottomColor: (theme) => theme.palette.text.secondary }
                        }}
                    >
                        {props.children}
                    </Select>
                </>
            )}
            <FormHelperText style={{ color: theme.palette.error.main }}>{props.error}</FormHelperText>
        </FormControl>
    )
}

export default Selectfield
