import { useState, useContext, useCallback, useEffect } from "react";
import { handleError } from "services/api";
import { NotificationContext } from "components/Notification";
import { ApiError } from "data/api/api_error";
import { Message } from "data/api/message";
import { useQuery, useQueryClient } from "react-query";
import { AuthenticationContext, AuthenticationCtx } from "components/misc/providers/AuthCtx";
import { v4 as uuidv4 } from 'uuid';
import { useLoading } from "form-hooks/loading_hook";

export type Getter<T> = (auth: AuthenticationCtx) => Promise<T>;
export type Deleter<T> = (auth: AuthenticationCtx, val: T) => Promise<Message>;

export interface DataCtx<T> {
    data: T,
    isLoading: boolean;
    refresh: () => void,
}

export interface CachedDataCtx<T> {
    data: T,
    isLoading: boolean;
    refresh: () => void,
}

export function useData<T>(getter: Getter<T>, defaultValue: any = [], dataKey?: string): DataCtx<T> {
    const notificationHandler = useContext(NotificationContext);
    const authCtx = useContext(AuthenticationContext);
    const [key] = useState(dataKey ?? uuidv4());

    const { isLoading, data, refetch } = useQuery({
        queryKey: [key, authCtx, getter],
        queryFn: async () => {
            return getter(authCtx)
        },
        onError: (e: ApiError) => {
            handleError(e, notificationHandler, authCtx);
            if (!e.response || e.response?.status === 401) {
                return;
            }
        },
        staleTime: Infinity,
        retry: 0,
        cacheTime: 0,
    });

    const loading = useLoading(isLoading);

    useEffect(() => {
        refetch();
    }, [getter]);

    return {
        data: data ?? defaultValue,
        isLoading: loading,
        refresh: () => refetch(),
    };
}

export function useCachedData<T>(key: string, getter: Getter<T>, defaultValue: any = []): CachedDataCtx<T> {
    const notificationHandler = useContext(NotificationContext);
    const authCtx = useContext(AuthenticationContext);

    const queryClient = useQueryClient();
    const { isLoading, data } = useQuery({
        queryKey: [key, authCtx],
        queryFn: async () => {
            return getter(authCtx)
        },
        onError: (e: ApiError) => {
            handleError(e, notificationHandler, authCtx);
            if (!e.response || e.response?.status === 401) {
                return;
            }
        },
        staleTime: 600000,
        retry: 0,
        cacheTime: 600000,
        onSuccess: (data) => {
            queryClient.setQueryData([key], data);
        },
    });

    const loading = useLoading(isLoading);

    return {
        data: data ?? defaultValue,
        isLoading: loading,
        refresh: () => queryClient.invalidateQueries([key]),
    };
}

export function useDelete<T>(deleter: Deleter<T>): [(val: T) => Promise<Message>] {
    const authCtx = useContext(AuthenticationContext);

    const deleteVal = useCallback((val: T) => {
        return deleter(authCtx, val);
    }, [deleter, authCtx]);

    return [deleteVal];
}
