import { Handle, NodeProps, Position } from '@xyflow/react';
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import LeftColumn from 'common/layout/LeftColumn';
import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import Selectfield from 'common/fields/Selectfield';
import { NumericFormat } from 'formats/common';
import FlowNodeComponent from 'components/misc/flow/FlowNodeComponent';
import { useEdit } from 'hooks/edit_hook';
import { IvrForm } from 'data/forms/services/ivr/ivr_form';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { DirectoriesContext } from 'components/misc/providers/DirectoriesCtx';

export interface Props extends NodeProps, FormKeyProps {
    style?: any;
    form: IvrForm;
    onChange: (f: IvrForm) => void;
}

const IvrFlowNode: React.FC<PropsWithChildren<any>> = ({ data }) => {
    const directoriesCtx = useContext(DirectoriesContext);
    const errorCtx = useContext(ErrorContext);
    const props = data as Props;
    const [form, setForm, reduceForm] = useEdit(props.form);

    useEffect(() => {
        props.onChange(form);
    }, [form]);

    useEffect(() => {
        reduceForm(props.form);
    }, [props.form]);

    return (
        <>
            <FlowNodeComponent
                style={{
                    width: "30rem",
                }}
            >
                <LargeRow>
                    <LeftColumn>
                        <Textfield
                            required
                            label={'Nom'}
                            error={errorCtx.getError("name", props.formKeyPath)}
                            value={form.name}
                            name={'name'}
                            editValue={setForm}
                        />
                    </LeftColumn>
                </LargeRow>
                <LargeRow>
                    <LeftColumn>
                        <Selectfield
                            label={'Son'}
                            error={errorCtx.getError("menu_sound", props.formKeyPath)}
                            value={form.menu_sound}
                            name={'menu_sound'}
                            editValue={setForm}
                        />
                    </LeftColumn>
                </LargeRow>
                <LargeRow>
                    <LeftColumn>
                        <Textfield
                            label={'Description'}
                            error={errorCtx.getError("description", props.formKeyPath)}
                            value={form.description}
                            name={'description'}
                            editValue={setForm}
                        />
                    </LeftColumn>
                </LargeRow>
                <LargeRow>
                    <LeftColumn>
                        <Textfield
                            label={"Nombre maximal d'essais"}
                            error={errorCtx.getError("max_tries", props.formKeyPath)}
                            value={form.max_tries}
                            name={'max_tries'}
                            editValue={setForm}
                            format={NumericFormat}
                        />
                    </LeftColumn>
                </LargeRow>
                <LargeRow>
                    <LeftColumn>
                        <Textfield
                            label={"Temps maximal par essai (secondes)"}
                            error={errorCtx.getError("timeout", props.formKeyPath)}
                            value={form.timeout}
                            name={'timeout'}
                            editValue={setForm}
                            format={NumericFormat}
                        />
                    </LeftColumn>
                </LargeRow>
                <LargeRow>
                    <LeftColumn>
                        <Selectfield
                            multiple
                            label={"Répertoires inclus"}
                            error={errorCtx.getError("directories", props.formKeyPath)}
                            value={form.directories}
                            name={'directories'}
                            editValue={setForm}
                            options={directoriesCtx.directories.map(d => {
                                return {
                                    id: d.id as string,
                                    label: d.name,
                                }
                            })}
                        />
                    </LeftColumn>
                </LargeRow>
            </FlowNodeComponent>
            <Handle
                type="target"
                position={Position.Top}
                id="incoming"
                isConnectable={true}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="timeout"
                style={{
                    top: "16.5%",
                }}
                isConnectable={true}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="invalid"
                style={{
                    top: "49.5%",
                }}
                isConnectable={true}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="abort"
                style={{
                    top: "82.5%",
                }}
                isConnectable={true}
            />
            <Handle
                type="source"
                position={Position.Bottom}
                id="choices"
                isConnectable={true}
            />
        </>
    )
}

export default IvrFlowNode
