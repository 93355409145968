import { LocalizationProvider } from "@mui/x-date-pickers";
import { CssBaseline } from "@mui/material";
import React from "react";
import Navbar from "./components/nav/Navbar";
import NotificationContainer from "components/Notification";
import RoutesContainer from "components/Routes";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment-timezone";
import ThemeContextProvider from "themes/Theme";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthContextProvider from "components/misc/providers/AuthCtx";
import TenantContextProvider from "components/misc/providers/TenantCtx";

const queryClient = new QueryClient();

const App: React.FC = () => {
    moment.tz.setDefault("America/New_York");

    return (
        <QueryClientProvider client={queryClient}>
            <CookiesProvider>
                <ThemeContextProvider>
                    <CssBaseline />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <NotificationContainer>
                            <AuthContextProvider>
                                <TenantContextProvider>
                                    <RoutesContainer>
                                        <Navbar />
                                    </RoutesContainer>
                                </TenantContextProvider>
                            </AuthContextProvider>
                        </NotificationContainer>
                    </LocalizationProvider>
                </ThemeContextProvider>
            </CookiesProvider>
        </QueryClientProvider>
    );
}

export default App;
