import { Format } from "common/fields/format";
import { ContextType } from "data/advanced/contexts/context";

export interface TextField extends ListField {
    type: "str";
    format?: Format;
}

export interface OptionField extends ListField {
    type: "option";
    options: string[];
}

export interface ContextOptionField extends ListField {
    type: "contexts";
    default?: ContextType;
}

export interface ListField {
    type: "option"|"str"|"contexts";
    default?: string;
}

export interface KeyOption {
    key: string;
    field?: ListField;
}

export const ContextListField = (def?: ContextType): ContextOptionField => {
    return {
        type: "contexts",
        default: def,
    }
};

export const OptionListField = (options: string[], def?: string): OptionField => {
    return {
        type: "option",
        options: options,
        default: def,
    }
};

export const YesNoListField = (def: "yes" | "no" = "yes"): OptionField => {
    return {
        type: "option",
        options: ["yes", "no"],
        default: def,
    }
};

export const StrField = (def?: string, format?: Format): TextField => {
    return {
        type: "str",
        format: format,
        default: def,
    };
};
