import { defaultFilterResult } from 'data/api/filter_result';
import { Line } from 'data/users/users/line';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllLines } from 'services/lines_service';

export interface LineCtx {
    lines: Line[];
    refresh: () => void;
    getLine: (id: number) => Line | undefined;
}

export const LineContext = createContext<LineCtx>({
    lines: [],
    refresh: () => { },
    getLine: () => { return undefined },
});

const LineContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: lines, refresh } = useCachedData("lines", getAllLines, defaultFilterResult());

    const ctx = useMemo<LineCtx>(() => {
        return {
            lines: lines.items,
            refresh: refresh,
            getLine: (id: number) => {
                return lines.items.find(l => l.id === id);
            },
        }
    }, [lines]);

    return (
        <LineContext.Provider value={ctx}>
            {props.children}
        </LineContext.Provider>
    )
}

export default LineContextProvider
