import { Forwards } from "data/users/forwards";

export interface ForwardDestinationForm {
    destination: string;
    enabled: boolean;
}

export interface ForwardsForm {
    busy?: ForwardDestinationForm;
    unconditional?: ForwardDestinationForm;
    noanswer?: ForwardDestinationForm;
}

export const forwardsToForm = (forwards: Forwards): ForwardsForm => {
    return {
        busy: forwards.busy ? {
            ...forwards.busy
        }:undefined,
        unconditional: forwards.unconditional ? {
            ...forwards.unconditional
        }:undefined,
        noanswer: forwards.noanswer ? {
            ...forwards.noanswer
        }:undefined,
    };
};
