import styled from '@emotion/styled'

export interface DisplayProps {
    theme: any;
    align?: any;
    columnDirection?: boolean;
}

export const StyledSubtitle = styled.span<DisplayProps>`
    color: ${props => props.theme.palette.text.secondary};
    text-align: ${props => props.align ? props.align : 'right'};

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        text-align: center;
    }

    @media screen and (max-width: 600px) {
        text-align: center;
    }
`

export const StyledInfoDisplay = styled.span<DisplayProps>`
    position: relative;
    display: grid;
    grid-template-columns: ${props => props.columnDirection ? '100%' : '50% auto'};
    text-align: ${props => props.align ? props.align : 'left'};
    gap: ${props => props.columnDirection ? '' : '1rem'};
    width: 100%;

    span {
        white-space: normal;
        word-wrap: break-word;
        overflow: auto;
    }

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        grid-template-columns: 100%;
        gap: 0.1rem;
        text-align: center;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 100%;
        gap: 0.1rem;
        text-align: center;
    }
`;
