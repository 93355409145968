import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultLineDeviceForm, defaultLineForm, LineDeviceForm, LineForm } from 'data/forms/users/users/line_form';
import { LineType, LineTypes } from 'data/users/users/line';
import { isValueChanged, useChangeSubProp, useEdit } from 'hooks/edit_hook';
import { keyPath } from 'hooks/save_hook';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import UserLineDeviceForm from './line_types/LineDeviceForm';
import UserLineSoftphoneForm from './line_types/LineSoftphoneForm';
import UserLineEndpointSIPForm from './UserLineEndpointSIPForm';
import UserLineGeneralForm from './UserLineGeneralForm';

export interface Props extends FormKeyProps {
    extensions: Set<number>;
    line?: LineForm;
    saveValue: (l: LineForm) => void;
}

const UserLineEmbeddedForm: React.FC<PropsWithChildren<Props>> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [showEndpoint, setShowEndpoint] = useState(false);
    const [line, setValue, reduceForm] = useEdit(props.line ?? defaultLineForm());

    useEffect(() => {
        if (!isValueChanged(line, props.line)) {
            return;
        }
        console.log("changed", props.line, line)
        reduceForm(props.line ?? defaultLineForm());
    }, [props.line]);

    useEffect(() => {
        props.saveValue(line);
    }, [line]);

    const changeDevice = useChangeSubProp("device", line.device, setValue);
    const setDevice = useCallback((device: LineDeviceForm) => {
        setValue("device", device);
    }, [setValue]);

    const extensions = useMemo(() => {
        const extensions = new Set(props.extensions);
        extensions.add(Number(line.exten));
        return Array.from(extensions.values()).map((e) => {
            return {
                id: e.toString(),
                label: e.toString(),
            };
        });
    }, [props.extensions, line.exten]);

    return (
        <>
            <GridRow
                columnsForAllSmallerScreens
                templateColumns={props.line?.id ? '15% 20% 20% 40% 5%':'15% 20% 20% 45%'}
            >
                <GridColumn>
                    <Selectfield
                        required
                        error={errorCtx.getError("exten", props.formKeyPath)}
                        name={"exten"}
                        label={"Extension"}
                        value={line.exten}
                        editValue={setValue}
                        options={extensions}
                    />
                </GridColumn>
                <GridColumn>
                    <Textfield
                        error={errorCtx.getError("label", props.formKeyPath)}
                        name={"label"}
                        label={"Étiquette"}
                        value={line.label}
                        editValue={setValue}
                    />
                </GridColumn>
                <GridColumn>
                    <Selectfield
                        error={errorCtx.getError("line_type", props.formKeyPath)}
                        name={"line_type"}
                        label={"Type"}
                        value={line.line_type}
                        editValue={(_k, v: LineType) => {
                            reduceForm({
                                line_type: v,
                                device: v === "device" ? defaultLineDeviceForm():undefined,
                            });
                        }}
                        options={Object.keys(LineTypes).map(l => {
                            return {
                                id: l,
                                label: LineTypes[l],
                            };
                        })}
                    />
                </GridColumn>
                {line.line_type === "device" &&
                    <UserLineDeviceForm
                        formKeyPath={keyPath([props.formKeyPath, "device"])}
                        setter={changeDevice}
                        device={line.device}
                        saveValue={(d: LineDeviceForm) => setDevice(d)}
                    />
                }
                {line.line_type === "softphone" &&
                    <UserLineSoftphoneForm line={line} />
                }
                {line.line_type !== "device" && line.line_type !== "softphone" &&
                    <GridColumn></GridColumn>
                }
                <GridColumn align='end'>
                    {line.id !== undefined &&
                        <Button variant='text' onClick={() => setShowEndpoint(true)}>
                            <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                        </Button>
                    }
                </GridColumn>
            </GridRow>
            <UserLineEndpointSIPForm
                formKeyPath={keyPath([props.formKeyPath, "endpoint_sip"])}
                endpoint={line.endpoint_sip}
                show={showEndpoint}
                onExit={() => setShowEndpoint(false)}
                saveValue={(v) => {
                    setShowEndpoint(false);
                    setValue("endpoint_sip", v);
                }}
            >
                <UserLineGeneralForm
                    line={line}
                    onChange={(l) => {
                        reduceForm(l);
                    }}
                />
            </UserLineEndpointSIPForm>
        </>
    )
}

export default UserLineEmbeddedForm
