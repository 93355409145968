import Selectfield from 'common/fields/Selectfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import ContextsListFieldComponent from 'components/endpoints/fields/ContextListFieldComponent';
import { SipTemplateContext } from 'components/misc/providers/SipTemplateCtx';
import { ContextListField } from 'data/endpoints/key_options';
import { LineForm } from 'data/forms/users/users/line_form';
import { useChangeSubProp, useEdit, useEditHook } from 'hooks/edit_hook';
import React, { useContext, useMemo } from 'react';

export interface Props {
    line: LineForm;
    onChange: (l: LineForm) => void;
}

const UserLineGeneralForm: React.FC<Props> = (props) => {
    const sipTemplateCtx = useContext(SipTemplateContext);
    const [form, _, reduceForm] = useEdit<LineForm>(props.line);

    const changeForm = useEditHook(form, reduceForm, (newForm) => {
        props.onChange(newForm);
    });
    const changeEndpoint = useChangeSubProp("endpoint_sip", form.endpoint_sip, changeForm);

    const templates = useMemo(() => {
        return form.endpoint_sip?.templates.map((t) => t.uuid?.toString() ?? "") ?? [];
    }, [form.endpoint_sip?.templates]);

    return (
        <>
            <Row>
                <LeftColumn>
                    <ContextsListFieldComponent
                        field={ContextListField("internal")}
                        value={form.context ?? ""}
                        setter={(v: string) => {
                            changeForm("context", v);
                        }}
                    />
                </LeftColumn>
            </Row>
            <LargeRow>
                <LeftColumn>
                    <Selectfield
                        multiple
                        draggable
                        label={"Gabarits SIP"}
                        value={templates}
                        name={"templates"}
                        options={sipTemplateCtx.sipTemplates.map(s => {
                            return {
                                id: s.uuid as string,
                                label: s.label,
                            };
                        })}
                        editValue={(k, v) => {
                            changeEndpoint(k, v.map((uuid: string) => {
                                return {
                                    uuid: uuid,
                                }
                            }));
                        }}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default UserLineGeneralForm
