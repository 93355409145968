import { toWazoRef, WazoRef } from "data/misc/wazo_ref";
import { Line } from "data/users/users/line";
import { User } from "data/users/users/user";
import { FallbacksForm, fallbacksToForm } from "../fallbacks_form";
import { ForwardsForm, forwardsToForm } from "../forwards_form";
import { FuncKeyForm } from "../funckey_form";
import { ScheduleForm, scheduleFormToBody, scheduleToForm } from "../schedules/schedule_form";
import { VoicemailForm, voicemailFormToBody, voicemailToForm } from "../voicemails/voicemail_form";
import { FollowMeForm, followMeToForm } from "./follow_me_form";
import { LineForm, lineToForm } from "./line_form";

export interface UserForm {
    id?: number;
    uuid?: string;
    firstname: string;
    lastname?: string;
    description?: string;

    caller_id?: string;
    outgoing_caller_id?: string;
    voicemail?: VoicemailForm;

    original_lines: Line[];
    lines: LineForm[];
    update_lines: LineForm[];
    delete_lines: WazoRef[];

    email: string
    call_record_incoming_external_enabled?: boolean;
    call_record_incoming_internal_enabled?: boolean;
    call_record_outgoing_external_enabled?: boolean;
    call_record_outgoing_internal_enabled?: boolean;
    ring_seconds?: number;
    simultaneous_calls?: number;

    dnd?: boolean;

    forwards?: ForwardsForm;
    fallbacks?: FallbacksForm;

    schedule?: ScheduleForm;

    follow_me?: FollowMeForm[];
    funckeys: FuncKeyForm[];

    groups?: WazoRef[];
    directories?: string[];
}

export const defaultUserForm = (): UserForm => {
    return {
        id: undefined,
        uuid: undefined,
        firstname: "",
        lastname: undefined,
        description: undefined,

        caller_id: undefined,
        outgoing_caller_id: undefined,
        voicemail: undefined,
        original_lines: [],
        lines: [],
        update_lines: [],
        delete_lines: [],

        email: "",
        call_record_incoming_external_enabled: undefined,
        call_record_incoming_internal_enabled: undefined,
        call_record_outgoing_external_enabled: undefined,
        call_record_outgoing_internal_enabled: undefined,
        ring_seconds: undefined,
        simultaneous_calls: undefined,

        dnd: undefined,

        forwards: undefined,
        fallbacks: undefined,

        schedule: undefined,

        follow_me: undefined,
        funckeys: [],
        groups: [],
        directories: [],
    };
};

export const userToForm = (user: User): UserForm => {
    return {
        id: user.id,
        uuid: user.uuid,
        firstname: user.firstname,
        lastname: user.lastname,
        description: user.description,

        caller_id: user.caller_id,
        outgoing_caller_id: user.outgoing_caller_id,
        voicemail: user.voicemail_info ? voicemailToForm(user.voicemail_info) : undefined,
        original_lines: user.lines_details,
        lines: user.lines_details.map(l => lineToForm(l)),
        update_lines: [],
        delete_lines: [],

        email: user.email,
        call_record_incoming_external_enabled: user.call_record_incoming_external_enabled,
        call_record_incoming_internal_enabled: user.call_record_incoming_internal_enabled,
        call_record_outgoing_external_enabled: user.call_record_outgoing_external_enabled,
        call_record_outgoing_internal_enabled: user.call_record_outgoing_internal_enabled,
        ring_seconds: user.ring_seconds,
        simultaneous_calls: user.simultaneous_calls,

        dnd: user.dnd,

        forwards: user.forwards ? forwardsToForm(user.forwards) : undefined,
        fallbacks: user.fallbacks ? fallbacksToForm(user.fallbacks) : undefined,

        schedule: user.schedule_details ? scheduleToForm(user.schedule_details) : undefined,

        follow_me: user.follow_me ? user.follow_me.map((f) => followMeToForm(f)):[],
        funckeys: [],
        groups: user.groups,
        directories: user.directories,
    };
};

export const userFormToBody = (user: UserForm): object => {
    return {
        ...user,
        voicemail: user.voicemail ? voicemailFormToBody(user.voicemail):undefined,
        update_lines: user.lines,
        delete_lines: user.original_lines.filter((l) => user.lines.find(line => line.id === l.id) === undefined).map(l => toWazoRef(l)),
        schedule: user.schedule ? scheduleFormToBody(user.schedule) : undefined,
    };
};
