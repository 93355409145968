import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TextField } from '@mui/material';
import { Setter } from 'hooks/edit_hook';
import { Format, formatDisplayValue, formatValue, isValidFormat } from './format';

const TextfieldStyle = {
    width: "100%",
} as any;

export interface TextfieldProps {
    style?: any;
    label: string;
    value: any;
    name: string;
    type?: string;
    required?: boolean;
    error?: string;
    disabled?: boolean;
    editValue: Setter;
    InputProps?: any;
    format?: Format;
    [key: string]: any;
}

const Textfield: React.FC<TextfieldProps> = ({
    style,
    label,
    value,
    name,
    type,
    required,
    error,
    disabled,
    editValue,
    InputProps,
    format,
    ...restProps
}) => {
    const [formatError, setFormatError] = useState(false);
    const [displayValue, setDisplayValue] = useState(value);
    const inputRef = useRef<any>();

    const validateFormat = useCallback((val: any) => {
        setDisplayValue(formatDisplayValue(val, format));
        setFormatError(!isValidFormat(val, format));
    }, [format]);

    const checkValueFormat = useCallback(() => {
        const val = formatValue(value, format);
        if (val !== value) {
            editValue(name, val);
        }

        validateFormat(val);
    }, [value, format, editValue, name, validateFormat]);

    useEffect(() => {
        if (document.activeElement === inputRef.current) {
            return
        }

        validateFormat(value);
    }, [value, format, validateFormat]);

    return (
        <TextField
            error={error !== undefined && error !== ""}
            inputRef={inputRef}
            helperText={error || (formatError ? format?.errorMsg ?? "la valeur n'est pas valide":'')}
            required={required}
            style={{...TextfieldStyle,...style}}
            disabled={disabled}
            label={label}
            variant="standard"
            type={type ? type:'text'}
            value={document.activeElement === inputRef.current ? value:displayValue ?? ""}
            onChange={(v) => editValue(name, v.target.value)}
            InputProps={InputProps}
            onBlur={() => checkValueFormat()}
            onFocus={() => setDisplayValue(value)}
            sx={{
                "& .MuiInput-underline:before": { borderBottomColor: (theme) => theme.palette.text.secondary}
            }}
            {...restProps}
        >
            {restProps.children}
        </TextField>
    )
}

export default Textfield
