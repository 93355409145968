import CheckboxField from 'common/fields/Checkbox';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import VoicemailEditFormComponent from 'components/users/voicemails/VoicemailEditForm';
import { UserForm } from 'data/forms/users/users/user_form';
import { defaultVoicemailForm, VoicemailForm } from 'data/forms/users/voicemails/voicemail_form';
import { EditCtx, useChangeSubProp } from 'hooks/edit_hook';
import React, { useEffect, useState } from 'react';

export interface Props {
    userEdit: EditCtx<UserForm>;
}

const UserVoicemailForm: React.FC<Props> = (props) => {
    const [user, setUserValue] = props.userEdit;
    const [enableVoicemail, setEnableVoicemail] = useState(user.voicemail !== undefined);
    const { voicemail } = user;
    const setFormValue = useChangeSubProp("voicemail", voicemail, setUserValue);

    useEffect(() => {
        setEnableVoicemail(props.userEdit[0].voicemail != null);
    }, [props.userEdit]);

    useEffect(() => {
        if (!enableVoicemail) {
            setUserValue("voicemail", undefined);
            return
        }

        if (!user.voicemail) {
            setUserValue("voicemail", defaultVoicemailForm());
        }
    }, [enableVoicemail]);

    return (
        <>
            <Row>
                <LeftColumn>
                    <CheckboxField
                        label={"Activer la boîte vocale"}
                        value={enableVoicemail}
                        name={""}
                        editValue={(_k, v) => setEnableVoicemail(v)}
                    />
                </LeftColumn>
            </Row>
            {enableVoicemail &&
                <>
                    <VoicemailEditFormComponent
                        formKeyPath='voicemail'
                        value={voicemail as VoicemailForm}
                        setter={setFormValue}
                    />
                </>
            }
        </>
    )
}

export default UserVoicemailForm
