import useWindowDimensions from 'form-hooks/window_hook';
import { useEdit } from 'hooks/edit_hook';
import React, { createContext, PropsWithChildren, useEffect, useMemo } from 'react'

export type NavState = "collapsed"|"visible";

export interface NavCtx {
    state: NavState;
    smallScreen: boolean;
    toggleState: () => void;
    onNavigate: () => void;
    isVisible: () => boolean;
}

export const NavContext = createContext<NavCtx>({
    state: "visible",
    smallScreen: false,
    toggleState: () => {},
    onNavigate: () => {},
    isVisible: () => false,
});

type State = {
    state: NavState,
    toggled: NavState,
    smallScreen: boolean;
};

const NavbarContextProvider: React.FC<PropsWithChildren> = (props) => {
    const windowCtx = useWindowDimensions();
    const [navState, _, reduce] = useEdit<State>({
        state: "collapsed",
        toggled: "collapsed",
        smallScreen: true,
    });

    const navCtx = useMemo<NavCtx>(() => {
        const newState = navState.state === "visible" ? "collapsed":"visible";
        return {
            state: navState.state,
            smallScreen: navState.smallScreen,
            toggleState: () => {
                reduce({
                    state: newState,
                    toggled: newState,
                });
            },
            onNavigate: () => {
                if (navState.smallScreen) {
                    reduce({
                        state: "collapsed",
                        toggled: "collapsed",
                    });
                }
            },
            isVisible: () => {
                return navState.state === "visible";
            },
        }
    }, [navState]);

    useEffect(() => {
        const visible = navState.state === "visible";
        const toggledVisible = navState.toggled === "visible";
        if (visible && !toggledVisible && windowCtx.isSmallScreen()) {
            reduce({
                state: "collapsed",
                smallScreen: true,
            });
        } else if (!windowCtx.isSmallScreen() && navState.smallScreen) {
            reduce({
                state: "visible",
                smallScreen: false,
            });
        }
    }, [windowCtx]);

    return (
        <NavContext.Provider value={navCtx}>
            {props.children}
        </NavContext.Provider>
    )
}

export default NavbarContextProvider
