import Selectfield from 'common/fields/Selectfield';
import Textfield from 'common/fields/Textfield';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { TrunkContext } from 'components/misc/providers/TrunkCtx';
import { OutboundRouteForm } from 'data/forms/routes/outbound/outbound_route_form';
import { EditCtx } from 'hooks/edit_hook';
import React, { useContext } from 'react';

export interface Props extends FormKeyProps {
    valueEdit: EditCtx<OutboundRouteForm>;
}

const OutboundRouteGeneralForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const trunkCtx = useContext(TrunkContext);
    const [form, setFormValue] = props.valueEdit;

    return (
        <>
            <Row>
                <LeftColumn>
                    <Textfield
                        required
                        label={"Nom"}
                        value={form.name}
                        error={errorCtx.getError("name", props.formKeyPath)}
                        name={"name"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <Textfield
                        label={"Description"}
                        value={form.description}
                        error={errorCtx.getError("description", props.formKeyPath)}
                        name={"description"}
                        editValue={setFormValue}
                    />
                </LeftColumn>
            </Row>
            <LargeRow>
                <LeftColumn>
                    <Selectfield
                        multiple
                        draggable
                        label={"Trunks"}
                        value={form.trunks?.map(t => t.id)}
                        error={errorCtx.getError("trunks", props.formKeyPath)}
                        name={"trunks"}
                        editValue={(_, v) => {
                            setFormValue("trunks", v.map((u: number) => {
                                return {
                                    id: u,
                                };
                            }))
                        }}
                        options={trunkCtx.trunks?.map(t => {
                            return {
                                id: t.id as number,
                                label: t.name,
                            };
                        })}
                    />
                </LeftColumn>
            </LargeRow>
        </>
    )
}

export default OutboundRouteGeneralForm
