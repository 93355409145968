import Textfield from 'common/fields/Textfield';
import { TextField } from 'data/endpoints/key_options';
import React, { useEffect } from 'react';

export interface Props {
    field?: TextField;
    value: string;
    setter: (v: string) => void;
    error?: string;
}

const StrListFieldComponent: React.FC<Props> = (props) => {
    useEffect(() => {
        if (props.value !== undefined) {
            return;
        }

        if (props.field?.default) {
            props.setter(props?.field?.default);
        }
    }, [props]);

    return (
        <>
            <Textfield
                name={"value"}
                label={"Valeur"}
                error={props.error}
                value={props.value}
                format={props.field?.format}
                editValue={(_, v) => props.setter(v)}
            />
        </>
    )
}

export default StrListFieldComponent
