import { tenantUrl, get, jsonBody, post, put } from './api';
import { FilterResult } from 'data/api/filter_result';
import { Message } from 'data/api/message';
import { buildQueryString, Filter } from 'data/api/filter';
import { OutboundRoute } from 'data/routes/outbound/outbound_route';
import { OutboundRouteForm, outboundRouteFormToBody } from 'data/forms/routes/outbound/outbound_route_form';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';

const endpoint = "outcall";

export const getAllOutboundRoutes = (authCtx: AuthenticationCtx): Promise<FilterResult<OutboundRoute>> => {
    return get<FilterResult<OutboundRoute>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getOutboundRoutes = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<OutboundRoute>> => {
    return get<FilterResult<OutboundRoute>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getOutboundRoute = (authCtx: AuthenticationCtx, id: number): Promise<OutboundRoute> => {
    return get<OutboundRoute>({
        url: tenantUrl(`${endpoint}/by/id/${id}`, authCtx),
        authentication: authCtx,
    });
}

export const updateOutboundRoute = (authCtx: AuthenticationCtx, route: OutboundRouteForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${route.id}`, authCtx),
        authentication: authCtx,
    }, jsonBody(outboundRouteFormToBody(route)));
}

export const createOutboundRoute = (authCtx: AuthenticationCtx, route: OutboundRouteForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(outboundRouteFormToBody(route)));
}
