import { Tab, Typography } from '@mui/material';
import Card from 'common/card/Card';
import CardBody from 'common/card/CardBody';
import LeftColumn from 'common/layout/LeftColumn';
import Row from 'common/layout/Row';
import FormActionsBar from 'components/misc/FormActionsBar';
import LoadingModal from 'components/misc/LoadingModal';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';
import DestinationsProvider from 'components/misc/providers/DestinationsCtx';
import ErrorContextProvider, { FormKeyProps } from 'components/misc/providers/ErrorCtx';
import FormTab from 'components/misc/Tab';
import FormTabs from 'components/misc/Tabs';
import { defaultInboundRouteForm, InboundRouteForm, inboundRouteToForm } from 'data/forms/routes/inbound/inbound_route_form';
import { InboundRoute } from 'data/routes/inbound/inbound_route';
import { useProviders } from 'form-hooks/providers_hook';
import { useData } from 'hooks/data_hook';
import { useEdit } from 'hooks/edit_hook';
import { useSave } from 'hooks/save_hook';
import React, { useCallback, useEffect, useState } from 'react';
import { createInboundRoute, getInboundRoute, updateInboundRoute } from 'services/inbound_routes_service';
import InboundRouteGeneralForm from './forms/InboundRouteGeneralForm';
import InboundRouteScheduleForm from './forms/InboundRouteScheduleForm';

export interface Props extends FormKeyProps {
    editValue?: InboundRoute;
    onExit: (updated: boolean) => void;
}

const InboundRouteFormComponent: React.FC<Props> = (props) => {
    const providers = useProviders();
    const formEdit = useEdit<InboundRouteForm>(defaultInboundRouteForm());
    const [form, _, reduceForm] = formEdit;
    const [tab, setTab] = useState("general");

    const getIncallDetails = useCallback((authCtx: AuthenticationCtx): Promise<InboundRoute> => {
        if (!props.editValue?.id) {
            return new Promise(resolve => {
                resolve({
                    id: undefined,
                } as InboundRoute);
            });
        }

        return getInboundRoute(authCtx, props.editValue?.id);
    }, [props.editValue]);

    const { data: incall, isLoading } = useData(getIncallDetails, { id: undefined });
    useEffect(() => {
        if (!incall?.id) {
            return
        }

        reduceForm(inboundRouteToForm(incall));
    }, [incall]);

    const onSave = useCallback(() => {
        props.onExit(true);
        providers.refreshProviders(["schedules"]);
    }, [providers, props]);

    const { isSaving, save, error } = useSave({
        id: form.id,
        updateHandler: updateInboundRoute,
        createHandler: createInboundRoute,
        onCreate: onSave,
        onUpdate: onSave,
    });

    return (
        <>
            <ErrorContextProvider error={error}>
                <DestinationsProvider>
                    <Row>
                        <LeftColumn style={{ justifyContent: "start" }}>
                            <Typography variant='h5'>Route entrante</Typography>
                        </LeftColumn>
                    </Row>
                    <FormTabs style={{ marginTop: "1rem" }} tab={tab} setTab={setTab}>
                        <FormTab
                            value={"general"}
                            label={"Général"}
                            error={tab !== "general" && error.hasAnyErrorInPaths([
                                "exten",
                                "destination",
                            ])}
                        />
                        <FormTab
                            value={"schedule"}
                            label={"Horaire"}
                            error={tab !== "schedule" && error.hasErrorInPath("schedule")}
                        />
                    </FormTabs>
                    <Card>
                        <CardBody noTopBorderRadius>
                            {tab === "general" &&
                                <InboundRouteGeneralForm valueEdit={formEdit} />
                            }
                            {tab === "schedule" &&
                                <InboundRouteScheduleForm valueEdit={formEdit} />
                            }
                        </CardBody>
                    </Card>
                    <FormActionsBar onCancel={() => props.onExit(false)} onConfirm={() => save(form)} />
                </DestinationsProvider>
            </ErrorContextProvider>
            <LoadingModal isLoading={isLoading || isSaving} />
        </>
    )
}

export default InboundRouteFormComponent
