import { Button } from '@mui/material';
import React from 'react';

export interface Props {
    onConfirm: () => void;
    onCancel: () => void;
    okLabel?: string;
    cancelLabel?: string;
    disabled?: boolean;
}

const FormActions: React.FC<Props> = (props) => {
    return (
        <>
            <Button
                style={{ minWidth: "10rem" }}
                variant='contained'
                color="primary"
                disabled={props.disabled}
                onClick={() => props.onConfirm()}
            >
                {props.okLabel ?? "Sauvegarder"}
            </Button>
            <Button
                style={{ minWidth: "10rem" }}
                variant='outlined'
                color="secondary"
                onClick={() => props.onCancel()}
            >
                {props.cancelLabel ?? "Annuler"}
            </Button>
        </>
    )
}

export default FormActions
