import { tenantUrl, get, jsonBody, post, put } from './api';
import { buildQueryString, Filter } from 'data/api/filter';
import { FilterResult } from 'data/api/filter_result';
import { User } from 'data/users/users/user';
import { UserForm, userFormToBody } from 'data/forms/users/users/user_form';
import { Message } from 'data/api/message';
import { AuthenticationCtx } from 'components/misc/providers/AuthCtx';

const endpoint = "user";

export const getAllUsers = (authCtx: AuthenticationCtx): Promise<FilterResult<User>> => {
    return get<FilterResult<User>>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    });
}

export const getUsers = (authCtx: AuthenticationCtx, filter: Filter): Promise<FilterResult<User>> => {
    return get<FilterResult<User>>({
        url: tenantUrl(`${endpoint}/${buildQueryString(filter)}`, authCtx),
        authentication: authCtx,
    });
}

export const getUser = (authCtx: AuthenticationCtx, userUuid: string): Promise<User> => {
    return get<User>({
        url: tenantUrl(`${endpoint}/by/id/${userUuid}`, authCtx),
        authentication: authCtx,
    });
}

export const updateUser = (authCtx: AuthenticationCtx, user: UserForm): Promise<Message> => {
    return put<Message>({
        url: tenantUrl(`${endpoint}/${user.uuid}`, authCtx),
        authentication: authCtx,
    }, jsonBody(userFormToBody(user)));
}

export const createUser = (authCtx: AuthenticationCtx, user: UserForm): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}`, authCtx),
        authentication: authCtx,
    }, jsonBody(userFormToBody(user)));
}

export const createManyUsers = (authCtx: AuthenticationCtx, users: UserForm[]): Promise<Message> => {
    return post<Message>({
        url: tenantUrl(`${endpoint}/multiple`, authCtx),
        authentication: authCtx,
    }, jsonBody(users.map(user => userFormToBody(user))));
}
