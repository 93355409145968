import styled from "@emotion/styled";

export const DashboardGeneralStyled = styled.div`
    justify-content: start;
    align-content: start;
    display: grid;
    height: 100vh;
    gap: 1rem;
    grid-template-columns: calc(5% - 0.86rem) repeat(5, calc(18% - 0.86rem)) calc(5% - 0.86rem);
    grid-template-areas:
        ". save save save save save ."
        "action-left lines lines lines lines lines action-right"
        "action-left schedule schedule schedule schedule schedule action-right"
        "action-left general general general general general action-right"
        "action-left dnd followme followme voicemail voicemail action-right"
        "action-left forwards forwards forwards voicemail voicemail action-right"
        "action-left fallbacks fallbacks fallbacks fallbacks fallbacks action-right";

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        grid-template-columns: repeat(6, calc(16.66% - 0.833rem));
        grid-template-areas:
            "save save save save save save"
            "action-left action-left action-left action-right action-right action-right"
            "lines lines lines lines lines lines"
            "schedule schedule schedule schedule schedule schedule"
            "general general general general general general"
            "dnd dnd followme followme followme followme"
            "forwards forwards forwards voicemail voicemail voicemail"
            "fallbacks fallbacks fallbacks fallbacks fallbacks fallbacks";
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, calc(50% - 0.5rem));
        grid-template-areas:
            "save save"
            "action-left action-right"
            "schedule schedule"
            "lines lines"
            "general general"
            "dnd followme"
            "forwards forwards"
            "fallbacks fallbacks"
            "voicemail voicemail";
    }
`;
