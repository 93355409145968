import { Button } from '@mui/material';
import LargeRow from 'common/layout/LargeRow';
import LeftColumn from 'common/layout/LeftColumn';
import React from 'react';

export interface Props {
    onConfirm: () => void;
    disabled?: boolean;
}

const ModalFormActionsBar: React.FC<Props> = (props) => {
    return (
        <LargeRow>
            <LeftColumn style={{justifyContent: "start", gap: "1rem"}}>
                <Button
                    disabled={props.disabled}
                    style={{minWidth: "10rem"}}
                    variant='contained'
                    color="primary"
                    onClick={() => props.onConfirm()}
                >
                    OK
                </Button>
            </LeftColumn>
        </LargeRow>
    )
}

export default ModalFormActionsBar
