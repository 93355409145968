import styled from '@emotion/styled';

export interface NavSectionProps {
    showDark?: boolean;
    theme: any;
}

export const NavSection = styled.div<NavSectionProps>`
    align-items: center;
    background: ${props => props.theme.palette.background.light};
    filter: brightness(${props => props.showDark ? 0.90:1});
    color: ${props => props.theme.palette.text.secondary};
    width: 100%;
    padding: 0.5rem;

    display: grid;
    width: 100%;
    justify-content: left !important;
    margin: 0.25rem 0rem;
    grid-template-columns: 4rem auto;

    :hover {
        cursor: pointer;
        filter: brightness(0.82);
    }
`;

export const NavItems = styled.div`
    max-height: 1000rem;

    transition: max-height 1s ease-in-out;
`;

export const NavItemsHidden = styled.div`
    max-height: 0;
    overflow: hidden;

    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
`;
