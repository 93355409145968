import { useTheme } from "@mui/material";
import React, { PropsWithChildren } from "react"
import { ShadowBox } from "./Shadow.styled";

export interface Props extends PropsWithChildren {
    style?: any;
    hidden?: boolean;
    fitContent?: boolean;
}

const Shadow: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <>
        { props.hidden ? (
            <div style={{...props.style}}>
                {props.children}
            </div>
        ):(
            <ShadowBox theme={theme} fitContent={props.fitContent} style={{...props.style}}>
                {props.children}
            </ShadowBox>
        )}
        </>
    )
}

export default Shadow
