import { env } from "env/env";
import { NotificationHandler } from "components/Notification";
import { ApiError } from "data/api/api_error";
import { AuthenticationCtx } from "components/misc/providers/AuthCtx";

export const STATUS_TOO_LARGE = 413;

export const jsonContent = {
    "Content-Type": "application/json"
};

export interface RequestCtx {
    url: string;
    authentication?: AuthenticationCtx;
    headers?: any;
}

export interface RequestBody {
    type: "json";
    body: { [key: string]: any };
}

interface BodyCtx {
    body?: any;
    headers?: object;
}

export function url(path: string): string {
    return `${env.apiEndpoint}/${env.apiVersion}/${path}`;
}

export function tenantUrl(path: string, auth: AuthenticationCtx): string {
    return `${env.apiEndpoint}/${env.apiVersion}/tenant/${auth.tenantId}/${path}`;
}

export function getHeaders(token?: string, headers?: any) {
    const additionalHeaders = {};
    if (token === undefined) {
        return headers;
    }

    return {
        ...additionalHeaders,
        "Authorization": `Bearer ${token}`,
        ...headers,
    };
}

function responseToError(res: Response) {
    return res.text().then(text => {
        throw new ApiError(res, text, res.statusText);
    });
}

export function handleResponse<T>(res: Response): Promise<T> {
    if (!res.ok) {
        return responseToError(res);
    }

    return res.json().then(res => res.data);
}

export function handleBlobResponse(res: Response): Promise<Blob> {
    if (!res.ok) {
        return responseToError(res);
    }

    return res.blob() as Promise<Blob>;
}

export function handleError(error: ApiError, notificationHandler: NotificationHandler, authCtx?: AuthenticationCtx): string[] {
    if (error?.response?.status === STATUS_TOO_LARGE) {
        notificationHandler({
            message: "La requête est trop volumineuse",
            type: "error",
            timeout: 4000,
        });
    }

    return [];
}

function retry<T>(call: () => Promise<Response>, maxTries: number = 5, delayMs: number = 1050, jitterMs: number = 50): Promise<T> {
    const promise = new Promise((resolve) => {
        let tryCount = 1;
        const maxDelay = delayMs + jitterMs;
        const minDelay = delayMs - jitterMs;
        const getDelay = () => Math.floor(Math.random() * (maxDelay - minDelay + 1) + minDelay);
        const wait = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

        const tryCall = (): Promise<T> => {
            return call().then((res: Response): any => {
                if (!res.ok && tryCount < maxTries && res.status === 429) {
                    tryCount++;
                    return wait(getDelay()).then(() => tryCall());
                }

                resolve(res);
            }).then(res => res);
        }

        tryCall();
    });

    return promise.then((res: any) => handleResponse<T>(res));
}

export function get<T>(ctx: RequestCtx): Promise<T> {
    const opts = {
        headers: getHeaders(ctx.authentication?.session?.access_token, ctx.headers),
    }

    return retry(() => fetch(ctx.url, opts));
}

function formatBody(body?: RequestBody): BodyCtx {
    if (!body) {
        return {};
    }

    switch (body.type) {
        case "json":
            return {
                body: JSON.stringify(body.body),
                headers: jsonContent,
            };
    }
}

export function jsonBody(json: object): RequestBody {
    return {
        type: "json",
        body: json,
    };
}

export function post<T>(ctx: RequestCtx, body?: RequestBody): Promise<T> {
    const bodyCtx = formatBody(body);
    const opts = {
        method: "POST",
        headers: getHeaders(
            ctx.authentication?.session?.access_token,
            {...bodyCtx.headers,...ctx.headers}
        ),
        body: bodyCtx.body,
    }

    return retry(() => fetch(ctx.url, opts));
}

export function put<T>(ctx: RequestCtx, body?: RequestBody): Promise<T> {
    const bodyCtx = formatBody(body);
    const opts = {
        method: "PUT",
        headers: getHeaders(
            ctx.authentication?.session?.access_token,
            {...bodyCtx.headers,...ctx.headers}
        ),
        body: bodyCtx.body,
    }

    return retry(() => fetch(ctx.url, opts));
}

export function del<T>(ctx: RequestCtx): Promise<T> {
    const opts = {
        method: "DELETE",
        headers: getHeaders(ctx.authentication?.session?.access_token, ctx.headers),
    }

    return retry(() => fetch(ctx.url, opts));
}
