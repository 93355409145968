import { MenuItem } from '@mui/material';
import Selectfield from 'common/fields/Selectfield';
import { Setter } from 'hooks/edit_hook';
import React from 'react';

export interface Props {
    limit: number;
    setLimit: Setter;
}

const ListLimitSelect: React.FC<Props> = (props) => {
    return (
        <Selectfield
            value={props.limit}
            name='limit'
            label='Éléments par page'
            editValue={props.setLimit}
        >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={30}>30</MenuItem>
        </Selectfield>
    )
}

export default ListLimitSelect
