import { defaultFilterResult } from 'data/api/filter_result';
import { useCachedData } from 'hooks/data_hook';
import { createContext, PropsWithChildren, useMemo } from 'react'
import { getAllPlugins } from 'services/devices_service';

export interface PluginCtx {
    refresh: () => void;
    plugins: string[];
}

export const PluginContext = createContext<PluginCtx>({
    refresh: () => { },
    plugins: [],
});

const PluginContextProvider: React.FC<PropsWithChildren> = (props) => {
    const { data: plugins, refresh } = useCachedData("plugins", getAllPlugins, []);

    const ctx = useMemo<PluginCtx>(() => {
        return {
            plugins: plugins?.sort((a, b) => a.localeCompare(b)),
            refresh: refresh,
        }
    }, [plugins]);

    return (
        <PluginContext.Provider value={ctx}>
            {props.children}
        </PluginContext.Provider>
    )
}

export default PluginContextProvider
