import { MenuItem } from '@mui/material';
import CheckboxField from 'common/fields/Checkbox';
import Selectfield from 'common/fields/Selectfield';
import LeftColumn from 'common/layout/LeftColumn';
import RightColumn from 'common/layout/RightColumn';
import Row, { NoRowPadding } from 'common/layout/Row';
import { VoicemailDestination } from 'data/misc/destinations';
import { useEdit } from 'hooks/edit_hook';
import React, { useContext, useEffect } from 'react';
import { DestinationContext } from '../providers/DestinationFormContext';
import { ErrorContext, FormKeyProps } from '../providers/ErrorCtx';

export interface Props extends FormKeyProps {
    value: VoicemailDestination | undefined;
    onEdit: (d: VoicemailDestination) => void;
}

const VoicemailDestinationForm: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const destinationCtx = useContext(DestinationContext);
    const [value, setValue, reduceValue] = useEdit({
        type: "voicemail",
        voicemail_id: props.value?.voicemail_id,
        greeting: props.value?.greeting ?? "busy",
        skip_instructions: props.value?.skip_instructions,
    } as VoicemailDestination);

    useEffect(() => {
        reduceValue({
            voicemail_id: props.value?.voicemail_id,
            greeting: props.value?.greeting ?? "busy",
            skip_instructions: props.value?.skip_instructions,
        });
    }, [props.value]);

    useEffect(() => {
        props.onEdit(value);
    }, [value]);

    return (
        <>
            <Row style={NoRowPadding}>
                <LeftColumn>
                    <Selectfield
                        required
                        label={"Boîte vocale"}
                        value={value?.voicemail_id}
                        error={errorCtx.getError("voicemail_id", props.formKeyPath)}
                        name={"voicemail_id"}
                        editValue={setValue}
                        options={destinationCtx.voicemails.map(v => {
                            return {
                                id: v.id as number,
                                label: `${v.number} - ${v.name}`,
                            };
                        })}
                    >
                    </Selectfield>
                </LeftColumn>
                <RightColumn>
                    <Selectfield
                        label={"Message"}
                        value={value?.greeting}
                        error={errorCtx.getError("greeting", props.formKeyPath)}
                        name={"greeting"}
                        editValue={setValue}
                    >
                        <MenuItem value={"busy"}>Occupé</MenuItem>
                        <MenuItem value={"unavailable"}>Non disponible</MenuItem>
                    </Selectfield>
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>
                    <CheckboxField
                        label={"Sauter les instructions"}
                        value={value?.skip_instructions ?? false}
                        name={"skip_instructions"}
                        editValue={setValue}
                    />
                </LeftColumn>
            </Row>
        </>
    )
}

export default VoicemailDestinationForm
