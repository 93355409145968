import { Typography } from '@mui/material';
import Selectfield from 'common/fields/Selectfield';
import GridColumn from 'common/layout/GridColumn';
import GridRow from 'common/layout/GridRow';
import {
    Destination,
    DestinationTypes,
    ExtensionDestination,
    GroupDestination,
    HangupDestination,
    IvrDestination,
    SoundDestination,
    UserDestination,
    VoicemailDestination
} from 'data/misc/destinations';
import { keyPath } from 'hooks/save_hook';
import React, { useEffect, useState } from 'react';
import { FormKeyProps } from '../providers/ErrorCtx';
import ExtensionDestinationForm from './ExtensionDestinationForm';
import GroupDestinationForm from './GroupDestinationForm';
import HangupDestinationForm from './HangupDestinationForm';
import IvrDestinationForm from './IvrDestinationForm';
import SoundDestinationForm from './SoundDestinationForm';
import UserDestinationForm from './UserDestinationForm';
import VoicemailDestinationForm from './VoicemailDestinationForm';

export interface Props extends FormKeyProps {
    label: string;
    value: Destination | undefined;
    onEdit: (d: Destination) => void;
    smallLabel?: boolean;
    separateRowLabel?: boolean;
}

export interface LabelProps {
    label: string;
    type?: string;
    smallLabel?: boolean;
    separateRowLabel?: boolean;
    setType: (type: any) => void;
}

const LabelRow: React.FC<LabelProps> = (props) => {
    return (
        <GridRow templateColumns={props.smallLabel ? '100%' : '40% 60%'}>
            {!props.smallLabel &&
                <GridColumn style={{ alignItems: "start", justifyContent: "start", textAlign: "start" }}>
                    <Typography variant="h6">
                        {props.label}
                    </Typography>
                </GridColumn>
            }
            <GridColumn style={{ alignItems: "start", justifyContent: "start" }}>
                <Selectfield
                    label={props.smallLabel ? props.label : "Destination"}
                    value={props.type ?? "none"}
                    name={""}
                    editValue={(_, v) => props.setType(v)}
                    options={Object.keys(DestinationTypes).map((k: string) => {
                        return {
                            label: DestinationTypes[k],
                            id: k,
                        };
                    })}
                ></Selectfield>
            </GridColumn>
        </GridRow>
    );
};

const DestinationForm: React.FC<Props> = (props) => {
    const [type, setType] = useState(props.value?.type);

    useEffect(() => {
        if (props.value?.type !== type) {
            const type = props.value?.type ?? "none";
            setType(type);
        }
    }, [props.value]);

    useEffect(() => {
        if (type !== "none") {
            return;
        }
        props.onEdit({ type: "none" });
    }, [type]);

    return (
        <>
            {props.separateRowLabel &&
                <LabelRow
                    label={props.label}
                    type={type}
                    setType={setType}
                    smallLabel={props.smallLabel}
                    separateRowLabel={props.separateRowLabel}
                />
            }
            <GridRow templateColumns={props.separateRowLabel ? '100%' : '48% 3.5% 48.5%'}>
                {!props.separateRowLabel &&
                    <>
                        <LabelRow
                            label={props.label}
                            type={type}
                            setType={setType}
                            smallLabel={props.smallLabel}
                            separateRowLabel={props.separateRowLabel}
                        />
                        <GridColumn></GridColumn>
                    </>
                }
                <GridColumn columnDirection style={{ alignItems: "start", justifyContent: "start" }}>
                    {type === "hangup" &&
                        <HangupDestinationForm
                            formKeyPath={keyPath([props.formKeyPath, "hangup"])}
                            value={props.value as HangupDestination}
                            onEdit={props.onEdit} />
                    }
                    {type === "extension" &&
                        <ExtensionDestinationForm
                            formKeyPath={keyPath([props.formKeyPath, "extension"])}
                            value={props.value as ExtensionDestination}
                            onEdit={props.onEdit} />
                    }
                    {type === "group" &&
                        <GroupDestinationForm
                            formKeyPath={keyPath([props.formKeyPath, "group"])}
                            value={props.value as GroupDestination}
                            onEdit={props.onEdit} />
                    }
                    {type === "ivr" &&
                        <IvrDestinationForm
                            formKeyPath={keyPath([props.formKeyPath, "ivr"])}
                            value={props.value as IvrDestination}
                            onEdit={props.onEdit} />
                    }
                    {type === "sound" &&
                        <SoundDestinationForm
                            formKeyPath={keyPath([props.formKeyPath, "sound"])}
                            value={props.value as SoundDestination}
                            onEdit={props.onEdit} />
                    }
                    {type === "user" &&
                        <UserDestinationForm
                            formKeyPath={keyPath([props.formKeyPath, "user"])}
                            value={props.value as UserDestination}
                            onEdit={props.onEdit} />
                    }
                    {type === "voicemail" &&
                        <VoicemailDestinationForm
                            formKeyPath={keyPath([props.formKeyPath, "voicemail"])}
                            value={props.value as VoicemailDestination}
                            onEdit={props.onEdit} />
                    }
                </GridColumn>
            </GridRow>
        </>
    )
}

export default DestinationForm
