import React, { useEffect } from 'react';
import { isValueChanged, Setter, useEdit } from 'hooks/edit_hook';
import Textfield from './Textfield';
import { CallerIDNameFormat, NumExtenFormat } from 'formats/common';

export interface CallerId {
    name: string;
    num: string;
}

export interface Props {
    style?: any;
    label: string;
    value: any;
    name: string;
    required?: boolean;
    error?: string;
    disabled?: boolean;
    editValue: Setter;
    onlyName?: boolean;
}

export const formatAndSplitCallerId = (callerid: string) => {
    if (!callerid) {
        return ["", ""];
    }

    const values = callerid.split("<")
        .filter(v => v !== "")
        .map(v => v.replaceAll(/["><]/g, ""));
    return values;
};

export const formatAndCombineCallerid = (callerid: CallerId) => {
    if (!callerid) {
        return "";
    }

    return `"${callerid.name.trim()}"${callerid.num.trim() ? '<' + callerid.num.trim() + '>' : ''}`;
};

const CallerID: React.FC<Props> = ({
    value,
    name,
    label,
    style,
    required,
    error,
    disabled,
    editValue,
    onlyName,
    ...restProps
}) => {
    const [form, setForm, reduceForm] = useEdit<CallerId>({
        name: "",
        num: "",
    });

    useEffect(() => {
        const split = formatAndSplitCallerId(value);
        if ((split.length > 0 && split[0] !== form.name) || (split.length > 1 && split[1] !== form.num)) {
            reduceForm({
                name: split[0],
                num: split.length > 1 ? split[1] : "",
            });
        }
    }, [value]);

    useEffect(() => {
        if (!form || !form.name) {
            return;
        }

        const formattedForm = formatAndCombineCallerid(form);
        if (isValueChanged(formattedForm, value)) {
            editValue(name, formatAndCombineCallerid(form));
        }
    }, [form]);

    return (
        <>
            <Textfield
                value={form.name}
                name={"name"}
                label={`Nom ${label}`}
                style={style}
                required={required}
                error={error}
                disabled={disabled}
                editValue={setForm}
                format={CallerIDNameFormat}
                {...restProps}
            />
            {!onlyName &&
                <Textfield
                    value={form.num}
                    name={"num"}
                    label={`Numéro ${label}`}
                    style={style}
                    required={required}
                    error={error}
                    disabled={disabled}
                    editValue={setForm}
                    format={NumExtenFormat}
                    {...restProps}
                />
            }
        </>
    );
};

export default CallerID;
