import EndpointSIPFormComponent from 'components/endpoints/EndpointSIPForm';
import { ErrorContext, FormKeyProps } from 'components/misc/providers/ErrorCtx';
import { defaultEndpointSIPForm } from 'data/forms/endpoints/endpoint_sip_form';
import { TrunkForm } from 'data/forms/routes/trunks/trunk_form';
import { EditCtx, useChangeSubProp, useReduceSubProp } from 'hooks/edit_hook';
import React, { useContext } from 'react';
import TrunkSIPGeneralFormComponent from './TrunkSIPGeneralForm';

export interface Props extends FormKeyProps {
    formEdit: EditCtx<TrunkForm>;
}

const TrunkSIPFormComponent: React.FC<Props> = (props) => {
    const errorCtx = useContext(ErrorContext);
    const [form, setForm] = props.formEdit;

    const endpoint = form.endpoint_sip ?? defaultEndpointSIPForm();
    const setEndpointForm = useChangeSubProp("endpoint_sip", form.endpoint_sip, setForm);
    const reduceEndpointForm = useReduceSubProp("endpoint_sip", form.endpoint_sip, setForm);

    return (
        <>
            <EndpointSIPFormComponent
                endpoint={endpoint}
                setter={setEndpointForm}
                reducer={reduceEndpointForm}
                errorGeneral={errorCtx.hasAnyErrorInPaths([
                    "name",
                    "label",
                    "context",
                    "endpoint_sip.templates",
                    "host",
                    "port",
                ])}
            >
                <TrunkSIPGeneralFormComponent formEdit={props.formEdit}/>
            </EndpointSIPFormComponent>
        </>
    )
}

export default TrunkSIPFormComponent
